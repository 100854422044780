import mapboxgl from 'mapbox-gl';

import {bind, isNumber, isTap, pixelString} from '../../util';
import {getLogger} from '../../logging';
import {AbstractObj, OBJ, Obj, SIGNAL, SLOT} from '../../obj';
import {Feature, FeatureCollection, Geometry} from 'geojson';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {DEFAULT_MAP_STYLE_URL, InteractiveMapControlMode} from '../../constants';
import {Control, InfoControl, StyleControl} from './controls';
import {MapboxDraw} from './draw';
import {DrawEventType, DrawMode} from './draw/constants';
import {GeoCoordinate, GeoRectangle, list, Point, set} from '../../tools';

const logger = getLogger('ui.map');
type Anchor = mapboxgl.Anchor;
export type LngLatBounds = mapboxgl.LngLatBounds;
export type GeoJSONSource = mapboxgl.GeoJSONSourceRaw;
export type Layer =
	| mapboxgl.BackgroundLayer
	| mapboxgl.CircleLayer
	| mapboxgl.FillExtrusionLayer
	| mapboxgl.FillLayer
	| mapboxgl.HeatmapLayer
	| mapboxgl.HillshadeLayer
	| mapboxgl.LineLayer
	| mapboxgl.RasterLayer
	| mapboxgl.SymbolLayer
	| mapboxgl.SkyLayer;
export type SourceData = mapboxgl.AnySourceData;

export enum MapCtrlEventType {
	Load = 'load',
	MouseClick = 'click',
	MouseDoubleClick = 'dblclick',
	MouseDown = 'mousedown',
	MouseEnter = 'mouseenter',
	MouseLeave = 'mouseleave',
	MouseMove = 'mousemove',
	MoveEnd = 'moveend',
	PitchEnd = 'pitchend',
	RotateEnd = 'rotateend',
	SourceData = 'sourcedata',
	TouchCancel = 'touchcancel',
	TouchEnd = 'touchend',
	TouchMove = 'touchmove',
	TouchStart = 'touchstart',
	Zoom = 'zoom',
	ZoomEnd = 'zoomend',
}

export interface IMapCamera {
	bearing: number;
	// Remember: center is [longitude, latitude]
	center: [number, number];
	pitch: number;
	zoom: number;
}

export interface IMapOpts extends IMapCamera {
	style: string;
}

export interface IMarkerOpts {
	anchor: Anchor;
	clickTolerance: number;
	color: string;
	draggable: boolean;
	el: ElObj | HTMLElement;
	offset: Point;
	rotation: number;
	scale: number;
}

interface IPopupOpts {
	anchor: Anchor;
	closeButton: boolean;
	closeOnClick: boolean;
	closeOnMove: boolean;
	cssClassName: string;
	el: ElObj | HTMLElement;
	focusAfterOpen: boolean;
	html: string;
	maxWidth: number | string;
	text: string;
}

interface DrawCtrlCreateEvent<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties> {
	features: Array<GeoJsonFeature<G, P>>;
	type: DrawEventType.Create;
}

interface DrawCtrlDeleteEvent<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties> {
	features: Array<GeoJsonFeature<G, P>>;
	type: DrawEventType.Delete;
}

interface DrawCtrlModeChangeEvent {
	mode: string;
	type: DrawEventType.ModeChange;
}

interface DrawCtrlSelectionChangeEvent<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties> {
	features: Array<GeoJsonFeature<G, P>>;
	points: Array<GeoJsonFeature<GeoJsonPoint, {}>>;
	type: DrawEventType.SelectionChange;
}

interface DrawCtrlUpdateEvent<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties> {
	action: 'change_coordinates' | 'move';
	features: Array<GeoJsonFeature<G, P>>;
	type: DrawEventType.Update;
}

@OBJ
export class GeoMap extends Obj {
	protected activeMapEventTypes: set<string>;
	protected activeMapEventTypesAtTimeOfDisable: set<string>;
	private awaitingStyleLoad: boolean;
	private controls: Map<InteractiveMapControlMode, Control>;
	private debugEl: DebugEl | null;
	private debugEnabled: boolean;
	private disabled: boolean;
	private draw: MapboxDraw | null;
	private iCtrlMode: InteractiveMapControlMode;
	protected map: mapboxgl.Map | null;
	protected mapLayers: list<{layer: Layer; beforeLayerId?: string}>;
	private mapLoaded: boolean;
	private mapLoading: boolean;
	private mapSources: list<{id: string; source: SourceData;}>;
	private pendingDebugEnabled: boolean | null;
	private pendingLayers: Array<{layer: Layer; beforeLayerId?: string}>;
	private pendingSourceData: Array<{id: string; data: Feature | FeatureCollection}>;
	private pendingSources: Array<{id: string; source: SourceData}>;
	private popups: list<mapboxgl.Popup>;
	private touchStartInfo: IPointerEventInfo;

	constructor(parent: AbstractObj, debug?: boolean);
	constructor(parent: AbstractObj);
	constructor(debug: boolean);
	constructor(a?: AbstractObj | boolean, b?: boolean) {
		let debug: boolean = false;
		let parent: AbstractObj | null = null;
		if (a !== undefined) {
			if (a instanceof AbstractObj) {
				parent = a;
			} else {
				debug = a;
			}
		}
		if (b !== undefined) {
			debug = b;
		}
		super(parent);
		this.activeMapEventTypes = new set<string>();
		this.activeMapEventTypesAtTimeOfDisable = new set<string>();
		this.awaitingStyleLoad = false;
		this.controls = new Map<InteractiveMapControlMode, InfoControl>();
		this.debugEl = null;
		this.debugEnabled = false;
		this.disabled = false;
		this.draw = null;
		this.iCtrlMode = InteractiveMapControlMode.NoMode;
		this.map = null;
		this.mapLayers = new list<{layer: Layer, beforeLayerId?: string}>();
		this.mapLoaded = false;
		this.mapLoading = false;
		this.mapSources = new list<{id: string, source: SourceData}>();
		this.pendingDebugEnabled = null;
		this.pendingLayers = [];
		this.pendingSourceData = [];
		this.pendingSources = [];
		this.popups = new list<mapboxgl.Popup>();
		this.touchStartInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
		this.setDebugEnabled(debug);
	}

	addFeature(feature: Feature | FeatureCollection | Geometry): void {
		if (this.draw) {
			this.draw.add(feature);
		} else {
			logger.warning('addFeature: Draw controller is not defined.');
		}
	}

	addLayer(layer: Layer, beforeLayerId?: string): void {
		this.mapLayers.append({layer, beforeLayerId});
		if (this.mapLoaded && this.map) {
			this.map.addLayer(layer, beforeLayerId);
		} else {
			this.pendingLayers.push({layer, beforeLayerId});
		}
	}

	protected addMapEventListeners(types: Array<string>): void {
		if (this.map) {
			for (const type of types) {
				if (!this.activeMapEventTypes.has(type)) {
					this.map.on(type, this.mapEvent);
					this.activeMapEventTypes.add(type);
				}
			}
		}
	}

	addMarker(coord: GeoCoordinate, opts: Partial<IMarkerOpts>): mapboxgl.Marker | null {
		if (this.map) {
			const el: HTMLElement | undefined = (opts.el instanceof ElObj) ?
				(<HTMLElement | null>opts.el.element() || undefined) :
				opts.el;
			const offset: [number, number] | undefined = opts.offset ?
				[opts.offset.x(), opts.offset.y()] :
				undefined;
			const o: mapboxgl.MarkerOptions = {
				anchor: opts.anchor,
				clickTolerance: opts.clickTolerance,
				color: opts.color,
				draggable: opts.draggable,
				element: el,
				offset: offset,
				rotation: opts.rotation,
				scale: opts.scale,
			};
			return (new mapboxgl.Marker(o))
				.setLngLat(geoCoordinateToLngLat(coord))
				.addTo(this.map);
		}
		return null;
	}

	addSource(id: string, source: SourceData) {
		this.mapSources.append({id, source});
		if (this.mapLoaded && this.map) {
			this.map.addSource(id, source);
		} else {
			this.pendingSources.push({id, source});
		}
	}

	bbox(): LngLatBounds {
		if (this.map) {
			return this.map.getBounds();
		}
		return new mapboxgl.LngLatBounds();
	}

	camera(): IMapCamera | null {
		if (this.map) {
			return {
				bearing: this.map.getBearing(),
				// Remember: center is [longitude, latitude]
				center: <[number, number]>this.map.getCenter().toArray(),
				pitch: this.map.getPitch(),
				zoom: this.map.getZoom(),
			};
		}
		return null;
	}

	@SIGNAL
	cameraChanged(): void {
	}

	closeAllPopups(): void {
		this.destroyPopups();
	}

	@SLOT
	private controlButtonClicked(mode: InteractiveMapControlMode): void {
		const ctrl = this.controlForMode(mode);
		if (!ctrl) {
			logger.warning('controlButtonClicked: Mode %s does not have an associated control.', mode);
			return;
		}
		switch (mode) {
			case InteractiveMapControlMode.InfoMode: {
				this.setInteractiveControlMode(ctrl.isActive() ?
					InteractiveMapControlMode.NoMode :
					InteractiveMapControlMode.InfoMode);
				break;
			}
			case InteractiveMapControlMode.StylePickerMode: {
				(<StyleControl>ctrl).setNextStyle();
				break;
			}
			default:
				logger.warning('controlButtonClicked: Unhandled control mode: %s', mode);
				break;
		}
	}

	protected controlForMode(mode: InteractiveMapControlMode): Control | null {
		return this.controls.get(mode) || null;
	}

	private deactivateDraw(): void {
		if (this.draw) {
			this.draw.changeMode(DrawMode.Static, {silent: true});
		}
	}

	destroy(): void {
		if (this.debugEl) {
			this.debugEl.destroy();
		}
		this.debugEl = null;
		this.destroyPopups();
		this.destroyMap();
		this.destroyMapControls();
		this.activeMapEventTypes.clear();
		this.activeMapEventTypesAtTimeOfDisable.clear();
		this.awaitingStyleLoad = false;
		this.debugEnabled = false;
		this.iCtrlMode = InteractiveMapControlMode.NoMode;
		this.mapLayers.clear();
		this.mapLoaded = false;
		this.mapLoading = false;
		this.mapSources.clear();
		this.pendingDebugEnabled = null;
		this.pendingLayers = [];
		this.pendingSourceData = [];
		this.pendingSources = [];
		// After map is destroyed
		this.activeMapEventTypes.clear();
		this.touchStartInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
		super.destroy();
	}

	protected destroyMapControls(): void {
		for (const obj of this.controls.values()) {
			obj.destroy();
		}
		this.controls.clear();
	}

	protected destroyMap(): void {
		if (this.map) {
			this.removeMapEventListeners();
			this.map.remove();
		}
		this.map = null;
	}

	protected destroyPopups(): void {
		for (const obj of this.popups) {
			obj.remove();
		}
		this.popups.clear();
	}

	protected drawCreateEvent(event: DrawCtrlCreateEvent): void {
		this.featuresCreated(event.features);
	}

	protected drawDeleteEvent(event: DrawCtrlDeleteEvent): void {
		this.featuresDeleted(event.features);
	}

	protected drawSelectionChangeEvent(event: DrawCtrlSelectionChangeEvent): void {
		this.featureSelectionChanged();
	}

	protected drawUpdateEvent(event: DrawCtrlUpdateEvent): void {
		this.featuresUpdated(event.features);
	}

	private drawModeChangeEvent(event: DrawCtrlModeChangeEvent): void {
		let nextMode: InteractiveMapControlMode = InteractiveMapControlMode.NoMode;
		switch (event.mode) {
			case DrawMode.SimpleSelect:
				nextMode = InteractiveMapControlMode.ShapeSelect;
				break;
			case DrawMode.DirectSelect:
				nextMode = InteractiveMapControlMode.ShapeSelect;
				break;
			case DrawMode.Static:
				nextMode = InteractiveMapControlMode.NoMode;
				break;
			case DrawMode.DrawPolygon:
			case DrawMode.DrawCircle:
			case DrawMode.DrawPoint:
			case DrawMode.DrawLineString:
				nextMode = InteractiveMapControlMode.DrawShape;
				break;
			default:
				logger.error('drawModeChangeEvent: Invalid mode "%s"', event.mode);
				break;
		}
		this.setInteractiveControlMode(nextMode);
	}

	@SIGNAL
	featuresCreated<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
	}

	@SIGNAL
	featuresDeleted<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
	}

	@SIGNAL
	featureSelectionChanged(): void {
	}

	@SIGNAL
	featuresUpdated<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
	}

	fitViewportToGeoShape(bounds: GeoRectangle, opts?: mapboxgl.FitBoundsOptions): void {
		if (this.map) {
			this.map.fitBounds(
				geoRectangleToLngLatBounds(bounds),
				opts);
		}
	}

	protected hasMapLayer(layerId: string): boolean {
		return Boolean(this.mapLayer(layerId));
	}

	hasPopupsOpen(): boolean {
		return !this.popups.isEmpty();
	}

	private initDebugEl(): void {
		if (!this.debugEl) {
			this.debugEl = new DebugEl({
				parent: ElObj.body(),
			});
		}
	}

	interactiveControlMode(): InteractiveMapControlMode {
		return this.iCtrlMode;
	}

	@SIGNAL
	private interactiveControlModeChanged(mode: InteractiveMapControlMode): void {
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isLoaded(): boolean {
		return this.mapLoaded;
	}

	load(root: HTMLElement | string, opts?: Partial<IMapOpts>): void {
		if (this.mapLoaded || this.mapLoading) {
			return;
		}
		this.mapLoading = true;
		opts = opts || {};
		const styleUrl: string = opts.style || DEFAULT_MAP_STYLE_URL;
		this.map = new mapboxgl.Map(this.mapOptions(root, opts));
		this.map.addControl(new mapboxgl.AttributionControl({
				compact: true,
			}),
			'bottom-right');
		this.map.addControl(new mapboxgl.NavigationControl({
				showZoom: true,
				showCompass: false,
			}),
			'top-right');
		this.draw = new MapboxDraw({
			controls: {
				circle: true,
				combine_features: false,
				line_string: false,
				point: false,
				polygon: true,
				trash: true,
				uncombine_features: false,
			},
			defaultMode: DrawMode.Static,
			keybindings: false,
		});
		this.map.addControl(this.draw, 'top-right');
		const ignoreCtrl = new InfoControl();
		this.controls.set(InteractiveMapControlMode.InfoMode, ignoreCtrl);
		Obj.connect(
			ignoreCtrl, 'buttonClicked',
			this, 'controlButtonClicked');
		this.map.addControl(ignoreCtrl, 'top-right');
		const styleCtrl = new StyleControl({currentStyleUrl: styleUrl});
		this.controls.set(InteractiveMapControlMode.StylePickerMode, styleCtrl);
		Obj.connect(
			styleCtrl, 'buttonClicked',
			this, 'controlButtonClicked');
		Obj.connect(
			styleCtrl, 'styleChanged',
			this, 'styleControlStyleChanged');
		this.map.addControl(styleCtrl, 'bottom-right');
		this.addMapEventListeners([
			MapCtrlEventType.Load,
			MapCtrlEventType.MouseClick,
			MapCtrlEventType.MouseDoubleClick,
			MapCtrlEventType.TouchCancel,
			MapCtrlEventType.TouchEnd,
			MapCtrlEventType.TouchStart,
			MapCtrlEventType.MoveEnd,
			MapCtrlEventType.PitchEnd,
			MapCtrlEventType.RotateEnd,
			MapCtrlEventType.ZoomEnd,
			DrawEventType.Create,
			DrawEventType.Delete,
			DrawEventType.ModeChange,
			DrawEventType.SelectionChange,
			DrawEventType.Update,
		]);
	}

	@SIGNAL
	loaded(): void {
	}

	@SIGNAL
	mapClicked(point: Point, coord: GeoCoordinate): void {
	}

	@bind
	private mapEvent(event: {type: string;}): void {
		switch (event.type) {
			case MapCtrlEventType.MouseMove:
				this.mapMouseMoveEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case MapCtrlEventType.TouchMove:
				this.mapTouchMoveEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case MapCtrlEventType.Zoom:
				this.mapZoomEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case MapCtrlEventType.MoveEnd:
				this.mapMoveEndEvent();
				break;
			case MapCtrlEventType.MouseClick:
				this.mapMouseClickEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case MapCtrlEventType.TouchStart:
				this.mapTouchStartEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case MapCtrlEventType.TouchEnd:
				this.mapTouchEndEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case MapCtrlEventType.TouchCancel:
				this.mapTouchCancelEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case MapCtrlEventType.MouseDoubleClick:
				this.mapMouseDoubleClickEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case MapCtrlEventType.PitchEnd:
				this.mapPitchEndEvent();
				break;
			case MapCtrlEventType.RotateEnd:
				this.mapRotateEndEvent();
				break;
			case MapCtrlEventType.ZoomEnd:
				this.mapZoomEndEvent();
				break;
			case DrawEventType.Update:
				this.drawUpdateEvent(<DrawCtrlUpdateEvent>event);
				break;
			case DrawEventType.SelectionChange:
				this.drawSelectionChangeEvent(<DrawCtrlSelectionChangeEvent>event);
				break;
			case DrawEventType.ModeChange:
				this.drawModeChangeEvent(<DrawCtrlModeChangeEvent>event);
				break;
			case DrawEventType.Create:
				this.drawCreateEvent(<DrawCtrlCreateEvent>event);
				break;
			case DrawEventType.Delete:
				this.drawDeleteEvent(<DrawCtrlDeleteEvent>event);
				break;
			case MapCtrlEventType.Load:
				this.mapLoadEvent(<mapboxgl.MapboxEvent>event);
				break;
			default:
				logger.warning('GeoMap::mapEvent: Invalid event type %s', event.type);
				break;
		}
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
		this.mapLoaded = true;
		this.mapLoading = false;
		moveAttrib();
		if (this.map) {
			if (this.pendingSources.length > 0) {
				for (let i = 0; i < this.pendingSources.length; ++i) {
					const item = this.pendingSources[i];
					this.map.addSource(item.id, item.source);
				}
			}
			this.pendingSources = [];
			if (this.pendingLayers.length > 0) {
				for (let i = 0; i < this.pendingLayers.length; ++i) {
					const item = this.pendingLayers[i];
					this.map.addLayer(item.layer, item.beforeLayerId);
				}
			}
			this.pendingLayers = [];
			if (this.pendingSourceData.length > 0) {
				for (let i = 0; i < this.pendingSourceData.length; ++i) {
					const obj = this.pendingSourceData[i];
					const src = <mapboxgl.GeoJSONSource>this.map.getSource(obj.id);
					src.setData(obj.data);
				}
			}
			this.pendingSourceData = [];
		}
		if ((typeof this.pendingDebugEnabled === 'boolean') && !this.disabled) {
			this.setDebugEnabled(this.pendingDebugEnabled);
		}
		this.pendingDebugEnabled = null;
		!this.disabled && this.draw && this.draw.setEnabled(true);
		this.loaded();
	}

	protected mapMouseClickEvent(event: mapboxgl.MapMouseEvent): void {
		this.mapPointerClickEvent(event);
	}

	protected mapMouseDoubleClickEvent(event: mapboxgl.MapMouseEvent): void {
		const {x, y} = event.point;
		const {lat, lng} = event.lngLat;
		this.mapDoubleClicked(new Point(x, y), new GeoCoordinate(lat, lng));
	}

	protected mapMouseMoveEvent(event: mapboxgl.MapMouseEvent): void {
		this.mapPointerMoveEvent(event);
	}

	protected mapMoveEndEvent(): void {
		this.moveEnded();
		this.cameraChanged();
		if (this.map) {
			const {lat, lng} = this.map.getCenter();
			this.updateDebug('center', lat, lng);
		}
	}

	protected mapOptions(root: HTMLElement | string, opts?: Partial<IMapOpts>): mapboxgl.MapboxOptions {
		opts = opts || {};
		return {
			accessToken: 'pk.eyJ1Ijoibmlja3NpbCIsImEiOiJja2xscnRrbXEwMjc3Mm5tdzk4eXR3eWtrIn0.zV0FxNlDS30nsDuxipF_Cg',
			attributionControl: false,
			center: [-77.887306, 34.210938],
			container: root,
			doubleClickZoom: false,
			style: opts.style || DEFAULT_MAP_STYLE_URL,
			zoom: 11,
			...opts,
		};
	}

	protected mapPitchEndEvent(): void {
		// this.cameraChanged();
		if (this.map) {
			this.updateDebug('pitch', this.map.getPitch());
		}
	}

	protected mapPointerClickEvent(event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent): void {
		const {x, y} = event.point;
		const {lat, lng} = event.lngLat;
		this.mapClicked(new Point(x, y), new GeoCoordinate(lat, lng));
		if (this.map && this.map.doubleClickZoom.isEnabled()) {
			this.map.doubleClickZoom.disable();
		}
	}

	protected mapPointerMoveEvent(event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent): void {
		const {lat, lng} = event.lngLat;
		this.updateDebug('coord', lat, lng);
	}

	@bind
	protected mapPopupCloseEvent(event?: any): void {
		if (event && 'target' in event) {
			const idx = this.popups.indexOf(event.target);
			if (idx >= 0) {
				this.popups.removeAt(idx);
			}
		}
		this.popupClosed();
	}

	protected mapRotateEndEvent(): void {
		// this.cameraChanged();
		if (this.map) {
			this.updateDebug('bearing', this.map.getBearing());
		}
	}

	protected mapTouchCancelEvent(event: mapboxgl.MapTouchEvent): void {
		// FIXME: Anything go here?
	}

	protected mapTouchEndEvent(event: mapboxgl.MapTouchEvent): void {
		if (isTap(this.touchStartInfo, {point: event.point, time: Date.now()})) {
			this.mapPointerClickEvent(event);
		} else {
			// FIXME: Anything go here?
		}
	}

	protected mapTouchMoveEvent(event: mapboxgl.MapTouchEvent): void {
		this.mapPointerMoveEvent(event);
	}

	protected mapTouchStartEvent(event: mapboxgl.MapTouchEvent): void {
		this.touchStartInfo = {
			point: event.point,
			time: Date.now(),
		};
	}

	private mapZoomEndEvent(): void {
		this.zoomChanged();
		// this.cameraChanged();
	}

	protected mapZoomEvent(event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent): void {
		this.zoomChanged();
		this.updateDebug('zoom', event.target.getZoom());
	}

	@SIGNAL
	mapDoubleClicked(point: Point, coord: GeoCoordinate): void {
	}

	protected mapLayer(layerId: string): Layer | null {
		if (this.map) {
			return <Layer | undefined>this.map.getLayer(layerId) || null;
		}
		return null;
	}

	mapStyleUrl(): string | null {
		const ctrl = this.controlForMode(InteractiveMapControlMode.StylePickerMode);
		if (ctrl) {
			return (<StyleControl>ctrl).styleUrl();
		} else {
			logger.warning('mapStyleUrl: Style picker not define in control map');
		}
		return null;
	}

	@SIGNAL
	protected moveEnded(): void {
	}

	openPopup(coord: GeoCoordinate, opts: Partial<IPopupOpts>): void {
		if (this.map) {
			const lngLat = geoCoordinateToLngLat(coord);
			const popupOpts: mapboxgl.PopupOptions = {
				closeButton: opts.closeButton,
				closeOnClick: opts.closeOnClick,
				closeOnMove: opts.closeOnMove,
				focusAfterOpen: opts.focusAfterOpen,
				anchor: opts.anchor,
			};
			if (opts.maxWidth !== undefined) {
				if (!isNumber(opts.maxWidth) && (opts.maxWidth.indexOf('px') >= 0)) {
					// Do nothing
				} else {
					popupOpts.maxWidth = pixelString(opts.maxWidth);
				}
			}
			const popup = new mapboxgl.Popup(popupOpts);
			this.popups.append(popup);
			popup.on('close', this.mapPopupCloseEvent);
			if (opts.cssClassName) {
				popup.addClassName(opts.cssClassName);
			}
			if (opts.html) {
				popup.setHTML(opts.html);
			}
			if (opts.text) {
				popup.setText(opts.text);
			}
			if (opts.el) {
				const el = (opts.el instanceof ElObj) ?
					<HTMLElement | null>opts.el.element() :
					opts.el;
				if (el) {
					popup.setDOMContent(el);
				}
			}
			popup.setLngLat(lngLat).addTo(this.map);
		}
	}

	pixelCoordinatesForGeoCoordinate(coord: GeoCoordinate): Point {
		const rv = new Point();
		if (this.map) {
			const pt = this.map.project({lng: coord.longitude(), lat: coord.latitude()});
			if ((pt.x < Number.MAX_VALUE) && (pt.y < Number.MAX_VALUE)) {
				rv.setX(pt.x);
				rv.setY(pt.y);
			}
		}
		return rv;
	}

	@SIGNAL
	popupClosed(): void {
	}

	private reloadMapData(): void {
		// Make a copy as they're about to be removed
		const layers = new list(this.mapLayers);
		const source = new list(this.mapSources);
		// Layers first, then sources
		for (const obj of layers) {
			this.removeLayer(obj.layer.id);
		}
		for (const obj of source) {
			this.removeSource(obj.id);
		}
		// Sources first, then layers
		for (const obj of source) {
			this.addSource(obj.id, obj.source);
		}
		for (const obj of layers) {
			this.addLayer(obj.layer, obj.beforeLayerId);
		}
		layers.clear();
		source.clear();
	}

	removeFeature(featureId: number | string | Array<number | string>): void {
		if (this.draw) {
			const ids = Array.isArray(featureId) ?
				featureId.map(x => String(x)) :
				[String(featureId)];
			this.draw.delete(ids);
		}
	}

	removeLayer(layerId: string): void {
		if (this.map) {
			if (this.map.getLayer(layerId)) {
				this.map.removeLayer(layerId);
			}
			const idx = this.mapLayers.findIndex(obj => (obj.layer.id === layerId));
			if (idx >= 0) {
				this.mapLayers.removeAt(idx);
			}
		}
	}

	protected removeMapEventListeners(types?: Array<string>): void {
		// If no types given, all active types removed
		if (this.map) {
			types = types || this.activeMapEventTypes.toArray();
			for (const type of types) {
				this.map.off(type, this.mapEvent);
				this.activeMapEventTypes.discard(type);
			}
		}
	}

	removeSource(sourceId: string): void {
		if (this.map) {
			if (this.map.getSource(sourceId)) {
				this.map.removeSource(sourceId);
			}
		}
		let idx = this.mapSources.findIndex(obj => (obj.id === sourceId));
		if (idx >= 0) {
			this.mapSources.removeAt(idx);
		}
		idx = this.pendingSourceData.findIndex(obj => (obj.id === sourceId));
		if (idx >= 0) {
			const pending = [...this.pendingSourceData];
			pending.splice(idx, 1);
			this.pendingSourceData = [...pending];
		}
	}

	selectedFeatureIds(): Array<string> {
		if (this.draw) {
			return this.draw.getSelectedIds();
		}
		return [];
	}

	private setControlActive(controlMode: InteractiveMapControlMode, active: boolean): void {
		const ctrl = this.controlForMode(controlMode);
		if (ctrl) {
			ctrl.setActive(active);
		}
		switch (controlMode) {
			case InteractiveMapControlMode.NoMode:
			case InteractiveMapControlMode.InfoMode:
				if (active) {
					this.deactivateDraw();
				}
				break;
		}
	}

	protected setDebugEnabled(enable: boolean): void {
		if (enable === this.debugEnabled) {
			return;
		}
		if (!this.mapLoaded) {
			this.pendingDebugEnabled = enable;
			return;
		}
		this.debugEnabled = enable;
		if (this.debugEnabled) {
			this.initDebugEl();
			if (this.map) {
				const {lat, lng} = this.map.getCenter();
				this.updateDebug('center', lat, lng);
				this.updateDebug('bearing', this.map.getBearing());
				this.updateDebug('pitch', this.map.getPitch());
				this.updateDebug('zoom', this.map.getZoom());
				this.updateDebug('coord', lat, lng);
				this.addMapEventListeners([
					MapCtrlEventType.MouseMove,
					MapCtrlEventType.TouchMove,
					MapCtrlEventType.Zoom,
				]);
			}
		} else {
			if (this.debugEl) {
				this.debugEl.destroy();
			}
			this.debugEl = null;
			// Haven't worked out a system to keep track of whether a certain
			// event type was added for use of the debugger or whether it was
			// added elsewhere. So, to avoid removing an event type which
			// something else is counting on, no event types are removed at
			// this point.
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		for (const obj of this.controls.values()) {
			obj.setDisabled(this.disabled);
		}
		if (this.disabled) {
			this.closeAllPopups();
			this.setInteractiveControlMode(InteractiveMapControlMode.NoMode);
			// Don't remove the `loaded` type because we need that to finish
			// making the UI look nice.
			const activeTypes = this.activeMapEventTypes
				.toArray()
				.filter(t => (t !== MapCtrlEventType.Load));
			this.activeMapEventTypesAtTimeOfDisable = new set<string>(activeTypes);
			this.removeMapEventListeners(activeTypes);
			this.draw && this.draw.setEnabled(false);
		} else {
			this.addMapEventListeners(this.activeMapEventTypesAtTimeOfDisable.toArray());
			this.draw && this.draw.setEnabled(true);
		}
	}

	setDrawButtonVisible(buttonId: string, visible: boolean): void {
		if (this.draw) {
			this.draw.setButtonVisible(buttonId, visible);
		}
	}

	setInteractiveControlMode(mode: InteractiveMapControlMode): void {
		if (mode === this.iCtrlMode) {
			return;
		}
		// Leave current mode
		this.setControlActive(this.iCtrlMode, false);
		this.iCtrlMode = mode;
		// Enter current mode
		this.setControlActive(this.iCtrlMode, true);
		this.interactiveControlModeChanged(this.iCtrlMode);
	}

	setSelectedFeatures(featureId: number | string | Array<number | string>): void {
		if (this.draw) {
			const ids = Array.isArray(featureId) ?
				featureId.map(x => String(x)) :
				[String(featureId)];
			this.draw.setSelectedFeatureIds(ids);
			if (this.map) {
				const canv = this.map.getCanvas();
				if (canv && !((canv === document.activeElement) || (canv.contains(document.activeElement)))) {
					canv.focus();
				}
			}
		}
	}

	setSourceData(sourceId: string, data: Feature | FeatureCollection) {
		if (this.mapLoaded && this.map) {
			const src = <mapboxgl.GeoJSONSource>this.map.getSource(sourceId);
			src.setData(data);
		} else {
			this.pendingSourceData.push({id: sourceId, data});
		}
		for (const obj of this.mapSources) {
			if ((obj.id === sourceId) && (obj.source.type === 'geojson')) {
				obj.source.data = {...data};
				break;
			}
		}
	}

	@SIGNAL
	styleChanged(newStyleUrl: string): void {
	}

	@SLOT
	private styleControlStyleChanged(): void {
		this.reloadMapData();
		const ctrl = this.controlForMode(InteractiveMapControlMode.StylePickerMode);
		if (ctrl) {
			this.styleChanged((<StyleControl>ctrl).styleUrl());
		} else {
			logger.warning('styleControlStyleChanged: Control not defined for style picker');
		}
	}

	private updateDebug(which: 'bearing', value: number): void;
	private updateDebug(which: 'center', latitude: number, longitude: number): void;
	private updateDebug(which: 'coord', latitude: number, longitude: number): void;
	private updateDebug(which: 'pitch', value: number): void;
	private updateDebug(which: 'zoom', value: number): void;
	private updateDebug(which: 'bearing' | 'center' | 'coord' | 'pitch' | 'zoom', a: number, b?: number): void {
		if (this.debugEl) {
			switch (which) {
				case 'bearing':
					this.debugEl.setBearing(a);
					break;
				case 'center':
					this.debugEl.setCenter(a, <number>b);
					break;
				case 'coord':
					this.debugEl.setCoord(a, <number>b);
					break;
				case 'pitch':
					this.debugEl.setPitch(a);
					break;
				case 'zoom':
					this.debugEl.setZoom(a);
					break;
			}
		}
	}

	zoom(): number {
		if (this.map) {
			return this.map.getZoom();
		}
		return -1;
	}

	@SIGNAL
	private zoomChanged(): void {
	}
}

class DebugEl extends ElObj {
	private bearingEl: ElObj | null;
	private centerEl: ElObj | null;
	private coordEl: ElObj | null;
	private pitchEl: ElObj | null;
	private zoomEl: ElObj | null;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'lb-map-debug',
			...classNames,
		];
		if (!(opts.tagName || opts.root)) {
			opts.tagName = 'div';
		}
		super(opts);
		this.centerEl = new ElObj({parent: this, tagName: 'div'});
		this.centerEl.setText('center:');
		this.bearingEl = new ElObj({parent: this, tagName: 'div'});
		this.bearingEl.setText('bearing:');
		this.pitchEl = new ElObj({parent: this, tagName: 'div'});
		this.pitchEl.setText('pitch:');
		this.zoomEl = new ElObj({parent: this, tagName: 'div'});
		this.zoomEl.setText('zoom:');
		this.coordEl = new ElObj({parent: this, tagName: 'div'});
		this.coordEl.setText('coord:');
	}

	destroy(): void {
		[
			this.bearingEl,
			this.centerEl,
			this.coordEl,
			this.pitchEl,
			this.zoomEl,
		].forEach(el => el && el.destroy());
		this.bearingEl = null;
		this.centerEl = null;
		this.coordEl = null;
		this.pitchEl = null;
		this.zoomEl = null;
		super.destroy();
	}

	setBearing(bearing: number): void {
		if (this.bearingEl) {
			this.bearingEl.setText(`bearing: ${bearing.toFixed(6)}`);
		}
	}

	setCenter(latitude: number, longitude: number): void {
		if (this.centerEl) {
			this.centerEl.setText(`center: ${latitude.toFixed(6)}, ${longitude.toFixed(6)}`);
		}
	}

	setCoord(latitude: number, longitude: number): void {
		if (this.coordEl) {
			this.coordEl.setText(`coord: ${latitude.toFixed(6)}, ${longitude.toFixed(6)}`);
		}
	}

	setPitch(pitch: number): void {
		if (this.pitchEl) {
			this.pitchEl.setText(`pitch: ${pitch.toFixed(6)}`);
		}
	}

	setZoom(zoom: number): void {
		if (this.zoomEl) {
			this.zoomEl.setText(`zoom: ${zoom.toFixed(6)}`);
		}
	}
}

function geoCoordinateToLngLat(coord: GeoCoordinate): mapboxgl.LngLat {
	return new mapboxgl.LngLat(coord.longitude(), coord.latitude());
}

function geoRectangleToLngLatBounds(rect: GeoRectangle): mapboxgl.LngLatBounds {
	return new mapboxgl.LngLatBounds(
		geoCoordinateToLngLat(rect.bottomLeft()),
		geoCoordinateToLngLat(rect.topRight()));
}

function moveAttrib(): void {
	const mbLogoEl = document.querySelector('.mapboxgl-ctrl-logo');
	const mbLogoElParent = mbLogoEl && mbLogoEl.parentElement;
	if (mbLogoElParent) {
		mbLogoElParent.classList.add('display--flex');
		setTimeout(() => {
			const attribEl = document.querySelector<HTMLElement>('.mapboxgl-ctrl-attrib');
			if (attribEl) {
				mbLogoElParent.appendChild(attribEl);
				attribEl.style.setProperty('margin', '0 0 0 10px');
			}
		}, 100);
	}
}
