import {OBJ, PROP, SIGNAL, SLOT} from '../obj';
import {ElObj} from '../elobj';
import {Point} from '../tools';

@OBJ
export class AbstractButton extends ElObj {
	private checkable: boolean = false;

	@SLOT
	click(): void {
		if (this.isDisabled()) {
			return;
		}
		this.pressed();
		this.nextCheckState();
		this.released();
		this.clicked(this.isChecked());
	}

	@SIGNAL
	clicked(checked: boolean = false, point: Point | null = null): void {
	}

	isCheckable(): boolean {
		return this.checkable;
	}

	@PROP({NOTIFY: 'toggled', USER: true, WRITE: 'setChecked'})
	isChecked(): boolean {
		if (this.checkable) {
			return super.isChecked();
		}
		return false;
	}

	isEnabled(): boolean {
		return !this.isDisabled();
	}

	nextCheckState(): void {
		if (this.checkable) {
			this.setChecked(!this.isChecked());
		}
	}

	@SIGNAL
	pressed(): void {
	}

	@SIGNAL
	released(): void {
	}

	setCheckable(checkable: boolean): void {
		if (this.checkable === checkable) {
			return;
		}
		this.checkable = checkable;
		this.setChecked(false);
	}

	@SLOT
	setChecked(checked: boolean): void {
		if (this.checkable && (checked !== this.isChecked())) {
			super.setChecked(checked);
			this.toggled(this.isChecked());
		}
	}

	setEnabled(enable: boolean): void {
		this.setDisabled(!enable);
	}

	@SLOT
	toggle(): void {
		this.setChecked(!this.isChecked());
	}

	@SIGNAL
	toggled(checked: boolean): void {
	}
}
