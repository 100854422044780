import {CheckState} from './constants';
import {list} from './tools';
import {getLogger} from './logging';
import {OBJ, Obj} from './obj';
import {
	closestMatchingElement,
	elementMatchesSelector,
	elementString,
	printTree,
} from './util';

const logger = getLogger('elobj');
const cssClassDisplayNone = 'display--none';
const cssClassVisibilityHidden = 'visibility--hidden';
export const staticNode: Element = document.createElement('div');
const staticRect: DOMRect = Object.freeze({
	bottom: 0,
	height: 0,
	left: 0,
	right: 0,
	top: 0,
	width: 0,
	x: 0,
	y: 0,
	toJSON: () => '',
});

export class EventListenerInfo {
	capture?: boolean;
	eventType: string;
	listener: EventListenerOrEventListenerObject;

	constructor(type: string, listener: EventListenerOrEventListenerObject, capture?: boolean) {
		this.capture = capture;
		this.eventType = type;
		this.listener = listener;
	}

	eq(other: EventListenerInfo): boolean {
		const {capture, eventType, listener} = this;
		return (capture === other.capture)
			&& (eventType === other.eventType)
			&& (listener === other.listener);
	}
}

export interface ElObjOpts {
	attributes: Iterable<[string, string]>;
	classNames: Iterable<string>;
	namespace: string | null;
	parent: ElObj | null;
	placementIndex: number;
	root: ElObj | Element | null;
	styles: Iterable<[string, string]>;
	tagName: TagName;
}

export function elObjOpts<T extends ElObjOpts = ElObjOpts>(a?: Partial<T> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null): Partial<T> {
	let opts: Partial<T> = {};
	let parent: ElObj | null = null;
	let root: Element | null = null;
	let tagName: TagName | null = null;
	if (a) {
		if (typeof a === 'string') {
			tagName = a;
		} else if (a instanceof ElObj) {
			parent = a;
		} else if (a instanceof Element) {
			root = a;
		} else {
			opts = a;
		}
	}
	if (b) {
		if (typeof b === 'string') {
			tagName = b;
		} else if (b instanceof ElObj) {
			parent = b;
		} else {
			root = b;
		}
	}
	if (c) {
		parent = c;
	}
	if (parent) {
		opts.parent = parent;
	}
	if (root) {
		opts.root = root;
	}
	if (tagName) {
		opts.tagName = tagName;
	}
	parent = null;
	root = null;
	tagName = null;
	return opts;
}

@OBJ
export class ElObj extends Obj {
	static cssClassNames = {
		DisplayNone: cssClassDisplayNone,
		VisibilityHidden: cssClassVisibilityHidden,
	};
	static IconClassName: string = 'material-icons';
	static IconSelector: string = `.${ElObj.IconClassName}`;
	static instanceCount: number = 0;

	static body(opts: Partial<ElObjOpts> | null = null): ElObj {
		opts = opts || {};
		opts.root = document.body;
		return new this(opts);
	}

	static cmp(a: ElObj, b: ElObj): number {
		return a.eq(b) ? 0 : -1;
	}

	static fromEvent(event: Event, opts: Partial<ElObjOpts> | null = null): ElObj {
		const tgt = event.target;
		if (tgt && (tgt instanceof Element)) {
			return new this(opts, tgt);
		}
		throw new Error('ElObj::fromEvent: Event target is null or not an instance of Element');
	}

	static fromSelector(selector: string, opts: Partial<ElObjOpts> | null = null): ElObj {
		const result = document.querySelector(selector);
		if (result) {
			return new this(opts, result);
		}
		throw new Error(`El::fromSelector: Element was not found using selector "${selector}"`);
	}

	instanceNumber: number;
	protected elem: Element;
	protected listenerInfo: list<EventListenerInfo>;
	protected textNode: Text | null;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		super();
		this.instanceNumber = ++(<typeof ElObj>this.constructor).instanceCount;
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		this.elem = staticNode;
		this.listenerInfo = new list();
		this.textNode = null;
		if (opts.root) {
			if (opts.root instanceof ElObj) {
				this.elem = opts.root.elem;
				this.listenerInfo = opts.root.listenerInfo;
				this.textNode = opts.root.textNode;
			} else {
				this.elem = opts.root;
			}
		} else if (opts.tagName) {
			if (opts.namespace) {
				this.elem = document.createElementNS(opts.namespace, opts.tagName);
			} else {
				this.elem = document.createElement(opts.tagName);
			}
		}
		if (opts.attributes) {
			this.setAttribute(opts.attributes);
		}
		if (opts.classNames) {
			if (typeof opts.classNames === 'string') {
				this.addClass(opts.classNames);
			} else {
				this.addClass(...opts.classNames);
			}
		}
		if (opts.styles) {
			for (const [name, value] of opts.styles) {
				this.setStyleProperty(name, value);
			}
		}
		if (opts.parent) {
			this._setParent(opts.parent, opts.placementIndex);
		}
	}

	appendChild(child: ElObj | Element): void {
		if (this._isValid()) {
			const childElem = this._elElem(child);
			if (childElem) {
				this.elem.appendChild(childElem);
			}
		}
	}

	addClass(...name: Array<string>): void {
		if (this._isValid() && (name.length > 0)) {
			this.elem.classList.add(...name);
		}
	}

	addEventListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
	addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
	addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {
		if (this._isValid()) {
			this.elem.addEventListener(type, listener, options);
			this.listenerInfo.append(
				new EventListenerInfo(
					type,
					listener,
					((options === undefined) || (typeof options === 'boolean')) ?
						options :
						undefined));
		}
	}

	attribute(name: string): string | null {
		return this._isValid() ?
			this.elem.getAttribute(name) :
			null;
	}

	blur(): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			this.elem.blur();
		}
	}

	checkState(): CheckState {
		if (this._isValid() && (this.elem instanceof HTMLInputElement)) {
			return this.elem.indeterminate ?
				CheckState.PartiallyChecked :
				this.elem.checked ?
					CheckState.Checked :
					CheckState.Unchecked;
		}
		return CheckState.Unchecked;
	}

	clear(): void {
		if (this._isValid()) {
			if (this.textNode) {
				this.textNode.data = '';
				this.textNode.remove();
				this.textNode = null;
			}
			let node: Node | null = this.elem.firstChild;
			while (node) {
				this.elem.removeChild(node);
				node = this.elem.firstChild;
			}
		}
	}

	clone(deep?: boolean): ElObj {
		return this._isValid() ?
			new ElObj(<Element>this.elem.cloneNode(deep)) :
			new ElObj();
	}

	closestMatchingAncestor(selector: string): ElObj | null {
		if (this._isValid()) {
			const result = closestMatchingElement(this.elem, selector);
			if (result) {
				return new ElObj(result);
			}
		}
		return null;
	}

	contains(el: ElObj | Element | null): boolean {
		return this._isValid() ?
			this.elem.contains(this._elElem(el)) :
			false;
	}

	destroy(): void {
		this.elem.remove();
		if (this._isValid()) {
			for (const info of this.listenerInfo) {
				this.elem.removeEventListener(info.eventType, info.listener, info.capture);
			}
		}
		this.listenerInfo.clear();
		if (this.textNode) {
			this.textNode.remove();
			this.textNode.data = '';
			this.textNode = null;
		}
		this.elem = staticNode;
		super.destroy();
	}

	dumpTree(): void {
		if (this._isValid()) {
			printTree(this.elem);
		}
	}

	private _elElem(el: ElObj | Element | null): Element | null {
		return el ?
			(el instanceof ElObj) ?
				el.element() :
				el :
			null;
	}

	element(): Element | null {
		return this._isValid() ?
			this.elem :
			null;
	}

	private ensureTextNode(): Text {
		this.textNode = findTextNode(this.elem);
		// Unsure if that spec means that there will ALWAYS be a Text node
		// or only if there was text content present within the node at
		// some point in its lifetime. Since I don't know and currently
		// lack the willpower to dive deep into this mystery, we'll settle
		// for a check and create a new instance if necessary.
		if (!this.textNode) {
			this.textNode = document.createTextNode('');
			this.elem.appendChild(this.textNode);
		}
		return this.textNode;
	}

	eq(other: ElObj | Element | null): boolean {
		return this._isValid() ?
			(this.elem === this._elElem(other)) :
			false;
	}

	focus(): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			this.elem.focus();
		}
	}

	hasAttribute(name: string): boolean {
		return this._isValid() ?
			this.elem.hasAttribute(name) :
			false;
	}

	hasClass(name: string): boolean {
		return this._isValid() ?
			this.elem.classList.contains(name) :
			false;
	}

	hasFocus(): boolean {
		if (this._isValid()) {
			const active = document.activeElement;
			return (active !== null) && (active === this.elem);
		}
		return false;
	}

	hide(): void {
		if (this._isValid()) {
			this.setVisible(false);
		}
	}

	insertAdjacentElement(position: AdjacentPosition, elemToInsert: ElObj | Element): void {
		if (this._isValid()) {
			const validInsert = this._elElem(elemToInsert);
			if (validInsert) {
				this.elem.insertAdjacentElement(position, validInsert);
			} else {
				logger.warning('insertAdjacentElement: Got invalid element for insert');
			}

		}
	}

	insertChild(index: number, child: ElObj | Element): void {
		const childElem = this._elElem(child);
		if (this._isValid() && childElem) {
			const children = this.makeList(Array.from(this.elem.children)
				.map(elem => (new ElObj(elem))));
			const count = children.size();
			if ((count === 0) || (index < 0) || (index >= count)) {
				this.elem.appendChild(childElem);
			} else {
				this.elem.children[index]
					.insertAdjacentElement('beforebegin', childElem);
			}
		}
	}

	isChecked(): boolean {
		return this._isValid() ?
			(this.checkState() === CheckState.Checked) :
			false;
	}

	isDisabled(): boolean {
		return (this._isValid() && disableable(this.elem)) ?
			this.elem.disabled :
			false;
	}

	isHidden(): boolean {
		return this.hasClass(ElObj.cssClassNames.DisplayNone);
	}

	isRequired(): boolean {
		return (this._isValid() && requireable(this.elem)) ?
			this.elem.required :
			false;
	}

	protected _isValid(): boolean {
		return this.elem !== staticNode;
	}

	isVisible(): boolean {
		return !this.isHidden();
	}

	private makeList(items: Iterable<ElObj>): list<ElObj> {
		return new list(items, ElObj.cmp);
	}

	matchesSelector(selector: string): boolean {
		return this._isValid() ?
			elementMatchesSelector(this.elem, selector) :
			false;
	}

	parentEl(): ElObj | null {
		return (this._isValid() && this.elem.parentElement) ?
			new ElObj(this.elem.parentElement) :
			null;
	}

	querySelectorAll(selector: string): list<ElObj> {
		return this._isValid() ?
			this.makeList(
				Array.from(
					this.elem.querySelectorAll(selector))
					.map(elem => (new ElObj(elem)))) :
			this.makeList([]);
	}

	querySelector(selector: string): ElObj | null {
		if (this._isValid()) {
			const result = this.elem.querySelector(selector);
			return result ?
				new ElObj(result) :
				null;
		}
		return null;
	}

	rect(): DOMRect {
		return this._isValid() ?
			this.elem.getBoundingClientRect() :
			staticRect;
	}

	removeAttribute(...name: Array<string>): void {
		if (this._isValid()) {
			name.forEach(n => this.elem.removeAttribute(n));
		}
	}

	removeChild(child: ElObj | Node): void {
		if (this._isValid()) {
			this.elem.removeChild((child instanceof ElObj) ?
				child.elem :
				child);
		}
	}

	removeClass(...name: Array<string>): void {
		if (this._isValid()) {
			this.elem.classList.remove(...name);
		}
	}

	removeEventListener<K extends keyof HTMLElementEventMap>(type: K, listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
	removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
	removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
		if (this._isValid()) {
			this.elem.removeEventListener(type, listener, options);
			const cmp = new EventListenerInfo(
				type,
				listener,
				((options === undefined) || (typeof options === 'boolean')) ?
					options :
					undefined);
			let i = this.listenerInfo.size() - 1;
			for (; i >= 0; --i) {
				const info = this.listenerInfo.at(i);
				if (info.eq(cmp)) {
					this.listenerInfo.removeAt(i);
				}
			}
		}
	}

	removeStyleProperty(name: string): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			this.elem.style.removeProperty(name);
		}
	}

	removeText(): void {
		if (this._isValid()) {
			if (this.textNode) {
				this.textNode.data = '';
				this.textNode.remove();
				this.textNode = null;
			}
			const nodes = this.elem.childNodes;
			for (let i = 0; i < nodes.length; ++i) {
				const node = nodes[i];
				if (node.nodeType === Node.TEXT_NODE) {
					this.elem.removeChild(node);
				}
			}
		}
	}

	replaceChild(newChild: ElObj, oldChild: ElObj): void {
		if (this._isValid()) {
			this.elem.replaceChild(newChild.elem, oldChild.elem);
		}
	}

	reportValidity(): boolean {
		return (this._isValid() && validable(this.elem)) ?
			this.elem.reportValidity() :
			true;
	}

	setAttribute(name: string, value: string): void;
	setAttribute(attrs: Iterable<[string, string]>): void;
	setAttribute(a: Iterable<[string, string]> | string, b?: string): void {
		if (this._isValid()) {
			if (typeof a === 'string') {
				this.elem.setAttribute(a, <string>b);
			} else {
				for (const [k, v] of a) {
					this.setAttribute(k, v);
				}
			}
		}
	}

	setChecked(checked: boolean): void {
		if (this._isValid()) {
			this.setCheckState(checked ?
				CheckState.Checked :
				CheckState.Unchecked);
		}
	}

	setCheckState(state: CheckState): void {
		if (this._isValid()) {
			if (this.elem instanceof HTMLInputElement) {
				if (state === CheckState.PartiallyChecked) {
					this.elem.indeterminate = true;
				} else {
					this.elem.indeterminate = false;
					this.elem.checked = state === CheckState.Checked;
				}
			} else {
				logger.warning('setCheckState: Element does not have "checked" property.');
			}
		}
	}

	setClass(add: boolean, ...name: Array<string>): void {
		if (this._isValid()) {
			if (add) {
				this.addClass(...name);
			} else {
				this.removeClass(...name);
			}
		}
	}

	setDisabled(disabled: boolean): void {
		if (this._isValid()) {
			if (disableable(this.elem)) {
				this.elem.disabled = disabled;
			} else {
				logger.warning('setDisabled: %s does not have "disabled" property.', this);
			}
		}
	}

	setDraggable(draggable: boolean): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			if (draggable) {
				this.setAttribute('draggable', 'true');
			} else {
				this.removeAttribute('draggable');
			}
		}
	}

	private _setParent(parent: ElObj | Element | null, insertAtIndex: number = -1): void {
		if (this._isValid()) {
			const parentElem = this._elElem(parent);
			if (!parentElem) {
				this.elem.remove();
				return;
			}
			const curr = this.parentEl();
			if (curr && curr.eq(parentElem)) {
				return;
			}
			if (insertAtIndex > 0) {
				const siblings = parentElem.children;
				if (insertAtIndex >= siblings.length) {
					parentElem.appendChild(this.elem);
				} else {
					parentElem.insertBefore(this.elem, siblings[insertAtIndex]);
				}
			} else {
				parentElem.appendChild(this.elem);
			}
		}
	}

	setRequired(required: boolean): void {
		if (this._isValid()) {
			if (requireable(this.elem)) {
				this.elem.required = required;
			} else {
				logger.warning('setRequired: %s does not have "required" property.', this);
			}
		}
	}

	setStyleProperty(name: string, value: string | null): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			this.elem.style.setProperty(name, value);
		}
	}

	setTabIndex(index: number): void {
		if (this._isValid() && (this.elem instanceof HTMLElement)) {
			this.elem.tabIndex = index;
		}
	}

	setText(text?: string | null): void {
		this._setText(text);
	}

	protected _setText(text?: string | null): void {
		if (this._isValid()) {
			this.ensureTextNode().data = text || '';
		}
	}

	setVisible(visible: boolean): void {
		this._setVisible(visible);
	}

	protected _setVisible(visible: boolean): void {
		if (this._isValid()) {
			this.setClass(!visible, ElObj.cssClassNames.DisplayNone);
		}
	}

	show(): void {
		if (this._isValid()) {
			this.setVisible(true);
		}
	}

	text(): string {
		return this._isValid() ?
			this.ensureTextNode().data :
			'';
	}

	toString(): string {
		const parts = [
			this._isValid() ?
				elementString(this.elem) :
				'<nuthin>',
			this.objectName().trim(),
		];
		const s = parts
			.filter(p => (p.length > 0))
			.join(', ');
		const n = this.constructor.name;
		return `${n}(${s})`;
	}
}

function disableable(el: Element): el is Element & {disabled: boolean;} {
	return (el instanceof HTMLInputElement)
		|| (el instanceof HTMLButtonElement)
		|| (el instanceof HTMLSelectElement)
		|| (el instanceof HTMLTextAreaElement)
		|| (el instanceof HTMLOptionElement)
		|| (el instanceof HTMLOptGroupElement)
		|| (el instanceof HTMLFieldSetElement)
		|| (el instanceof HTMLLinkElement)
		|| (el instanceof SVGStyleElement);
}

function findTextNode(elem: Element): Text | null {
	// WARNING: This routine will normalize this element's entire subtree.
	//
	// The Node.normalize() method puts the specified node and all of
	// its sub-tree into a "normalized" form. In a normalized sub-tree,
	// no text nodes in the sub-tree are empty and there are no
	// adjacent text nodes.
	elem.normalize();
	const childNodes = elem.childNodes;
	for (let i = 0; i < childNodes.length; ++i) {
		const node = childNodes[i];
		if (node.nodeType === Node.TEXT_NODE) {
			return <Text>node;
		}
	}
	return null;
}

function requireable(el: Element): el is Element & {required: boolean;} {
	return (el instanceof HTMLInputElement)
		|| (el instanceof HTMLSelectElement)
		|| (el instanceof HTMLTextAreaElement);
}

function validable(el: Element): el is Element & {reportValidity(): boolean;} {
	return (el instanceof HTMLFormElement)
		|| (el instanceof HTMLInputElement)
		|| (el instanceof HTMLTextAreaElement)
		|| (el instanceof HTMLSelectElement)
		|| (el instanceof HTMLButtonElement)
		|| (el instanceof HTMLFieldSetElement)
		|| (el instanceof HTMLObjectElement)
		|| (el instanceof HTMLOutputElement);
}
