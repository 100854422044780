import {IMapboxDrawContext} from './mapboxdraw';
import {DrawMode, CssClassName} from './constants';
import {getLogger} from '../../../logging';
import {ElObjOpts} from '../../../elobj';
import {Obj, OBJ, SLOT} from '../../../obj';
import {PushButton} from '../../pushbutton';
import {list} from '../../../tools';
import type {EventThing} from './events';
import {
	bind,
	closestMatchingElement,
	iterableToArray,
	stringIterableToStringArray,
} from '../../../util';

const logger = getLogger('draw.ui');

export enum ButtonId {
	Circle = 'circle',
	CombineFeatures = 'combinefeatures',
	LineString = 'linestring',
	Point = 'point',
	Polygon = 'polygon',
	Trash = 'trash',
	UncombineFeatures = 'uncombinefeatures',
}

export interface IMapClass {
	feature: string | null;
	mode: string | null;
	mouse: string | null;
}

const classTypes: ['mode', 'feature', 'mouse'] = ['mode', 'feature', 'mouse'];

export class UI {
	private activeBtn: HTMLButtonElement | null;
	private readonly buttonIdMap: Map<string, HTMLButtonElement>;
	ctx!: IMapboxDrawContext; // Set where instantiated just after construction
	private currentMapClasses: IMapClass;
	private enabled: boolean;
	private nextMapClasses: IMapClass;

	constructor() {
		this.activeBtn = null;
		this.buttonIdMap = new Map<string, HTMLButtonElement>();
		this.currentMapClasses = {
			feature: null,
			mode: null,
			mouse: null,
		};
		this.enabled = false;
		this.nextMapClasses = {
			feature: null,
			mode: null,
			mouse: null,
		};
	}

	addButtons(): HTMLDivElement {
		const controls = this.ctx.options.controls;
		const controlGroup = document.createElement('div');
		controlGroup.className = `${CssClassName.ControlGroup} ${CssClassName.ControlBase} lb-map-control`;
		if (!controls) {
			return controlGroup;
		}
		if (controls.line_string) {
			this.buttonIdMap.set(
				ButtonId.LineString,
				this.createControlButton(
					ButtonId.LineString,
					{
						className: CssClassName.ControlButtonLine,
						container: controlGroup,
						title: this.buttonTitle(ButtonId.LineString),
					}));
		}
		if (controls.polygon) {
			this.buttonIdMap.set(
				ButtonId.Polygon,
				this.createControlButton(ButtonId.Polygon,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonPolygon,
						title: this.buttonTitle(ButtonId.Polygon),
					}));
		}
		if (controls.circle) {
			this.buttonIdMap.set(
				ButtonId.Circle,
				this.createControlButton(ButtonId.Circle,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonCircle,
						title: this.buttonTitle(ButtonId.Circle),
					}));
		}
		if (controls.point) {
			this.buttonIdMap.set(
				ButtonId.Point,
				this.createControlButton(ButtonId.Point,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonPoint,
						title: this.buttonTitle(ButtonId.Point),
					}));
		}
		if (controls.trash) {
			this.buttonIdMap.set(
				ButtonId.Trash,
				this.createControlButton(ButtonId.Trash,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonTrash,
						title: this.buttonTitle(ButtonId.Trash),
					}));
		}
		if (controls.combine_features) {
			this.buttonIdMap.set(
				ButtonId.CombineFeatures,
				this.createControlButton(ButtonId.CombineFeatures,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonCombineFeatures,
						title: this.buttonTitle(ButtonId.CombineFeatures),
					}));
		}
		if (controls.uncombine_features) {
			this.buttonIdMap.set(
				ButtonId.UncombineFeatures,
				this.createControlButton(ButtonId.UncombineFeatures,
					{
						container: controlGroup,
						className: CssClassName.ControlButtonUncombineFeatures,
						title: this.buttonTitle(ButtonId.UncombineFeatures),
					}));
		}
		return controlGroup;
	}

	private buttonActivated(buttonId: string): void {
		let nextMode: string = '';
		switch (buttonId) {
			case ButtonId.LineString:
				nextMode = DrawMode.DrawLineString;
				break;
			case ButtonId.Polygon:
				nextMode = DrawMode.DrawPolygon;
				break;
			case ButtonId.Point:
				nextMode = DrawMode.DrawPoint;
				break;
			case ButtonId.Circle:
				nextMode = DrawMode.DrawCircle;
				break;
			case ButtonId.Trash:
				this.ctx.events.trash();
				break;
			case ButtonId.CombineFeatures:
				this.ctx.events.combineFeatures();
				break;
			case ButtonId.UncombineFeatures:
				this.ctx.events.uncombineFeatures();
				break;
			default:
				logger.warning('buttonClicked: Got invalid element ID: %s', buttonId);
				return;
		}
		if (nextMode.length > 0) {
			this.ctx.events.changeMode(nextMode);
		}
		this.setActiveButton(buttonId);
	}

	private buttonClicked(buttonId: string, isActive: boolean): void {
		if (isActive) {
			this.buttonActivated(buttonId);
		} else {
			this.buttonDeactivated(buttonId);
		}
	}

	@bind
	private buttonClickEvent(event: MouseEvent): void {
		if (this.enabled) {
			const btn = closestMatchingElement<HTMLButtonElement>(
				<Element | null>event.target,
				'button');
			if (btn) {
				const btnId = this.buttonId(btn);
				if (btnId.length > 0) {
					event.preventDefault();
					event.stopPropagation();
					this.buttonClicked(btnId, btn !== this.activeBtn);
					focusCanvasIfNecessary();
				}
			}
		}
	}

	private buttonDeactivated(buttonId: string): void {
		switch (buttonId) {
			case ButtonId.LineString:
			case ButtonId.Polygon:
			case ButtonId.Circle:
			case ButtonId.Point:
			case ButtonId.Trash:
				this.ctx.events.trash();
				break;
			case ButtonId.CombineFeatures:
			case ButtonId.UncombineFeatures:
				break;
			default:
				logger.warning('buttonDeactivated: Got invalid element ID: %s', buttonId);
				return;
		}
		this.deactivateButtons();
	}

	private buttonId(button: Element): string {
		for (const [id, obj] of this.buttonIdMap) {
			if (obj === button) {
				return id;
			}
		}
		return '';
	}

	private buttonTitle(buttonId: string): string {
		const parts: Array<string> = [];
		switch (buttonId) {
			case ButtonId.LineString:
				parts.push('LineString tool');
				if (this.ctx.options.keybindings) {
					parts.push('(l)');
				}
				break;
			case ButtonId.Polygon:
				parts.push('Polygon tool');
				if (this.ctx.options.keybindings) {
					parts.push('(p)');
				}
				break;
			case ButtonId.Circle:
				parts.push('Circle tool');
				if (this.ctx.options.keybindings) {
					parts.push('(c)');
				}
				break;
			case ButtonId.Point:
				parts.push('Marker tool');
				if (this.ctx.options.keybindings) {
					parts.push('(m)');
				}
				break;
			case ButtonId.Trash:
				parts.push('Delete');
				break;
			case ButtonId.CombineFeatures:
				parts.push('Combine');
				break;
			case ButtonId.UncombineFeatures:
				parts.push('Uncombine');
				break;
			default:
				logger.warning('buttonTitle: Got invalid ID: %s', buttonId);
				break;
		}
		return parts.join(' ');
	}

	clearMapClasses(): void {
		this.queueMapClasses({
			mode: null,
			feature: null,
			mouse: null,
		});
		this.updateMapClasses();
	}

	createControlButton(id: string, options: {container: HTMLElement; className: string; title: string;}): HTMLButtonElement {
		const button = document.createElement('button');
		button.disabled = !this.enabled;
		button.className = `${CssClassName.ControlButton} ${options.className}`;
		button.setAttribute('title', options.title);
		options.container.appendChild(button);
		button.addEventListener('click', this.buttonClickEvent, true);
		if (id === ButtonId.Trash) {
			button.classList.add('display--none');
		}
		return button;
	}

	deactivateButtons(): void {
		if (this.activeBtn) {
			this.activeBtn.classList.remove(CssClassName.ActiveButton);
		}
		this.activeBtn = null;
	}

	isEnabled(): boolean {
		return this.enabled;
	}

	queueMapClasses(options: Partial<IMapClass>): void {
		this.nextMapClasses = {...this.nextMapClasses, ...options};
	}

	removeButtons(): void {
		for (const obj of this.buttonIdMap.values()) {
			if (obj.parentNode) {
				obj.parentNode.removeChild(obj);
			}
		}
		this.buttonIdMap.clear();
	}

	setActiveButton(buttonId: string): void {
		this.deactivateButtons();
		switch (buttonId) {
			case ButtonId.Trash:
			case ButtonId.CombineFeatures:
			case ButtonId.UncombineFeatures:
				return;
		}
		const button = this.buttonIdMap.get(buttonId);
		if (button) {
			button.classList.add(CssClassName.ActiveButton);
			this.activeBtn = button;
		}
	}

	setButtonVisible(buttonId: string, visible: boolean): void {
		const button = this.buttonIdMap.get(buttonId);
		if (button) {
			if (visible) {
				button.classList.remove('display--none');
			} else {
				button.classList.add('display--none');
			}
		} else {
			logger.error('setButtonVisible: No object found for given ID.');
		}
	}

	setEnabled(enable: boolean): void {
		if (enable === this.enabled) {
			return;
		}
		this.enabled = enable;
		if (this.enabled) {
			for (const btn of this.buttonIdMap.values()) {
				btn.disabled = false;
				btn.style.removeProperty('opacity');
			}
		} else {
			this.deactivateButtons();
			for (const btn of this.buttonIdMap.values()) {
				btn.disabled = true;
				btn.style.setProperty('opacity', '0.2');
			}
		}
	}

	updateMapClasses(): void {
		if (this.ctx.container) {
			const classesToRemove: Array<string> = [];
			const classesToAdd: Array<string> = [];
			classTypes.forEach(type => {
				if (this.nextMapClasses[type] !== this.currentMapClasses[type]) {
					classesToRemove.push(`${type}-${this.currentMapClasses[type]}`);
					if (this.nextMapClasses[type] !== null) {
						classesToAdd.push(`${type}-${this.nextMapClasses[type]}`);
					}
				}
			});
			if (classesToRemove.length > 0) {
				this.ctx.container.classList.remove(...classesToRemove);
			}
			if (classesToAdd.length > 0) {
				this.ctx.container.classList.add(...classesToAdd);
			}
			this.currentMapClasses = {
				...this.currentMapClasses,
				...this.nextMapClasses,
			};
		}
	}
}

@OBJ
class UIButtonGroup extends Obj {
	private buttons: list<UIButton>;
	private eventThing: EventThing;

	constructor(eventThing: EventThing) {
		super();
		this.buttons = new list<UIButton>();
		this.eventThing = eventThing;
	}

	addButton(button: UIButton): void {
		if (this.buttons.indexOf(button) >= 0) {
			logger.warning('addButton: Button already added');
			return;
		}
	}

	buttonClicked(button: UIButton): void {
		let nextMode: string = '';
		let persist: boolean = true;
		let proc: Function | null = null;
		switch (button.id()) {
			case ButtonId.LineString:
				nextMode = DrawMode.DrawLineString;
				break;
			case ButtonId.Polygon:
				nextMode = DrawMode.DrawPolygon;
				break;
			case ButtonId.Point:
				nextMode = DrawMode.DrawPoint;
				break;
			case ButtonId.Circle:
				nextMode = DrawMode.DrawCircle;
				break;
			case ButtonId.Trash:
				persist = false;
				proc = this.eventThing.trash;
				break;
			case ButtonId.CombineFeatures:
				persist = false;
				proc = this.eventThing.combineFeatures;
				break;
			case ButtonId.UncombineFeatures:
				persist = false;
				proc = this.eventThing.uncombineFeatures;
				break;
			default:
				logger.warning('buttonClicked: Got invalid element ID: %s', button.id());
				return;
		}
		if (nextMode.length > 0) {
			this.eventThing.changeMode.call(this.eventThing, nextMode);
		} else if (proc) {
			proc.call(this.eventThing);
		} else {
			logger.warning('buttonClicked: No further button activity defined.');
		}
		// button.setActive(
		// 	!button.isActivated() && button.isPersistent());
	}
}

interface UIButtonOpts extends Partial<ElObjOpts> {
	id: string;
	bindKey: boolean;
	group: UIButtonGroup;
	persistUponActivation: boolean;
}

@OBJ
class UIButton extends PushButton {
	private activated: boolean;
	private eyeD: string;
	private group: UIButtonGroup;

	constructor(opts: UIButtonOpts) {
		opts = opts || {};
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['title', buttonTitle(opts.id, opts.bindKey)],
			['type', 'button'],
			...attributes,
		];
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			CssClassName.ControlButton,
			...classNames,
		];
		opts.tagName = 'button';
		super(opts);
		this.activated = false;
		this.group = opts.group;
		this.eyeD = opts.id;
		Obj.connect(this, 'clicked', this, '_clicked');
	}

	@SLOT
	private _clicked(): void {
		this.group.buttonClicked(this);
	}

	destroy(): void {
		Obj.disconnect(this, 'clicked', this, '_clicked');
		super.destroy();
	}

	id(): string {
		return this.eyeD;
	}

	isActivated(): boolean {
		return this.activated;
	}

	setActive(active: boolean): void {
		if (active === this.activated) {
			return;
		}
		this.activated = active;
		this.setClass(this.activated, CssClassName.ActiveButton);
	}
}

function focusCanvasIfNecessary(): void {
	// FIXME: ehh?
	if (document.activeElement === document.body) {
		const mapEl = document.querySelector<HTMLElement>('.mapboxgl-canvas');
		if (mapEl) {
			mapEl.focus();
		}
	}
}

function buttonTitle(buttonId: string, keybindingsInUse: boolean): string {
	const parts: Array<string> = [];
	switch (buttonId) {
		case ButtonId.LineString:
			parts.push('LineString tool');
			if (keybindingsInUse) {
				parts.push('(l)');
			}
			break;
		case ButtonId.Polygon:
			parts.push('Polygon tool');
			if (keybindingsInUse) {
				parts.push('(p)');
			}
			break;
		case ButtonId.Circle:
			parts.push('Circle tool');
			if (keybindingsInUse) {
				parts.push('(c)');
			}
			break;
		case ButtonId.Point:
			parts.push('Marker tool');
			if (keybindingsInUse) {
				parts.push('(m)');
			}
			break;
		case ButtonId.Trash:
			parts.push('Delete');
			break;
		case ButtonId.CombineFeatures:
			parts.push('Combine');
			break;
		case ButtonId.UncombineFeatures:
			parts.push('Uncombine');
			break;
		default:
			logger.warning('buttonTitle: Got invalid ID: %s', buttonId);
			break;
	}
	return parts.join(' ');
}
