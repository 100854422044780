import mapboxgl from 'mapbox-gl';

import {Control, ControlButton} from './control';
import {OBJ, SIGNAL} from '../../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../../elobj';
import {DEFAULT_MAP_STYLE_URL, InteractiveMapControlMode} from '../../../constants';
import {bind} from '../../../util';
import {getLogger} from '../../../logging';

const logger = getLogger('ui.map.StyleControl');

export enum MapStyle {
	Map,
	Satellite,
}

const styleUrlMap = new Map<MapStyle, string>([
	[MapStyle.Map, DEFAULT_MAP_STYLE_URL],
	[MapStyle.Satellite, 'mapbox://styles/mapbox/satellite-streets-v11'],
]);

interface StyleControlOpts extends ElObjOpts {
	currentStyleUrl: string;
}

@OBJ
export class StyleControl extends Control {
	static mode: InteractiveMapControlMode = InteractiveMapControlMode.StylePickerMode;

	static styleUrlForMapStyle(mapStyle: MapStyle): string {
		const rv = styleUrlMap.get(mapStyle);
		if (rv === undefined) {
			logger.warning('StyleControl::styleUrlForMapStyle: No style URL defined for map style: %s', mapStyle);
			return '';
		}
		return rv;
	}

	private currentStyle: MapStyle;
	private loadingStyle: boolean;

	constructor(opts: Partial<StyleControlOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<StyleControlOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<StyleControlOpts> | null, tagName?: TagName);
	constructor(opts: Partial<StyleControlOpts> | null, root?: Element | null);
	constructor(opts: Partial<StyleControlOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<StyleControlOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<StyleControlOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<StyleControlOpts>(a, b, c);
		super(opts);
		this.currentStyle = MapStyle.Map;
		this.loadingStyle = false;
		if (opts.currentStyleUrl) {
			this.setCurrentStyleFromStyleUrl(opts.currentStyleUrl);
		}
	}

	protected _onAdd(map: mapboxgl.Map): void {
		super._onAdd(map);
		const btn = new ControlButton({
			parent: this,
		});
		this.buttons.append(btn);
		this.updateButtonForStyle(btn, this.currentStyle);
		this.connectButton(btn);
	}

	protected _onRemove(map: mapboxgl.Map): void {
		map.off('data', this.mapDataEvent);
		super._onRemove(map);
	}

	@bind
	private mapDataEvent(event: mapboxgl.MapDataEvent): void {
		if (this.loadingStyle) {
			if (event.target.isStyleLoaded()) {
				event.target.off('data', this.mapDataEvent);
				this.loadingStyle = false;
				this.styleChanged();
			}
		}
	}

	private setCurrentStyleFromStyleUrl(styleUrl: string): void {
		for (const [mapStyle, url] of styleUrlMap) {
			if (styleUrl === url) {
				this.currentStyle = mapStyle;
				return;
			}
		}
	}

	setNextStyle(): void {
		let nextStyle: MapStyle;
		switch (this.currentStyle) {
			case MapStyle.Map:
				nextStyle = MapStyle.Satellite;
				break;
			case MapStyle.Satellite:
				nextStyle = MapStyle.Map;
				break;
		}
		this.setStyle(nextStyle);
	}

	setStyle(style: MapStyle): void {
		if (style === this.currentStyle) {
			return;
		}
		this.currentStyle = style;
		let url: string;
		switch (this.currentStyle) {
			case MapStyle.Map:
				url = StyleControl.styleUrlForMapStyle(MapStyle.Map);
				break;
			case MapStyle.Satellite:
				url = StyleControl.styleUrlForMapStyle(MapStyle.Satellite);
				break;
		}
		if (this.map) {
			this.map.on('data', this.mapDataEvent);
			this.loadingStyle = true;
			this.map.setStyle(url);
		}
		for (const obj of this.buttons) {
			this.updateButtonForStyle(obj, this.currentStyle);
		}
	}

	style(): MapStyle {
		return this.currentStyle;
	}

	@SIGNAL
	styleChanged(): void {
	}

	styleUrl(): string {
		return StyleControl.styleUrlForMapStyle(this.currentStyle);
	}

	private updateButtonForStyle(button: ControlButton, style: MapStyle): void {
		let icon: string;
		let title: string;
		switch (style) {
			case MapStyle.Map:
				icon = 'satellite';
				title = 'switch to satellite view';
				break;
			case MapStyle.Satellite:
				icon = 'map';
				title = 'switch to map view';
				break;
		}
		button.setIcon(icon);
		button.setTitle(title);
	}
}
