import {BackgroundLayer, CircleLayer, FillExtrusionLayer, FillLayer, HeatmapLayer, HillshadeLayer, IControl, LineLayer, Map as MapboxMap, MapboxEvent, RasterLayer, SkyLayer, SymbolLayer} from 'mapbox-gl';

import {EventThing} from './events';
import {UI} from './ui';
import {Store} from './store';
import {featuresAtClick} from './featuresat';
import {Mode} from './modes/mode';
import {Feature} from './features/feature';
import {Polygon} from './features/polygon';
import {LineString} from './features/linestring';
import {Point} from './features/point';
import {MultiFeature} from './features/multi';
import {SimpleSelect} from './modes/simpleselect';
import {DirectSelect} from './modes/directselect';
import {DrawCircle} from './modes/drawcircle';
import {DrawPoint} from './modes/drawpoint';
import {DrawPolygon} from './modes/drawpolygon';
import {DrawLineString} from './modes/drawlinestring';
import {Static} from './modes/static';
import {bind, geoJsonNormalize, hat} from '../../../util';
import {DrawMode, MapLayerId, MapSourceId} from './constants';
import {OBJ, Obj} from '../../../obj';

interface ModeMaker {
	new(ctx: IMapboxDrawContext): Mode;
}

interface IMapboxDrawOptions {
	boxSelect: boolean;
	clickBuffer: number;
	controls: IMapboxDrawControls;
	defaultMode: string;
	displayControlsDefault: boolean;
	keybindings: boolean;
	modes: {[modeKey: string]: ModeMaker};
	styles: Array<any>;
	touchBuffer: number;
	touchEnabled: boolean;
	userProperties: boolean;
}

export interface IMapboxDrawContext {
	api: MapboxDraw;
	container: HTMLElement | null;
	events: EventThing;
	map: MapboxMap | null;
	options: IMapboxDrawOptions;
	store: Store;
	ui: UI;
}

@OBJ
export class MapboxDraw extends Obj implements IControl {
	static modes = {
		[DrawMode.DirectSelect]: DirectSelect,
		[DrawMode.DrawCircle]: DrawCircle,
		[DrawMode.DrawLineString]: DrawLineString,
		[DrawMode.DrawPoint]: DrawPoint,
		[DrawMode.DrawPolygon]: DrawPolygon,
		[DrawMode.SimpleSelect]: SimpleSelect,
		[DrawMode.Static]: Static,
	};

	private controlContainer: HTMLElement | null;
	private readonly ctx: IMapboxDrawContext;
	private enabled: boolean;
	private mapLoaded: boolean;
	private modeNameBeforeDisable: string;
	private readonly options: IMapboxDrawOptions;
	private postMapLoadExec: boolean;

	constructor(options?: Partial<IMapboxDrawOptions>) {
		super();
		this.controlContainer = null;
		this.enabled = false;
		this.mapLoaded = false;
		this.options = setupOptions(options);
		this.modeNameBeforeDisable = this.options.defaultMode;
		this.options.modes = MapboxDraw.modes;
		this.postMapLoadExec = false;
		const events = new EventThing();
		const store = new Store();
		const ui = new UI();
		this.ctx = {
			api: this,
			container: null,
			events,
			map: null,
			options: this.options,
			store,
			ui,
		};
		store.ctx = this.ctx;
		events.ctx = this.ctx;
		ui.ctx = this.ctx;
	}

	add(geojson: GeoJsonFeature | GeoJsonFeatureCollection | GeoJsonGeometry): Array<string> {
		const featureCollection: GeoJsonFeatureCollection = JSON.parse(JSON.stringify(geoJsonNormalize(geojson)));
		const ids: Array<string> = [];
		for (let i = 0; i < featureCollection.features.length; ++i) {
			const feature = featureCollection.features[i];
			if (feature.geometry === null) {
				throw new Error('Invalid geometry: null');
			}
			if (feature.geometry.type === 'GeometryCollection') {
				throw new Error('Invalid geometry: "GeometryCollection"');
			}
			const featId = (feature.id === undefined) ?
				hat() :
				String(feature.id);
			feature.id = featId;
			const feat = this.ctx.store.get(featId);
			if (feat && (feat.type === feature.geometry.type)) {
				// If a feature of that id has already been created, and we are swapping it out ...
				if (!coordsIsEqual(feat.getCoordinates(), feature.geometry.coordinates)) {
					feat.incomingCoords(feature.geometry.coordinates);
				}
			} else {
				// If the feature has not yet been created ...
				const Model = featureTypes[feature.geometry.type];
				if (Model === undefined) {
					throw new Error(`Invalid geometry type: ${feature.geometry.type}.`);
				}
				const internalFeature = new Model(this.ctx, <GeoJsonFeature<GeoJsonGeometryNoCollection>>feature);
				this.ctx.store.add(internalFeature);
			}
			ids.push(featId);
		}
		this.ctx.store.render();
		return ids;
	}

	addLayers(): void {
		// drawn features style
		const map = this.ctx.map;
		if (map) {
			map.addSource(MapSourceId.Cold, {
				data: {
					type: 'FeatureCollection',
					features: [],
				},
				type: 'geojson',
			});
			// hot features style
			map.addSource(MapSourceId.Hot, {
				data: {
					type: 'FeatureCollection',
					features: [],
				},
				type: 'geojson',
			});
			this.ctx.options.styles.forEach(style => map.addLayer(style));
		}
		this.ctx.store.setDirty(true);
		this.ctx.store.render();
	}

	changeMode(modeName: DrawMode.SimpleSelect, options?: SimpleSelectModeOpt): this;
	changeMode(modeName: DrawMode.DirectSelect, options: DirectSelectModeOpt): this;
	changeMode(modeName: DrawMode.DrawLineString, options?: DrawLineStringModeOpt): this;
	changeMode(modeName: string, options?: {[key: string]: any}): this;
	changeMode(modeName: string, options?: {[key: string]: any}): this {
		const currMode = this.getMode();
		const opts = options || {};
		switch (modeName) {
			case DrawMode.SimpleSelect: {
				if (currMode === DrawMode.SimpleSelect) {
					// Avoid changing modes just to re-select what's already
					// selected.
					this.setSelectedFeatureIds(opts.featureIds || []);
				}
				break;
			}
			case DrawMode.DirectSelect: {
				if ((currMode === DrawMode.DirectSelect) && (opts.featureId !== undefined)) {
					const selectedIds = this.ctx.store.getSelectedIds();
					if ((selectedIds.length > 0) && (opts.featureId === selectedIds[0])) {
						return this;
					}
				}
				break;
			}
		}
		this.ctx.events.changeMode(
			modeName,
			options,
			{silent: (typeof opts.silent === 'boolean') ? opts.silent : false}); // FIXME: Was true. Does it work correctly?
		return this;
	}

	combineFeatures(): this {
		this.ctx.events.combineFeatures();
		return this;
	}

	delete(featureIds: Array<string>): this {
		this.ctx.store.delete(featureIds, {silent: true});
		// If we were in direct select mode and our selected feature no longer
		// exists (because it was deleted), we need to get out of that mode.
		if ((this.getMode() === DrawMode.DirectSelect) && (this.ctx.store.getSelectedIds().length === 0)) {
			this.ctx.events.changeMode(
				DrawMode.SimpleSelect,
				undefined,
				{silent: false}); // FIXME: Was true. Does it work correctly?
		} else {
			this.ctx.store.render();
		}
		return this;
	}

	deleteAll(): this {
		this.ctx.store.delete(this.ctx.store.getAllIds(), {silent: true});
		// If we were in direct select mode, now our selected feature no
		// longer exists, so escape that mode.
		if (this.getMode() === DrawMode.DirectSelect) {
			this.ctx.events.changeMode(
				DrawMode.SimpleSelect,
				undefined,
				{silent: false}); // FIXME: Was true. Does it work correctly?
		} else {
			this.ctx.store.render();
		}
		return this;
	}

	destroy(): void {
		// Stop connect attempt in the event that control is removed before
		// map is loaded
		const ctx = this.ctx;
		this.removeLayers();
		ctx.ui.removeButtons();
		ctx.events.setEnabled(false);
		ctx.ui.clearMapClasses();
		ctx.ui.setEnabled(false);
		ctx.map = null;
		ctx.container = null;
		ctx.store.destroy();
		if (this.controlContainer && this.controlContainer.parentNode) {
			this.controlContainer.parentNode.removeChild(this.controlContainer);
		}
		this.controlContainer = null;
		this.mapLoaded = false;
		this.postMapLoadExec = false;
	}

	get<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(featureId: string): GeoJsonFeature<G, P> | null {
		const feature = this.ctx.store.get(featureId);
		return feature && <GeoJsonFeature<G, P>>feature.toGeoJSON();
	}

	getAll<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		return {
			features: <Array<GeoJsonFeature<G, P>>>this.ctx.store.getAll().map(feature => feature.toGeoJSON()),
			type: 'FeatureCollection',
		};
	}

	getFeatureIdsAt(point: IGenericPoint): Array<string> {
		const rv: Array<string> = [];
		const feats = featuresAtClick(point, null, this.ctx);
		for (let i = 0; i < feats.length; ++i) {
			const feat = feats[i];
			if (feat.properties && (feat.properties.id !== undefined)) {
				rv.push(feat.properties.id);
			}
		}
		return rv;
	}

	getMode(): string {
		return this.ctx.events.currentModeName();
	}

	getSelected<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		return {
			features: <Array<GeoJsonFeature<G, P>>>this.ctx.store.getSelected().map(feat => feat.toGeoJSON()),
			type: 'FeatureCollection',
		};
	}

	getSelectedIds(): Array<string> {
		return this.ctx.store.getSelectedIds();
	}

	getSelectedPoints(): GeoJsonFeatureCollection<GeoJsonPoint, {}> {
		return {
			type: 'FeatureCollection',
			features: this.ctx.store.getSelectedCoordinates().map(coordinate => ({
				geometry: {
					coordinates: coordinate.coordinates,
					type: 'Point',
				},
				properties: {},
				type: 'Feature',
			})),
		};
	}

	isEnabled(): boolean {
		return this.enabled;
	}

	@bind
	private mapLoadEvent(event: MapboxEvent): void {
		this.mapLoaded = true;
		event.target.off('load', this.mapLoadEvent);
		this.postMapLoad();
	}

	onAdd(map: MapboxMap): HTMLElement {
		const ctx = this.ctx;
		ctx.map = map;
		ctx.container = map.getContainer();
		this.controlContainer = ctx.ui.addButtons();
		if (map.loaded()) {
			this.postMapLoad();
		} else {
			map.on('load', this.mapLoadEvent);
			if (map.loaded() && !this.mapLoaded) {
				this.postMapLoad();
			}
		}
		return this.controlContainer;
	}

	onRemove(map: MapboxMap): void {
		// Stop connect attempt in the event that control is removed before
		// map is loaded
		this.destroy();
		this.restoreMapConfig(map);
	}

	private postMapLoad(): void {
		if (!this.postMapLoadExec) {
			this.postMapLoadExec = true;
			this.addLayers();
			if (this.enabled) {
				this._enable();
			}
		}
	}

	private removeLayers() {
		const map = this.ctx.map;
		if (map) {
			const styles = this.ctx.options.styles;
			for (let i = 0; i < styles.length; ++i) {
				const style = styles[i];
				if (map.getLayer(style.id)) {
					map.removeLayer(style.id);
				}
			}
			if (map.getSource(MapSourceId.Cold)) {
				map.removeSource(MapSourceId.Cold);
			}
			if (map.getSource(MapSourceId.Hot)) {
				map.removeSource(MapSourceId.Hot);
			}
		}
	}

	private restoreMapConfig(map: MapboxMap): void {
		this.ctx.store.restoreMapConfig(map);
	}

	set(featureCollection: GeoJsonFeatureCollection): Array<string> {
		const renderBatch = this.ctx.store.createRenderBatch();
		let toDelete: Array<string> = this.ctx.store.getAllIds().slice();
		const newIds: Array<string> = this.add(featureCollection);
		const newIdsLookup: Set<string> = new Set<string>(newIds);
		this.delete(toDelete.filter(id => !newIdsLookup.has(id)));
		renderBatch();
		return newIds;
	}

	private setBoxSelectEnabled(enable: boolean): void {
		const map = this.ctx.map;
		if (map) {
			if (enable) {
				this.ctx.store.setMapConfigValue('boxZoom', map.boxZoom.isEnabled());
				map.boxZoom.disable();
				// Need to toggle dragPan on and off or else first  dragPan
				// disable attempt in simple select doesn't work.
				map.dragPan.disable();
				map.dragPan.enable();
			} else {
				const boxZoomIsEnabled = this.ctx.store.mapConfigValue('boxZoom');
				if ((typeof boxZoomIsEnabled === 'boolean') && (map.boxZoom.isEnabled() !== boxZoomIsEnabled)) {
					if (boxZoomIsEnabled) {
						map.boxZoom.enable();
					} else {
						map.boxZoom.disable();
					}
				}
			}
		}
	}

	setButtonVisible(buttonId: string, visible: boolean): void {
		this.ctx.ui.setButtonVisible(buttonId, visible);
	}

	setEnabled(enable: boolean): void {
		if (enable === this.enabled) {
			return;
		}
		this.enabled = enable;
		if (this.enabled) {
			this._enable();
		} else {
			this._disable();
		}
	}

	private _disable(): void {
		this.modeNameBeforeDisable = this.ctx.events.currentModeName();
		this.ctx.ui.setEnabled(false);
		this.ctx.events.setEnabled(false);
		this.ctx.ui.updateMapClasses();
		this.ctx.map && this.restoreMapConfig(this.ctx.map);
	}

	private _enable(): void {
		this.ctx.map && this.storeMapConfig(this.ctx.map);
		if (this.ctx.options.boxSelect) {
			this.setBoxSelectEnabled(true);
		}
		this.ctx.events.setEnabled(true);
		this.ctx.ui.setEnabled(true);
		this.ctx.events.changeMode(this.modeNameBeforeDisable);
	}

	setFeatureProperty(featureId: string, property: string, value: any): this {
		this.ctx.store.setFeatureProperty(featureId, property, value);
		return this;
	}

	setSelectedFeatureIds(featureIds: Array<string>): void {
		// If we are changing the selection within simple select mode,
		// just change the selection instead of stopping and re-starting
		// the mode.
		//
		// Otherwise just call to change the mode with the given feature
		// IDs where they will be selected upon mode activation.
		if (this.getMode() === DrawMode.SimpleSelect) {
			if (stringSetsAreEqual(new Set<string>(featureIds), new Set(this.ctx.store.getSelectedIds()))) {
				return;
			}
			this._setSelectedFeatureIds(featureIds, false);
		} else {
			this.changeMode(DrawMode.SimpleSelect, {featureIds});
		}
	}

	private _setSelectedFeatureIds(featureIds: Array<string>, notify: boolean): void {
		this.ctx.store.setSelected(featureIds, {silent: !notify});
		this.ctx.store.render();
	}

	private storeMapConfig(map: MapboxMap): void {
		this.ctx.store.storeMapConfig(map);
	}

	trash(): this {
		this.ctx.events.trash();
		return this;
	}

	uncombineFeatures(): this {
		this.ctx.events.uncombineFeatures();
		return this;
	}
}

const staticDefaultControls: IMapboxDrawControls = Object.freeze({
	circle: true,
	combine_features: true,
	line_string: true,
	point: true,
	polygon: true,
	trash: true,
	uncombine_features: true,
});

type AnyLayer =
	| BackgroundLayer
	| CircleLayer
	| FillExtrusionLayer
	| FillLayer
	| HeatmapLayer
	| HillshadeLayer
	| LineLayer
	| RasterLayer
	| SymbolLayer
	| SkyLayer;

function addSources(layers: Array<AnyLayer>, sourceBucket: string): Array<object> {
	return layers.map(lyr => {
		if (lyr.source) {
			return lyr;
		}
		return {
			...lyr,
			id: `${lyr.id}.${sourceBucket}`,
			source: (sourceBucket === 'hot') ?
				MapSourceId.Hot :
				MapSourceId.Cold,
		};
	});
}

function setupOptions(options?: Partial<IMapboxDrawOptions>): IMapboxDrawOptions {
	options = {...(options || {})};
	const controls: IMapboxDrawControls = options.controls || {...staticDefaultControls};
	if (((typeof options.displayControlsDefault === 'boolean') && !options.displayControlsDefault)) {
		controls.combine_features = false;
		controls.line_string = false;
		controls.point = false;
		controls.polygon = false;
		controls.trash = false;
		controls.uncombine_features = false;
	}
	const rv: IMapboxDrawOptions = {
		...staticDefaultOptions,
		controls,
		...options,
	};
	rv.styles = addSources(rv.styles, 'cold').concat(addSources(rv.styles, 'hot'));
	return rv;
}

const layers = [
	{
		'id': MapLayerId.PolygonFillInactive,
		'type': 'fill',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'fill-color': '#3bb2d0',
			'fill-outline-color': '#3bb2d0',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.PolygonFillActive,
		'type': 'fill',
		'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		'paint': {
			'fill-color': '#fbb03b',
			'fill-outline-color': '#fbb03b',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.PolygonMidpoint,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['==', 'meta', 'midpoint'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.PolygonStrokeInactive,
		'type': 'line',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#3bb2d0',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.PolygonStrokeActive,
		'type': 'line',
		'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.LineInactive,
		'type': 'line',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'LineString'],
			['!=', 'mode', 'static'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#3bb2d0',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.LineActive,
		'type': 'line',
		'filter': [
			'all',
			['==', '$type', 'LineString'],
			['==', 'active', 'true'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.PolygonAndLineVertexStrokeInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.PolygonAndLineVertexInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.PointPointStrokeInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-opacity': 1,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.PointInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#3bb2d0',
		},
	},
	{
		'id': MapLayerId.PointStrokeActive,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['==', 'active', 'true'],
			['!=', 'meta', 'midpoint'],
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.PointActive,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.PolygonFillStatic,
		'type': 'fill',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		'paint': {
			'fill-color': '#404040',
			'fill-outline-color': '#404040',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.PolygonStrokeStatic,
		'type': 'line',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#404040',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.LineStatic,
		'type': 'line',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#404040',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.PointStatic,
		'type': 'circle',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#404040',
		},
	},
];

const staticDefaultOptions: IMapboxDrawOptions = Object.freeze({
	boxSelect: true,
	clickBuffer: 2,
	controls: staticDefaultControls,
	defaultMode: DrawMode.SimpleSelect,
	displayControlsDefault: true,
	keybindings: true,
	modes: {},
	styles: layers,
	touchBuffer: 25,
	touchEnabled: true,
	userProperties: false,
});

interface FeatureMaker {
	new(ctx: IMapboxDrawContext, feature: GeoJsonFeature<GeoJsonGeometryNoCollection>): Feature;
}

const featureTypes: {[key: string]: FeatureMaker} = {
	Polygon,
	LineString,
	Point,
	MultiPolygon: <FeatureMaker>MultiFeature,
	MultiLineString: <FeatureMaker>MultiFeature,
	MultiPoint: <FeatureMaker>MultiFeature,
};

function stringSetsAreEqual(a: Set<string>, b: Set<string>): boolean {
	if (a.size !== b.size) {
		return false;
	}
	return JSON.stringify(Array.from(a).map(id => id).sort()) === JSON.stringify(Array.from(b).map(id => id).sort());
}

type _Coords = GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][];

function coordsIsEqual(a: _Coords, b: _Coords): boolean {
	if (a.length !== b.length) {
		return false;
	}
	if (a.length === 0) {
		return true;
	}
	// a, b have length >= 1
	if (Array.isArray(a[0])) {
		if (!Array.isArray(b[0])) {
			return false;
		}
		// ring/line
		return coordsIsEqual(a[0], b[0]);
	} else {
		// coords
		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
	}
	return true;
}
