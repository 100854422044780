import {range} from './util';

const EPSILON = 1e-14;
const LL_EPSILON = 1e-11;

export class Tile {
	x: number;
	y: number;
	z: number;

	constructor(x: number, y: number, z: number) {
		this.x = x;
		this.y = y;
		this.z = z;
	}

	toString(): string {
		return `${this.z}/${this.x}/${this.y}/`;
	}
}

export function quadkey(x: number, y: number, z: number): string {
	const parts: Array<string> = [];
	for (let i = z; i > 0; --i) {
		let digit: number = 0;
		const mask = 1 << (i - 1);
		if ((x & mask) !== 0) {
			digit += 1;
		}
		if ((y & mask) !== 0) {
			digit += 2;
		}
		parts.push(String(digit));
	}
	return parts.join('');
}

function _radians(degrees: number): number {
	return degrees * Math.PI / 180;
}

function _xy(lng: number, lat: number, truncate: boolean = false): [number, number] {
	if (truncate) {
		[lng, lat] = truncateLngLat(lng, lat);
	}
	const x = lng / 360.0 + 0.5;
	const sinlat = Math.sin(_radians(lat));
	const y = 0.5 - 0.25 * Math.log((1.0 + sinlat) / (1.0 - sinlat)) / Math.PI;
	return [x, y];
}

function tile(lng: number, lat: number, zoom: number, truncate: boolean = false): Tile {
	const [x, y] = _xy(lng, lat, truncate);
	const Z2 = Math.pow(2, zoom);
	let xtile: number;
	if (x <= 0) {
		xtile = 0;
	} else if (x >= 1) {
		xtile = Math.trunc(Z2 - 1);
	} else {
		xtile = Math.trunc(Math.floor((x + EPSILON) * Z2));
	}
	let ytile: number;
	if (y <= 0) {
		ytile = 0;
	} else if (y >= 1) {
		ytile = Math.trunc(Z2 - 1);
	} else {
		ytile = Math.trunc(Math.floor((y + EPSILON) * Z2));
	}
	return new Tile(xtile, ytile, zoom);
}

export function *tiles(west: number, south: number, east: number, north: number, zooms: Array<number>, truncate: boolean = false): IterableIterator<Tile> {
	if (truncate) {
		[west, south] = truncateLngLat(west, south);
		[east, north] = truncateLngLat(east, north);
	}
	let bboxes: Array<[number, number, number, number]>;
	if (west > east) {
		const bboxWest: [number, number, number, number] = [-180.0, south, east, north];
		const bboxEast: [number, number, number, number] = [west, south, 180.0, north];
		bboxes = [bboxWest, bboxEast];
	} else {
		bboxes = [[west, south, east, north]];
	}
	for (let i = 0; i < bboxes.length; ++i) {
		let [w, s, e, n] = bboxes[i];
		w = Math.max(-180.0, w);
		s = Math.max(-85.051129, s);
		e = Math.min(180.0, e);
		n = Math.min(85.051129, n);
		for (let k = 0; k < zooms.length; ++k) {
			const z = zooms[k];
			const ulTile = tile(w, n, z);
			const lrTile = tile(e - LL_EPSILON, s + LL_EPSILON, z);
			for (const n of range(ulTile.x, lrTile.x + 1)) {
				for (const p of range(ulTile.y, lrTile.y + 1)) {
					yield new Tile(n, p, z);
				}
			}
		}
	}
}

function truncateLngLat(lng: number, lat: number): [number, number] {
	if (lng > 180.0) {
		lng = 180.0;
	} else if (lng < -180.0) {
		lng = -180.0;
	}
	if (lat > 90.0) {
		lat = 90.0;
	} else if (lat < -90.0) {
		lat = -90.0;
	}
	return [lng, lat];
}
