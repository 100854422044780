import {PushButton, PushButtonOpts} from './pushbutton';
import {OBJ, SIGNAL} from '../obj';
import {ElObj, elObjOpts} from '../elobj';
import {bind, stringIterableToStringArray} from '../util';

export interface ToolButtonOpts extends Omit<PushButtonOpts, 'text'> {
	icon: string;
	outlined: boolean;
}

@OBJ
export class ToolButton extends PushButton {
	constructor(opts: Partial<ToolButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ToolButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ToolButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ToolButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<ToolButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ToolButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ToolButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ToolButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-icon-button',
			opts.outlined ? 'material-icons-outlined' : 'material-icons',
			'lb-tool-button',
			...classNames,
		];
		super(opts);
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
	}

	protected addEventListeners(): void {
		super.addEventListeners();
		this.addEventListener('mouseenter', this.domEvent);
		this.addEventListener('mouseleave', this.domEvent);
	}

	@bind
	protected domEvent(event: Event): void {
		switch (event.type) {
			case 'mouseenter':
				this.domMouseEnterEvent(<MouseEvent>event);
				break;
			case 'mouseleave':
				this.domMouseLeaveEvent(<MouseEvent>event);
				break;
			default:
				super.domEvent(event);
				break;
		}
	}

	protected domMouseEnterEvent(event: MouseEvent): void {
		this.pointerEntered();
	}

	protected domMouseLeaveEvent(event: MouseEvent): void {
		this.pointerLeft();
	}

	icon(): string {
		return this.text().trim();
	}

	@SIGNAL
	private pointerEntered(): void {
	}

	@SIGNAL
	private pointerLeft(): void {
	}

	protected removeEventListeners(): void {
		super.removeEventListeners();
		this.removeEventListener('mouseenter', this.domEvent);
		this.removeEventListener('mouseleave', this.domEvent);
	}

	setIcon(icon?: string | null): void {
		if (icon) {
			this.setText(icon);
		} else {
			this.removeText();
		}
	}
}

