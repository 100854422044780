import {MDCCheckbox} from '@material/checkbox';

import {AbstractButton} from './abstractbutton';
import {OBJ, SIGNAL} from '../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {CheckState} from '../constants';
import {bind, stringIterableToStringArray} from '../util';
import {Variant} from '../variant';

export interface CheckboxOpts extends ElObjOpts {
	checkState: CheckState;
	inputId: string;
	inputName: string;
}

@OBJ
export class Checkbox extends AbstractButton {
	private ctrl: MDCCheckbox;
	private inputEl: ElObj;

	constructor(opts: Partial<CheckboxOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<CheckboxOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<CheckboxOpts> | null, tagName?: TagName);
	constructor(opts: Partial<CheckboxOpts> | null, root?: Element | null);
	constructor(opts: Partial<CheckboxOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<CheckboxOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<CheckboxOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<CheckboxOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-checkbox',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.inputEl = new ElObj({
			attributes: [
				['type', 'checkbox'],
			],
			classNames: 'mdc-checkbox__native-control',
			parent: this,
			tagName: 'input',
		});
		const bg = new ElObj({
			classNames: 'mdc-checkbox__background',
			parent: this,
			tagName: 'div',
		});
		const checkmark = new ElObj({
			attributes: [
				['viewBox', '0 0 24 24'],
			],
			classNames: 'mdc-checkbox__checkmark',
			namespace: 'http://www.w3.org/2000/svg',
			parent: bg,
			tagName: 'svg',
		});
		const path = new ElObj({
			attributes: [
				['d', 'M1.73,12.91 8.1,19.28 22.79,4.59'],
				['fill', 'none'],
			],
			classNames: 'mdc-checkbox__checkmark-path',
			namespace: 'http://www.w3.org/2000/svg',
			parent: checkmark,
			tagName: 'path',
		});
		new ElObj({
			classNames: 'mdc-checkbox__mixedmark',
			parent: bg,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-checkbox__ripple',
			parent: this,
			tagName: 'div',
		});
		this.ctrl = new MDCCheckbox(this.elem);
		this.setCheckable(true);
		if (opts.checkState !== undefined) {
			this.setCheckState(opts.checkState);
		}
		if (opts.inputId !== undefined) {
			this.setInputId(opts.inputId);
		}
		if (opts.inputName !== undefined) {
			this.setInputName(opts.inputName);
		}
		this.init();
	}

	protected addEventListeners(): void {
		this.addEventListener('change', this.domEvent);
		this.addEventListener('mousedown', this.domEvent);
		this.addEventListener('mouseup', this.domEvent);
		this.addEventListener('click', this.domEvent);
	}

	blur(): void {
		this.inputEl.blur();
	}

	checkState(): CheckState {
		return this.inputEl.checkState();
	}

	destroy(): void {
		this.removeEventListeners();
		this.ctrl.destroy();
		this.inputEl.destroy();
		super.destroy();
	}

	@bind
	protected domEvent(event: Event): void {
		switch (event.type) {
			case 'change':
				const state = this.checkState();
				this.stateChanged(state);
				this.toggled(state === CheckState.Checked);
				break;
			default:
				event.stopImmediatePropagation();
				event.stopPropagation();
				break;
		}
	}

	focus(): void {
		this.inputEl.focus();
	}

	hasFocus(): boolean {
		return this.inputEl.hasFocus();
	}

	protected init(): void {
		this.addEventListeners();
	}

	inputId(): string {
		return this.inputEl.attribute('id') || '';
	}

	inputName(): string {
		return this.inputEl.attribute('name') || '';
	}

	property(name: string): Variant {
		switch (name) {
			case 'checked':
				return new Variant(this.isChecked());
			case 'checkState':
				return new Variant(this.checkState());
			default:
				return super.property(name);
		}
	}

	protected removeEventListeners(): void {
		this.removeEventListener('change', this.domEvent);
	}

	reportValidity(): boolean {
		return this.inputEl.reportValidity();
	}

	setCheckState(state: CheckState): void {
		if (state === this.checkState()) {
			return;
		}
		if (state === CheckState.PartiallyChecked) {
			this.ctrl.indeterminate = true;
		} else {
			this.ctrl.checked = (state === CheckState.Checked);
		}
		const newState = this.checkState();
		if (state === newState) {
			this.stateChanged(newState);
		}
	}

	setDisabled(disabled: boolean): void {
		this.inputEl.setDisabled(disabled);
		this.setClass(disabled, 'mdc-checkbox--disabled');
		this.ctrl.disabled = disabled;
	}

	setInputId(id: string | null): void {
		if (typeof id === 'string') {
			this.inputEl.setAttribute('id', id);
		} else {
			this.inputEl.removeAttribute('id');
		}
	}

	setInputName(name: string | null): void {
		if (typeof name === 'string') {
			this.inputEl.setAttribute('name', name);
		} else {
			this.inputEl.removeAttribute('name');
		}
	}

	setProperty(name: string, value: Variant): boolean {
		switch (name) {
			case 'checked':
				this.setChecked(value.toBoolean());
				return true;
			case 'checkState':
				this.setCheckState(value.toNumber());
				return true;
			case 'disabled':
				this.setDisabled(value.toBoolean());
				return true;
			default:
				return super.setProperty(name, value);
		}
	}

	@SIGNAL
	stateChanged(state: number): void {
	}
}
