import {getLogger} from '../../logging';
import {ElObj} from '../../elobj';

const logger = getLogger('projectlistview');

export function init(): void {
	const root = ElObj.body().querySelector('#id_lb-main');
	if (root) {
		import('./view')
			.then(mod => (new mod.ProjectListView(root)));
	} else {
		logger.error('Root element was not found');
	}
}
