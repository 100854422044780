import {list} from './tools';
import {Variant} from './variant';
import {getLogger} from './logging';
import {assert, stringRepeat} from './util';
import {MetaType} from './constants';
import {ChildEvt, Evt} from './evt';

const logger = getLogger('Obj');

function dumpRecursive(level: number, obj: Obj | null) {
	if (!obj) {
		return;
	}
	const buf = stringRepeat(' ', level / 2 * 8);
	const name = obj.objectName();
	console.log('%s%s::%s', buf, obj.metaObject().className(), name);
	const children = obj.children();
	if (children.size() > 0) {
		for (let i = 0; i < children.size(); ++i) {
			dumpRecursive(level + 1, children.at(i));
		}
	}
}

function findChildHelper<T extends new (...args: any) => AbstractObj>(parent: AbstractObj, childType: T, findRecursively: boolean, name?: string): AbstractObj | null {
	const children = parent.children();
	for (let i = 0; i < children.size(); ++i) {
		const child = children.at(i);
		if ((child instanceof childType) && (!name || (child.objectName() === name))) {
			return child;
		}
	}
	if (findRecursively) {
		for (let i = 0; i < children.size(); ++i) {
			const child = findChildHelper(children.at(i), childType, findRecursively, name);
			if (child) {
				return child;
			}
		}
	}
	return null;
}

function findChildrenHelper<T extends new (...args: any) => AbstractObj>(parent: AbstractObj, childType: T): list<AbstractObj> {
	const rv = new list<AbstractObj>();
	const children = parent.children();
	for (let i = 0; i < children.size(); ++i) {
		const child = children.at(i);
		if (child instanceof childType) {
			rv.append(child);
		}
		rv.append(findChildrenHelper(child, childType));
	}
	return rv;
}

const propMetadataKey = Symbol('PROP');
const signalMetadataKey = Symbol('SIGNAL');
const slotMetadataKey = Symbol('SLOT');

interface ClsInfo {
	className: string;
	propInfos: Array<IPropInfo>;
	signalNames: Array<string>;
	slotNames: Array<string>;
}

const protoInfoMap: Map<Object, ClsInfo> = new Map();

function clsInfo(proto: Object): ClsInfo {
	let rv = protoInfoMap.get(proto);
	if (rv === undefined) {
		rv = {
			className: '',
			propInfos: [],
			signalNames: [],
			slotNames: [],
		};
		protoInfoMap.set(proto, rv);
	}
	return rv;
}

function likelyAnObjMethodAndNotSomeGetterSetterOrWhatever(desc: PropertyDescriptor): boolean {
//     configurable?: boolean;
//     enumerable?: boolean;
//     value?: any;   <----------------- If this descriptor describes a method, the function implementation is set on `value` member
//     writable?: boolean;
//     get?(): any;
//     set?(v: any): void;
	return typeof desc.value === 'function';
}

export function OBJ(ctor: Function) {
	const nfo = clsInfo(ctor.prototype);
	nfo.className = ctor.name;
	const superCls = Object.getPrototypeOf(ctor);
	const mobj = new MetaObj(ctor.prototype);
	mobj.m_className = nfo.className;
	mobj.m_propInfos = nfo.propInfos;
	mobj.m_sigNames = nfo.signalNames;
	mobj.m_slotNames = nfo.slotNames;
	(<typeof AbstractObj>ctor).metaobj = mobj;
	mobj.superMeta = (superCls === Function.prototype) ?
		null :
		superCls.metaobj;

}

export interface IPropDecl {
	NOTIFY: string;
	USER: boolean;
	WRITE: string;
}

export interface IPropInfo extends IPropDecl {
	READ: string;
	TYPE: any;
}

export function PROP(decl?: Partial<IPropDecl>) {
	const dec: Partial<IPropDecl> = decl || {};
	return function <T extends (...args: any) => any>(target: Object, propName: string, desc: TypedPropertyDescriptor<T>) {
		const infos: Array<IPropInfo> = Reflect.getOwnMetadata(propMetadataKey, target) || [];
		if (propName) {
			const info: IPropInfo = {
				NOTIFY: dec.NOTIFY || '',
				READ: propName,
				TYPE: Reflect.getMetadata('design:returntype', target, propName),
				USER: dec.USER || false,
				WRITE: dec.WRITE || '',
			};
			const idx = infos.findIndex(i => (i.READ === info.READ));
			if (idx >= 0) {
				infos[idx] = info;
			} else {
				infos.push(info);
			}
			clsInfo(target).propInfos.push(info);
			Reflect.defineMetadata(propMetadataKey, [...infos], target);
		}
	};
}

export function SIGNAL<T extends (...args: any) => any>(target: Object, propName: string, desc: TypedPropertyDescriptor<T>): void {
	clsInfo(target).signalNames.push(propName);
	const names = Reflect.getOwnMetadata(signalMetadataKey, target) || [];
	Reflect.defineMetadata(signalMetadataKey, [...names, propName], target);
	const initialDescriptorValue = desc.value;
	if (initialDescriptorValue) {
		desc.value = <T>function (this: Obj, ...args: Parameters<T>) {
			MetaObj.activate(this, this.metaObject(), propName, args);
		};
	}
}

export function SLOT<T extends (...args: any) => any>(target: Object, propName: string, desc: TypedPropertyDescriptor<T>): void {
	clsInfo(target).slotNames.push(propName);
	const names = Reflect.getOwnMetadata(slotMetadataKey, target) || [];
	Reflect.defineMetadata(slotMetadataKey, [...names, propName], target);
}

export class MetaMethod {
	// No implementation
	isValid(): boolean {
		return false;
	}
}

export class MetaProperty {
	metaobj: MetaObj | null;
	nameTmp: string;
	typeTmp: MetaType;
	user: boolean;
	writable: boolean;

	constructor() {
		this.metaobj = null;
		this.nameTmp = '';
		this.typeTmp = MetaType.Invalid;
		this.user = false;
		this.writable = true;
	}

	hasNotifySignal(): boolean {
		// No implementation
		return false;
	}

	isReadable(): boolean {
		// No implementation
		return Boolean(this.nameTmp);
	}

	isUser(obj: Obj | null = null): boolean {
		// No implementation
		return this.user;
	}

	isValid(): boolean {
		return (this.metaobj !== null) && this.isReadable();
	}

	isWritable(): boolean {
		return this.writable;
	}

	name(): string {
		return this.nameTmp;
	}

	notifySignal(): MetaMethod {
		// No implementation
		return new MetaMethod();
	}

	type(): MetaType {
		return this.typeTmp;
	}

	typeName(): string {
		if (this.typeTmp in MetaType) {
			return MetaType[this.typeTmp];
		}
		return '';
	}
}

export class MetaObj {
	static activate(sender: Obj, metaObj: MetaObj, signalName: string, argv: Array<any>): void {
		if (sender.d.blockSig) {
			return;
		}
		const senderConnData = sender.d.connections;
		const senderConns = senderConnData.connectionsForSignal(signalName);
		for (let i = 0; i < senderConns.length; ++i) {
			const conn = senderConns[i];
			if (!conn) {
				continue;
			}
			const rcvr = conn.receiver;
			if (!rcvr) {
				continue;
			}
			const methName = conn.methodName;
			if (!methName) {
				continue;
			}
			const senderData = new Sender(rcvr, sender);
			const desc = this.methodDescriptor(rcvr.metaObject(), methName);
			if (desc) {
				const callFunc = desc.value;
				if (callFunc && (typeof callFunc === 'function')) {
					callFunc.apply(rcvr, argv);
				}
			}
			senderData.destroy();
		}
	}

	static methodDescriptor(mobj: MetaObj, method: string): PropertyDescriptor | null {
		let curr: MetaObj | null = mobj;
		while (curr) {
			const desc = Object.getOwnPropertyDescriptor(curr.objProto, method);
			if (desc) {
				return desc;
			}
			curr = curr.superMeta;
		}
		return null;
	}

	m_className: string;
	m_propInfos: Array<IPropInfo>;
	m_sigNames: Array<string>;
	m_slotNames: Array<string>;
	objProto: Object;
	superMeta: MetaObj | null;

	constructor(objProto: Object) {
		this.objProto = objProto;
		this.superMeta = null;
		this.m_className = '';
		this.m_propInfos = [];
		this.m_sigNames = [];
		this.m_slotNames = [];
	}

	className(): string {
		return this.m_className;
	}

	inherits(other: MetaObj): boolean {
		let m: MetaObj | null = this;
		do {
			if (other === m) {
				return true;
			}
		} while ((m = m.superMeta));
		return false;
	}

	superClass(): MetaObj | null {
		return this.superMeta;
	}

	userProperty(): MetaProperty {
		// const infos = allPropInfo(this);
		for (let i = 0; i < this.m_propInfos.length; ++i) {
			const info = this.m_propInfos[i];
			if (info.USER) {
				const rv = new MetaProperty();
				rv.metaobj = this;
				rv.nameTmp = info.READ;
				rv.typeTmp = tmpTyp(info.TYPE);
				rv.user = info.USER;
				rv.writable = Boolean(info.WRITE);
				return rv;
			}
		}
		return new MetaProperty();
	}
}

function tmpTyp(thing: any): MetaType {
	try {
		if (thing === String) {
			return MetaType.String;
		}
		if (thing === Boolean) {
			return MetaType.Boolean;
		}
		if (thing === Number) {
			return MetaType.Number;
		}
		if (thing === null) {
			return MetaType.Null;
		}
		return MetaType.Invalid;
	} catch {
		return MetaType.Invalid;
	}
}

export class Sender {
	previous: Sender | null;
	receiver: Obj | null;
	sender: Obj | null;

	constructor(receiver: Obj | null = null, sender: Obj | null = null) {
		this.previous = null;
		this.receiver = receiver;
		this.sender = sender;
		if (this.receiver) {
			const rcvrConnData = this.receiver.d.connections;
			this.previous = rcvrConnData.currentSender;
			rcvrConnData.currentSender = this;
		}
	}

	destroy(): void {
		if (this.receiver) {
			this.receiver.d.connections.currentSender = this.previous;
		}
		this.previous = null;
		this.receiver = null;
		this.sender = null;
	}

	receiverDeleted(): void {
		let s: Sender | null = this;
		while (s) {
			s.receiver = null;
			s = s.previous;
		}
	}
}

export function propMeta(target: Object): Array<IPropInfo> {
	return Reflect.getOwnMetadata(propMetadataKey, target) || [];
}

export function signalMeta(target: Object): Array<string> {
	return Reflect.getOwnMetadata(signalMetadataKey, target) || [];
}

export function slotMeta(target: Object): Array<string> {
	return Reflect.getOwnMetadata(slotMetadataKey, target) || [];
}

export class Connection {
	methodName: string | null;
	receiver: Obj | null;
	sender: Obj | null;
	signalName: string | null;

	constructor() {
		this.methodName = null;
		this.receiver = null;
		this.sender = null;
		this.signalName = null;
	}

	isValid(): boolean {
		return !(this.methodName && this.receiver && this.sender && this.signalName);
	}
}

export class ConnectionData {
	currentSender: Sender | null;
	senderConnections: Array<Connection>;
	signalConnections: Map<string, Array<Connection>>;

	constructor() {
		this.currentSender = null;
		this.senderConnections = [];
		this.signalConnections = new Map();
	}

	connectionsForSignal(signalName: string): Array<Connection> {
		let rv = this.signalConnections.get(signalName);
		if (rv === undefined) {
			rv = [];
			this.signalConnections.set(signalName, rv);
		}
		return rv;
	}

	removeConnection(conn: Connection): void {
		const rcvr = conn.receiver;
		assert(rcvr);
		const sender = conn.sender;
		assert(sender);
		const signalName = conn.signalName;
		assert(signalName);
		const senderConnData = sender.d.connections;
		const signalConns = senderConnData.connectionsForSignal(signalName);
		conn.receiver = null;
		let idx = signalConns.indexOf(conn);
		if (idx >= 0) {
			signalConns.splice(idx, 1);
		}
		const rcvrConnData = rcvr.d.connections;
		const rcvrSenderConns = rcvrConnData.senderConnections;
		idx = rcvrSenderConns.indexOf(conn);
		if (idx >= 0) {
			rcvrSenderConns.splice(idx, 0);
		}
	}
}

export class ExtraData {
	eventFilters: list<AbstractObj> = new list();
	objName: string = '';
	propertyNames: list<string> = new list();
	propertyValues: Array<Variant> = [];
	runningTimers: Array<number> = [];
}

export class ObjPrivate {
	static allObjCount: number = 0;

	blockSig: boolean = false;
	children: list<AbstractObj> = new list();
	connections: ConnectionData = new ConnectionData();
	createdNumber!: number; // Assigned within Obj constructor
	currentChildBeingDeleted: Obj | null = null;
	deleteLaterCalled: boolean = false;
	extraData: ExtraData | null = null;
	isComponent: boolean = false;
	isDeletingChildren: boolean = false;
	isWindow: boolean = false;
	parent: Obj | null = null;
	qq!: Obj; // Assigned within Obj constructor
	receiveChildEvents: boolean = true;
	sendChildEvents: boolean = true;
	wasDeleted: boolean = false;

	get q(): Obj {
		return this.qq;
	}

	deleteChildren(): void {
		assert(!this.isDeletingChildren, 'Obj::isDeletingChildren already set. Did this function recurse?');
		this.isDeletingChildren = true;
		for (let i = 0; i < this.children.size(); ++i) {
			this.currentChildBeingDeleted = this.children.at(i);
			this.currentChildBeingDeleted.destroy();
		}
		this.children.clear();
		this.currentChildBeingDeleted = null;
		this.isDeletingChildren = false;
	}

	setParentHelper(parent?: Obj | null): void {
		const newParent = parent || null;
		const q = this.q;
		assert(q !== newParent, 'Cannot parent an Obj to itself.');
		if (newParent === this.parent) {
			return;
		}
		if (this.parent) {
			const parentD = this.parent.d;
			if (parentD.isDeletingChildren && this.wasDeleted && (parentD.currentChildBeingDeleted === q)) {
				// Don't do anything since Obj::deleteChildren()
				// already cleared our entry in parentD.children.
			} else {
				const idx = parentD.children.indexOf(q);
				if (idx < 0) {
					// We're probably recursing into setParent(). Don't do
					// anything.
				} else if (parentD.isDeletingChildren) {
					parentD.children.at(idx).destroy();
				} else {
					parentD.children.removeAt(idx);
					const evt = new ChildEvt(Evt.ChildRemoved, q);
					// window.StatApp.sendEvent(this.parent, evt);
				}
			}
		}
		this.parent = newParent;
		if (this.parent) {
			const parentD = this.parent.d;
			parentD.children.append(q);
			if (!this.isComponent) {
				const evt = new ChildEvt(ChildEvt.ChildAdded, q);
				// window.StatApp.sendEvent(this.parent, evt);
			}
		}
	}

	toString(): string {
		return `${this.q.metaObject().className()} (Data)`;
	}
}

export interface ObjOpts {
	dd: ObjPrivate;
	parent: AbstractObj;
}

export function objOpts<T extends ObjOpts = ObjOpts>(a?: Partial<T> | ObjPrivate | AbstractObj | null, b?: ObjPrivate | AbstractObj | null, c?: AbstractObj | null): Partial<T> {
	let dd: ObjPrivate | null = null;
	let opts: Partial<T> = {};
	let parent: AbstractObj | null = null;
	if (a) {
		if (a instanceof ObjPrivate) {
			dd = a;
		} else if (a instanceof AbstractObj) {
			parent = a;
		} else {
			opts = {...a};
		}
	}
	if (b) {
		if (b instanceof ObjPrivate) {
			dd = b;
		} else {
			parent = b;
		}
	}
	if (c) {
		parent = c;
	}
	if (dd) {
		opts.dd = dd;
	}
	if (parent) {
		opts.parent = parent;
	}
	dd = null;
	parent = null;
	return opts;
}

@OBJ
export abstract class AbstractObj {
	static metaobj: MetaObj;

	static connect(sender: AbstractObj, signalName: string, receiver: AbstractObj, methodName: string): Connection {
		// Ensure signal exists for sender
		// Ensure method (slot/signal) exists for receiver
		const senderMeta = sender.metaObject();
		const senderHasSignal = this.senderHasSignal(senderMeta, signalName);
		if (!senderHasSignal) {
			logger.warning(`AbstractObj::connect signal "${signalName}" not found for sender ${senderMeta.className()}`);
			return new Connection();
		}
		const rcvrMeta = receiver.metaObject();
		const rcvrHasMethod = this.receiverHasMethod(rcvrMeta, methodName);
		if (!rcvrHasMethod) {
			logger.warning(`AbstractObj::connect method "${methodName}" not found for receiver ${rcvrMeta.className()}`);
			return new Connection();
		}
		const conn = new Connection();
		conn.sender = sender;
		conn.signalName = signalName;
		conn.receiver = receiver;
		conn.methodName = methodName;
		const senderConnData = sender.d.connections;
		const senderSignalConns = senderConnData.connectionsForSignal(signalName);
		senderSignalConns.push(conn);
		const rcvrConnData = receiver.d.connections;
		const rcvrSenderConns = rcvrConnData.senderConnections;
		rcvrSenderConns.push(conn);
		return conn;
	}

	static disconnect(sender: AbstractObj, signalName?: string | null, receiver?: AbstractObj | null, methodName?: string | null): boolean;
	static disconnect(connection: Connection): boolean;
	static disconnect(senderOrConn: AbstractObj | Connection, signalName: string | null = null, receiver: AbstractObj | null = null, methodName: string | null = null): boolean {
		if (senderOrConn instanceof Connection) {
			if (senderOrConn.receiver && senderOrConn.sender && senderOrConn.signalName) {
				senderOrConn.sender.d.connections.removeConnection(senderOrConn);
				return true;
			}
			return false;
		}
		const sender = senderOrConn;
		let rv: boolean = false;
		if (receiver === null && methodName !== null) {
			logger.warning('AbstractObj::disconnect: Unexpected null parameter');
			return rv;
		}
		let senderMeta: MetaObj | null = sender.metaObject();
		if (signalName) {
			const senderHasSignal = this.senderHasSignal(senderMeta, signalName);
			if (!senderHasSignal) {
				return rv;
			}
		}
		if (methodName && receiver) {
			const rcvrMeta = receiver.metaObject();
			const rcvrHasMethod = this.receiverHasMethod(rcvrMeta, methodName);
			if (!rcvrHasMethod) {
				return rv;
			}
		}
		const senderConnData = sender.d.connections;
		if (signalName) {
			const conns = senderConnData.connectionsForSignal(signalName);
			for (let i = 0; i < conns.length; ++i) {
				const conn = conns[i];
				const rcvr = conn.receiver;
				if (rcvr && ((receiver === null) || ((rcvr === receiver) && ((methodName === null) || (conn.methodName === methodName))))) {
					if (conn.receiver) {
						senderConnData.removeConnection(conn);
					}
					rv = true;
				}
			}
		}
		return rv;
	}

	static receiverHasMethod(rcvrMeta: MetaObj, method: string): boolean {
		let curr: MetaObj | null = rcvrMeta;
		while (curr) {
			if ((curr.m_slotNames.indexOf(method) >= 0) || (curr.m_sigNames.indexOf(method) >= 0)) {
				return true;
			}
			curr = curr.superMeta;
		}
		return false;
	}

	static senderHasSignal(senderMeta: MetaObj, signal: string): boolean {
		let curr: MetaObj | null = senderMeta;
		while (curr) {
			if (curr.m_sigNames.indexOf(signal) >= 0) {
				return true;
			}
			curr = curr.superMeta;
		}
		return false;
	}

	dd: ObjPrivate;

	constructor(opts: Partial<ObjOpts>, dd?: ObjPrivate | null, parent?: AbstractObj | null);
	constructor(dd: ObjPrivate | null, parent?: AbstractObj | null);
	constructor(opts: Partial<ObjOpts>, parent?: AbstractObj | null);
	constructor(parent?: AbstractObj | null);
	constructor(opts?: Partial<ObjOpts>);
	constructor(a?: Partial<ObjOpts> | ObjPrivate | AbstractObj | null, b?: ObjPrivate | AbstractObj | null, c?: AbstractObj | null) {
		const createdNumber = ++ObjPrivate.allObjCount;
		const opts = objOpts(a, b, c);
		this.dd = opts.dd || new ObjPrivate();
		this.dd.qq = this;
		this.dd.createdNumber = createdNumber;
		// if (!this.isComponentType()) {
		// 	this.setParent(parent);
		// }
		this.setParent(opts.parent);
	}

	blockSignals(block: boolean): boolean {
		// If block is true, signals emitted by this object are blocked
		// (i.e., emitting a signal will not invoke anything connected to it).
		// If block is false, no such blocking will occur.
		//
		// The return value is the previous value of signalsBlocked().
		//
		// Note that the destroyed() signal will be emitted even if the
		// signals for this object have been blocked.
		//
		// Signals emitted while being blocked are not buffered.
		const d = this.d;
		const previous = d.blockSig;
		d.blockSig = block;
		return previous;
	}

	protected childEvent(event: ChildEvt): void {
	}

	children(): list<AbstractObj> {
		return this.dd.children;
	}

	connect(sender: AbstractObj, signalName: string, methodName: string): Connection {
		return AbstractObj.connect(sender, signalName, this, methodName);
	}

	get d(): ObjPrivate {
		return this.dd;
	}

	@SLOT
	deleteLater(): void {
		// window.StatApp.sendEvent(this, new Evt(Evt.DeferredDelete));
	}

	destroy(): void {
		const d = this.dd;
		d.wasDeleted = true;
		if (!d.isComponent) {
			this.destroyed(this);
		}
		const connData = this.d.connections;
		if (connData.currentSender) {
			connData.currentSender.receiverDeleted();
			connData.currentSender = null;
		}
		// Disconnect all receivers
		for (const conns of connData.signalConnections.values()) {
			for (const conn of conns) {
				assert(conn.receiver);
				connData.removeConnection(conn);
			}
		}
		// Disconnect all senders
		for (let i = 0; i < connData.senderConnections.length; ++i) {
			const conn = connData.senderConnections[i];
			if (conn.sender && conn.receiver) {
				const senderData = conn.sender.d.connections;
				senderData.removeConnection(conn);
			}
		}
		connData.senderConnections.length = 0;
		connData.senderConnections = [];
		connData.signalConnections.clear();
		connData.signalConnections = new Map();
		if (d.children.size() > 0) {
			d.deleteChildren();
		}
		if (d.parent) {
			d.setParentHelper(null);
		}
	}

	@SIGNAL
	destroyed(obj: AbstractObj): void {
	}

	disconnect(signalName?: string | null, receiver?: AbstractObj | null, methodName?: string | null): boolean;
	disconnect(receiver: AbstractObj, methodName?: string | null): boolean;
	disconnect(signalNameOrRcvr: AbstractObj | string | null = null, receiverOrMethodName: AbstractObj | string | null = null, methodName: string | null = null): boolean {
		if (signalNameOrRcvr instanceof AbstractObj && (receiverOrMethodName === null || (typeof receiverOrMethodName === 'string'))) {
			return AbstractObj.disconnect(this, null, signalNameOrRcvr, receiverOrMethodName);
		}
		return AbstractObj.disconnect(this, <string | null>signalNameOrRcvr, <AbstractObj | null>receiverOrMethodName, methodName);
	}

	dumpObjectInfo() {
		const d = this.dd;
		const p = d.parent ?
			`${d.parent.metaObject().className()}::${d.parent.objectName()}` :
			'null';
		const parts = [
			`object name: ${this.objectName()}`,
			`class name: ${this.metaObject().className()}`,
			`parent: ${p}`,
			`children count: ${d.children.size()}`,
		];
		console.log(parts.join('\n'));
	}

	dumpObjectTree(): void {
		dumpRecursive(0, this);
	}

	findChild<T extends new (...args: any) => any>(childType: T, name: string, findRecursively?: boolean): AbstractObj | null;
	findChild<T extends new (...args: any) => any>(childType: T, findRecursively?: boolean): AbstractObj | null;
	findChild<T extends new (...args: any) => any>(childType: T, name?: string): AbstractObj | null;
	findChild<T extends new (...args: any) => any>(a: T, b?: boolean | string, c?: boolean): AbstractObj | null {
		let findRecursively: boolean = true;
		let name: string | undefined = undefined;
		if (b !== undefined) {
			if (typeof b === 'string') {
				name = b;
			} else {
				findRecursively = b;
			}
		}
		if (c !== undefined) {
			findRecursively = c;
		}
		return findChildHelper<T>(this, a, findRecursively, name);
	}

	findChildren<T extends new (...args: any) => any>(childType: T): list<AbstractObj> {
		return findChildrenHelper<T>(this, childType);
	}

	event(event: Evt): boolean {
		// Return true if the event was recognized and processed
		switch (event.type()) {
			case Evt.ChildAdded:
			case Evt.ChildRemoved:
				this.childEvent(<ChildEvt>event);
				break;
			default:
				return false;
		}
		return true;
	}

	eventFilter(watched: AbstractObj, event: Evt): boolean {
		// Filters events if this object has been installed as an event filter
		// for the `watched` object.
		//
		// In your reimplementation of this function, if you want to filter
		// the `event` out, i.e. stop it being handled further, return true;
		// otherwise return false.
		//
		// Warning: If you destroy the receiver object in this function, be
		// sure to return true. Otherwise, the event will be forwarded to the
		// destroyed object leading to a possible crash.
		return false;
	}

	installEventFilter(obj: AbstractObj): void {
		const d = this.d;
		if (!d.extraData) {
			d.extraData = new ExtraData();
		}
		d.extraData.eventFilters.removeAll(obj);
		d.extraData.eventFilters.prepend(obj);
	}

	// isComponentType(): this is Component {
	// 	return this.dd.isComponent;
	// }

	// isWindowType(): this is AWindow {
	// 	return this.dd.isWindow;
	// }

	metaObject(): MetaObj {
		return (<typeof AbstractObj>this.constructor).metaobj;
	}

	objectName(): string {
		return this.dd.extraData ? this.dd.extraData.objName : '';
	}

	@SIGNAL
	objectNameChanged(newName: string): void {
	}

	parent(): AbstractObj | null {
		return this.dd.parent;
	}

	property(name: string): Variant {
		switch (name) {
			case 'objectName':
				return new Variant(this.objectName());
			default:
				return new Variant();
		}
	}

	removeEventFilter(obj: AbstractObj): void {
		const d = this.d;
		if (d.extraData) {
			for (let i = 0; i < d.extraData.eventFilters.size(); ++i) {
				if (d.extraData.eventFilters.at(i) === obj) {
					d.extraData.eventFilters.removeAt(i);
				}
			}
		}
	}

	sender(): AbstractObj | null {
		const d = this.d;
		const cd = d.connections;
		if (!cd.currentSender) {
			return null;
		}
		for (let i = 0; i < cd.senderConnections.length; ++i) {
			const conn = cd.senderConnections[i];
			if (conn.sender === cd.currentSender.sender) {
				return cd.currentSender.sender;
			}
		}
		return null;
	}

	setObjectName(name: string): void {
		const d = this.dd;
		if (!d.extraData) {
			d.extraData = new ExtraData();
		}
		if (d.extraData.objName !== name) {
			d.extraData.objName = name;
			this.objectNameChanged(d.extraData.objName);
		}
	}

	setParent(parent?: AbstractObj | null): void {
		const d = this.d;
		assert(!d.isComponent);
		d.setParentHelper(parent);
	}

	setProperty(name: string, value: Variant): boolean {
		switch (name) {
			case 'objectName':
				this.setObjectName(value.toString());
				return true;
			default:
				return false;
		}
	}

	signalsBlocked(): boolean {
		// Returns true if signals are blocked; otherwise returns false.
		//
		// Signals are not blocked by default.
		return this.d.blockSig;
	}

	toString(): string {
		const s = this.metaObject().className();
		const name = this.objectName();
		if (name) {
			return `${s} (${name})`;
		}
		return s;
	}
}

export class Obj extends AbstractObj {
}
