import axios from 'axios';

import {REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY} from './constants';
import {isNumber, trailingslashurljoin} from './util';
import {GeoCoordinate} from './tools';

const STANDARD_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};
const XSRF_CFG = {
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
};

class RequestSvc {
	static cancelTokenSource(): ICancelTokenSource {
		return axios.CancelToken.source();
	}

	static coordToParam(coord: GeoCoordinate): string {
		return `${coord.longitude()},${coord.latitude()}`;
	}

	static isCancellation(obj: any): obj is ICancellation {
		return axios.isCancel(obj);
	}

	private buildUrl(base: string, parts?: Array<string>): string {
		return trailingslashurljoin(base, ...(parts || []));
	}

	cancelTokenSource(): ICancelTokenSource {
		return RequestSvc.cancelTokenSource();
	}

	DELETE<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'DELETE', url: url, ...cfg});
	}

	GET<T>(url: string, params?: any, cfg?: SafeReqCfg): Promise<IResponse<T>> {
		return this.request({method: 'GET', params: params, url: url, ...cfg});
	}

	isCancellation(obj: any): obj is ICancellation {
		return RequestSvc.isCancellation(obj);
	}

	PATCH<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'PATCH', url: url, ...cfg});
	}

	POST<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'POST', url: url, ...cfg});
	}

	preparedRequestConfig(cfg: IRequestConfig): IRequestConfig {
		const headers: any = cfg.headers ?
			{...STANDARD_HEADERS, ...cfg.headers} :
			{...STANDARD_HEADERS};
		let rv: IRequestConfig = {
			cancelToken: cfg.cancelToken,
			data: cfg.data,
			headers: headers,
			method: cfg.method,
			params: cfg.params,
			responseType: cfg.responseType ? cfg.responseType : 'json',
			timeout: cfg.timeout,
			url: cfg.url,
			wellKnown: Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY),
		};
		switch (cfg.method) {
			case 'DELETE':
			case 'PATCH':
			case 'POST':
			case 'PUT':
				rv = {...rv, ...XSRF_CFG};
				break;
		}
		return rv;
	}

	PUT<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'PUT', url: url, ...cfg});
	}

	async request<T>(cfg: IRequestConfig): Promise<IResponse<T>> {
		const prepped = this.preparedRequestConfig(cfg);
		const rv = await axios.request<T>(prepped);
		rv.config = prepped;
		return <IResponse<T>>rv;
	}

	url(baseUri: string, ...parts: Array<number | string>): string {
		const strings = parts
			.filter(p => ((typeof p === 'string') || isNumber(p)))
			.map(x => String(x));
		return this.buildUrl(baseUri, strings);
	}
}

class AccountSvc extends RequestSvc {
	async get(cfg?: SafeReqCfg): Promise<IAccount> {
		const url = this.url('/api', 'account');
		return (await this.GET<IAccount>(url, undefined, cfg)).data;
	}
}

export interface IFilterListOpt {
	point: GeoCoordinate;
	projectSlug: string;
}

class FilterSvc extends RequestSvc {
	async create(data: INewFilter, cfg?: UnsafeReqCfg): Promise<IFilter> {
		const url = this.url('/api', 'filters');
		return (await this.POST<IFilter>(url, data, cfg)).data;
	}

	async delete(pk: FilterPk, cfg?: UnsafeReqCfg): Promise<void> {
		const url = this.url('/api', 'filters', pk);
		return (await this.DELETE<void>(url, undefined, cfg)).data;
	}

	async get(pk: FilterPk, cfg?: SafeReqCfg): Promise<IFilter> {
		const url = this.url('/api', 'filters', pk);
		return (await this.GET<IFilter>(url, undefined, cfg)).data;
	}

	async list(opt?: Partial<IFilterListOpt>, cfg?: SafeReqCfg): Promise<Array<IFilter>> {
		const url = this.url('/api', 'filters');
		let params: Partial<IFilterURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				if (!params) {
					params = {};
				}
				params['project'] = opt.projectSlug;
			}
			if (opt.point) {
				if (!params) {
					params = {};
				}
				params['point'] = RequestSvc.coordToParam(opt.point);
			}
		}
		return (await this.GET<Array<IFilter>>(url, params, cfg)).data;
	}

	async update(data: IFilter, cfg?: UnsafeReqCfg): Promise<IFilter> {
		const url = this.url('/api', 'filters', data.id);
		return (await this.PUT<IFilter>(url, data, cfg)).data;
	}
}

interface IGeoMapConfigurationListOpt {
	image: 'sync';
	projectSlug: string;
}

class GeoMapConfigurationSvc extends RequestSvc {
	async get(pk: GeoMapConfigurationPk, syncImage: boolean = false, cfg?: SafeReqCfg): Promise<IGeoMapConfiguration> {
		let params: Partial<IGeoMapConfigurationURLParam> | undefined = undefined;
		if (syncImage) {
			params = {
				image: 'sync',
			};
		}
		const url = this.url('/api', 'maps', pk);
		return (await this.GET<IGeoMapConfiguration>(url, params, cfg)).data;
	}

	async list(opt?: Partial<IGeoMapConfigurationListOpt>, cfg?: SafeReqCfg): Promise<Array<IGeoMapConfiguration>> {
		let params: Partial<IGeoMapConfigurationURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				params = {project: opt.projectSlug};
				if (opt.image) {
					params.image = opt.image;
				}
			}
		}
		const url = this.url('/api', 'maps');
		return (await this.GET<Array<IGeoMapConfiguration>>(url, params, cfg)).data;
	}

	async update(data: Pick<IGeoMapConfiguration, 'id'> & Partial<Omit<IGeoMapConfiguration, 'id' | 'imageId' | 'imageUrl'>>, cfg?: UnsafeReqCfg): Promise<IGeoMapConfiguration> {
		const url = this.url('/api', 'maps', data.id);
		return (await this.PUT<IGeoMapConfiguration>(url, data, cfg)).data;
	}
}

interface IGeoRefListOpt {
	doNotMail: boolean;
}

interface IGeoRefSetDoNotMailOpt {
	geometry: IGeoRefMultiPolygon | IGeoRefPoint | null;
	parcelPk: ParcelPk;
}

class GeoRefSvc extends RequestSvc {
	async create(data: Partial<INewGeoRef>, cfg?: UnsafeReqCfg): Promise<IGeoRef | null> {
		const url = this.url('/api', 'geo-refs');
		return (await this.POST<IGeoRef | null>(url, data, cfg)).data;
	}

	async delete(pk: GeoRefPk, cfg?: UnsafeReqCfg): Promise<void> {
		const url = this.url('/api', 'geo-refs', pk);
		return (await this.DELETE<void>(url, cfg)).data;
	}

	async doNotMailList(cfg?: SafeReqCfg): Promise<Array<IDoNotMail>> {
		return await this.list({doNotMail: true}, cfg);
	}

	async geoRefList(cfg?: SafeReqCfg): Promise<Array<IGeoRef>> {
		return await this.list(undefined, cfg);
	}

	async list(opt?: {doNotMail: false}, cfg?: SafeReqCfg): Promise<Array<IGeoRef>>;
	async list(opt: {doNotMail: true}, cfg?: SafeReqCfg): Promise<Array<IDoNotMail>>;
	async list(opt?: Partial<IGeoRefListOpt>, cfg?: SafeReqCfg): Promise<Array<IDoNotMail | IGeoRef>> {
		let params: Partial<IGeoRefURLParam> | undefined = undefined;
		if (opt !== undefined) {
			if (opt.doNotMail) {
				params = {filter: 'donotmail'};
			}
		}
		const url = this.url('/api', 'geo-refs');
		return (await this.GET<Array<IDoNotMail | IGeoRef>>(url, params, cfg)).data;
	}

	async setDoNotMail(doNotMail: boolean, data: Partial<IGeoRefSetDoNotMailOpt>, cfg?: UnsafeReqCfg): Promise<IGeoRef | null> {
		const payload: Partial<INewGeoRef> = {
			doNotMail,
			point: null,
			multipolygon: null,
			parcelPk: data.parcelPk,
		};
		if (data.geometry) {
			switch (data.geometry.type) {
				case 'MultiPolygon': {
					payload.multipolygon = data.geometry;
					break;
				}
				case 'Point': {
					payload.point = data.geometry;
					break;
				}
			}
		}
		return await this.create(payload, cfg);
	}

	async update(data: IGeoRef, cfg?: UnsafeReqCfg): Promise<IGeoRef> {
		const url = this.url('/api', 'geo-refs', data.id);
		return (await this.PUT<IGeoRef>(url, data, cfg)).data;
	}
}

interface IInvoiceListOpt {
	projectSlug: string;
}

class InvoiceSvc extends RequestSvc {
	async get(pk: InvoicePk, cfg?: SafeReqCfg): Promise<IInvoice> {
		const url = this.url('/api', 'invoices', pk);
		return (await this.GET<IInvoice>(url, cfg)).data;
	}

	async list(opt?: Partial<IInvoiceListOpt>, cfg?: SafeReqCfg): Promise<Array<IInvoice>> {
		let params: Partial<IInvoiceURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				params = {
					project: opt.projectSlug,
				};
			}
		}
		const url = this.url('/api', 'invoices');
		return (await this.GET<Array<IInvoice>>(url, params, cfg)).data;
	}
}

interface IParcelListOpt {
	point: GeoCoordinate;
	projectSlug: string;
	returnFieldName: string;
}

class ParcelSvc extends RequestSvc {
	async get(pk: ParcelPk, cfg?: SafeReqCfg): Promise<IParcel> {
		const url = this.url('/api', 'parcels', pk);
		return (await this.GET<IParcel>(url, cfg)).data;
	}

	async list(point: GeoCoordinate, cfg?: SafeReqCfg): Promise<Array<IParcel>> {
		const params: Partial<IParcelURLParam> | undefined = {
			point: RequestSvc.coordToParam(point),
		};
		const url = this.url('/api', 'parcels');
		return (await this.GET<Array<IParcel>>(url, params, cfg)).data;
	}

	async projectParcelPkList(projectSlug: string, point?: GeoCoordinate, cfg?: SafeReqCfg): Promise<Array<ParcelPk>> {
		const params: Partial<IParcelURLParam> = {
			field: 'pk',
			project: projectSlug,
		};
		if (point) {
			params.point = RequestSvc.coordToParam(point);
		}
		const url = this.url('/api', 'parcels');
		return (await this.GET<Array<ParcelPk>>(url, params, cfg)).data;
	}
}

class PaymentSvc extends RequestSvc {
	async create(data: INewPayment, cfg?: UnsafeReqCfg): Promise<IPaymentIntent> {
		const url = this.url('/api', 'payments');
		return (await this.POST<IPaymentIntent>(url, data, cfg)).data;
	}
}

class PaymentMethodSvc extends RequestSvc {
	async delete(pk: PaymentMethodPk, cfg?: UnsafeReqCfg): Promise<void> {
		const url = this.url('/api', 'payment-methods', pk);
		return (await this.DELETE<void>(url, cfg)).data;
	}

	async list(cfg?: SafeReqCfg): Promise<Array<IPaymentMethod>> {
		const url = this.url('/api', 'payment-methods');
		return (await this.GET<Array<IPaymentMethod>>(url, cfg)).data;
	}
}

class PlaceSvc extends RequestSvc {
	async list(name: string, cfg?: SafeReqCfg): Promise<Array<IPlace>> {
		const params: Partial<IPlaceURLParam> | undefined = {
			name,
		};
		const url = this.url('/api', 'places');
		return (await this.GET<Array<IPlace>>(url, params, cfg)).data;
	}
}

class PriceSvc extends RequestSvc {
	async get(pk: PricePk, cfg?: SafeReqCfg): Promise<IPrice> {
		const url = this.url('/api', 'prices', pk);
		return (await this.GET<IPrice>(url, cfg)).data;
	}

	async list(cfg?: SafeReqCfg): Promise<Array<IPrice>> {
		const url = this.url('/api', 'prices');
		return (await this.GET<Array<IPrice>>(url, cfg)).data;
	}
}

interface IPriceListOpt {
	pricePk: PricePk;
}

class PriceTierSvc extends RequestSvc {
	async get(pk: PriceTierPk, cfg?: SafeReqCfg): Promise<IPriceTier> {
		const url = this.url('/api', 'price-tiers', pk);
		return (await this.GET<IPriceTier>(url, cfg)).data;
	}

	async list(opt?: Partial<IPriceListOpt>, cfg?: SafeReqCfg): Promise<Array<IPriceTier>> {
		let params: Partial<IPriceTierURLParam> | undefined = undefined;
		if (opt) {
			if (opt.pricePk !== undefined) {
				params = {
					price: opt.pricePk,
				};
			}
		}
		const url = this.url('/api', 'price-tiers');
		return (await this.GET<Array<IPriceTier>>(url, params, cfg)).data;
	}
}

enum ProjectFilterParam {
	// NB: Keep in sync with back-end api.views.project::FilterParam
	Archived = 'archived',
	NotArchived = 'not_archived',
	All = 'all',
}

interface IProjectGetOpt {
	syncInvoice: boolean;
	syncPayment: boolean;
}

export interface IProjectListOpt {
	filter: ProjectFilterParam;
	sortField: string;
	sortOrder: 'asc' | 'desc';
}

class ProjectSvc extends RequestSvc {
	filterParam: typeof ProjectFilterParam = ProjectFilterParam;

	async archive(slug: string, cfg?: UnsafeReqCfg): Promise<IProject> {
		const obj = await this.get(slug);
		if (obj.archived) {
			return obj;
		}
		return await this.update({...obj, archived: true}, cfg);
	}

	async archivedList(opt?: Partial<Omit<IProjectListOpt, 'filter'>>, cfg?: SafeReqCfg): Promise<Array<IProject>> {
		return await this.list({
			...(opt || {}),
			filter: ProjectFilterParam.Archived,
		}, cfg);
	}

	async clone(slug: string, cfg?: UnsafeReqCfg): Promise<IProject> {
		const url = this.url('/api', 'projects', slug);
		return (await this.POST<IProject>(url, cfg)).data;
	}

	async get(slug: string, opt?: Partial<IProjectGetOpt>, cfg?: SafeReqCfg): Promise<IProject> {
		let params: Partial<IProjectURLParam> | undefined = undefined;
		if (opt) {
			if (opt.syncInvoice) {
				params = {
					invoice: 'sync',
				};
			}
			if (opt.syncPayment) {
				if (!params) {
					params = {};
				}
				params.payment = 'sync';
			}
		}
		const url = this.url('/api', 'projects', slug);
		return (await this.GET<IProject>(url, params, cfg)).data;
	}

	async list(opt?: Partial<IProjectListOpt>, cfg?: SafeReqCfg): Promise<Array<IProject>> {
		let params: Partial<IProjectURLParam> | undefined = undefined;
		if (opt) {
			params = {};
			if ((opt.sortField !== undefined) && (opt.sortOrder !== undefined)) {
				params['field'] = opt.sortField;
				params['order'] = opt.sortOrder;
			}
			if (opt.filter !== undefined) {
				params['filter'] = opt.filter;
			}
		}
		const url = this.url('/api', 'projects');
		return (await this.GET<Array<IProject>>(url, params, cfg)).data;
	}

	async merge(slugs: Array<string>, cfg?: UnsafeReqCfg): Promise<IProject> {
		const url = this.url('/api', 'projects');
		return (await this.POST<IProject>(url, {projects: slugs}, cfg)).data;
	}

	async restore(slug: string, cfg?: UnsafeReqCfg): Promise<IProject> {
		const obj = await this.get(slug);
		if (!obj.archived) {
			return obj;
		}
		return await this.update({...obj, archived: false}, cfg);
	}

	async update(data: IProject, cfg?: UnsafeReqCfg): Promise<IProject> {
		const url = this.url('/api', 'projects', data.slug);
		return (await this.PUT<IProject>(url, data, cfg)).data;
	}

	async visibleList(opt?: Partial<Omit<IProjectListOpt, 'filter'>>, cfg?: SafeReqCfg): Promise<Array<IProject>> {
		return await this.list({
			...(opt || {}),
			filter: ProjectFilterParam.NotArchived,
		}, cfg);
	}
}

class UISvc extends RequestSvc {
	async get(cfg?: SafeReqCfg): Promise<IUI> {
		const url = this.url('/api', 'ui');
		return (await this.GET<IUI>(url, cfg)).data;
	}

	async update(data: IUI, cfg?: UnsafeReqCfg): Promise<IUI> {
		const url = this.url('/api', 'ui');
		return (await this.PUT<IUI>(url, data, cfg)).data;
	}
}

class Svc {
	account: AccountSvc;
	filter: FilterSvc;
	geoRef: GeoRefSvc;
	invoice: InvoiceSvc;
	map: GeoMapConfigurationSvc;
	parcel: ParcelSvc;
	payment: PaymentSvc;
	paymentMethod: PaymentMethodSvc;
	place: PlaceSvc;
	price: PriceSvc;
	priceTier: PriceTierSvc;
	project: ProjectSvc;
	ui: UISvc;

	constructor() {
		this.account = new AccountSvc();
		this.filter = new FilterSvc();
		this.geoRef = new GeoRefSvc();
		this.invoice = new InvoiceSvc();
		this.map = new GeoMapConfigurationSvc();
		this.parcel = new ParcelSvc();
		this.payment = new PaymentSvc();
		this.paymentMethod = new PaymentMethodSvc();
		this.place = new PlaceSvc();
		this.price = new PriceSvc();
		this.priceTier = new PriceTierSvc();
		this.project = new ProjectSvc();
		this.ui = new UISvc();
	}

	cancelTokenSource(): ICancelTokenSource {
		return RequestSvc.cancelTokenSource();
	}

	isCancellation(obj: any): obj is ICancellation {
		return RequestSvc.isCancellation(obj);
	}
}

export function isErrorResponse(obj: any): obj is IResponse<IErrorResponseData> {
	try {
		return ('data' in obj)
			&& ('error' in obj.data)
			&& ('code' in obj.data.error)
			&& ('details' in obj.data.error)
			&& ('message' in obj.data.error)
			&& ('target' in obj.data.error);
	} catch {
	}
	return false;
}

export function isNetworkErrorObject(obj: any): obj is INetworkError {
	try {
		return (obj instanceof Error)
			&& ('isAxiosError' in obj)
			&& ((<any>obj).isAxiosError === true)
			&& (isErrorResponse((<any>obj).response) || ((<any>obj).response === undefined))
			&& ('config' in obj)
			&& isRequestConfig((<any>obj).config);
	} catch {
	}
	return false;
}

export function isRequestConfig(obj: any): obj is IRequestConfig {
	try {
		if ('wellKnown' in obj) {
			return obj.wellKnown === Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY);
		}
	} catch {
	}
	return false;
}

export const svc = new Svc();
