import {IProjectListOpt, svc} from '../../request';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {bind, numberFormat, stringIterableToStringArray} from '../../util';
import {Menu} from '../../ui/menu';
import {getLogger} from '../../logging';
import {SegmentedButtonGroup} from '../../ui/segmentedbutton';
import {TileLayout} from './tilelayout';
import {ListLayout} from './listlayout';
import {Layout} from './layout';
import {ToolButton} from '../../ui/toolbutton';
import {FancyPushButton} from '../../ui/pushbutton';
import {SortOrder} from '../../constants';
import {list, Point} from '../../tools';
import {PaymentDialogView} from '../paymentdialog';

const logger = getLogger('projectlistview');

enum LayoutStyle {
	List,
	Tile,
}

const viewLayouts: Array<{icon: string; layout: LayoutStyle;}> = [
	{icon: 'view_list', layout: LayoutStyle.List},
	{icon: 'view_module', layout: LayoutStyle.Tile},
];

@OBJ
export class ProjectListView extends Obj {
	private currentLayout: Layout | null;
	currentLayoutStyle: LayoutStyle | null;
	protected batchActions: Array<ProjectBatchAction>;
	protected layoutGridRoot: ElObj;
	private currentSlug: string;
	head: Head;
	private optMenu: Menu | null;
	private paymentDialog: PaymentDialogView | null;
	private rootEl: ElObj;

	constructor(rootEl: ElObj) {
		super();
		this.batchActions = [ProjectBatchAction.Merge, ProjectBatchAction.Archive];
		this.currentLayout = null;
		this.currentLayoutStyle = null;
		this.currentSlug = '';
		this.head = new Head(this);
		this.optMenu = null;
		this.paymentDialog = null;
		this.rootEl = rootEl;
		this.layoutGridRoot = new ElObj({
			classNames: [
				'mdc-layout-grid',
			],
			parent: this.rootEl,
			tagName: 'div',
		});
		this.init();
	}

	async archiveProject(slug: string): Promise<void> {
		const result = await this.archiveProjects([slug]);
		if ((result.length === 1) && result[0].archived) {
			window.location.reload();
		} else {
			logger.error('archiveProject: Project archive attempt failed');
		}
	}

	private async archiveProjects(slugs: Array<string>): Promise<Array<IProject>> {
		const rv: Array<IProject> = [];
		for (const slug of slugs) {
			rv.push(await svc.project.archive(slug));
		}
		return rv;
	}

	async beginPayment(slug: string): Promise<void> {
		if (this.paymentDialog) {
			return;
		}
		this.currentSlug = slug;
		if (this.currentLayout) {
			this.currentLayout.setProgressVisible(this.currentSlug, true);
		}
		const paymentData = await this.fetchPaymentData(slug);
		const invoice = await this.fetchProjectInvoice(slug);
		const title = `${slug} - ${numberFormat(invoice.totalQuantity)} mailing addresses`;
		this.paymentDialog = new PaymentDialogView({
			amount: invoice.total,
			paymentIntent: paymentData,
			title,
		});
		Obj.connect(
			this.paymentDialog, 'finished',
			this, 'endPayment');
		this.paymentDialog.beginPayment();
		if (this.currentLayout) {
			this.currentLayout.setProgressVisible(this.currentSlug, false);
		}
	}

	async cloneProject(slug: string): Promise<void> {
		const clone = await svc.project.clone(slug);
		window.location.assign(clone.absoluteUrl);
	}

	private currentlySelectedProjects(): Array<string> {
		if (this.currentLayout) {
			return this.currentLayout.selectedProjects();
		}
		logger.error('currentlySelectedProjects: Current layout is not defined.');
		return [];
	}

	destroy(): void {
		window.removeEventListener('pageshow', this.domEvent);
		this.destroyPaymentDialog();
		this.destroyOptMenu();
		this.destroyCurrentViewLayout();
		super.destroy();
	}

	private destroyCurrentViewLayout(): void {
		if (this.currentLayout) {
			Obj.disconnect(
				this.currentLayout, 'projectSelectionChanged',
				this, 'projectSelectionChanged');
			this.currentLayout.destroy();
		}
		this.currentLayout = null;
	}

	private destroyOptMenu(): void {
		if (this.optMenu) {
			Obj.disconnect(
				this.optMenu, 'selectionChanged',
				this, 'optMenuSelectionChanged');
			Obj.disconnect(
				this.optMenu, 'closed',
				this, 'optMenuClosed');
			this.optMenu.destroy();
		}
		this.optMenu = null;
	}

	private destroyPaymentDialog(): void {
		if (this.paymentDialog) {
			Obj.disconnect(
				this.paymentDialog, 'finished',
				this, 'endPayment');
			this.paymentDialog.destroy();
		}
		this.paymentDialog = null;
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'pageshow':
				this.domPageShowEvent(<PageTransitionEvent>event);
				break;
		}
	}

	private domPageShowEvent(event: PageTransitionEvent): void {
		if (event.persisted) {
			// Cached, browser nav actuated view
			this.syncLayoutProjects();
		}
	}

	async downloadList(slug: string): Promise<void> {
		const proj = await this.fetchProject(slug);
		if (proj.absoluteListUrl) {
			window.location.assign(proj.absoluteListUrl);
		} else {
			logger.error('downloadList: "%s" did not return a valid URL', slug);
		}
	}

	async downloadListWithOwnerName(slug: string): Promise<void> {
		const proj = await this.fetchProject(slug);
		if (proj.absoluteListUrl) {
			window.location.assign(`${proj.absoluteListUrl}?opt=owner`);
		} else {
			logger.error('downloadListWithOwnerName: "%s" did not return a valid URL', slug);
		}
	}

	@SLOT
	private async endPayment(success: boolean): Promise<void> {
		if (!this.paymentDialog) {
			return;
		}
		if (success) {
			const obj = await this.fetchProject(this.currentSlug, {syncPayment: true});
			if (this.currentLayout) {
				this.currentLayout.setDownloadUrl(obj.slug, obj.absoluteListUrl);
			} else {
				logger.error('endPayment: Current layout is not defined.');
			}
		}
		this.destroyPaymentDialog();
	}

	async fetchInvoice(pk: number): Promise<IInvoice> {
		return await svc.invoice.get(pk);
	}

	private async fetchPaymentData(slug: string): Promise<IPaymentIntent> {
		return await svc.payment.create({projectSlug: slug});
	}

	private async fetchProject(slug: string, opt?: Partial<{syncInvoice: boolean; syncPayment: boolean;}>): Promise<IProject> {
		return await svc.project.get(slug, opt);
	}

	private async fetchProjectInvoice(slug: string): Promise<IInvoice> {
		const objs = await svc.invoice.list({projectSlug: slug});
		if (objs.length === 1) {
			return objs[0];
		}
		throw new Error(`fetchProjectInvoice: Got ${objs.length} objects returned. Expected exactly 1.`);
	}

	protected async fetchProjects(opt?: Partial<IProjectListOpt>): Promise<Array<IProject>> {
		opt = opt || {};
		if (opt.filter === undefined) {
			opt.filter = svc.project.filterParam.NotArchived;
		}
		return await svc.project.list(opt);
	}

	protected async init(): Promise<void> {
		window.addEventListener('pageshow', this.domEvent);
		let ui = await svc.ui.get();
		if (ui.projectListLayoutStyle.trim().length < 1) {
			ui = await svc.ui.update({...ui, projectListLayoutStyle: 'tile'});
		}
		this.head.setSortFieldNames(ui.sortFieldNames.map(tup => tup[1]));
		const sortFieldNameIndex = ui.sortFieldNames.findIndex(tup => (tup[0] === ui.projectListSortField));
		if (sortFieldNameIndex >= 0) {
			this.head.setCurrentSortField(ui.sortFieldNames[sortFieldNameIndex][1]);
		} else {
			logger.error('init: Invalid sort field name or sort field options');
		}
		this.head.setCurrentSortOrder(ui.projectListSortAsc ?
			SortOrder.AscendingOrder :
			SortOrder.DescendingOrder);
		let layoutStyle: LayoutStyle = LayoutStyle.Tile;
		switch (ui.projectListLayoutStyle) {
			case 'tile':
				layoutStyle = LayoutStyle.Tile;
				break;
			case 'list':
				layoutStyle = LayoutStyle.List;
				break;
			default:
				logger.warning('init: User UI returned invalid layout data');
				break;
		}
		await this.setCurrentLayoutStyle(layoutStyle);
		Obj.connect(
			this.head, 'layoutStyleToggled',
			this, 'setCurrentLayoutStyle');
		this.rootEl.insertChild(0, this.head);
		this.head.initialize();
		Obj.connect(
			this.head.actionBar, 'actionTriggered',
			this, 'projectBatchActionTriggered');
		this.head.actionBar.setActions(this.batchActions);
		Obj.connect(
			this.head.sortBar, 'sortFieldChanged',
			this, 'sortFieldChanged');
		Obj.connect(
			this.head.sortBar, 'sortOrderChanged',
			this, 'sortOrderChanged');
	}

	private async mergeProjects(slugs: Array<string>): Promise<void> {
		const merged = await svc.project.merge(slugs);
		window.location.assign(merged.absoluteUrl);
	}

	@SLOT
	private async projectBatchActionTriggered(action: ProjectBatchAction): Promise<void> {
		switch (action) {
			case ProjectBatchAction.Archive:
				await this.archiveProjects(this.currentlySelectedProjects());
				window.location.reload();
				break;
			case ProjectBatchAction.Restore:
				await this.restoreProjects(this.currentlySelectedProjects());
				window.location.reload();
				break;
			case ProjectBatchAction.Merge:
				const selectedSlugs = this.currentlySelectedProjects();
				if (selectedSlugs.length > 0) {
					await this.mergeProjects(selectedSlugs);
				} else {
					logger.warning('projectBatchActionTriggered: No projects selected.');
				}
				break;
			default:
				logger.error('projectBatchActionTriggered: Got invalid action: %s', action);
				break;
		}
	}

	@SLOT
	private projectSelectionChanged(): void {
		if (this.currentLayout) {
			const selected = this.currentLayout.selectedProjects();
			this.head.actionBar.setVisible(selected.length > 0);
		} else {
			this.head.actionBar.hide();
		}
	}

	async restoreProject(slug: string): Promise<void> {
		const result = await this.restoreProjects([slug]);
		if ((result.length === 1) && !result[0].archived) {
			window.location.reload();
		} else {
			logger.error('restoreProject: Project restoration attempt failed');
		}
	}

	private async restoreProjects(slugs: Array<string>): Promise<Array<IProject>> {
		const rv: Array<IProject> = [];
		for (const slug of slugs) {
			rv.push(await svc.project.restore(slug));
		}
		return rv;
	}

	@SLOT
	private async setCurrentLayoutStyle(layout: LayoutStyle): Promise<void> {
		if (layout === this.currentLayoutStyle) {
			return;
		}
		this.destroyCurrentViewLayout();
		this.currentLayoutStyle = layout;
		switch (this.currentLayoutStyle) {
			case LayoutStyle.List: {
				this.currentLayout = new ListLayout(this, {classNames: ['mdc-layout-grid__inner'], parent: this.layoutGridRoot});
				Obj.connect(
					this.currentLayout, 'projectSelectionChanged',
					this, 'projectSelectionChanged');
				break;
			}
			case LayoutStyle.Tile: {
				this.head.actionBar.hide();
				this.currentLayout = new TileLayout(this, {classNames: ['mdc-layout-grid__inner'], parent: this.layoutGridRoot});
				Obj.connect(
					this.currentLayout, 'projectSelectionChanged',
					this, 'projectSelectionChanged');
				break;
			}
		}
		await this.syncLayoutProjects();
		setTimeout(async () => {
			if (this.currentLayoutStyle !== null) {
				let styleText: string;
				switch (this.currentLayoutStyle) {
					case LayoutStyle.List:
						styleText = 'list';
						break;
					case LayoutStyle.Tile:
						styleText = 'tile';
						break;
				}
				const obj = await svc.ui.get();
				if (obj.projectListLayoutStyle !== styleText) {
					await svc.ui.update({...obj, projectListLayoutStyle: styleText});
				}
			}
		}, 1);
	}

	@SLOT
	private async sortFieldChanged(fieldName: string): Promise<void> {
		const userUi = await svc.ui.get();
		const sortFieldTupIndex = userUi.sortFieldNames.findIndex(tup => (tup[1] === fieldName));
		if (sortFieldTupIndex >= 0) {
			const fieldValue = userUi.sortFieldNames[sortFieldTupIndex][0];
			const updated = await svc.ui.update({...userUi, projectListSortField: fieldValue});
			const sortFieldTupIdx = updated.sortFieldNames.findIndex(tup => (tup[0] === updated.projectListSortField));
			if (sortFieldTupIdx >= 0) {
				this.head.setCurrentSortField(updated.sortFieldNames[sortFieldTupIdx][1]);
				if (this.currentLayout) {
					const projects = await this.fetchProjects({
						sortField: updated.projectListSortField,
						sortOrder: updated.projectListSortAsc ? 'asc' : 'desc',
					});
					await this.currentLayout.setProjects(projects);
				}
			} else {
				logger.error('sortFieldChanged: Invalid field value');
			}
		} else {
			logger.error('sortFieldChanged: Invalid field name');
		}
	}

	@SLOT
	private async sortOrderChanged(sortOrder: SortOrder): Promise<void> {
		const userUi = await svc.ui.get();
		const updated = await svc.ui.update({...userUi, projectListSortAsc: sortOrder === SortOrder.AscendingOrder});
		this.head.setCurrentSortOrder(updated.projectListSortAsc ?
			SortOrder.AscendingOrder :
			SortOrder.DescendingOrder);
		if (this.currentLayout) {
			const projects = await this.fetchProjects({
				sortField: updated.projectListSortField,
				sortOrder: updated.projectListSortAsc ? 'asc' : 'desc',
			});
			await this.currentLayout.setProjects(projects);
		}
	}

	private async syncLayoutProjects(): Promise<void> {
		if (this.currentLayout) {
			const ui = await svc.ui.get();
			const projects = await this.fetchProjects({
				sortField: ui.projectListSortField,
				sortOrder: ui.projectListSortAsc ? 'asc' : 'desc',
			});
			await this.currentLayout.setProjects(projects);
		} else {
			logger.warning('syncLayoutProjects: Called when no layout is defined.');
		}
	}
}

@OBJ
class Head extends ElObj {
	actionBar: ActionBar;
	private layoutStyleSwitcher: SegmentedButtonGroup;
	sortBar: SortBar;
	private view: ProjectListView | null;

	constructor(view: ProjectListView | null, opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(view: ProjectListView | null, opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(view: ProjectListView | null, opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts?: Partial<ElObjOpts>);
	constructor(view: ProjectListView | null, root?: Element | null);
	constructor(view: ProjectListView | null, tagName?: TagName);
	constructor(view: ProjectListView | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-layout-grid',
			'lb-project-list-head',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.view = view;
		const grid = new ElObj({
			classNames: 'mdc-layout-grid__inner',
			parent: this,
			tagName: 'div',
		});
		const cell = new ElObj({
			classNames: [
				'mdc-layout-grid__cell',
				'mdc-layout-grid__cell--span-12',
			],
			parent: grid,
			tagName: 'div',
		});
		const container = new ElObj({
			classNames: 'lb-project-list-inner-head-container',
			parent: cell,
			tagName: 'div',
		});
		new ElObj({
			attributes: [
				['aria-hidden', 'true'],
			],
			parent: container,
			styles: [
				['visibility', 'hidden'],
			],
			tagName: 'div',
		});
		const rightSideContainer = new ElObj({
			classNames: 'lb-project-list-inner-head-right-container',
			parent: container,
			tagName: 'div',
		});
		this.actionBar = new ActionBar();
		this.actionBar.hide();
		rightSideContainer.appendChild(this.actionBar);
		this.sortBar = new SortBar({parent: rightSideContainer});
		this.layoutStyleSwitcher = new SegmentedButtonGroup({
			multiSelect: false,
			parent: rightSideContainer,
		});
	}

	initialize(): void {
		const currStyle = this.view ?
			this.view.currentLayoutStyle :
			null;
		this.layoutStyleSwitcher.setSegments(
			viewLayouts.map(v => ({icon: v.icon, selected: currStyle === v.layout})));
		Obj.connect(
			this.layoutStyleSwitcher, 'clicked',
			this, 'layoutStyleSwitcherToggled');
	}

	@SLOT
	private layoutStyleSwitcherToggled(index: number, selected: boolean, segmentId: string): void {
		if ((index >= 0) && (index < viewLayouts.length)) {
			const obj = viewLayouts[index];
			this.layoutStyleToggled(obj.layout);
		} else {
			logger.error('layoutStyleSwitcherToggled: Got invalid index (%s) for view layout', index);
		}
	}

	@SIGNAL
	private layoutStyleToggled(style: LayoutStyle): void {
	}

	setCurrentSortField(fieldName: string): void {
		this.sortBar.setSortField(fieldName);
	}

	setCurrentSortOrder(sortOrder: SortOrder): void {
		this.sortBar.setSortOrder(sortOrder);
	}

	setSortFieldNames(fieldNames: Iterable<string>): void {
		this.sortBar.setSortFieldNames(fieldNames);
	}
}

export enum ProjectBatchAction {
	Archive,
	Merge,
	Restore,
}

@OBJ
class ActionBar extends ElObj {
	private actionMap: Map<ToolButton, ProjectBatchAction>;
	private dividerEl: ElObj;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-project-list-head-action-bar',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.actionMap = new Map<ToolButton, ProjectBatchAction>();
		this.dividerEl = new ElObj({
			classNames: 'lb-project-list-head-action-bar-divider',
			parent: this,
			tagName: 'div',
		});
	}

	@SLOT
	private actionClicked(checked: boolean, point: Point): void {
		const obj = <ToolButton | null>this.sender();
		if (obj) {
			const axn = this.actionMap.get(obj);
			if (axn === undefined) {
				logger.error('actionClicked: Signal sender is not mapped to known action.');
			} else {
				this.actionTriggered(axn);
			}
		}
	}

	@SIGNAL
	private actionTriggered(action: ProjectBatchAction): void {
	}

	private addAction(action: ProjectBatchAction): void {
		for (const axn of this.actionMap.values()) {
			if (axn === action) {
				// Already added
				return;
			}
		}
		let icon: string;
		let title: string;
		switch (action) {
			case ProjectBatchAction.Archive:
				icon = 'archive';
				title = 'Archive';
				break;
			case ProjectBatchAction.Merge:
				icon = 'merge_type';
				title = 'Merge';
				break;
			case ProjectBatchAction.Restore:
				icon = 'unarchive';
				title = 'Restore';
				break;
		}
		const btn = new ToolButton({
			classNames: [
				'lb-project-list-head-action-bar-action',
			],
			icon,
			outlined: true,
			styles: [
				['color', 'rgba(0, 0, 0, 0.6)'],
			],
			title,
		});
		this.dividerEl.insertAdjacentElement('beforebegin', btn);
		this.actionMap.set(btn, action);
		Obj.connect(
			btn, 'clicked',
			this, 'actionClicked');
	}

	destroy(): void {
		this.destroyActions();
		super.destroy();
	}

	private destroyAction(obj: ToolButton): void {
		Obj.disconnect(
			obj, 'clicked',
			this, 'actionClicked');
		obj.destroy();
	}

	private destroyActions(): void {
		for (const obj of this.actionMap.keys()) {
			this.destroyAction(obj);
		}
		this.actionMap.clear();
	}

	setActions(actions: Array<ProjectBatchAction>): void {
		this.destroyActions();
		for (const axn of actions) {
			this.addAction(axn);
		}
	}
}

interface SortBarOpts extends ElObjOpts {
	currentSortField: string;
	currentSortOrder: SortOrder;
	sortFieldNames: Iterable<string>;
}

@OBJ
class SortBar extends ElObj {
	static sortOrderIcon = {ascending: 'arrow_upward', descending: 'arrow_downward'};

	private sortFieldButton: FancyPushButton;
	private sortFieldMenu: Menu | null;
	private sortFieldNames: list<string>;
	private sortOrderButton: ToolButton;

	constructor(opts: Partial<SortBarOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<SortBarOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<SortBarOpts> | null, tagName?: TagName);
	constructor(opts: Partial<SortBarOpts> | null, root?: Element | null);
	constructor(opts: Partial<SortBarOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<SortBarOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<SortBarOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<SortBarOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-project-list-view-sort-container',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.sortFieldNames = new list<string>(opts.sortFieldNames ?
			stringIterableToStringArray(opts.sortFieldNames) :
			[]);
		this.sortFieldButton = new FancyPushButton({
			parent: this,
			text: opts.currentSortField,
		});
		Obj.connect(
			this.sortFieldButton, 'clicked',
			this, 'sortFieldButtonClicked');
		this.sortOrderButton = new ToolButton({
			icon: (opts.currentSortOrder === SortOrder.DescendingOrder) ?
				'arrow_downward' :
				'arrow_upward',
			parent: this,
		});
		Obj.connect(
			this.sortOrderButton, 'clicked',
			this, 'sortOrderButtonClicked');
		new ElObj({
			classNames: 'lb-project-list-head-action-bar-divider',
			parent: this,
			tagName: 'div',
		});
		this.sortFieldMenu = null;
	}

	destroy(): void {
		Obj.disconnect(
			this.sortFieldButton, 'clicked',
			this, 'sortFieldButtonClicked');
		Obj.disconnect(
			this.sortOrderButton, 'clicked',
			this, 'sortOrderButtonClicked');
		this.sortOrderButton.destroy();
		this.sortFieldButton.destroy();
		this.destroySortFieldMenu();
		super.destroy();
	}

	currentSortOrder(): SortOrder {
		return (this.sortOrderButton.icon() === SortBar.sortOrderIcon.ascending) ?
			SortOrder.AscendingOrder :
			SortOrder.DescendingOrder;
	}

	private destroySortFieldMenu(): void {
		if (this.sortFieldMenu) {
			Obj.disconnect(
				this.sortFieldMenu, 'closed',
				this, 'sortFieldMenuClosed');
			Obj.disconnect(
				this.sortFieldMenu, 'selectionChanged',
				this, 'sortFieldMenuSelectionChanged');
			this.sortFieldMenu.destroy();
		}
		this.sortFieldMenu = null;
	}

	private openSortFieldMenu(point: Point): void {
		this.destroySortFieldMenu();
		this.sortFieldMenu = new Menu();
		for (const name of this.sortFieldNames) {
			this.sortFieldMenu.addItem(name);
		}
		Obj.connect(
			this.sortFieldMenu, 'closed',
			this, 'sortFieldMenuClosed');
		Obj.connect(
			this.sortFieldMenu, 'selectionChanged',
			this, 'sortFieldMenuSelectionChanged');
		this.sortFieldMenu.open(point);
	}

	setSortField(fieldName: string): void {
		if (this.sortFieldNames.indexOf(fieldName) >= 0) {
			this.sortFieldButton.setText(fieldName);
		} else {
			logger.error('setSortField: Given field name not found within current options.');
		}
	}

	setSortFieldNames(fieldNames: Iterable<string>): void {
		this.sortFieldNames = new list<string>(stringIterableToStringArray(fieldNames));
	}

	setSortOrder(sortOrder: SortOrder): void {
		this.setSortOrderIcon(sortOrder);
	}

	private setSortOrderIcon(sortOrder: SortOrder): void {
		this.sortOrderButton.setIcon(
			(sortOrder === SortOrder.AscendingOrder) ?
				SortBar.sortOrderIcon.ascending :
				SortBar.sortOrderIcon.descending);
	}

	@SLOT
	private sortFieldButtonClicked(checked: boolean, point: Point): void {
		this.openSortFieldMenu(point);
	}

	@SIGNAL
	private sortFieldChanged(fieldName: string): void {
	}

	@SLOT
	private sortFieldMenuClosed(): void {
		this.destroySortFieldMenu();
	}

	@SLOT
	private sortFieldMenuSelectionChanged(index: number): void {
		if ((index >= 0) && (index < this.sortFieldNames.size())) {
			this.sortFieldChanged(this.sortFieldNames.at(index));
		} else {
			logger.error('sortFieldMenuSelectionChanged: Invalid index');
		}
	}

	@SLOT
	private sortOrderButtonClicked(): void {
		const newSortOrder = (this.currentSortOrder() === SortOrder.AscendingOrder) ?
			SortOrder.DescendingOrder :
			SortOrder.AscendingOrder;
		this.sortOrderChanged(newSortOrder);
	}

	@SIGNAL
	private sortOrderChanged(sortOrder: SortOrder): void {
	}
}
