import {MDCLinearProgress} from '@material/linear-progress';

import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {OBJ, SLOT} from '../../obj';
import {iterableToArray, stringIterableToStringArray} from '../../util';

const INDETERMINATE_CLASSNAME = 'mdc-linear-progress--indeterminate';

interface LinearProgressOpts extends ElObjOpts {
	indeterminate: boolean;
}

@OBJ
export class LinearProgress extends ElObj {
	private ctrl: MDCLinearProgress;
	private vis: boolean;

	constructor(opts: Partial<LinearProgressOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<LinearProgressOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<LinearProgressOpts> | null, tagName?: TagName);
	constructor(opts: Partial<LinearProgressOpts> | null, root?: Element | null);
	constructor(opts: Partial<LinearProgressOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<LinearProgressOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<LinearProgressOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<LinearProgressOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', 'progressbar'],
			['aria-label', 'Progress bar'],
			['aria-valuemin', '0'],
			['aria-valuemax', '1'],
			['aria-valuenow', '0'],
			...attributes,
		];
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		if (opts.indeterminate) {
			classNames.unshift(INDETERMINATE_CLASSNAME);
		}
		opts.classNames = [
			'mdc-linear-progress',
			'mdc-linear-progress--closed',
			'lb-linear-progress',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.vis = false;
		const buf = new ElObj({
			classNames: 'mdc-linear-progress__buffer',
			parent: this,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-linear-progress__buffer-bar',
			parent: buf,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-linear-progress__buffer-dots',
			parent: buf,
			tagName: 'div',
		});
		const primary = new ElObj({
			classNames: [
				'mdc-linear-progress__bar',
				'mdc-linear-progress__primary-bar',
			],
			parent: this,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-linear-progress__bar-inner',
			parent: primary,
			tagName: 'span',
		});
		const secondary = new ElObj({
			classNames: [
				'mdc-linear-progress__bar',
				'mdc-linear-progress__secondary-bar',
			],
			parent: this,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-linear-progress__bar-inner',
			parent: secondary,
			tagName: 'span',
		});
		this.ctrl = new MDCLinearProgress(this.elem);
		if (opts.indeterminate !== undefined) {
			this.setIndeterminate(opts.indeterminate);
		}
	}

	@SLOT
	close(): void {
		this.setOpen(false);
	}

	destroy(): void {
		this.close();
		this.ctrl.destroy();
		super.destroy();
	}

	hide(): void {
		this.setOpen(false);
	}

	isHidden(): boolean {
		return !this.isVisible();
	}

	isIndeterminate(): boolean {
		return this.hasClass(INDETERMINATE_CLASSNAME);
	}

	isOpen(): boolean {
		return this.vis;
	}

	isVisible(): boolean {
		return this.vis;
	}

	@SLOT
	open(): void {
		this.setOpen(true);
	}

	setIndeterminate(indeterminate: boolean): void {
		this.ctrl.determinate = !indeterminate;
	}

	setOpen(open: boolean): void {
		if (open === this.vis) {
			return;
		}
		this.vis = open;
		if (this.vis) {
			this.ctrl.open();
		} else {
			this.ctrl.close();
		}
	}

	setVisible(visible: boolean): void {
		this.setOpen(visible);
	}

	show(): void {
		this.setOpen(true);
	}
}
