import {
	MapMouseEvent,
	MapTouchEvent,
} from 'mapbox-gl';

import {Mode} from './mode';
import {LineString} from '../features/linestring';
import {
	isVertex,
	createVertex,
	isAtCoordinates,
	doubleClickZoom,
} from './util';
import {
	MapMouseFeatureEvt,
	MapTouchFeatureEvt,
	KeyboardEvt,
} from '../events';
import {
	DrawMode,
	Cursor,
	DrawEventType,
} from '../constants';

export class DrawLineString extends Mode {
	name = DrawMode.DrawLineString;
	state!: {currentVertexPosition: number; direction: 'backwards' | 'forward'; line: LineString;};

	clickAnywhere(event: MapMouseEvent | MapTouchEvent): void {
		if ((this.state.currentVertexPosition > 0) && isAtCoordinates(event.lngLat, <[number, number]>this.state.line.coordinates[this.state.currentVertexPosition - 1]) ||
			(this.state.direction === 'backwards') && isAtCoordinates(event.lngLat, <[number, number]>this.state.line.coordinates[this.state.currentVertexPosition + 1])) {
			this.changeMode(DrawMode.SimpleSelect, {featureIds: [this.state.line.id]});
		} else {
			this.updateUIClasses({mouse: Cursor.Add});
			this.state.line.updateCoordinate(
				String(this.state.currentVertexPosition),
				event.lngLat.lng,
				event.lngLat.lat);
			if (this.state.direction === 'forward') {
				this.state.currentVertexPosition++;
				this.state.line.updateCoordinate(
					String(this.state.currentVertexPosition),
					event.lngLat.lng,
					event.lngLat.lat);
			} else {
				this.state.line.addCoordinate(
					'0',
					event.lngLat.lng,
					event.lngLat.lat);
			}
		}
	}

	clickOnVertex(): void {
		this.changeMode(
			DrawMode.SimpleSelect,
			{featureIds: [this.state.line.id]});
	}

	protected keyPressEvent(evt: KeyboardEvt) {
		switch (evt.event.key) {
			case 'Enter':
				this.changeMode(
					DrawMode.SimpleSelect,
					{featureIds: [this.state.line.id]});
				break;
			case 'Escape':
				this.deleteFeature([this.state.line.id], {silent: true});
				this.changeMode(DrawMode.SimpleSelect);
				break;
		}
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt): void {
		this.touchTapMouseClickEvent(evt);
	}

	protected mouseMoveEvent(evt: MapMouseFeatureEvt) {
		this.state.line.updateCoordinate(
			String(this.state.currentVertexPosition),
			evt.event.lngLat.lng,
			evt.event.lngLat.lat);
		if (isVertex(evt.featureTarget)) {
			this.updateUIClasses({mouse: Cursor.Pointer});
		}
	}

	setup(opts?: Partial<{featureId: string; from: GeoJsonFeature<GeoJsonPoint> | GeoJsonPoint | Array<number>}>): void {
		opts = opts || {};
		const featureId = opts.featureId;
		let line: LineString | null;
		let currentVertexPosition: number;
		let direction: 'forward' | 'backwards' = 'forward';
		if (featureId) {
			line = <LineString | null>this.getFeature(featureId);
			if (!line) {
				throw new Error('Could not find a feature with the provided featureId');
			}
			let frm: Array<number> = [];
			if (Array.isArray(opts.from)) {
				frm = opts.from;
			} else {
				if (opts.from) {
					if (opts.from.type === 'Feature') {
						frm = opts.from.geometry.coordinates;
					} else {
						frm = opts.from.coordinates;
					}
				}
			}
			const lastCoord = line.coordinates.length - 1;
			if ((<GeoJsonPosition[]>line.coordinates)[lastCoord][0] === frm[0] && (<GeoJsonPosition[]>line.coordinates)[lastCoord][1] === frm[1]) {
				currentVertexPosition = lastCoord + 1;
				// add one new coordinate to continue from
				line.addCoordinate(String(currentVertexPosition), ...(<[number, number]>line.coordinates[lastCoord]));
			} else if ((<GeoJsonPosition[]>line.coordinates)[0][0] === frm[0] && (<GeoJsonPosition[]>line.coordinates)[0][1] === frm[1]) {
				direction = 'backwards';
				currentVertexPosition = 0;
				// add one new coordinate to continue from
				line.addCoordinate(String(currentVertexPosition), ...(<[number, number]>line.coordinates[0]));
			} else {
				throw new Error('`from` should match the point at either the start or the end of the provided LineString');
			}
		} else {
			line = <LineString>this.newFeature({
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: [],
				},
			});
			currentVertexPosition = 0;
			this.addFeature(line);
		}
		this.clearSelectedFeatures();
		doubleClickZoom.disable(this.ctx);
		this.updateUIClasses({mouse: Cursor.Add});
		this.activateUIButton('line_string');
		this.state = {
			line,
			currentVertexPosition,
			direction,
		};
	}

	stop(): void {
		doubleClickZoom.enable(this.ctx);
		this.ctx.ui.deactivateButtons();
		// check to see if we've deleted this feature
		if (this.getFeature(this.state.line.id)) {
			//remove last added coordinate
			this.state.line.removeCoordinate(`${this.state.currentVertexPosition}`);
			if (this.state.line.isValid()) {
				this.fireEvent(DrawEventType.Create,
					{features: [this.state.line.toGeoJSON()]});
			} else {
				this.deleteFeature([this.state.line.id], {silent: true});
				this.changeMode(DrawMode.SimpleSelect, {}, {silent: true});
			}
		}
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		const isActiveLine = feature.properties.id === this.state.line.id;
		feature.properties.active = isActiveLine ?
			'true' :
			'false';
		// Only render the line if it has at least one real coordinate
		if (isActiveLine && (feature.geometry.coordinates.length >= 2)) {
			feature.properties.meta = 'feature';
			let coords: GeoJsonPosition;
			let path: string;
			if (this.state.direction === 'forward') {
				coords = <GeoJsonPosition>feature.geometry.coordinates[feature.geometry.coordinates.length - 2];
				path = String(feature.geometry.coordinates.length - 2);
			} else {
				coords = <GeoJsonPosition>feature.geometry.coordinates[1];
				path = '1';
			}
			display(createVertex(this.state.line.id, coords, path, false));
			display(feature);
		} else {
			display(feature);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt): void {
		this.touchTapMouseClickEvent(evt);
	}

	private touchTapMouseClickEvent(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		if (isVertex(evt.featureTarget)) {
			this.clickOnVertex();
		} else {
			this.clickAnywhere(evt.event);
		}
	}

	trash(): void {
		this.deleteFeature([this.state.line.id], {silent: true});
		this.changeMode(DrawMode.SimpleSelect);
		super.trash();
	}
}
