import {MDCDialog, MDCDialogCloseEvent} from '@material/dialog';

import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {bind} from '../util';
import {OBJ, SIGNAL} from '../obj';
import {getLogger} from '../logging';

const logger = getLogger('ui.dialog');
const TITLE_ID = 'id_lb-dialog-title';

interface DialogElOpts extends ElObjOpts {
	title: string;
}

@OBJ
export class Dialog extends ElObj {
	private closing: boolean;
	private ctrl: MDCDialog | null;

	constructor(opts: Partial<DialogElOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<DialogElOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<DialogElOpts> | null, tagName?: TagName);
	constructor(opts: Partial<DialogElOpts> | null, root?: Element | null);
	constructor(opts: Partial<DialogElOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<DialogElOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<DialogElOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<DialogElOpts>(a, b, c);
		opts.classNames = ['mdc-dialog'];
		opts.root = null;
		opts.tagName = 'div';
		super(opts);
		this.closing = false;
		this.ctrl = null;
		const cont = new ElObj({
			classNames: 'mdc-dialog__container',
			parent: this,
			tagName: 'div',
		});
		const surface = new ElObj({
			attributes: [
				['role', 'alertdialog'],
				['aria-modal', 'true'],
				['aria-labelledby', 'id_lb-dialog-title'],
				['aria-describedby', 'id_lb-dialog-body'],
			],
			classNames: 'mdc-dialog__surface',
			parent: cont,
			tagName: 'div',
		});
		new ElObj({
			attributes: [
				['id', TITLE_ID],
			],
			classNames: 'mdc-dialog__title',
			parent: surface,
			tagName: 'h2',
		});
		new ElObj({
			attributes: [
				['id', 'id_lb-dialog-body'],
			],
			classNames: 'mdc-dialog__content',
			parent: surface,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-dialog__scrim',
			parent: this,
			tagName: 'div',
		});
		if (opts.title) {
			this.setTitle(opts.title);
		}
		ElObj.body().appendChild(this);
	}

	appendElObj(obj: ElObj): void {
		this.insertElObj(-1, obj);
	}

	private bodyElObj(): ElObj | null {
		return this.querySelector('#id_lb-dialog-body');
	}

	close(action?: string): void {
		if (this.closing) {
			return;
		}
		this.closing = true;
		if (this.ctrl) {
			this.ctrl.close(action);
		}
	}

	@SIGNAL
	private closed(action: string): void {
	}

	private ctrlClosedEvent(event: MDCDialogCloseEvent): void {
		this.closed(event.detail.action || '');
		this._destroy();
	}

	private ctrlOpenedEvent(): void {
		this.opened();
	}

	destroy(action?: string): void {
		this.close(action);
	}

	private _destroy(): void {
		this.destroyCtrl();
		super.destroy();
	}

	private destroyCtrl(): void {
		if (this.ctrl) {
			this.removeEventListener('MDCDialog:opened', this.domEvent);
			this.removeEventListener('MDCDialog:closed', this.domEvent);
			this.ctrl.destroy();
		}
		this.ctrl = null;
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'MDCDialog:opened':
				this.ctrlOpenedEvent();
				break;
			case 'MDCDialog:closed':
				this.ctrlClosedEvent(<MDCDialogCloseEvent>event);
				break;
		}
	}

	insertElObj(index: number, obj: ElObj): void {
		const body = this.bodyElObj();
		if (body) {
			body.insertChild(index, obj);
		}
	}

	isOpen(): boolean {
		return Boolean(this.ctrl);
	}

	open(): void {
		if (this.isOpen()) {
			return;
		}
		const root = <HTMLElement | null>this.element();
		if (!root) {
			logger.error('open: No root element');
			return;
		}
		this.addEventListener('MDCDialog:opened', this.domEvent);
		this.addEventListener('MDCDialog:closed', this.domEvent);
		this.ctrl = new MDCDialog(root, undefined, bogusFactory);
		this.ctrl.open();
	}

	@SIGNAL
	private opened(): void {
	}

	setTitle(title: string): void {
		const el = this.titleEl();
		if (el) {
			el.setText(title);
		}
	}

	private titleEl(): ElObj | null {
		return this.querySelector(`#${TITLE_ID}`);
	}
}

class BogusFocusTrap {
	constructor(root: any, options: any) {
	}

	releaseFocus(): void {
	}

	trapFocus(): void {
	}
}

function bogusFactory(root: any, options: any): BogusFocusTrap {
	return new BogusFocusTrap(root, options);
}
