import {MDCMenu, MDCMenuItemComponentEvent} from '@material/menu';

import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {OBJ, SIGNAL, SLOT} from '../obj';
import {bind, iterableToArray, stringIterableToStringArray} from '../util';
import {Point} from '../tools';
import {List, ListItem, ListItemOpts, ListOpts} from './list';

@OBJ
export class Menu extends ElObj {
	private ctrl: MDCMenu;
	private list: MenuList;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-menu',
			'mdc-menu-surface',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.list = new MenuList({parent: this});
		this.ctrl = new MDCMenu(this.elem);
		this.addEventListeners();
	}

	private addEventListeners(): void {
		this.addEventListener('MDCMenuSurface:closed', this.domEvent);
		this.addEventListener('MDCMenuSurface:closing', this.domEvent);
		this.addEventListener('MDCMenuSurface:opened', this.domEvent);
		this.addEventListener('MDCMenu:selected', this.domEvent);
	}

	addItem(item: Partial<ListItemOpts> | ListItem | string): void {
		if (typeof item === 'string') {
			item = {
				text: item,
			};
		}
		this.list.addItem(item);
	}

	@SLOT
	clear(): void {
		this.list.clear();
	}

	@SLOT
	close(): void {
		this.setOpen(false);
	}

	@SIGNAL
	private closed(): void {
	}

	@SIGNAL
	private closing(): void {
	}

	count(): number {
		return this.list.count();
	}

	private ctrlClosedEvent(): void {
		window.removeEventListener('click', this.domEvent);
		window.removeEventListener('keydown', this.domEvent, true);
		window.removeEventListener('resize', this.domEvent);
		this.closed();
	}

	private ctrlClosingEvent(): void {
		this.closing();
	}

	private ctrlOpenedEvent(): void {
		window.addEventListener('click', this.domEvent);
		window.addEventListener('keydown', this.domEvent, true);
		window.addEventListener('resize', this.domEvent);
		this.opened();
	}

	private ctrlSelectedEvent(event: MDCMenuItemComponentEvent): void {
		this.selectionChanged(event.detail.index);
	}

	destroy(): void {
		window.removeEventListener('click', this.domEvent);
		window.removeEventListener('keydown', this.domEvent, true);
		window.removeEventListener('resize', this.domEvent);
		try {
			this.close();
		} catch {
		}
		this.removeEventListeners();
		this.list.destroy();
		this.ctrl.destroy();
		super.destroy();
	}

	private domClickEvent(event: MouseEvent): void {
		let el: ElObj;
		try {
			el = ElObj.fromEvent(event);
		} catch {
			return;
		}
		if (!this.contains(el)) {
			this.close();
		}
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'click':
				this.domClickEvent(<MouseEvent>event);
				break;
			case 'resize':
				this.domResizeEvent(event);
				break;
			case 'MDCMenuSurface:closed':
				this.ctrlClosedEvent();
				break;
			case 'MDCMenuSurface:closing':
				this.ctrlClosingEvent();
				break;
			case 'MDCMenuSurface:opened':
				this.ctrlOpenedEvent();
				break;
			case 'MDCMenu:selected':
				this.ctrlSelectedEvent(<MDCMenuItemComponentEvent>event);
				break;
			case 'keydown':
				this.domKeyDownEvent(<KeyboardEvent>event);
				break;
		}
	}

	private domKeyDownEvent(event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			event.stopImmediatePropagation();
			event.stopPropagation();
			this.close();
		}
	}

	private domResizeEvent(event: Event): void {
		this.close();
	}

	isOpen(): boolean {
		return this.ctrl.open;
	}

	item(index: number): ListItem | null {
		return this.list.item(index);
	}

	@SLOT
	open(point?: Point): void {
		this.setOpen(true, point);
	}

	@SIGNAL
	private opened(): void {
	}

	private removeEventListeners(): void {
		this.removeEventListener('MDCMenuSurface:closed', this.domEvent);
		this.removeEventListener('MDCMenuSurface:closing', this.domEvent);
		this.removeEventListener('MDCMenuSurface:opened', this.domEvent);
		this.removeEventListener('MDCMenu:selected', this.domEvent);
	}

	@SIGNAL
	private selectionChanged(index: number): void {
	}

	setOpen(open: boolean, point?: Point): void {
		if (open !== this.isOpen()) {
			if (open && point) {
				ElObj.body().appendChild(this);
				this.ctrl.setAbsolutePosition(point.x(), point.y());
			}
			this.ctrl.open = open;
		}
	}
}

@OBJ
class MenuList extends List {
	constructor(opts: Partial<ListOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', 'menu'],
			['aria-hidden', 'true'],
			['aria-orientation', 'vertical'],
			['tabindex', '-1'],
			...attributes,
		];
		super(opts);
	}
}

@OBJ
class MenuListItem extends ListItem {
	constructor(opts: Partial<ListItemOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListItemOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListItemOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListItemOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListItemOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListItemOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListItemOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListItemOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', 'menuitem'],
			...attributes,
		];
		super(opts);
	}
}
