import {assert} from '../util';

export class stack<T> {
	private data: Array<T>;

	constructor(...items: Array<T>) {
		this.data = new Array(...items);
	}

	at(index: number): T {
		// Returns the item at index position i in the stack.
		// `index` must be a valid index position in the stack
		// (i.e., 0 <= i < count()).
		const rv = this.data[index];
		assert(rv);
		return rv;
	}

	clear(): void {
		this.data.length = 0;
		this.data = [];
	}

	count(): number {
		return this.data.length;
	}

	destroy(): void {
		this.clear();
	}

	isEmpty(): boolean {
		return this.data.length < 1;
	}

	pop(): T {
		const rv = this.data.pop();
		assert(rv);
		return rv;
	}

	push(item: T): void {
		this.data.push(item);
	}

	top(): T {
		const rv = this.data[this.data.length - 1];
		assert(rv);
		return rv;
	}

	get [Symbol.toStringTag](): string {
		return 'stack';
	}

	*[Symbol.iterator](): IterableIterator<T> {
		yield *this.data[Symbol.iterator]();
	}
}
