import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {OBJ, SIGNAL} from '../obj';
import {bind, clamp, stringIterableToStringArray} from '../util';
import {getLogger} from '../logging';

const logger = getLogger('ui.combobox');

@OBJ
export class ComboBox extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'select';
		super(opts);
		this.init();
	}

	private addEventListeners(): void {
		this.addEventListener('change', this.domEvent);
		this.addEventListener('click', this.domEvent);
		this.addEventListener('mousedown', this.domEvent);
		this.addEventListener('mouseup', this.domEvent);
	}

	addItem(text: string, value?: string): void {
		this.insertItem(this.count(), text, value);
	}

	addItems(texts: Iterable<string>): void {
		this.insertItems(this.count(), texts);
	}

	clear(): void {
		for (const obj of Array.from(this.element().options)) {
			this.removeChild(obj);
		}
	}

	count(): number {
		return this.element().options.length;
	}

	currentIndex(): number {
		return this.element().selectedIndex;
	}

	@SIGNAL
	currentIndexChanged(index: number): void {
	}

	currentText(): string {
		const elem = this.element();
		const idx = elem.selectedIndex;
		if (idx >= 0) {
			const opt = elem.options[idx];
			if (opt) {
				return (opt.textContent && opt.textContent.trim()) || '';
			}
		}
		return '';
	}

	@SIGNAL
	currentTextChanged(text: string): void {
	}

	destroy(): void {
		this.removeEventListeners();
		this.clear();
		super.destroy();
	}

	private domChangeEvent(event: Event): void {
		this.currentIndexChanged(this.currentIndex());
		this.currentTextChanged(this.currentText());
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'change':
				this.domChangeEvent(event);
				break;
			case 'mousedown':
			case 'mouseup':
			case 'click':
				event.stopImmediatePropagation();
				event.stopPropagation();
				break;
		}
	}

	element(): HTMLSelectElement {
		return <HTMLSelectElement>super.element();
	}

	private init(): void {
		this.addEventListeners();
	}

	insertItem(index: number, text: string, value?:string): void {
		index = clamp(0, index, this.count());
		const obj = new OptionElObj({text, value});
		this.insertChild(index, obj);
	}

	insertItems(index: number, texts: Iterable<string>): void {
		index = clamp(0, index, this.count());
		const strings = stringIterableToStringArray(texts);
		for (let i = 0; i < strings.length; ++i) {
			this.insertItem(index + i, strings[i]);
		}
	}

	itemText(index: number): string {
		const elem = this.element();
		const opts = elem.options;
		const count = opts.length;
		if (count > 0) {
			index = clamp(0, index, count - 1);
			if (index >= 0) {
				const text = opts[index].textContent;
				if (text) {
					return text.trim();
				}
			}
		}
		return '';
	}

	hasAcceptableInput(): boolean {
		return true;
	}

	private removeEventListeners(): void {
		this.removeEventListener('change', this.domEvent);
	}

	setCurrentIndex(index: number): void {
		if (index === this.currentIndex()) {
			return;
		}
		const elem = this.element();
		index = clamp(0, index, this.count() - 1);
		elem.selectedIndex = index;
		this.currentIndexChanged(elem.selectedIndex);
		this.currentTextChanged(this.currentText());
	}
}

interface OptionElObjOpts extends ElObjOpts {
	text: string;
	value: string;
}

@OBJ
class OptionElObj extends ElObj {
	constructor(opts: Partial<OptionElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<OptionElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<OptionElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<OptionElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<OptionElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<OptionElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<OptionElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<OptionElObjOpts>(a, b, c);
		opts.tagName = 'option';
		super(opts);
		if (opts.text !== undefined) {
			this.setText(opts.text);
		}
		if (opts.value !== undefined) {
			this.setValue(opts.value);
		}
	}

	element(): HTMLOptionElement {
		return <HTMLOptionElement>super.element();
	}

	setValue(value: string): void {
		this.element().value = value;
	}

	value(): string {
		return this.element().value;
	}
}
