import {
	assert,
	divmod,
	isNumber,
	modf,
	padStart,
} from './util';

interface DateParts {
	year: number;
	month: number;
	day: number;
}

interface TimeParts {
	hour: number;
	minute: number;
	second: number;
}

type DateTimeParts = DateParts & TimeParts;

export const MAX_ORDINAL = 3652059;
export const MAX_YEAR = 9999;
export const MIN_YEAR = 1;

function jsDatePartsFromDateObj(obj: Date): DateTimeParts {
	const year = obj.getFullYear();
	const month = obj.getMonth() + 1;
	const day = obj.getDate();
	const hour = obj.getHours();
	const minute = obj.getMinutes();
	const second = obj.getSeconds();
	return {
		year,
		month,
		day,
		hour,
		minute,
		second,
	};
}

const monthNames = [
	'',
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
const monthNamesAbbr = [
	'',
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
const weekdayNames = [
	'',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
];
const weekdayNamesAbbr = [
	'',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
	'Sun',
];

// -1 is a placeholder for indexing purposes.
const _DAYS_IN_MONTH = [-1, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
// -1 is a placeholder for indexing purposes.
const _DAYS_BEFORE_MONTH = [-1];
let dbm: number = 0;
for (let i = 1; i < _DAYS_IN_MONTH.length; ++i) {
	const dim = _DAYS_IN_MONTH[i];
	_DAYS_BEFORE_MONTH.push(dbm);
	dbm += dim;
}

function _is_leap(year: number): boolean {
	// year -> 1 if leap year, else 0
	return ((year % 4) === 0) && (((year % 100) !== 0) || ((year % 400) === 0));
}

function _days_before_year(year: number): number {
	// year -> number of days before January 1st of year
	const y = year - 1;
	const y1 = Math.floor(y / 4);
	const y2 = Math.floor(y / 100);
	const y3 = Math.floor(y / 400);
	return y * 365 + y1 - y2 + y3;
}

function _days_before_month(year: number, month: number): number {
	// year, month -> number of days in year preceding first day of month
	assert((month >= 1) && (month <= 12), 'month must be in 1..12');
	return _DAYS_BEFORE_MONTH[month] + Number((month > 2) && _is_leap(year));
}

function _days_in_month(year: number, month: number): number {
	// year, month -> number of days in that month in that year
	assert((month >= 1) && (month <= 12), 'month must be in 1..12');
	if ((month === 2) && _is_leap(year)) {
		return 29;
	}
	return _DAYS_IN_MONTH[month];
}

function _ymd2ord(year: number, month: number, day: number): number {
	// year, month, day -> ordinal, considering 01-Jan-0001 as day 1
	assert((month >= 1) && (month <= 12), 'month must be in 1..12');
	const dim = _days_in_month(year, month);
	assert((day >= 1) && (day <= dim), `day must be in 1..${dim}`);
	return _days_before_year(year) + _days_before_month(year, month) + day;
}

function _parse_isoformat_date(dateString: string): [number, number, number] {
	// It is assumed that this function will only be called with a
	// string of length exactly 10, and (though this is not used) ASCII-only
	const year = Number.parseInt(dateString.slice(0, 4));
	if (dateString[4] !== '-') {
		throw new Error(`Invalid date separator: ${dateString[4]}`);
	}
	const month = Number.parseInt(dateString.slice(5, 7));
	if (dateString[7] !== '-') {
		throw new Error(`Invalid date separator: ${dateString[7]}`);
	}
	const day = Number.parseInt(dateString.slice(8, 10));
	return [year, month, day];
}

function _parse_hh_mm_ss_ff(timeString: string): number[] {
	// Parses things of the form HH[:MM[:SS[.fff[fff]]]]
	const strLen = timeString.length;
	const timeComps = [0, 0, 0, 0];
	let pos = 0;
	for (let i = 0; i < 3; ++i) {
		if ((strLen - pos) < 2) {
			throw new Error('Incomplete time component');
		}
		timeComps[i] = Number.parseInt(timeString.slice(pos, pos + 2));
		pos += 2;
		const nextChar = timeString.slice(pos, pos + 1);
		if (!nextChar || (i >= 2)) {
			break;
		}
		if (nextChar !== ':') {
			throw new Error(`Invalid time separator: ${nextChar}`);
		}
		pos += 1;
	}
	if (pos < strLen) {
		if (timeString[pos] !== '.') {
			throw new Error('Invalid microsecond component');
		}
		pos += 1;
		const lenRemainder = strLen - pos;
		if ((lenRemainder !== 3) && (lenRemainder !== 6)) {
			throw new Error('Invalid microsecond component');
		}
		timeComps[3] = Number.parseInt(timeString.slice(pos));
		if (lenRemainder === 3) {
			timeComps[3] *= 1000;
		}
	}
	return timeComps;
}

function _parse_isoformat_time(timeString: string): number[] {
	// Format supported is HH[:MM[:SS[.fff[fff]]]][+HH:MM[:SS[.ffffff]]]
	const strLen = timeString.length;
	if (strLen < 2) {
		throw new Error('Isoformat time too short');
	}
	return _parse_hh_mm_ss_ff(timeString);
}

const _DI400Y = _days_before_year(401); // number of days in 400 years
const _DI100Y = _days_before_year(101); // number of days in 100 years
const _DI4Y = _days_before_year(5);   // number of days in 4 years
// A 4-year cycle has an extra leap day over what we'd get from pasting
// together 4 single years.
assert(_DI4Y === (4 * 365 + 1));
// Similarly, a 400-year cycle has an extra leap day over what we'd get from
// pasting together 4 100-year cycles.
assert(_DI400Y === (4 * _DI100Y + 1));
// OTOH, a 100-year cycle has one fewer leap day than we'd get from
// pasting together 25 4-year cycles.
assert(_DI100Y === (25 * _DI4Y - 1));

function _ord2ymd(n: number): [number, number, number] {
	// ordinal -> (year, month, day), considering 01-Jan-0001 as day 1
	//
	// n is a 1-based index, starting at 1-Jan-1. The pattern of leap years
	// repeats exactly every 400 years. The basic strategy is to find the
	// closest 400-year boundary at or before n, then work with the offset
	// from that boundary to n. Life is much clearer if we subtract 1 from
	// n first -- then the values of n at 400-year boundaries are exactly
	// those divisible by _DI400Y:
	//
	//     D  M   Y            n              n-1
	//     -- --- ----        ----------     ----------------
	//     31 Dec -400        -_DI400Y       -_DI400Y -1
	//      1 Jan -399         -_DI400Y +1   -_DI400Y      400-year boundary
	//     ...
	//     30 Dec  000        -1             -2
	//     31 Dec  000         0             -1
	//      1 Jan  001         1              0            400-year boundary
	//      2 Jan  001         2              1
	//      3 Jan  001         3              2
	//     ...
	//     31 Dec  400         _DI400Y        _DI400Y -1
	//      1 Jan  401         _DI400Y +1     _DI400Y      400-year boundary
	n -= 1;
	let n400: number;
	[n400, n] = divmod(n, _DI400Y);
	let year = n400 * 400 + 1; // ..., -399, 1, 401, ...
	// Now n is the (non-negative) offset, in days, from January 1 of year, to
	// the desired date. Now compute how many 100-year cycles precede n.
	// Note that it's possible for n100 to equal 4! In that case 4 full
	// 100-year cycles precede the desired day, which implies the desired
	// day is December 31 at the end of a 400-year cycle.
	let n100: number;
	[n100, n] = divmod(n, _DI100Y);
	// Now compute how many 4-year cycles precede it.
	let n4: number;
	[n4, n] = divmod(n, _DI4Y);
	// And now how many single years. Again n1 can be 4, and again meaning
	// that the desired day is December 31 at the end of the 4-year cycle.
	let n1: number;
	[n1, n] = divmod(n, 365);
	year += (n100 * 100 + n4 * 4 + n1);
	if ((n1 === 4) || (n100 == 4)) {
		assert(n === 0);
		return [year - 1, 12, 31];
	}
	// Now the year is correct, and n is the offset from January 1. We find
	// the month via an estimate that's either exact or one too large.
	const leapyear = (n1 === 3) && ((n4 !== 24) || (n100 === 3));
	assert(leapyear === _is_leap(year));
	let month = (n + 50) >> 5;
	let preceding = _DAYS_BEFORE_MONTH[month] + Number((month > 2) && leapyear);
	if (preceding > n) { // estimate is too large
		month -= 1;
		preceding -= _DAYS_IN_MONTH[month] + Number((month === 2) && leapyear);
	}
	n -= preceding;
	assert((n >= 0) && (n < _days_in_month(year, month)));
	// Now the year and month are correct, and n is the offset from the
	// start of that month: we're done!
	return [year, month, n + 1];
}

function _cmp(x: number, y: number): number {
	return (x === y) ? 0 : (x > y) ? 1 : -1;
}

function _cmpTup(a: number[], b: number[]): number {
	// a, b must be same length
	assert(a.length === b.length, 'a, b, must be same length');
	for (let i = 0; i < a.length; ++i) {
		const res = _cmp(a[i], b[i]);
		if (res !== 0) {
			return res;
		}
	}
	return 0;
}

export class timedelta {
	static cmp(a: timedelta, b: timedelta): number {
		return a._cmp(b);
	}

	readonly _days: number;
	readonly _seconds: number;
	readonly _microseconds: number;

	constructor(days: number = 0, seconds: number = 0, microseconds: number = 0, milliseconds: number = 0, minutes: number = 0, hours: number = 0, weeks: number = 0) {
		let d: number;
		let s: number = 0;
		let us: number;
		// Normalize everything to days, seconds, microseconds.
		days += (weeks * 7);
		seconds += (minutes * 60 + hours * 3600);
		microseconds += (milliseconds * 1000);
		let daysecondsfrac: number;
		// Get rid of all fractions, and normalize s and us.
		if (Number.isInteger(days)) {
			daysecondsfrac = 0.0;
			d = days;
		} else {
			let dayfrac: number;
			[dayfrac, days] = modf(days);
			let daysecondswhole: number;
			[daysecondsfrac, daysecondswhole] = modf(dayfrac * (24.0 * 3600.0));
			assert(daysecondswhole === Math.trunc(daysecondswhole)); // can't overflow
			s = Math.trunc(daysecondswhole);
			assert(days === Math.trunc(days));
			d = Math.trunc(days);
		}
		assert(Math.abs(daysecondsfrac) <= 1.0);
		assert(Number.isInteger(d));
		assert(Math.abs(s) <= (24 * 3600));
		let secondsfrac: number;
		if (Number.isInteger(seconds)) {
			secondsfrac = daysecondsfrac;
		} else {
			[secondsfrac, seconds] = modf(seconds);
			assert(Math.trunc(seconds) === seconds);
			seconds = Math.trunc(seconds);
			secondsfrac += daysecondsfrac;
			assert(Math.abs(secondsfrac) <= 2.0);
		}
		assert(Math.abs(secondsfrac) <= 2.0);
		assert(Number.isInteger(seconds));
		[days, seconds] = divmod(seconds, 24 * 3600);
		d += days;
		s += Math.trunc(seconds);
		assert(Number.isInteger(s));
		assert(Math.abs(s) <= (2 * 24 * 3600));
		const usdouble = secondsfrac * 1e6;
		assert(Math.abs(usdouble) < 2.1e6); //exact value not critical
		if (Number.isInteger(microseconds)) {
			microseconds = Math.trunc(microseconds);
			[seconds, microseconds] = divmod(microseconds, 1000000);
			[days, seconds] = divmod(seconds, 24 * 3600);
			d += days;
			s += seconds;
			microseconds = Math.round(microseconds + usdouble);
		} else {
			microseconds = Math.round(microseconds + usdouble);
			[seconds, microseconds] = divmod(microseconds, 1000000);
			[days, seconds] = divmod(seconds, 24 * 3600);
			d += days;
			s += seconds;
		}
		assert(Number.isInteger(s));
		assert(Number.isInteger(microseconds));
		assert(Math.abs(s) <= (3 * 24 * 3600));
		assert(Math.abs(microseconds) <= 3.1e6);
		// Just a little bit of carrying possible for microseconds and seconds.
		[seconds, us] = divmod(microseconds, 1000000);
		s += seconds;
		[days, s] = divmod(s, 24 * 3600);
		d += days;
		assert(Number.isInteger(d));
		assert(Number.isInteger(s) && ((s >= 0) && (s < 24 * 3600)));
		assert(Number.isInteger(us) && ((us >= 0) && (us < 1000000)));
		if (Math.abs(d) > 999999999) {
			throw new Error(`timedelta # of days is too large: ${d}`);
		}
		this._days = d;
		this._seconds = s;
		this._microseconds = us;
	}

	get days(): number {
		return this._days;
	}

	get seconds(): number {
		return this._seconds;
	}

	get microseconds(): number {
		return this._microseconds;
	}

	abs(): timedelta {
		return (this._days < 0) ?
			this.neg() :
			this;
	}

	add(other: timedelta): timedelta {
		return new timedelta(
			this._days + other._days,
			this._seconds + other._seconds,
			this._microseconds + other._microseconds);
	}

	bool(): boolean {
		return (this._days !== 0) || (this._seconds !== 0) || (this._microseconds !== 0);
	}

	divmod(other: timedelta): [number, timedelta] {
		const [q, r] = divmod(this._toMicroseconds(), other._toMicroseconds());
		return [q, new timedelta(0, 0, r)];
	}

	eq(other: timedelta): boolean {
		return this._cmp(other) === 0;
	}

	ge(other: timedelta): boolean {
		return this._cmp(other) >= 0;
	}

	gt(other: timedelta): boolean {
		return this._cmp(other) > 0;
	}

	le(other: timedelta): boolean {
		return this._cmp(other) <= 0;
	}

	lt(other: timedelta): boolean {
		return this._cmp(other) < 0;
	}

	mod(other: timedelta): timedelta {
		const r = this._toMicroseconds() % other._toMicroseconds();
		return new timedelta(0, 0, r);
	}

	mul(n: number): timedelta {
		if (!Number.isInteger(n)) {
			throw new Error(`Expected integer, got ${n}`);
		}
		return new timedelta(
			this._days * n,
			this._seconds * n,
			this._microseconds * n);
	}

	neg(): timedelta {
		return new timedelta(
			-this._days,
			-this._seconds,
			-this._microseconds);
	}

	sub(other: timedelta): timedelta {
		return new timedelta(
			this._days - other._days,
			this._seconds - other._seconds,
			this._microseconds - other._microseconds);
	}

	toString(): string {
		let hh: number;
		let mm: number;
		let ss: number;
		[mm, ss] = divmod(this._seconds, 60);
		[hh, mm] = divmod(mm, 60);
		let s: string = `${hh}:${padStart(mm, 2, '0')}:${padStart(ss, 2, '0')}`;
		if (this._days) {
			const plural = (Math.abs(this._days) === 1) ? 's' : '';
			s = `${this._days} day${plural}, ${s}`;
		}
		if (this._microseconds) {
			s = `${s}.${padStart(this._microseconds, 6, '0')}`;
		}
		return s;
	}

	totalSeconds(): number {
		return ((this._days * 86400 + this._seconds) * 10 ** 6 + this._microseconds) / 10 ** 6;
	}

	_cmp(other: timedelta): number {
		return _cmpTup(this._getstate(), other._getstate());
	}

	_getstate(): [number, number, number] {
		return [this._days, this._seconds, this._microseconds];
	}

	_toMicroseconds(): number {
		return ((this._days * (24 * 3600) + this._seconds) * 1000000 + this._microseconds);
	}
}

export class date {
	static fromisoformat(dateString: string): date {
		if (!(typeof dateString === 'string')) {
			throw new Error('fromisoformat: argument must be str');
		}
		try {
			assert(dateString.length === 10);
			return new this(..._parse_isoformat_date(dateString));
		} catch {
			throw new Error(`Invalid isoformat string: ${dateString}`);
		}
	}

	static fromjsdate(obj: Date): date {
		const {year, month, day} = jsDatePartsFromDateObj(obj);
		return new this(year, month, day);
	}

	static fromordinal(n: number): date {
		return new this(..._ord2ymd(n));
	}

	static fromtimestamp(ts: number): date {
		// ts: ** MILLISECONDS ** since the Unix Epoch
		return this.fromjsdate(new Date(ts));
	}

	static today(): date {
		return this.fromtimestamp(Date.now());
	}

	readonly year: number;
	readonly month: number;
	readonly day: number;

	constructor(year: number, month: number, day: number) {
		assert((year >= MIN_YEAR) && (year <= MAX_YEAR), `year must be in ${MIN_YEAR}..${MAX_YEAR}`);
		assert((month >= 1) && (month <= 12), 'month must be in 1..12');
		const dim = _days_in_month(year, month);
		assert((day >= 1) && (day <= dim), `day must be in 1..${dim}`);
		this.year = year;
		this.month = month;
		this.day = day;
	}

	add(other: timedelta): date {
		const o = this.toordinal() + other.days;
		if ((o > 0) && (o <= MAX_ORDINAL)) {
			return date.fromordinal(o);
		}
		throw new Error('result out of range');
	}

	eq(other: date): boolean {
		return this._cmp(other) === 0;
	}

	ge(other: date): boolean {
		return this._cmp(other) >= 0;
	}

	gt(other: date): boolean {
		return this._cmp(other) > 0;
	}

	isoformat(): string {
		const {year, month, day} = this;
		return `${padStart(year, 4, '0')}-${padStart(month, 2, '0')}-${padStart(day, 2, '0')}`;
	}

	isoweekday(): number {
		// Return the day of the week as an integer, where Monday is 1 and
		// Sunday is 7.
		// For example, date(2002, 12, 4).isoweekday() == 3, a Wednesday.
		return (this.toordinal() % 7) || 7;
	}

	le(other: date): boolean {
		return this._cmp(other) <= 0;
	}

	lt(other: date): boolean {
		return this._cmp(other) < 0;
	}

	monthName(): string {
		return monthNames[this.month];
	}

	monthNameAbbr(): string {
		return monthNamesAbbr[this.month];
	}

	replace(values?: Partial<DateParts>): date {
		// Return a date with the same value, except for those parameters
		// given new values by whichever keyword arguments are specified.
		const obj = Object.assign({}, values);
		const year = isNumber(obj.year) ? obj.year : this.year;
		const month = isNumber(obj.month) ? obj.month : this.month;
		const day = isNumber(obj.day) ? obj.day : this.day;
		return new date(year, month, day);
	}

	sub(other: date): timedelta;
	sub(other: timedelta): date;
	sub(other: date | timedelta): timedelta | date {
		if (other instanceof timedelta) {
			return this.add(new timedelta(-other.days));
		}
		const days1 = this.toordinal();
		const days2 = other.toordinal();
		return new timedelta(days1 - days2);
	}

	tojsdate(): Date {
		return new Date(this.isoformat());
	}

	toordinal(): number {
		// Return proleptic Gregorian ordinal for the year, month and day.
		//
		// January 1 of year 1 is day 1. Only the year, month and day values
		// contribute to the result.
		const {year, month, day} = this;
		return _ymd2ord(year, month, day);
	}

	toString(): string {
		return this.isoformat();
	}

	weekday(): number {
		// Return the day of the week as an integer, where Monday is 0 and
		// Sunday is 6.
		// For example, date(2002, 12, 4).weekday() == 2, a Wednesday.
		return (this.toordinal() + 6) % 7;
	}

	weekdayName(): string {
		return weekdayNames[this.isoweekday()];
	}

	weekdayNameAbbr(): string {
		return weekdayNamesAbbr[this.isoweekday()];
	}

	_cmp(other: date): number {
		const {year: y1, month: m1, day: d1} = this;
		const {year: y2, month: m2, day: d2} = other;
		return _cmpTup([y1, m1, d1], [y2, m2, d2]);
	}
}

export class datetime extends date {
	static combine(date: date, time: time): datetime {
		const {year, month, day} = date;
		const {hour, minute, second} = time;
		return new this(year, month, day, hour, minute, second);
	}

	static fromisoformat(dateTimeString: string): datetime {
		const dateString = dateTimeString.slice(0, 10);
		const timeString = dateTimeString.slice(11);
		let dateComp: [number, number, number];
		try {
			dateComp = _parse_isoformat_date(dateString);
		} catch {
			throw new Error(`Invalid isoformat string: ${dateTimeString}`);
		}
		let timeComp: [number, number, number];
		if (timeString) {
			try {
				timeComp = _parse_isoformat_time(timeString) as [number, number, number];
			} catch {
				throw new Error(`Invalid isoformat string: ${dateTimeString}`);
			}
		} else {
			timeComp = [0, 0, 0];
		}
		return new this(...dateComp.concat(timeComp) as [number, number, number, number, number, number]);
	}

	static fromjsdate(obj: Date): datetime {
		const {year, month, day, hour, minute, second} = jsDatePartsFromDateObj(obj);
		return new this(year, month, day, hour, minute, second);
	}

	static fromtimestamp(ts: number): datetime {
		// ts: ** MILLISECONDS ** since the Unix Epoch
		return this.fromjsdate(new Date(ts));
	}

	static now(): datetime {
		return this.fromtimestamp(Date.now());
	}

	static today(): datetime {
		return this.now();
	}

	readonly hour: number;
	readonly minute: number;
	readonly second: number;

	constructor(year: number, month: number, day: number, hour: number = 0, minute: number = 0, second: number = 0) {
		super(year, month, day);
		assert((hour >= 0) && (hour <= 23), 'hour must be in 0..23');
		assert((minute >= 0) && (minute <= 59), 'minute must be in 0..59');
		assert((second >= 0) && (second <= 59), 'second must be in 0..59');
		this.hour = hour;
		this.minute = minute;
		this.second = second;
	}

	add(other: timedelta): datetime {
		let delta = new timedelta(this.toordinal(), this.second, 0, 0, this.minute, this.hour, 0);
		delta = delta.add(other);
		const [hour, rem] = divmod(delta.seconds, 3600);
		const [minute, second] = divmod(rem, 60);
		if ((delta.days > 0) && (delta.days <= MAX_ORDINAL)) {
			return datetime.combine(date.fromordinal(delta.days), new time(hour, minute, second));
		}
		throw new Error('result out of range');
	}

	date(): date {
		const {year, month, day} = this;
		return new date(year, month, day);
	}

	eq(other: datetime): boolean {
		return this._cmp(other) === 0;
	}

	ge(other: datetime): boolean {
		return this._cmp(other) >= 0;
	}

	gt(other: datetime): boolean {
		return this._cmp(other) > 0;
	}

	isoformat(sep: string = 'T'): string {
		const datefmat = super.isoformat();
		const timefmat = this.time().isoformat();
		return `${datefmat}${sep}${timefmat}`;
	}

	le(other: datetime): boolean {
		return this._cmp(other) <= 0;
	}

	lt(other: datetime): boolean {
		return this._cmp(other) < 0;
	}

	replace(values?: Partial<DateTimeParts>): datetime {
		// Return a datetime with the same attributes, except for those
		// attributes given new values by whichever keyword arguments are
		// specified.
		const obj = Object.assign({}, values);
		const year = isNumber(obj.year) ? obj.year : this.year;
		const month = isNumber(obj.month) ? obj.month : this.month;
		const day = isNumber(obj.day) ? obj.day : this.day;
		const hour = isNumber(obj.hour) ? obj.hour : this.hour;
		const minute = isNumber(obj.minute) ? obj.minute : this.minute;
		const second = isNumber(obj.second) ? obj.second : this.second;
		return new datetime(year, month, day, hour, minute, second);
	}

	sub(other: datetime): timedelta;
	sub(other: timedelta): datetime;
	sub(other: datetime | timedelta): timedelta | datetime {
		if (other instanceof timedelta) {
			return this.add(other.neg());
		}
		const days1 = this.toordinal();
		const days2 = other.toordinal();
		const secs1 = this.second + this.minute * 60 + this.hour * 3600;
		const secs2 = other.second + other.minute * 60 + other.hour * 3600;
		return new timedelta(days1 - days2, secs1 - secs2);
	}

	time(): time {
		const {hour, minute, second} = this;
		return new time(hour, minute, second);
	}

	timestamp(): number {
		// returns the number of ** MILLISECONDS ** since the Unix Epoch.
		return this.tojsdate().getTime();
	}

	_cmp(other: datetime): number {
		const {year: y1, month: m1, day: d1, hour: h1, minute: min1, second: s1} = this;
		const {year: y2, month: m2, day: d2, hour: h2, minute: min2, second: s2} = other;
		return _cmpTup([y1, m1, d1, h1, min1, s1], [y2, m2, d2, h2, min2, s2]);
	}
}

export class time {
	static fromisoformat(timeString: string): time {
		try {
			return new this(..._parse_isoformat_time(timeString));
		} catch {
			throw new Error(`Invalid isoformat string: ${timeString}`);
		}
	}

	static fromjsdate(obj: Date): time {
		const {hour, minute, second} = jsDatePartsFromDateObj(obj);
		return new this(hour, minute, second);
	}

	readonly hour: number;
	readonly minute: number;
	readonly second: number;

	constructor(hour: number = 0, minute: number = 0, second: number = 0) {
		assert((hour >= 0) && (hour <= 23), 'hour must be in 0..23');
		assert((minute >= 0) && (minute <= 59), 'minute must be in 0..59');
		assert((second >= 0) && (second <= 59), 'second must be in 0..59');
		this.hour = hour;
		this.minute = minute;
		this.second = second;
	}

	eq(other: time): boolean {
		return this._cmp(other) === 0;
	}

	ge(other: time): boolean {
		return this._cmp(other) >= 0;
	}

	gt(other: time): boolean {
		return this._cmp(other) > 0;
	}

	isoformat(): string {
		const {hour, minute, second} = this;
		return `${padStart(hour, 2, '0')}:${padStart(minute, 2, '0')}:${padStart(second, 2, '0')}`;
	}

	le(other: time): boolean {
		return this._cmp(other) <= 0;
	}

	lt(other: time): boolean {
		return this._cmp(other) < 0;
	}

	replace(values?: Partial<TimeParts>): time {
		const obj = Object.assign({}, values);
		const hour = isNumber(obj.hour) ? obj.hour : this.hour;
		const minute = isNumber(obj.minute) ? obj.minute : this.minute;
		const second = isNumber(obj.second) ? obj.second : this.second;
		return new time(hour, minute, second);
	}

	toString(): string {
		return this.isoformat();
	}

	_cmp(other: time): number {
		const {hour: h1, minute: m1, second: s1} = this;
		const {hour: h2, minute: m2, second: s2} = other;
		return _cmpTup([h1, m1, s1], [h2, m2, s2]);
	}
}

interface CmpFunc<T = any> {
	_cmp(obj: T): number;
}

export function datetimeSortKey<T extends CmpFunc>(a: T, b: T): number {
	return a._cmp(b);
}
