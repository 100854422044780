import {AbstractButton} from './abstractbutton';
import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {OBJ} from '../obj';
import {bind, stringIterableToStringArray} from '../util';
import {CheckState} from '../constants';

@OBJ
export class RadioButton extends AbstractButton {
	private inputEl: ElObj;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-radio',
			'lb-radio-button',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.inputEl = new ElObj({
			attributes: [
				['type', 'radio'],
			],
			classNames: [
				'mdc-radio__native-control',
			],
			parent: this,
			tagName: 'input',
		});
		const bg = new ElObj({
			classNames: 'mdc-radio__background',
			parent: this,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-radio__outer-circle',
			parent: bg,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-radio__inner-circle',
			parent: bg,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-radio__ripple',
			parent: this,
			tagName: 'div',
		});
		this.setCheckable(true);
		this.inputEl.addEventListener('change', this.domEvent);
		this.addEventListener('mousedown', this.domEvent);
		this.addEventListener('mouseup', this.domEvent);
		this.addEventListener('click', this.domEvent);
	}

	checkState(): CheckState {
		return this.inputEl.checkState();
	}

	destroy(): void {
		this.inputEl.removeEventListener('change', this.domEvent);
		this.removeEventListener('mousedown', this.domEvent);
		this.removeEventListener('mouseup', this.domEvent);
		this.removeEventListener('click', this.domEvent);
		this.inputEl.destroy();
		super.destroy();
	}

	private domChangeEvent(event: Event): void {
		this.toggled(this.isChecked());
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'change':
				this.domChangeEvent(event);
				break;
			default:
				event.stopImmediatePropagation();
				event.stopPropagation();
				break;
		}
	}

	id(): string {
		return this.inputEl.attribute('id') || '';
	}

	isDisabled(): boolean {
		return this.inputEl.isDisabled();
	}

	isRequired(): boolean {
		return this.inputEl.isRequired();
	}

	name(): string {
		return this.inputEl.attribute('name') || '';
	}

	setCheckState(state: CheckState): void {
		this.inputEl.setCheckState(state);
	}

	setDisabled(disabled: boolean): void {
		this.inputEl.setDisabled(disabled);
		this.setClass(disabled, 'mdc-radio mdc-radio--disabled');
	}

	setId(id: string | null): void {
		if (typeof id === 'string') {
			this.inputEl.setAttribute('id', id);
		} else {
			this.inputEl.removeAttribute('id');
		}
	}

	setName(name: string | null): void {
		if ((typeof name === 'string') && (name.trim().length > 0)) {
			this.inputEl.setAttribute('name', name);
		} else {
			this.inputEl.removeAttribute('name');
		}
	}

	setRequired(required: boolean): void {
		this.inputEl.setRequired(required);
	}

	setValue(value: string | null): void {
		if (typeof value === 'string') {
			this.inputEl.setAttribute('value', value);
		} else {
			this.inputEl.removeAttribute('value');
		}
	}

	value(): string {
		return this.inputEl.attribute('value') || '';
	}
}
