import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {numberFormat, stringIterableToStringArray} from '../../util';
import {PushButton, PushButtonOpts} from '../../ui/pushbutton';
import {LinearProgress} from '../../ui/progress';
import {Menu} from '../../ui/menu';
import type {Layout} from './layout';
import {Point} from '../../tools';
import {NBSP_CHAR_CODE} from '../../constants';

enum ProjectOption {
	Archive = 'Archive',
	Copy = 'Copy',
	Restore = 'Restore',
	DownloadListWithOwnerName = 'Download w/Owner Names',
}

@OBJ
export class ProjectTile extends ElObj {
	private actions: Actions;
	private primaryAction: PrimaryAction;
	project: IProject;
	private view: Layout | null;

	constructor(view: Layout | null, project: IProject, opts?: Partial<ElObjOpts>) {
		opts = opts || {};
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card',
			'mdc-card--outlined',
			'lb-project-list-item',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.project = project;
		this.view = view;
		this.primaryAction = new PrimaryAction({parent: this});
		this.actions = new Actions({parent: this});
		const op = [
			ProjectOption.Copy,
			this.project.archived ?
				ProjectOption.Restore :
				ProjectOption.Archive,
		];
		if (this.project.absoluteListUrl && this.project.downloadWithOwnerNameEnabled) {
			op.push(
				ProjectOption.DownloadListWithOwnerName,
			);
		}
		this.actions.setMenuOptions(op);
		this.setId(`id_lb-project-${this.project.slug}`);
		this.setTitle((this.project.title.trim().length > 0) ?
			project.title :
			'Untitled Project');
		Obj.connect(
			this.actions, 'payButtonClicked',
			this, 'payButtonClicked');
		Obj.connect(
			this.actions, 'projectOptionSelected',
			this, 'projectOptionSelected');
	}

	addActionIcon(icon: ActionIcon): void {
		this.actions.addActionIcon(icon);
	}

	destroy(): void {
		Obj.disconnect(
			this.actions, 'projectOptionSelected',
			this, 'projectOptionSelected');
		this.view = null;
		this.primaryAction.destroy();
		this.actions.destroy();
		super.destroy();
	}

	id(): string {
		return this.attribute('id') || '';
	}

	@SLOT
	private async payButtonClicked(): Promise<void> {
		if (this.view) {
			await this.view.payButtonClicked(this.project.slug);
		}
	}

	@SLOT
	private async projectOptionSelected(option: ProjectOption): Promise<void> {
		if (!this.view) {
			return;
		}
		switch (option) {
			case ProjectOption.Copy:
				await this.view.cloneButtonClicked(this.project.slug);
				break;
			case ProjectOption.Archive:
				await this.view.archiveButtonClicked(this.project.slug);
				break;
			case ProjectOption.Restore:
				await this.view.restoreButtonClicked(this.project.slug);
				break;
			case ProjectOption.DownloadListWithOwnerName:
				await this.view.downloadWithOwnerNameButtonClicked(this.project.slug);
				break;
		}
	}

	setBackgroundImageUrl(url: string): void {
		this.primaryAction.setBackgroundImageUrl(url);
	}

	setId(id: string): void {
		this.setAttribute('id', id);
	}

	setInvoiceQuantity(count: number | string): void {
		this.primaryAction.setInvoiceQuantity(count);
	}

	setProgressVisible(visible: boolean): void {
		this.primaryAction.setProgressVisible(visible);
	}

	setDownloadUrl(url: string | null): void {
		this.actions.setDownloadUrl(url);
	}

	setPayButtonEnabled(enabled: boolean): void {
		this.actions.setPayButtonEnabled(enabled);
	}

	setTitle(title: string): void {
		this.primaryAction.setTitle(title);
	}

	setUrl(url: string): void {
		this.primaryAction.setHref(url);
	}

	url(): string {
		return this.primaryAction.href();
	}
}

@OBJ
class PrimaryAction extends ElObj {
	media: Media;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card__primary-action',
			...classNames,
		];
		opts.tagName = 'a';
		super(opts);
		this.media = new Media({parent: this});
	}

	destroy(): void {
		this.media.destroy();
		super.destroy();
	}

	element(): HTMLAnchorElement {
		return <HTMLAnchorElement>super.element();
	}

	href(): string {
		return this.element().href;
	}

	setBackgroundImageUrl(url: string): void {
		this.media.setBackgroundImageUrl(url);
	}

	setHref(href: string): void {
		this.element().href = href;
	}

	setInvoiceQuantity(count: number | string): void {
		this.media.content.inner.header.setInvoiceQuantity(count);
	}

	setProgressVisible(visible: boolean): void {
		this.media.setProgressVisible(visible);
	}

	setTitle(title: string): void {
		this.media.content.inner.header.setTitle(title);
	}
}

@OBJ
class Media extends ElObj {
	content: MediaContent;
	private linearProgress: LinearProgress;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card__media',
			'mdc-card__media--16-9',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.content = new MediaContent({parent: this});
		this.linearProgress = new LinearProgress({
			indeterminate: true,
			parent: this,
		});
	}

	destroy(): void {
		this.content.destroy();
		this.linearProgress.destroy();
		super.destroy();
	}

	setBackgroundImageUrl(url: string): void {
		this.setStyleProperty('background-image', `url("${url}")`);
	}

	setProgressVisible(visible: boolean): void {
		this.linearProgress.setOpen(visible);
	}
}

@OBJ
class MediaContent extends ElObj {
	inner: MediaContentInner;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card__media-content',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.inner = new MediaContentInner({parent: this});
	}
}

@OBJ
class MediaContentInner extends ElObj {
	header: ContentHeader;
	body: ContentBody;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-project-list-card-content',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.header = new ContentHeader({parent: this});
		this.body = new ContentBody({parent: this});
	}

	destroy(): void {
		this.header.destroy();
		this.body.destroy();
		super.destroy();
	}
}

@OBJ
class ContentHeader extends ElObj {
	private countEl: ElObj;
	private titleEl: ElObj;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'content-header',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.titleEl = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--headline6',
				'truncate',
			],
			parent: this,
			tagName: 'h2',
		});
		this.titleEl.setText(String.fromCharCode(NBSP_CHAR_CODE));
		this.countEl = new ElObj({
			classNames: 'lb-project-addr-count',
			parent: this,
			tagName: 'div',
		});
		this.countEl.setText(String.fromCharCode(NBSP_CHAR_CODE));
	}

	setInvoiceQuantity(count: number | string): void {
		this.countEl.setText(`Mailing addresses: ${numberFormat(count)}`);
	}

	setTitle(title: string): void {
		if (title.trim().length < 1) {
			title = String.fromCharCode(NBSP_CHAR_CODE);
			this.titleEl.removeAttribute('title');
		}
		this.titleEl.setText(title);
		this.titleEl.setAttribute('title', title);
	}
}

@OBJ
class ContentBody extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'content-body',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		const btn = new ElObj({
			attributes: [
				['type', 'button'],
			],
			classNames: [
				'mdc-button',
				'mdc-button--unelevated',
			],
			parent: this,
			tagName: 'button',
		});
		new ElObj({
			classNames: 'mdc-button__ripple',
			parent: btn,
			tagName: 'span',
		});
		const label = new ElObj({
			classNames: 'mdc-button__label',
			parent: btn,
			tagName: 'span',
		});
		label.setText('OPEN');
	}
}

@OBJ
class Actions extends ElObj {
	private actionButtons: ActionButtons;
	private actionIcons: ElObj;
	private menu: Menu | null;
	private menuOpts: Array<ProjectOption>;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card__actions',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.actionButtons = new ActionButtons({parent: this});
		Obj.connect(
			this.actionButtons, 'payButtonClicked',
			this, 'payButtonClicked');
		this.actionIcons = new ElObj({
			classNames: ['mdc-card__action-icons', 'mdc-menu-surface--anchor'],
			parent: this,
			tagName: 'div',
		});
		this.menu = null;
		this.menuOpts = [];
	}

	@SLOT
	private actionIconClicked(checked: boolean, point: Point): void {
		this.openMenu(point);
	}

	addActionIcon(icon: ActionIcon): void {
		Obj.connect(
			icon, 'clicked',
			this, 'actionIconClicked');
		this.actionIcons.appendChild(icon);
	}

	destroy(): void {
		this.destroyMenu();
		this.actionIcons.destroy();
		this.actionButtons.destroy();
		super.destroy();
	}

	private destroyMenu(): void {
		if (this.menu) {
			Obj.disconnect(
				this.menu, 'selectionChanged',
				this, 'menuSelectionChanged');
			Obj.disconnect(
				this.menu, 'closed',
				this, 'menuClosed');
			this.menu.destroy();
		}
		this.menu = null;
	}

	@SLOT
	private menuClosed(): void {
		this.destroyMenu();
	}

	@SLOT
	private menuSelectionChanged(index: number): void {
		if ((index >= 0) && (index < this.menuOpts.length)) {
			this.projectOptionSelected(this.menuOpts[index]);
		}
	}

	private openMenu(point: Point): void {
		this.destroyMenu();
		this.menu = new Menu();
		Obj.connect(
			this.menu, 'selectionChanged',
			this, 'menuSelectionChanged');
		Obj.connect(
			this.menu, 'closed',
			this, 'menuClosed');
		for (const obj of this.menuOpts) {
			this.menu.addItem(obj);
		}
		this.menu.open(point);
	}

	@SIGNAL
	private payButtonClicked(): void {
	}

	@SIGNAL
	private projectOptionSelected(option: ProjectOption): void {
	}

	setDownloadUrl(url: string | null): void {
		this.actionButtons.setDownloadUrl(url);
	}

	setMenuOptions(opts: Array<ProjectOption>): void {
		this.menuOpts = opts;
		if (this.menu && this.menu.isOpen()) {
			this.menu.clear();
			for (const obj of this.menuOpts) {
				this.menu.addItem(obj);
			}
		}
	}

	setPayButtonEnabled(enabled: boolean): void {
		this.actionButtons.setPayButtonEnabled(enabled);
	}
}

@OBJ
export class ActionButtons extends ElObj {
	private downloadButton: ActionAnchor | null;
	private payButton: ActionButton | null;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-card__action-buttons',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.downloadButton = null;
		this.payButton = null;
		this.initPayButton();
	}

	destroy(): void {
		this.destroyDownloadButton();
		this.destroyPayButton();
		super.destroy();
	}

	private destroyDownloadButton(): void {
		if (this.downloadButton) {
			this.downloadButton.destroy();
		}
		this.downloadButton = null;
	}

	private destroyPayButton(): void {
		if (this.payButton) {
			Obj.disconnect(
				this.payButton, 'clicked',
				this, 'payButtonClicked');
			this.payButton.destroy();
		}
		this.payButton = null;
	}

	private initDownloadButton(): ActionAnchor {
		if (!this.downloadButton) {
			this.downloadButton = new ActionAnchor({
				attributes: [
					['download', ''],
				],
				parent: this,
				text: 'Download',
			});
		}
		return this.downloadButton;
	}

	private initPayButton(): ActionButton {
		if (!this.payButton) {
			this.payButton = new ActionButton({
				parent: this,
				text: 'Pay',
			});
			this.setPayButtonEnabled(false);
			Obj.connect(
				this.payButton, 'clicked',
				this, 'payButtonClicked');
		}
		return this.payButton;
	}

	@SIGNAL
	private payButtonClicked(): void {
	}

	setDownloadUrl(url: string | null): void {
		this.removeAttribute('title');
		if ((typeof url === 'string') && (url.trim().length > 0)) {
			this.destroyPayButton();
			const btn = this.initDownloadButton();
			btn.setHref(url);
		} else {
			this.destroyDownloadButton();
			this.initPayButton();
		}
	}

	setPayButtonEnabled(enabled: boolean): void {
		let title: string = '';
		if (this.payButton) {
			this.payButton.setDisabled(!enabled);
			if (!enabled) {
				title = 'Nothing to pay for yet';
			}
		}
		if (title.length > 0) {
			this.setAttribute('title', title);
		} else {
			this.removeAttribute('title');
		}
	}
}

@OBJ
export class ActionButton extends PushButton {
	static cssClassNames = {
		...ElObj.cssClassNames,
		Label: 'lb-project-action-button-label',
		Root: 'lb-project-action-button',
	};

	constructor(opts: Partial<PushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<PushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PushButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-button',
			'mdc-card__action',
			'mdc-card__action--button',
			ActionButton.cssClassNames.Root,
			...classNames,
		];
		const text: string | undefined = opts.text;
		opts.text = undefined;
		super(opts);
		const el = this.querySelector('.mdc-button__ripple');
		if (!el) {
			new ElObj({
				classNames: 'mdc-button__ripple',
				parent: this,
				tagName: 'span',
			});
		}
		if (!this.labelEl()) {
			new ElObj({
				classNames: ['mdc-button__label', ActionButton.cssClassNames.Label],
				parent: this,
				tagName: 'span',
			});
		}
		if (text) {
			this.setText(text);
		}
	}

	private labelEl(): ElObj | null {
		return this.querySelector(`.${ActionButton.cssClassNames.Label}`);
	}

	setText(text?: string | null): void {
		const el = this.labelEl();
		el && el.setText(text);
	}

	text(): string {
		const el = this.labelEl();
		return el ?
			el.text() :
			'';
	}
}

@OBJ
export class ActionAnchor extends ActionButton {
	constructor(opts: Partial<PushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<PushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PushButtonOpts>(a, b, c);
		opts.tagName = 'a';
		super(opts);
	}

	element(): HTMLAnchorElement {
		return <HTMLAnchorElement>super.element();
	}

	href(): string {
		return this.element().href;
	}

	setHref(href: string): void {
		this.element().href = href;
	}
}

@OBJ
export class ActionIcon extends PushButton {
	static cssClassNames = {
		...ElObj.cssClassNames,
		Root: 'lb-project-list-item-option-menu-toggle',
	};

	constructor(opts: Partial<PushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<PushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PushButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'material-icons',
			'mdc-icon-button',
			'mdc-card__action',
			'mdc-card__action--icon',
			ActionIcon.cssClassNames.Root,
			...classNames,
		];
		super(opts);
		if (!opts.root) {
			this.setAttribute('title', 'More options');
			this.setText('more_vert');
		}
	}
}
