import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {bind, clamp, stringIterableToStringArray} from '../util';
import {getLogger} from '../logging';
import {list, Point} from '../tools';

const logger = getLogger('ui.list');

export interface ListItemOpts extends ElObjOpts {
	leadingIcon: {name: string; outlined?: boolean;},
	leadingObj: ElObj;
	secondaryText: string;
	text: string;
	trailingIcon: {name: string; outlined?: boolean;},
	trailingObj: ElObj;
	twoLine: boolean;
}

@OBJ
export class ListItem extends ElObj {
	private disabled: boolean;
	protected leadingEl: ElObj | null;
	protected lineText: {primary: ElObj; secondary: ElObj} | null;
	protected ripEl: ElObj;
	protected textEl: ElObj;
	protected trailingEl: ElObj | null;
	protected twoLine: boolean;

	constructor(opts: Partial<ListItemOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListItemOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListItemOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListItemOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListItemOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListItemOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListItemOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListItemOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-list-item',
			'lb-list-item',
			...classNames,
		];
		opts.tagName = 'li';
		super(opts);
		this.disabled = false;
		this.leadingEl = null;
		this.lineText = null;
		this.ripEl = new ElObj({
			classNames: 'mdc-list-item__ripple',
			parent: this,
			tagName: 'span',
		});
		this.textEl = this.textElObj();
		this.textEl.addClass('lb-list-item__text');
		this.appendChild(this.textEl);
		this.trailingEl = null;
		this.twoLine = false;
		if (opts.twoLine !== undefined) {
			this.setTwoLine(opts.twoLine);
		}
		if (opts.text) {
			this.setText(opts.text);
		}
		if (opts.secondaryText) {
			this.setSecondaryText(opts.secondaryText);
		}
		if (opts.leadingObj) {
			this.setLeadingObj(opts.leadingObj);
		} else if (opts.leadingIcon) {
			this.setLeadingIcon(opts.leadingIcon.name, opts.leadingIcon.outlined);
		}
		if (opts.trailingObj) {
			this.setTrailingObj(opts.trailingObj);
		} else if (opts.trailingIcon) {
			this.setTrailingIcon(opts.trailingIcon.name, opts.trailingIcon.outlined);
		}
		this.addEventListeners();
	}

	protected addEventListeners(): void {
		this.addEventListener('click', this.domEvent);
	}

	@SIGNAL
	private clicked(point: Point): void {
	}

	destroy(): void {
		this.removeEventListeners();
		if (this.trailingEl) {
			this.trailingEl.destroy();
		}
		this.trailingEl = null;
		if (this.leadingEl) {
			this.leadingEl.destroy();
		}
		this.leadingEl = null;
		if (this.lineText) {
			this.lineText.secondary.destroy();
			this.lineText.primary.destroy();
		}
		this.lineText = null;
		this.textEl.destroy();
		this.ripEl.destroy();
		super.destroy();
	}

	@bind
	protected domEvent(event: Event): void {
		switch (event.type) {
			case 'click':
				this.domMouseClickEvent(<MouseEvent>event);
				break;
		}
	}

	protected domMouseClickEvent(event: MouseEvent): void {
		this.clicked(new Point(event.clientX, event.clientY));
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isSelected(): boolean {
		return this.isChecked();
	}

	primaryText(): string {
		return this.text();
	}

	private primaryTextEl(): ElObj {
		return this.lineText ?
			this.lineText.primary :
			this.textEl;
	}

	protected removeEventListeners(): void {
		this.removeEventListener('click', this.domEvent);
	}

	secondaryText(): string {
		if (this.lineText) {
			return this.lineText.secondary.text();
		}
		logger.warning('secondaryText: Multi-line not set.');
		return '';
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		if (this.disabled) {
			this.removeEventListeners();
			this.addClass('mdc-list-item--disabled');
		} else {
			this.removeClass('mdc-list-item--disabled');
			this.addEventListeners();
		}
	}

	setLeadingIcon(icon: string | null, outlined: boolean = false): void {
		if ((typeof icon !== 'string') || (icon.trim().length < 1)) {
			if (this.leadingEl) {
				this.leadingEl.destroy();
			}
			this.leadingEl = null;
			return;
		}
		if (!this.leadingEl) {
			this.leadingEl = new ElObj({
				classNames: [
					'mdc-list-item__graphic',
					'lb-list-item__leading-obj',
				],
				tagName: 'span',
			});
			this.insertChild(0, this.leadingEl);
		}
		this.leadingEl.setClass(!outlined, 'material-icons');
		this.leadingEl.setClass(outlined, 'material-icons-outlined');
		this.leadingEl.setText(icon);
	}

	setLeadingObj(obj: ElObj | null): void {
		if (!obj) {
			if (this.leadingEl) {
				this.leadingEl.destroy();
			}
			this.leadingEl = null;
			return;
		}
		if (!this.leadingEl) {
			this.leadingEl = new ElObj({
				classNames: [
					'mdc-list-item__graphic',
					'lb-list-item__leading-obj',
				],
				tagName: 'div',
			});
			this.insertChild(0, this.leadingEl);
		}
		this.leadingEl.clear();
		this.leadingEl.appendChild(obj);
	}

	setPrimaryText(text?: string | null): void {
		this.setText(text);
	}

	setSecondaryText(text?: string | null): void {
		if (this.lineText) {
			this.lineText.secondary.setText(text);
		} else {
			logger.warning('setSecondaryText: Multi-line not set.');
		}
	}

	setText(text?: string | null): void {
		this.primaryTextEl().setText(text);
	}

	setTrailingIcon(icon: string | null, outlined: boolean = false): void {
		if ((typeof icon !== 'string') || (icon.trim().length < 1)) {
			if (this.trailingEl) {
				this.trailingEl.destroy();
			}
			this.trailingEl = null;
			return;
		}
		if (!this.trailingEl) {
			this.trailingEl = new ElObj({
				classNames: [
					'mdc-list-item__graphic',
					'lb-list-item__trailing-obj',
				],
				tagName: 'span',
			});
			this.appendChild(this.trailingEl);
		}
		this.trailingEl.setClass(!outlined, 'material-icons');
		this.trailingEl.setClass(outlined, 'material-icons-outlined');
		this.trailingEl.setText(icon);
	}

	setTrailingObj(obj: ElObj | null): void {
		if (!obj) {
			if (this.trailingEl) {
				this.trailingEl.destroy();
			}
			this.trailingEl = null;
			return;
		}
		if (!this.trailingEl) {
			this.trailingEl = new ElObj({
				classNames: [
					'mdc-list-item__meta',
					'lb-list-item__trailing-obj',
				],
				tagName: 'div',
			});
			this.appendChild(this.trailingEl);
		}
		this.trailingEl.clear();
		this.trailingEl.appendChild(obj);
	}

	setTwoLine(twoLine: boolean): void {
		if (twoLine === this.twoLine) {
			return;
		}
		this.twoLine = twoLine;
		if (this.twoLine) {
			if (!this.lineText) {
				this.lineText = {
					primary: new ElObj({
						classNames: 'mdc-list-item__primary-text',
						parent: this.textEl,
						tagName: 'span',
					}),
					secondary: new ElObj({
						classNames: 'mdc-list-item__secondary-text',
						parent: this.textEl,
						tagName: 'span',
					}),
				};
			}
		} else {
			if (this.lineText) {
				this.lineText.primary.destroy();
				this.lineText.secondary.destroy();
			}
			this.lineText = null;
		}
	}

	text(): string {
		return this.primaryTextEl().text();
	}

	protected textElObj(): ElObj {
		return new ElObj({
			classNames: 'mdc-list-item__text',
			tagName: 'span',
		});
	}
}

interface ItemPrototype {
	new(data?: Partial<ListItemOpts>): ListItem;
}

export interface ListOpts extends ElObjOpts {
	itemPrototype: ItemPrototype;
	twoLine: boolean;
}

@OBJ
export class List extends ElObj {
	private disabled: boolean;
	private itemProto: ItemPrototype;
	protected items: list<ListItem>;
	private twoLine: boolean;

	constructor(opts: Partial<ListOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		if (opts.twoLine) {
			classNames.unshift('mdc-list--two-line');
		}
		opts.classNames = [
			'mdc-list',
			...classNames,
		];
		opts.tagName = 'ul';
		super(opts);
		this.disabled = false;
		this.itemProto = opts.itemPrototype || ListItem;
		this.items = new list<ListItem>();
		this.twoLine = Boolean(opts.twoLine);
	}

	addItem(data: Partial<ListItemOpts> | ListItem): void {
		this.insertItem(this.items.size(), data);
	}

	clear(): void {
		for (const obj of this.items) {
			obj.destroy();
		}
		this.items.clear();
	}

	count(): number {
		return this.items.size();
	}

	currentRow(): number {
		for (let i = 0; i < this.items.size(); ++i) {
			if (this.items.at(i).hasFocus()) {
				return i;
			}
		}
		return -1;
	}

	destroy(): void {
		this.clear();
		super.destroy();
	}

	index(item: ListItem): number {
		return this.items.indexOf(item);
	}

	insertItem(index: number, data: Partial<ListItemOpts> | ListItem): void {
		index = clamp(0, index, this.items.size());
		let item: ListItem;
		if (data instanceof ListItem) {
			item = data;
			item.setTwoLine(this.twoLine);
		} else {
			data.twoLine = this.twoLine;
			item = new this.itemProto(data);
		}
		Obj.connect(
			item, 'clicked',
			this, 'itemClicked');
		item.setDisabled(this.disabled);
		this.insertChild(index, item);
		this.items.insert(index, item);
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isEmpty(): boolean {
		return this.count() < 1;
	}

	item(index: number): ListItem | null {
		if ((index >= 0) && (index < this.count())) {
			return this.items.at(index);
		}
		return null;
	}

	@SIGNAL
	private itemActivated(item: ListItem): void {
	}

	@SLOT
	private itemClicked(point: Point): void {
		const sender = <ListItem | null>this.sender();
		if (sender) {
			const index = this.items.indexOf(sender);
			if ((index >= 0) && (index < this.items.size())) {
				this.itemActivated(this.items.at(index));
			} else {
				logger.error('itemClicked: Invalid signal sender.');
			}
		} else {
			logger.warning('itemClicked: Sender is null');
		}
	}

	itemPrototype(): ItemPrototype {
		return this.itemProto;
	}

	removeItem(index: number): boolean {
		if ((index >= 0) && (index < this.items.size())) {
			const item = this.items.takeAt(index);
			Obj.disconnect(
				item, 'clicked',
				this, 'itemClicked');
			item.destroy();
			return true;
		}
		return false;
	}

	selectedItems(): list<ListItem> {
		return new list(this.items.filter(f => f.isSelected()));
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		for (const obj of this.items) {
			obj.setDisabled(this.disabled);
		}
	}

	setItemPrototype(itemPrototype: ItemPrototype | null): void {
		this.itemProto = itemPrototype || ListItem;
	}

	*[Symbol.iterator](): IterableIterator<ListItem> {
		yield *this.items;
	}
}
