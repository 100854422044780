import {Obj, OBJ, SIGNAL} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import type {TextInput} from './textinput';
import {bind, stringIterableToStringArray} from '../../util';
import {getLogger} from '../../logging';

const logger = getLogger('textinput.icon');

export enum TextInputIconPosition {
	Leading = 'leading',
	Trailing = 'trailing',
}

export interface TextInputIconOpts extends ElObjOpts {
	icon: string;
	interactive: boolean;
	outlined: boolean;
	position: TextInputIconPosition;
	textInput: TextInput;
	title: string;
}

@OBJ
export class TextInputIcon extends ElObj {
	private connected: boolean;
	private disabled: boolean;
	private input: TextInput | null;
	private inter: boolean;
	private pos: TextInputIconPosition;

	constructor(opts: Partial<TextInputIconOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<TextInputIconOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TextInputIconOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TextInputIconOpts> | null, root?: Element | null);
	constructor(opts: Partial<TextInputIconOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<TextInputIconOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<TextInputIconOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<TextInputIconOpts>(a, b, c);
		const pos = opts.position || TextInputIconPosition.Trailing;
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			opts.outlined ?
				'material-icons-outlined' :
				'material-icons',
			'mdc-text-field__icon',
			`mdc-text-field__icon--${pos}`,
			...classNames,
		];
		opts.tagName = 'i';
		super(opts);
		this.connected = false;
		this.disabled = false;
		this.input = null;
		this.inter = false;
		this.pos = pos;
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
		if (opts.interactive !== undefined) {
			this.setInteractive(opts.interactive);
		}
		if (opts.textInput) {
			this.setTextInput(opts.textInput);
		}
		if (opts.title) {
			this.setTitle(opts.title);
		}
	}

	@SIGNAL
	private activated(position: TextInputIconPosition): void {
	}

	private attachAsLeading(textInput: TextInput): boolean {
		if (textInput.isOutlined()) {
			let el = textInput.querySelector('.mdc-notched-outline');
			if (el) {
				el.insertAdjacentElement('afterend', this);
				return true;
			}
			el = textInput.querySelector('.mdc-text-field__input');
			if (el) {
				el.insertAdjacentElement('beforebegin', this);
				return true;
			}
			logger.error('setTextInput: Unable to find an anchor element.');
		} else {
			let el = textInput.labelEl();
			if (el) {
				el.insertAdjacentElement('afterend', this);
				return true;
			}
			el = textInput.querySelector('.mdc-text-field__input');
			if (el) {
				el.insertAdjacentElement('beforebegin', this);
				return true;
			}
			logger.error('setTextInput: Unable to find an anchor element.');
		}
		return false;
	}

	private attachAsTrailing(textInput: TextInput): boolean {
		if (textInput.isOutlined()) {
			textInput.appendChild(this);
			return true;
		} else {
			let el = textInput.querySelector('.mdc-text-field__input');
			if (el) {
				el.insertAdjacentElement('afterend', this);
				return true;
			}
			el = textInput.querySelector('.mdc-line-ripple');
			if (el) {
				el.insertAdjacentElement('beforebegin', this);
				return true;
			}
			logger.error('setTextInput: Unable to find an anchor element.');
		}
		return false;
	}

	private connectToTextInput(): void {
		if (this.input && !this.connected) {
			Obj.connect(
				this, 'activated',
				this.input, 'iconActivated');
			this.connected = true;
		}
	}

	private ctrlIconEvent(event: Event): void {
		this.activated(this.pos);
	}

	destroy(): void {
		this.removeEventListener('MDCTextField:icon', this.domEvent);
		this.disconnectFromTextInput();
		this.connected = false;
		this.disabled = false;
		this.input = null;
		this.inter = false;
		super.destroy();
	}

	private disconnectFromTextInput(): void {
		if (this.input && this.connected) {
			Obj.disconnect(
				this, 'activated',
				this.input, 'iconActivated');
			this.connected = false;
		}
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'MDCTextField:icon':
				this.ctrlIconEvent(event);
				break;
		}
	}

	icon(): string {
		return this.text().trim();
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isEnabled(): boolean {
		return !this.isDisabled();
	}

	isInteractive(): boolean {
		return this.inter;
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.setClass(this.disabled, 'lb-text-input-icon--disabled');
		if (this.disabled) {
			if (this.inter) {
				this._setInteractive(false);
			}
		} else {
			if (this.inter) {
				this._setInteractive(true);
			}
		}
	}

	setEnabled(enabled: boolean): void {
		this.setDisabled(!enabled);
	}

	setIcon(icon: string, outlined?: boolean): void {
		if (outlined !== undefined) {
			if (outlined) {
				this.addClass('material-icons-outlined');
				this.removeClass('material-icons');
			} else {
				this.addClass('material-icons');
				this.removeClass('material-icons-outlined');
			}
		}
		this.setText(icon);
	}

	setInteractive(interactive: boolean): void {
		if (interactive === this.inter) {
			return;
		}
		this.inter = interactive;
		this._setInteractive(this.inter);
	}

	private _setInteractive(interactive: boolean): void {
		if (interactive) {
			this.connectToTextInput();
			this.setAttribute('role', 'button');
			this.setTabIndex(0);
			this.addEventListener('MDCTextField:icon', this.domEvent);
		} else {
			this.removeEventListener('MDCTextField:icon', this.domEvent);
			this.removeAttribute('tabindex');
			this.removeAttribute('role');
			this.disconnectFromTextInput();
		}
	}

	setTextInput(textInput: TextInput | null): void {
		if (textInput === this.input) {
			return;
		}
		const textInputCls = `mdc-text-field--with-${this.pos}-icon`;
		if (this.input) {
			this.input.removeClass(textInputCls);
		}
		this.disconnectFromTextInput();
		this.connected = false;
		this.input = textInput;
		if (this.input) {
			let ok: boolean;
			if (this.pos === TextInputIconPosition.Leading) {
				ok = this.attachAsLeading(this.input);
			} else if (this.pos === TextInputIconPosition.Trailing) {
				ok = this.attachAsTrailing(this.input);
			} else {
				logger.error('setTextInput: Have invalid position.');
				ok = false;
			}
			if (ok) {
				this.input.addClass(textInputCls);
				this.connectToTextInput();
			} else {
				this.input = null;
				this.elem.remove();
			}
		} else {
			this.elem.remove();
		}
	}

	setTitle(title: string | null): void {
		if (typeof title === 'string') {
			this.setAttribute('title', title);
		} else {
			this.removeAttribute('title');
		}
	}

	title(): string {
		return this.attribute('title') || '';
	}
}
