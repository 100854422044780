import {List, ListItem, ListItemOpts, ListOpts} from '../../../ui/list';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {ToolButton, ToolButtonOpts} from '../../../ui/toolbutton';
import {CheckState} from '../../../constants';
import {ElObj, elObjOpts} from '../../../elobj';
import {getLogger} from '../../../logging';
import {Point} from '../../../tools';

const logger = getLogger('projectdetailview');

@OBJ
export class FilterList extends List {
	constructor(opts: Partial<ListOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListOpts>(a, b, c);
		super(opts);
	}

	addFilter(filter: IFilter): void {
		if (this.indexForFilterId(filter.id) >= 0) {
			logger.info('addFilter: Filter already added.');
			return;
		}
		this.addItem(new FilterListItem(this, filter));
	}

	@SIGNAL
	filterClicked(filterId: number, point: Point): void {
	}

	@SIGNAL
	filterEnabledClicked(filterId: number): void {
	}

	indexForFilterId(filterId: number): number {
		for (let i = 0; i < this.items.size(); ++i) {
			const item = <FilterListItem>this.items.at(i);
			if (item.filterId === filterId) {
				return i;
			}
		}
		return -1;
	}

	removeFilter(filterId: number): void {
		const index = this.indexForFilterId(filterId);
		if (index >= 0) {
			this.removeItem(index);
		} else {
			logger.error('removeFilter: Invalid index for object ID');
		}
	}

	updateFilter(filter: IFilter): void {
		for (const obj of this.items) {
			if ((<FilterListItem>obj).filterId === filter.id) {
				(<FilterListItem>obj).setFilter(filter);
				return;
			}
		}
		logger.error('updateFilter: List item was not found for given object');
	}
}

@OBJ
class FilterListItem extends ListItem {
	private enabledToggle: FilterEnabledToggle | null;
	filterId: number;
	private parentList: FilterList | null;

	constructor(parentList: FilterList | null, filter: IFilter, opts?: Partial<ListItemOpts>) {
		opts = opts || {};
		opts.text = (filter.label.trim().length > 0) ?
			filter.label :
			'Unlabeled Filter';
		super(opts);
		this.enabledToggle = null;
		this.filterId = filter.id;
		this.parentList = parentList;
		this.initEnabledToggle(filter.enabled);
	}

	@SLOT
	private _clicked(point: Point): void {
		if (this.parentList) {
			this.parentList.filterClicked(this.filterId, point);
		}
	}

	private connectEnabledToggle(): void {
		if (this.enabledToggle) {
			Obj.connect(
				this, 'clicked',
				this, '_clicked');
			Obj.connect(
				this.enabledToggle, 'clicked',
				this, 'enabledToggleClicked');
		}
	}

	destroy(): void {
		this.enabledToggle = null;
		this.filterId = Number.NaN;
		this.parentList = null;
		super.destroy();
	}

	private destroyEnabledToggle(): void {
		if (this.enabledToggle) {
			this.disconnectEnabledToggle();
			this.enabledToggle.destroy();
		}
	}

	private disconnectEnabledToggle(): void {
		if (this.enabledToggle) {
			Obj.disconnect(
				this, 'clicked',
				this, '_clicked');
			Obj.disconnect(
				this.enabledToggle, 'clicked',
				this, 'enabledToggleClicked');
		}
	}

	@SLOT
	private enabledToggleClicked(): void {
		if (this.parentList) {
			this.parentList.filterEnabledClicked(this.filterId);
		}
	}

	private initEnabledToggle(enabled: boolean = false): void {
		if (!this.enabledToggle) {
			this.enabledToggle = new FilterEnabledToggle(enabled);
			this.setLeadingObj(this.enabledToggle);
			this.enabledToggle.setDisabled(this.isDisabled());
			this.connectEnabledToggle();
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.isDisabled()) {
			return;
		}
		super.setDisabled(disabled);
		disabled = this.isDisabled();
		if (disabled) {
			this.disconnectEnabledToggle();
		}
		if (this.enabledToggle) {
			this.enabledToggle.setDisabled(disabled);
		}
		if (!disabled) {
			this.connectEnabledToggle();
		}
	}

	setFilter(filter: IFilter): void {
		this.filterId = filter.id;
		this.setText((filter.label.trim().length > 0) ? filter.label : 'Unlabeled Filter');
		if (this.enabledToggle) {
			this.enabledToggle.setChecked(filter.enabled);
		}
	}
}

@OBJ
export class FilterEnabledToggle extends ToolButton {
	static StateIconName = {
		off: 'toggle_off',
		on: 'toggle_on',
	};

	static iconNameForState(enabled: boolean): string {
		return enabled ?
			this.StateIconName.on :
			this.StateIconName.off;
	}

	constructor(enabled: boolean, opts?: Partial<ToolButtonOpts>) {
		super(opts);
		this.setCheckable(true);
		this.setCheckState(enabled ? CheckState.Checked : CheckState.Unchecked);
	}

	checkState(): CheckState {
		return (this.icon() === FilterEnabledToggle.StateIconName.on) ?
			CheckState.Checked :
			CheckState.Unchecked;
	}

	protected domMouseClickEvent(event: MouseEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		super.domMouseClickEvent(event);
	}

	setCheckState(state: CheckState): void {
		const checked = state === CheckState.Checked;
		this.setIcon(FilterEnabledToggle.iconNameForState(checked));
		if (checked) {
			this.setStyleProperty('color', '#00CC75');
		} else {
			this.removeStyleProperty('color');
		}
		this.setTitle(checked ? 'Click to disable' : 'Click to enable');
	}

	setDisabled(disabled: boolean): void {
		super.setDisabled(disabled);
		if (disabled) {
			this.setStyleProperty('opacity', '0.38');
		} else {
			this.removeStyleProperty('opacity');
		}
	}
}
