import {Obj, OBJ, SIGNAL, SLOT} from '../../../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../../../elobj';
import {bind, isNumber, pixelString, stringIterableToStringArray} from '../../../../util';
import {TextInput, TextInputIconPosition} from '../../../../ui/textinput';
import {ToolButton} from '../../../../ui/toolbutton';
import {Filter} from './filter';
import {CheckState} from '../../../../constants';
import {FancyPushButton} from '../../../../ui/pushbutton';
import {getLogger} from '../../../../logging';
import {Menu} from '../../../../ui/menu';
import {FilterEnabledToggle} from '../filterlist';
import {list, Point} from '../../../../tools';

const logger = getLogger('projectdetailview.box');

interface FilterBoxOpts extends ElObjOpts {
	expressionToken: IExpressionToken;
}

@OBJ
export class FilterBox extends ElObj {
	private addButton: FancyPushButton;
	private editButton: FancyPushButton;
	private buttonBox: ElObj;
	private childFilterBox: ElObj | null;
	private childFilters: list<Filter>;
	private disabled: boolean;
	private dragMaxX: number;
	private dragMinX: number;
	private dragMaxY: number;
	private dragMinY: number;
	private dragStartWidth: number;
	private enabledToggle: FilterEnabledToggle;
	private expressionToken: IExpressionToken;
	private headerHeader: HeaderHeader;
	private moreOptsButton: ToolButton;
	private moreOptsMenu: Menu | null;
	private pos: {left: number; top: number;};
	private textInput: TextInput;
	private topLevelFilter: IFilter | null;

	constructor(opts: Partial<FilterBoxOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<FilterBoxOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<FilterBoxOpts> | null, tagName?: TagName);
	constructor(opts: Partial<FilterBoxOpts> | null, root?: Element | null);
	constructor(opts: Partial<FilterBoxOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<FilterBoxOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<FilterBoxOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<FilterBoxOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-filterbox',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.disabled = false;
		this.dragMaxX = 0;
		this.dragMinX = 0;
		this.dragMaxY = 0;
		this.dragMinY = 0;
		this.dragStartWidth = 0;
		this.moreOptsMenu = null;
		this.childFilterBox = null;
		this.expressionToken = opts.expressionToken || {attributes: [], operators: []};
		this.topLevelFilter = null;
		this.childFilters = new list<Filter>();
		this.headerHeader = new HeaderHeader({parent: this});
		Obj.connect(
			this.headerHeader, 'closeButtonClicked',
			this, 'closeButtonClicked');
		Obj.connect(
			this.headerHeader, 'pressed',
			this, 'headerHeaderPressed');
		Obj.connect(
			this.headerHeader, 'released',
			this, 'headerHeaderReleased');
		const header = new ElObj({
			classNames: [
				'lb-filterbox-header',
				'lb-filterbox-section',
			],
			parent: this,
			tagName: 'div',
		});
		const enabledToggleContainer = new ElObj({
			parent: header,
			styles: [
				['display', 'flex'],
				['align-items', 'center'],
				['justify-content', 'center'],
				['padding', '0 7px'],
			],
			tagName: 'div',
		});
		this.enabledToggle = new FilterEnabledToggle(
			false,
			{
				classNames: [
					'lb-filterbox-header-toolbutton',
				],
				parent: enabledToggleContainer,
				title: 'Enable/disable this filter',
			});
		Obj.connect(
			this.enabledToggle, 'clicked',
			this, 'topLevelFilterEnabledToggleClicked');
		this.textInput = new TextInput({
			classNames: [
				'lb-filterbox-input',
			],
			parent: header,
			trailingIcon: {icon: 'save', outlined: true, interactive: true, title: 'Save label'},
			fullWidth: true,
		});
		Obj.connect(this.textInput, 'iconActivated', this, 'topLevelFilterTextInputIconActivated');
		Obj.connect(this.textInput, 'returnPressed', this, 'topLevelFilterTextInputReturnPressed');
		this.textInput.setAttribute('title', 'This filter\'s label');
		this.moreOptsButton = new ToolButton({
			classNames: [
				'lb-filterbox-header-toolbutton',
			],
			icon: 'more_vert',
			parent: header,
			title: 'More options',
		});
		Obj.connect(
			this.moreOptsButton, 'clicked',
			this, 'moreButtonClicked');
		this.buttonBox = new ElObj({
			classNames: [
				'lb-filterbox-buttonbox',
				'lb-filterbox-section',
			],
			parent: this,
			tagName: 'div',
		});
		this.editButton = new FancyPushButton({
			leadingIcon: {name: 'edit'},
			parent: this.buttonBox,
			text: 'Edit Shape',
			title: 'Edit shape',
		});
		Obj.connect(this.editButton, 'clicked', this, 'editButtonClicked');
		this.addButton = new FancyPushButton({
			filled: true,
			leadingIcon: {name: 'add'},
			parent: this.buttonBox,
			text: 'Add Filter',
			title: 'Add another filter',
		});
		Obj.connect(this.addButton, 'clicked', this, 'addButtonClicked');
		this.pos = {left: 0, top: 0};
		this.addEventListener('mousedown', this.domEvent);
	}

	@SIGNAL
	private addButtonClicked(): void {
	}

	addChildFilterExpression(childFilter: IFilter): void {
		for (const obj of this.childFilters) {
			if (obj.filterId === childFilter.id) {
				this.setChildFilterData(obj.filterId, childFilter);
				return;
			}
		}
		if (!this.childFilterBox) {
			this.childFilterBox = new ElObj({
				classNames: [
					'lb-filter-expressionbox',
					'lb-filterbox-section',
				],
				tagName: 'div',
			});
			this.insertChild(2, this.childFilterBox);
		}
		const filter = new Filter({
			classNames: 'lb-child-filter',
			expressionToken: this.expressionToken,
			parent: this.childFilterBox,
		});
		this.childFilters.append(filter);
		filter.topLevel = false;
		filter.filterId = isNumber(childFilter.id) ?
			childFilter.id :
			-filter.instanceNumber;
		const blocked = this.blockSignals(true);
		filter.setEnabledToggleVisible(true);
		filter.setExpressionVisible(true);
		filter.setMoreOptionsButtonVisible(true);
		filter.setEnabledToggleCheckState(childFilter.enabled ?
			CheckState.Checked :
			CheckState.Unchecked);
		filter.setExpressionData(childFilter.expression);
		this.blockSignals(blocked);
		filter.setDisabled(this.disabled);
		if (!this.disabled) {
			Obj.connect(
				filter, 'enabledChanged',
				this, 'childFilterEnabledChanged');
			Obj.connect(
				filter, 'deleteButtonClicked',
				this, 'childFilterDeleteButtonClicked');
			Obj.connect(
				filter, 'exprOperandTextInputReturnPressed',
				this, 'childFilterExprOperandTextInputReturnPressed');
			Obj.connect(
				filter, 'expressionChanged',
				this, 'childFilterExpressionChanged');
		}
	}

	addEmptyChildFilter(): void {
		if (!this.topLevelFilter) {
			logger.error('addEmptyChildFilter: Child filter cannot be created without defined top-level filter.');
			return;
		}
		this.addChildFilterExpression(
			{
				enabled: true,
				geometry: null,
				children: [],
				expression: {
					id: Number.NaN,
					lhs: '',
					operator: '',
					rhs: '',
				},
				geometryIsCircle: false,
				icon: '',
				id: Number.NaN,
				label: '',
				parentId: this.topLevelFilter.id,
			});
	}

	childFilter(filterId: number): IFilter | null {
		if (this.topLevelFilter) {
			for (const obj of this.childFilters) {
				if (obj.filterId === filterId) {
					const expr = obj.expressionData();
					return {
						enabled: obj.enabledToggleCheckState() === CheckState.Checked,
						expression: expr,
						children: [],
						geometry: null,
						geometryIsCircle: false,
						icon: '',
						id: filterId,
						label: '',
						parentId: this.topLevelFilter.id,
					};
				}
			}
			logger.error('childFilter: Unknown object ID.');
		} else {
			logger.error('childFilter: Top-level filter is not defined.');
		}
		return null;
	}

	@SLOT
	private childFilterEnabledChanged(enabledToggleIsChecked: boolean): void {
		const sender = <Filter | null>this.sender();
		if (sender) {
			for (const obj of this.childFilters) {
				if (obj === sender) {
					this.enabledChanged(obj.filterId, !enabledToggleIsChecked);
					return;
				}
			}
		}
		logger.error('childFilterEnabledChanged: Sender not found within known objects.');
	}

	@SLOT
	private childFilterDeleteButtonClicked(): void {
		const sender = <Filter | null>this.sender();
		if (sender) {
			for (const obj of this.childFilters) {
				if (obj === sender) {
					this.deleteButtonPressed(obj.filterId);
					return;
				}
			}
		}
		logger.error('childFilterDeleteButtonClicked: Sender not found within known objects.');
	}

	@SLOT
	private childFilterExpressionChanged(): void {
		const sender = <Filter | null>this.sender();
		if (sender) {
			for (const obj of this.childFilters) {
				if (obj === sender) {
					this.expressionChanged(obj.filterId);
					return;
				}
			}
		}
		logger.error('childFilterExpressionChanged: Sender not found within known objects.');
	}

	@SLOT
	private childFilterExprOperandTextInputReturnPressed(): void {
		const sender = <Filter | null>this.sender();
		if (sender) {
			for (const obj of this.childFilters) {
				if (obj === sender) {
					this.saveButtonPressed(obj.filterId);
					return;
				}
			}
		}
		logger.error('childFilterExprOperandTextInputReturnPressed: Sender not found within known objects.');
	}

	@SIGNAL
	private closeButtonClicked(): void {
	}

	@SIGNAL
	private deleteButtonPressed(filterId: number): void {
	}

	destroy(): void {
		window.removeEventListener('mousemove', this.domEvent);
		this.headerHeader.destroy();
		this.destroyMoreOptsMenu();
		for (const obj of this.childFilters) {
			obj.destroy();
		}
		this.childFilters.clear();
		if (this.childFilterBox) {
			this.childFilterBox.destroy();
		}
		this.childFilterBox = null;
		this.textInput.destroy();
		this.topLevelFilter = null;
		this.pos = {left: 0, top: 0};
		super.destroy();
	}

	private destroyMoreOptsMenu(): void {
		if (this.moreOptsMenu) {
			Obj.disconnect(
				this.moreOptsMenu, 'closed',
				this, 'moreOptsMenuClosed');
			Obj.disconnect(
				this.moreOptsMenu, 'selectionChanged',
				this, 'moreOptsMenuSelectionChanged');
			this.moreOptsMenu.destroy();
		}
		this.moreOptsMenu = null;
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'mousemove':
				this.domMouseMoveEvent(<MouseEvent>event);
				break;
		}
	}

	private domMouseMoveEvent(event: MouseEvent): void {
		this.pos.left = Math.max(Math.min(this.pos.left + event.movementX, this.dragMaxX), this.dragMinX);
		this.pos.top = Math.min(Math.max(this.pos.top + event.movementY, 0), this.dragMaxY);
		this.updatePos();
	}

	@SIGNAL
	private editButtonClicked(): void {
	}

	@SIGNAL
	private enabledChanged(filterId: number, enabled: boolean): void {
	}

	@SIGNAL
	private expressionChanged(filterId: number): void {
	}

	expressionData(filterId: number): IExpression | null {
		if (filterId === this.id()) {
			// Top-level currently does not have an expression
			return null;
		} else {
			for (const obj of this.childFilters) {
				if (obj.filterId === filterId) {
					return obj.expressionData();
				}
			}
			logger.error('expressionData: Unknown object ID');
			return null;
		}
	}

	hasChildFilter(childFilterId: number): boolean {
		return this.childFilters
			.findIndex(obj => (obj.filterId === childFilterId)) >= 0;
	}

	@SLOT
	private headerHeaderPressed(point: Point): void {
		const el = <HTMLElement | null>this.element();
		if (el) {
			const myWidth = el.getBoundingClientRect().width;
			const myHeight = el.getBoundingClientRect().height;
			const cont = document.getElementById('id_lb-project-detail-map');
			if (cont) {
				const contRect = cont.getBoundingClientRect();
				this.dragMinY = 0;
				this.dragMaxY = contRect.height - myHeight;
				this.dragStartWidth = myWidth;
				this.dragMinX = 0;
				this.dragMaxX = contRect.width - myWidth;
				const sty = window.getComputedStyle(el);
				const leftStr = sty.left;
				const topStr = sty.top;
				this.pos.left = Number(leftStr.slice(0, leftStr.length - 2));
				this.pos.top = Number(topStr.slice(0, topStr.length - 2));
				this.updatePos();
				el.style.setProperty('bottom', 'auto');
				document.documentElement.classList.add('overflow--hidden');
				window.addEventListener('mouseleave', this.domEvent);
				window.addEventListener('mousemove', this.domEvent);
			} else {
				logger.error('Unable to ascertain container geometry.');
			}
		}
	}

	@SLOT
	private headerHeaderReleased(point: Point): void {
		document.documentElement.classList.remove('overflow--hidden');
		window.removeEventListener('mousemove', this.domEvent);
	}

	id(): number {
		return this.topLevelFilter ?
			this.topLevelFilter.id :
			Number.NaN;
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isFilterPersisted(filterId: number): boolean {
		return isNumber(filterId) && (filterId > 0);
	}

	isTopLevelFilter(filterId: number): boolean {
		return this.topLevelFilter ?
			(this.topLevelFilter.id === filterId) :
			false;
	}

	label(): string {
		return this.textInput.text();
	}

	@SLOT
	private moreButtonClicked(checked: boolean, point: Point): void {
		this.openMoreOptsMenu(point);
	}

	@SLOT
	private moreOptsMenuClosed(): void {
		this.destroyMoreOptsMenu();
	}

	@SLOT
	private moreOptsMenuSelectionChanged(index: number): void {
		if (index === 0) {
			if (this.topLevelFilter) {
				this.deleteButtonPressed(this.topLevelFilter.id);
			} else {
				logger.error('moreOptsMenuSelectionChanged: Top-level filter not defined.');
			}
		}
	}

	@SIGNAL
	moved(): void {
	}

	private openMoreOptsMenu(point: Point): void {
		this.destroyMoreOptsMenu();
		this.moreOptsMenu = new Menu();
		Obj.connect(
			this.moreOptsMenu, 'closed',
			this, 'moreOptsMenuClosed');
		Obj.connect(
			this.moreOptsMenu, 'selectionChanged',
			this, 'moreOptsMenuSelectionChanged');
		this.moreOptsMenu.addItem('Delete');
		this.moreOptsMenu.open(point);
	}

	removeChildFilter(filterId: number): void {
		const index = this.childFilters.findIndex(obj => (obj.filterId === filterId));
		if (index >= 0) {
			const obj = this.childFilters.takeAt(index);
			Obj.disconnect(
				obj, 'enabledChanged',
				this, 'childFilterEnabledChanged');
			Obj.disconnect(
				obj, 'deleteButtonClicked',
				this, 'childFilterDeleteButtonClicked');
			Obj.disconnect(
				obj, 'exprOperandTextInputReturnPressed',
				this, 'childFilterExprOperandTextInputReturnPressed');
			obj.destroy();
			if (this.childFilters.isEmpty() && this.childFilterBox) {
				this.childFilterBox.destroy();
				this.childFilterBox = null;
			}
		} else {
			logger.error('removeChildFilter: Given object ID not found');
		}
	}

	@SIGNAL
	private saveButtonPressed(filterId: number): void {
	}

	setChildFilterExpressionData(filterId: number, data: IExpression): void {
		for (const obj of this.childFilters) {
			if (obj.filterId === filterId) {
				const blocked = this.blockSignals(true);
				obj.setExpressionData(data);
				this.blockSignals(blocked);
				return;
			}
		}
		logger.error('setChildFilterExpressionData: Unknown object ID');
	}

	setChildFilterData(childFilterId: number, data: IFilter): void {
		for (const obj of this.childFilters) {
			if (obj.filterId === childFilterId) {
				obj.filterId = isNumber(data.id) ?
					data.id :
					-obj.instanceNumber;
				const blocked = this.blockSignals(true);
				obj.setEnabledToggleVisible(true);
				obj.setExpressionVisible(true);
				obj.setMoreOptionsButtonVisible(true);
				obj.setEnabledToggleCheckState(data.enabled ?
					CheckState.Checked :
					CheckState.Unchecked);
				if (data.expression) {
					obj.setExpressionData(data.expression);
				}
				this.blockSignals(blocked);
				return;
			}
		}
		logger.error('setChildFilterData: Unknown object ID');
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.headerHeader.setDisabled(this.disabled);
		this.enabledToggle.setDisabled(this.disabled);
		this.textInput.setDisabled(this.disabled);
		this.moreOptsButton.setDisabled(this.disabled);
		for (const obj of this.childFilters) {
			obj.setDisabled(this.disabled);
			if (this.disabled) {
				Obj.disconnect(
					obj, 'enabledChanged',
					this, 'childFilterEnabledChanged');
				Obj.disconnect(
					obj, 'deleteButtonClicked',
					this, 'childFilterDeleteButtonClicked');
				Obj.disconnect(
					obj, 'exprOperandTextInputReturnPressed',
					this, 'childFilterExprOperandTextInputReturnPressed');
				Obj.disconnect(
					obj, 'expressionChanged',
					this, 'childFilterExpressionChanged');
			} else {
				Obj.connect(
					obj, 'enabledChanged',
					this, 'childFilterEnabledChanged');
				Obj.connect(
					obj, 'deleteButtonClicked',
					this, 'childFilterDeleteButtonClicked');
				Obj.connect(
					obj, 'exprOperandTextInputReturnPressed',
					this, 'childFilterExprOperandTextInputReturnPressed');
				Obj.connect(
					obj, 'expressionChanged',
					this, 'childFilterExpressionChanged');
			}
		}
		this.editButton.setDisabled(this.disabled);
		this.addButton.setDisabled(this.disabled);
		if (this.disabled) {
			this.destroyMoreOptsMenu();
			Obj.disconnect(
				this.headerHeader, 'closeButtonClicked',
				this, 'closeButtonClicked');
			Obj.disconnect(
				this.headerHeader, 'pressed',
				this, 'headerHeaderPressed');
			Obj.disconnect(
				this.headerHeader, 'released',
				this, 'headerHeaderReleased');
			Obj.disconnect(
				this.enabledToggle, 'clicked',
				this, 'topLevelFilterEnabledToggleClicked');
			Obj.disconnect(
				this.textInput, 'iconActivated',
				this, 'topLevelFilterTextInputIconActivated');
			Obj.disconnect(
				this.textInput, 'returnPressed',
				this, 'topLevelFilterTextInputReturnPressed');
			Obj.disconnect(
				this.moreOptsButton, 'clicked',
				this, 'moreButtonClicked');
			Obj.disconnect(
				this.editButton, 'clicked',
				this, 'editButtonClicked');
			Obj.disconnect(
				this.addButton, 'clicked',
				this, 'addButtonClicked');
		} else {
			Obj.connect(
				this.headerHeader, 'closeButtonClicked',
				this, 'closeButtonClicked');
			Obj.connect(
				this.headerHeader, 'pressed',
				this, 'headerHeaderPressed');
			Obj.connect(
				this.headerHeader, 'released',
				this, 'headerHeaderReleased');
			Obj.connect(
				this.enabledToggle, 'clicked',
				this, 'topLevelFilterEnabledToggleClicked');
			Obj.connect(
				this.textInput, 'iconActivated',
				this, 'topLevelFilterTextInputIconActivated');
			Obj.connect(
				this.textInput, 'returnPressed',
				this, 'topLevelFilterTextInputReturnPressed');
			Obj.connect(
				this.moreOptsButton, 'clicked',
				this, 'moreButtonClicked');
			Obj.connect(
				this.editButton, 'clicked',
				this, 'editButtonClicked');
			Obj.connect(
				this.addButton, 'clicked',
				this, 'addButtonClicked');
		}
	}

	setFilter(data: IFilter | null): void {
		if (this.topLevelFilter === data) {
			return;
		}
		this.topLevelFilter = data;
		if (this.topLevelFilter) {
			const blocked = this.blockSignals(true);
			this.enabledToggle.setChecked(this.topLevelFilter.enabled);
			this.textInput.setText(this.topLevelFilter.label);
			this.blockSignals(blocked);
		}
	}

	setPosition(x: number, y: number): void {
		const rect = this.rect();
		const bodyRect = document.body.getBoundingClientRect();
		let left = Math.max(4, x - (rect.width / 2));
		if ((x + rect.width) > bodyRect.width) {
			left = (bodyRect.width - rect.width) - 4;
		}
		const top = Math.max(4, y - (rect.height / 2));
		this.setStyleProperty('left', pixelString(left));
		this.setStyleProperty('top', pixelString(top));
	}

	@SLOT
	private topLevelFilterEnabledToggleClicked(): void {
		if (this.topLevelFilter) {
			this.enabledChanged(this.topLevelFilter.id, !this.topLevelFilter.enabled);
		} else {
			logger.error('topLevelFilterEnabledToggleClicked: Top-level filter not defined.');
		}
	}

	@SLOT
	private topLevelFilterTextInputIconActivated(position: TextInputIconPosition): void {
		if (position === TextInputIconPosition.Trailing) {
			if (this.topLevelFilter) {
				this.saveButtonPressed(this.topLevelFilter.id);
			} else {
				logger.error('topLevelFilterTextInputIconActivated: Top-level filter not defined.');
			}
		} else {
			logger.warning('topLevelFilterTextInputIconActivated: Got signal from object in unexpected position.');
		}
	}

	@SLOT
	private topLevelFilterTextInputReturnPressed(): void {
		if (this.topLevelFilter) {
			this.saveButtonPressed(this.topLevelFilter.id);
		} else {
			logger.error('topLevelFilterTextInputReturnPressed: Top-level filter not defined.');
		}
	}

	private updatePos(): void {
		(<HTMLElement>this.elem).style.setProperty('left', pixelString(this.pos.left));
		(<HTMLElement>this.elem).style.setProperty('top', pixelString(this.pos.top));
	}
}

@OBJ
class HeaderHeader extends ElObj {
	closeButton: ToolButton;
	private disabled: boolean;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-filterbox-header-header',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.disabled = false;
		new ElObj({
			classNames: [
				'visibility--hidden',
			],
			parent: this,
			tagName: 'div',
		});
		this.closeButton = new ToolButton({
			classNames: [
				'lb-filterbox-header-toolbutton',
			],
			icon: 'close',
			parent: this,
			title: 'Close panel',
		});
		Obj.connect(
			this.closeButton, 'clicked',
			this, 'closeButtonClicked');
		this.addEventListeners();
	}

	private addEventListeners(): void {
		this.addEventListener('mousedown', this.domEvent);
		this.addEventListener('mouseup', this.domEvent);
		document.body.addEventListener('mouseleave', this.domEvent);
	}

	@SIGNAL
	private closeButtonClicked(): void {
	}

	destroy(): void {
		this.removeEventListeners();
		Obj.disconnect(
			this.closeButton, 'clicked',
			this, 'closeButtonClicked');
		this.closeButton.destroy();
		super.destroy();
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'mousedown':
				this.domMouseDownEvent(<MouseEvent>event);
				break;
			case 'mouseup':
				this.domMouseUpEvent(<MouseEvent>event);
				break;
			case 'mouseleave':
				this.domMouseLeaveEvent(<MouseEvent>event);
				break;
		}
	}

	private domMouseDownEvent(event: MouseEvent): void {
		this.pressed(new Point(event.clientX, event.clientY));
	}

	private domMouseLeaveEvent(event: MouseEvent): void {
		this.released(new Point(event.clientX, event.clientY));
	}

	private domMouseUpEvent(event: MouseEvent): void {
		this.released(new Point(event.clientX, event.clientY));
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	@SIGNAL
	private pressed(point: Point): void {
	}

	@SIGNAL
	private released(point: Point): void {
	}

	private removeEventListeners(): void {
		this.removeEventListener('mousedown', this.domEvent);
		this.removeEventListener('mouseup', this.domEvent);
		document.body.removeEventListener('mouseleave', this.domEvent);
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.closeButton.setDisabled(this.disabled);
		if (this.disabled) {
			this.removeEventListeners();
			Obj.disconnect(
				this.closeButton, 'clicked',
				this, 'closeButtonClicked');
		} else {
			Obj.connect(
				this.closeButton, 'clicked',
				this, 'closeButtonClicked');
			this.addEventListeners();
		}
	}
}
