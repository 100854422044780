export const CSRF_COOKIE_NAME = 'csrftoken';
export const CSRF_INPUT_NAME = 'csrfmiddlewaretoken';
export const CSS_CLASS_FULL_WIDTH = 'width--100-percent';
export const DEFAULT_MAP_STYLE_URL = 'mapbox://styles/mapbox/streets-v11';
export const GEO_TILES_URI_PREFIX = `${window.location.origin}/api/geo/area/tiles`;
export const INFINITY_CHAR_CODE = 8734;
export const NBSP_CHAR_CODE = 160;
export const PARCEL_LAYER_MIN_ZOOM = 13.0;
export const PARCEL_LINE_LAYER_MIN_ZOOM = 14.5;
export const PARCEL_SOURCE_LAYER_ID = 'lb-area-tiles';
export const REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY = '_LB_REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY';
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_QUARTER_HOUR = 900;
export const TAP_DISTANCE_TOLERANCE = 25;
export const TAP_INTERVAL = 250;


export enum AlignmentFlag {
	AlignLeft = 0x0001,
	AlignLeading = AlignLeft,
	AlignRight = 0x0002,
	AlignTrailing = AlignRight,
	AlignHCenter = 0x0004,
	AlignJustify = 0x0008,
	AlignAbsolute = 0x0010,
	AlignHorizontal_Mask = AlignLeft | AlignRight | AlignHCenter | AlignJustify | AlignAbsolute,
	AlignTop = 0x0020,
	AlignBottom = 0x0040,
	AlignVCenter = 0x0080,
	AlignBaseline = 0x0100,
	AlignVertical_Mask = AlignTop | AlignBottom | AlignVCenter | AlignBaseline,
	AlignCenter = AlignVCenter | AlignHCenter,
}

export enum ArrowType {
	NoArrow,
	UpArrow,
	DownArrow,
	LeftArrow,
	RightArrow,
}

export enum ButtonRole {
	InvalidRole = -1,
	AcceptRole,
	RejectRole,
	DestructiveRole,
	ActionRole,
	HelpRole,
	YesRole,
	NoRole,
	ResetRole,
	ApplyRole,
	NRoles,
}

export const ButtonRoleOrder: ButtonRole[] = [
	ButtonRole.RejectRole,
	ButtonRole.NoRole,
	ButtonRole.DestructiveRole,
	ButtonRole.ResetRole,
	ButtonRole.HelpRole,
	ButtonRole.ActionRole,
	ButtonRole.ApplyRole,
	ButtonRole.AcceptRole,
	ButtonRole.YesRole,
];

export enum CaseSensitivity {
	CaseInsensitive,
	CaseSensitive,
}

export enum CheckIndexOption {
	NoOption = 0x0000,
	IndexIsValid = 0x0001,
	DoNotUseParent = 0x0002,
	ParentIsInvalid = 0x0004,
}

export enum CheckState {
	Unchecked = 0,
	PartiallyChecked = 1,
	Checked = 2,
}

export enum CoordinateType {
	InvalidCoordinate = 0,
	Coordinate2D = 1,
}

export enum DialogCode {
	Rejected,
	Accepted,
}

export enum FocusReason {
	MouseFocusReason,
	TabFocusReason,
	BacktabFocusReason,
	ActiveWindowFocusReason,
	PopupFocusReason,
	ShortcutFocusReason,
	MenuBarFocusReason,
	OtherFocusReason,
	NoFocusReason,
}

export enum InteractiveMapControlMode {
	NoMode,
	DrawShape,
	InfoMode,
	ShapeSelect,
	StylePickerMode,
}

export enum ItemDataRole {
	DisplayRole = 0,
	DecorationRole = 1,
	EditRole = 2,
	ToolTipRole = 3,
	StatusTipRole = 4,
	WhatsThisRole = 5,
	FontRole = 6,
	TextAlignmentRole = 7,
	BackgroundRole = 8,
	ForegroundRole = 9,
	CheckStateRole = 10,
	AccessibleTextRole = 11,
	AccessibleDescriptionRole = 12,
	SizeHintRole = 13,
	InitialSortOrderRole = 14,
	DisplayPropertyRole = 27,
	DecorationPropertyRole = 28,
	ToolTipPropertyRole = 29,
	StatusTipPropertyRole = 30,
	WhatsThisPropertyRole = 31,
	UserRole = 0x0100,
}

export enum ItemFlag {
	NoItemFlags = 0,
	ItemIsSelectable = 1,
	ItemIsEditable = 2,
	ItemIsDragEnabled = 4,
	ItemIsDropEnabled = 8,
	ItemIsUserCheckable = 16,
	ItemIsEnabled = 32,
	ItemIsAutoTristate = 64,
	ItemNeverHasChildren = 128,
	ItemIsUserTristate = 256,
}

export enum Key {
	Backspace = 'Backspace',
	Delete = 'Delete',
	Enter = 'Enter',
	Escape = 'Escape',
	Tab = 'Tab',
	ArrowUp = 'ArrowUp',
	ArrowRight = 'ArrowRight',
	ArrowDown = 'ArrowDown',
	ArrowLeft = 'ArrowLeft',
	Shift = 'Shift',
	Meta = 'Meta',
	Alt = 'Alt',
	Control = 'Control',
}

export enum KeyboardModifier {
	NoModifier = 0x00000000,
	ShiftModifier = 0x02000000,
	ControlModifier = 0x04000000,
	AltModifier = 0x08000000,
	MetaModifier = 0x10000000,
	KeypadModifier = 0x20000000,
	GroupSwitchModifier = 0x40000000,
	// Do not extend the mask to include 0x01000000
	KeyboardModifierMask = 0xfe000000
}

export enum LayoutChangeHint {
	NoLayoutChangeHint,
	VerticalSortHint,
	HorizontalSortHint,
}

export enum MatchFlag {
	MatchExactly = 0,
	MatchContains = 1,
	MatchStartsWith = 2,
	MatchEndsWith = 3,
	MatchWildcard = 5,
	MatchFixedString = 8,
	MatchRegularExpression = 9,
	MatchCaseSensitive = 16,
	MatchWrap = 32,
	MatchRecursive = 64,
}

export enum MetaType {
	Invalid = 0,
	Boolean = 1,
	Number = 6,
	Decimal = 7,
	String = 10,
	TimeDelta = 13,
	Date = 14,
	Time = 15,
	DateTime = 16,
	Url = 17,
	Variant = 41,
	Null = 51,
}

export enum MouseButton {
	NoButton = 0x00000000,
	LeftButton = 0x00000001,
	RightButton = 0x00000002,
	MiddleButton = 0x00000004,
	BackButton = 0x00000008,
	XButton1 = BackButton,
	ExtraButton1 = XButton1,
	ForwardButton = 0x00000010,
	XButton2 = ForwardButton,
	ExtraButton2 = ForwardButton,
	TaskButton = 0x00000020,
	ExtraButton3 = TaskButton,
	ExtraButton4 = 0x00000040,
	ExtraButton5 = 0x00000080,
	ExtraButton6 = 0x00000100,
	ExtraButton7 = 0x00000200,
	ExtraButton8 = 0x00000400,
	ExtraButton9 = 0x00000800,
	ExtraButton10 = 0x00001000,
	ExtraButton11 = 0x00002000,
	ExtraButton12 = 0x00004000,
	ExtraButton13 = 0x00008000,
	ExtraButton14 = 0x00010000,
	ExtraButton15 = 0x00020000,
	ExtraButton16 = 0x00040000,
	ExtraButton17 = 0x00080000,
	ExtraButton18 = 0x00100000,
	ExtraButton19 = 0x00200000,
	ExtraButton20 = 0x00400000,
	ExtraButton21 = 0x00800000,
	ExtraButton22 = 0x01000000,
	ExtraButton23 = 0x02000000,
	ExtraButton24 = 0x04000000,
	AllButtons = 0x07ffffff,
	MaxMouseButton = ExtraButton24,
	// 4 high-order bits remain available for future use (0x08000000 through 0x40000000).
	MouseButtonMask = 0xffffffff
}

// Keep in sync with Invoice.State
export enum InvoiceState {
	Draft = 'draft',
	Settled = 'settled',
	Void = 'void',
}

export enum Orientation {
	Horizontal = 0x1,
	Vertical = 0x2
}

export enum SortOrder {
	AscendingOrder,
	DescendingOrder,
}

export enum StandardButton {
	NoButton = 0x00000000,
	Accept = 0x00000100,
	Decline = 0x00000200,
	Ok = 0x00000400,
	Save = 0x00000800,
	SaveAll = 0x00001000,
	Open = 0x00002000,
	Yes = 0x00004000,
	YesToAll = 0x00008000,
	No = 0x00010000,
	NoToAll = 0x00020000,
	Abort = 0x00040000,
	Retry = 0x00080000,
	Ignore = 0x00100000,
	Close = 0x00200000,
	Cancel = 0x00400000,
	Discard = 0x00800000,
	Help = 0x01000000,
	Apply = 0x02000000,
	Reset = 0x04000000,
	RestoreDefaults = 0x08000000,
	FirstButton = Accept,         // internal
	LastButton = RestoreDefaults, // internal
}
