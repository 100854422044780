import {OBJ} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {numberFormat, stringIterableToStringArray} from '../../util';
import {List, ListItem, ListOpts} from '../../ui/list';
import {getLogger} from '../../logging';
import {CircularProgress} from '../../ui/progress';

const logger = getLogger('projectdetailview.stats');

interface ProjectDetailStatsOpts extends ElObjOpts {
	heading: string;
}

@OBJ
export class ProjectDetailStats extends ElObj {
	private header: Header;
	private list: StatsList;

	constructor(opts: Partial<ProjectDetailStatsOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ProjectDetailStatsOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ProjectDetailStatsOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ProjectDetailStatsOpts> | null, root?: Element | null);
	constructor(opts: Partial<ProjectDetailStatsOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ProjectDetailStatsOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ProjectDetailStatsOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ProjectDetailStatsOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.header = new Header({
			parent: this,
		});
		this.setHeading(opts.heading || 'Stats');
		this.list = new StatsList({
			parent: this,
		});
	}

	beginSync(): void {
		this.list.beginSync();
	}

	endSync(): void {
		this.list.endSync();
	}

	heading(): string {
		return this.header.text();
	}

	setHeading(heading: string): void {
		this.header.setText(heading);
	}

	setAddressCount(count: number): void {
		this.list.setAddressCount(count);
	}

	setPrice(price: number | string): void {
		this.list.setPrice(price);
	}
}

@OBJ
class Header extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-list-group__subheader',
			...classNames,
		];
		opts.tagName = 'h5';
		super(opts);
	}
}

@OBJ
class StatsList extends List {
	private addressCountItem: ListItem;
	private addressCountItemProgress: CircularProgress | null;
	private priceItem: ListItem;
	private priceItemProgress: CircularProgress | null;

	constructor(opts: Partial<ListOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListOpts>(a, b, c);
		opts.twoLine = true;
		super(opts);
		this.addressCountItemProgress = null;
		this.addItem({
			leadingIcon: {name: 'mail'},
			secondaryText: 'unique mailing addresses',
			text: '---',
			twoLine: true,
		});
		let item = this.item(0);
		if (!item) {
			logger.error('StatsList: Unable to add list items');
			item = new ListItem();
		}
		this.addressCountItem = item;
		this.priceItemProgress = null;
		this.addItem({
			leadingIcon: {name: 'attach_money'},
			secondaryText: 'total price',
			text: '---',
			twoLine: true,
		});
		item = this.item(1);
		if (!item) {
			logger.error('StatsList: Unable to add list items');
			item = new ListItem();
		}
		this.priceItem = item;
	}

	beginSync(): void {
		this.destroyProgress();
		this.addressCountItem.addClass('lb-list-item--sync');
		this.addressCountItem.setPrimaryText('---');
		this.addressCountItemProgress = new CircularProgress({indeterminate: true, size: 'small'});
		this.addressCountItem.setLeadingObj(this.addressCountItemProgress);
		this.addressCountItemProgress.open();
		this.priceItem.addClass('lb-list-item--sync');
		this.priceItem.setPrimaryText('---');
		this.priceItemProgress = new CircularProgress({indeterminate: true, size: 'small'});
		this.priceItem.setLeadingObj(this.priceItemProgress);
		this.priceItemProgress.open();
	}

	destroy(): void {
		this.destroyProgress();
		this.addressCountItem.destroy();
		super.destroy();
	}

	private destroyProgress(): void {
		if (this.addressCountItemProgress) {
			this.addressCountItemProgress.destroy();
		}
		this.addressCountItemProgress = null;
		if (this.priceItemProgress) {
			this.priceItemProgress.destroy();
		}
		this.priceItemProgress = null;
	}

	endSync(): void {
		if (this.addressCountItemProgress) {
			this.addressCountItemProgress.close();
		}
		this.destroyProgress();
		this.addressCountItem.setLeadingIcon('mail');
		this.addressCountItem.removeClass('lb-list-item--sync');
		if (this.priceItemProgress) {
			this.priceItemProgress.close();
		}
		this.destroyProgress();
		this.priceItem.setLeadingIcon('attach_money');
		this.priceItem.removeClass('lb-list-item--sync');
	}

	setAddressCount(count: number): void {
		this.addressCountItem.setPrimaryText(numberFormat(count));
	}

	setPrice(price: number | string): void {
		this.priceItem.setPrimaryText(`$${numberFormat(price)}`);
	}
}
