import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {bind, iterableToArray, stringIterableToStringArray, stringRepeat} from '../../util';
import {Checkbox} from '../../ui/checkbox';
import {List, ListItem, ListItemOpts, ListOpts} from '../../ui/list';
import {RadioButton} from '../../ui/radiobutton';
import {ToolButton} from '../../ui/toolbutton';

@OBJ
export class PaymentMethodView extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-payment-method-view',
			...classNames,
		];
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
	}

	addPaymentMethod(paymentMethod: IPaymentMethod): void {
	}

	hasAcceptableInput(): boolean {
		return false;
	}

	@SIGNAL
	protected paymentMethodChanged(): void {
	}

	@SIGNAL
	protected paymentMethodRemovalRequest(paymentMethod: IPaymentMethod): void {
	}
}

@OBJ
class PaymentMethodList extends List {
	constructor(opts: Partial<ListOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ListOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ListOpts> | null, root?: Element | null);
	constructor(opts: Partial<ListOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ListOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ListOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ListOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', 'radiogroup'],
			...attributes,
		];
		opts.itemPrototype = PaymentMethodListItem;
		super(opts);
	}

	addPaymentMethod(paymentMethod: IPaymentMethod): void {
		this.addItem({paymentMethod, parentList: this});
	}

	addItem(data: Partial<PaymentMethodListItemOpts> | PaymentMethodListItem): void {
		super.addItem(data);
	}

	insertItem(index: number, data: Partial<PaymentMethodListItemOpts> | PaymentMethodListItem): void {
		super.insertItem(index, data);
	}

	@SIGNAL
	private paymentMethodChanged(paymentMethod: IPaymentMethod): void {
	}

	_paymentMethodChanged(paymentMethod: IPaymentMethod): void {
		this.paymentMethodChanged(paymentMethod);
	}

	@SIGNAL
	private paymentMethodRemovalRequest(paymentMethod: IPaymentMethod): void {
	}

	_paymentMethodRemovalRequest(paymentMethod: IPaymentMethod): void {
		this.paymentMethodRemovalRequest(paymentMethod);
	}

	selectedPaymentMethod(): IPaymentMethod | null {
		for (const obj of this.items) {
			const item = <PaymentMethodListItem>obj;
			if (item.isSelected()) {
				return item.paymentMethod;
			}
		}
		return null;
	}
}

interface PaymentMethodListItemOpts extends ListItemOpts {
	parentList: PaymentMethodList | null;
	paymentMethod: IPaymentMethod | null;
}

@OBJ
class PaymentMethodListItem extends ListItem {
	private parentList: PaymentMethodList | null;
	paymentMethod: IPaymentMethod | null;
	private radio: RadioButton;
	private toolButton: ToolButton;

	constructor(opts: Partial<PaymentMethodListItemOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PaymentMethodListItemOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PaymentMethodListItemOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PaymentMethodListItemOpts> | null, root?: Element | null);
	constructor(opts: Partial<PaymentMethodListItemOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PaymentMethodListItemOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PaymentMethodListItemOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PaymentMethodListItemOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', 'radio'],
			...attributes,
		];
		const paymentMethod = opts.paymentMethod || null;
		if (paymentMethod) {
			const dots = stringRepeat(String.fromCharCode(8226), 4);
			opts.text = `${paymentMethod.brand} ${dots}${paymentMethod.last4}`;
		}
		super(opts);
		this.parentList = opts.parentList || null;
		this.paymentMethod = paymentMethod;
		this.radio = new RadioButton();
		this.radio.setId(this.radioId());
		this.radio.setName('paymentmethod');
		Obj.connect(
			this.radio, 'toggled',
			this, 'radioToggled');
		this.setLeadingObj(this.radio);
		this.toolButton = new ToolButton({
			icon: 'remove_circle_outline',
			title: 'Remove this payment method',
		});
		Obj.connect(
			this.toolButton, 'clicked',
			this, 'toolButtonClicked');
		Obj.connect(
			this.toolButton, 'pointerEntered',
			this, 'toolButtonPointerEntered');
		Obj.connect(
			this.toolButton, 'pointerLeft',
			this, 'toolButtonPointerLeft');
		this.setTrailingObj(this.toolButton);
		this.removeEventListener('click', this.domEvent);
	}

	destroy(): void {
		this.parentList = null;
		this.paymentMethod = null;
		this.radio.destroy();
		Obj.disconnect(
			this.toolButton, 'clicked',
			this, 'toolButtonClicked');
		Obj.disconnect(
			this.toolButton, 'pointerEntered',
			this, 'toolButtonPointerEntered');
		Obj.disconnect(
			this.toolButton, 'pointerLeft',
			this, 'toolButtonPointerLeft');
		this.toolButton.destroy();
		super.destroy();
	}

	isChecked(): boolean {
		return this.radio.isChecked();
	}

	isSelected(): boolean {
		return this.isChecked();
	}

	private radioId(): string {
		return `id_lb-payment-method-list-item-radio-${this.instanceNumber}`;
	}

	@SLOT
	private radioToggled(checked: boolean): void {
		if (checked && this.parentList && this.paymentMethod) {
			this.parentList._paymentMethodChanged(this.paymentMethod);
		}
	}

	protected textElObj(): ElObj {
		return new ElObj({
			attributes: [
				['for', this.radioId()],
			],
			classNames: [
				'mdc-list-item__text',
				'display--flex',
				'align-items--center',
				'width--100-percent',
				'height--100-percent',
			],
			tagName: 'label',
		});
	}

	@SLOT
	private toolButtonClicked(): void {
		if (this.parentList && this.paymentMethod) {
			this.parentList._paymentMethodRemovalRequest(this.paymentMethod);
		}
	}

	@SLOT
	private toolButtonPointerEntered(): void {
		this.toolButton.setStyleProperty('color', 'red');
	}

	@SLOT
	private toolButtonPointerLeft(): void {
		this.toolButton.removeStyleProperty('color');
	}
}

@OBJ
export class ExistingPaymentMethodView extends PaymentMethodView {
	private list: PaymentMethodList;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.list = new PaymentMethodList({parent: this});
		Obj.connect(
			this.list, 'paymentMethodChanged',
			this, 'paymentMethodChanged');
		Obj.connect(
			this.list, 'paymentMethodRemovalRequest',
			this, 'paymentMethodRemovalRequest');
	}

	addPaymentMethod(paymentMethod: IPaymentMethod): void {
		return this.list.addPaymentMethod(paymentMethod);
	}

	clear(): void {
		this.list.clear();
	}

	destroy(): void {
		Obj.disconnect(
			this.list, 'paymentMethodChanged',
			this, 'paymentMethodChanged');
		this.list.destroy();
		super.destroy();
	}

	hasAcceptableInput(): boolean {
		return Boolean(this.list.selectedPaymentMethod());
	}

	selectedPaymentMethod(): IPaymentMethod | null {
		return this.list.selectedPaymentMethod();
	}
}

interface NewPaymentMethodViewOpts extends Partial<ElObjOpts> {
	paymentVendor: stripe.Stripe;
}

@OBJ
export class NewPaymentMethodView extends PaymentMethodView {
	static PaymentMethodInputId: string = 'id_lb-payment-form-payment-input';

	private inputObj: ElObj;
	private isValid: boolean;
	paymentMethodInput: stripe.elements.Element;
	private savePaymentMethodCheckbox: Checkbox;

	constructor(opts: NewPaymentMethodViewOpts) {
		if (!opts.tagName) {
			opts.tagName = 'div';
		}
		super(opts);
		this.isValid = false;
		this.inputObj = new ElObj({
			attributes: [
				['id', NewPaymentMethodView.PaymentMethodInputId],
			],
			parent: this,
			tagName: 'div',
		});
		this.paymentMethodInput = opts.paymentVendor.elements().create('card');
		this.paymentMethodInput.on('change', this.paymentMethodInputChangeEvent);
		this.paymentMethodInput.mount(this.inputObj.element());
		const label = new ElObj({
			classNames: [
				'display--flex',
				'align-items--center',
				'margin-top--8',
			],
			parent: this,
			styles: [
				['margin-left', '-10px'],
			],
			tagName: 'label',
		});
		this.savePaymentMethodCheckbox = new Checkbox({parent: label});
		label.setText('Save this payment method');
	}

	clear(): void {
		this.paymentMethodInput.clear();
		this.savePaymentMethodCheckbox.setChecked(false);
	}

	destroy(): void {
		this.savePaymentMethodCheckbox.destroy();
		this.paymentMethodInput.unmount();
		this.paymentMethodInput.destroy();
		this.inputObj.destroy();
		super.destroy();
	}

	hasAcceptableInput(): boolean {
		return this.isValid;
	}

	@bind
	private paymentMethodInputChangeEvent(event?: stripe.elements.ElementChangeResponse): void {
		let valid: boolean = false;
		if (event) {
			if (event.complete && !Boolean(event.error)) {
				valid = true;
			}
		}
		this.isValid = valid;
		this.paymentMethodChanged();
	}

	savePaymentMethod(): boolean {
		return this.savePaymentMethodCheckbox.isChecked();
	}
}
