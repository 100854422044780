import {SourceData} from '../../../ui/map';
import {GEO_TILES_URI_PREFIX, PARCEL_LAYER_MIN_ZOOM} from '../../../constants';

export enum Id {
	DoNotMailParcel = 'id_lb-do-not-mail-parcel-source',
	DoNotMailParcelCentroid = 'id_lb-do-not-mail-parcel-centroid-source',
	Filter = 'id_lb-filter-source',
	Parcel = 'id_lb-parcel-source',
}

interface ISource {
	id: string;
	source: SourceData;
}

export const sources: Array<ISource> = [
	// NB: DO NOT RE-ORDER
	{
		id: Id.Parcel,
		source: {
			minzoom: PARCEL_LAYER_MIN_ZOOM,
			promoteId: 'area_pk',
			tiles: [
				`${GEO_TILES_URI_PREFIX}/{z}/{x}/{y}/`,
			],
			type: 'vector',
		},
	},
	{
		id: Id.Filter,
		source: {
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
	},
	{
		source: {
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
		id: Id.DoNotMailParcel,
	},
	{
		source: {
			cluster: true,
			clusterMaxZoom: 13,
			clusterRadius: 50,
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
		id: Id.DoNotMailParcelCentroid,
	},
];
