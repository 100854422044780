import {svc} from '../../request';
import {Obj, OBJ, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {getLogger} from '../../logging';
import {TierTable} from './tiertable';
import {ComboBox} from '../../ui/combobox';
import {FancyPushButton} from '../../ui/pushbutton';
import {TextInput} from '../../ui/textinput';
import {isNumber, stringIterableToStringArray} from '../../util';
import {Checkbox} from '../../ui/checkbox';

const logger = getLogger('views.pricecreate');

enum PricingModel {
	Standard,
	Volume,
}

const pricingModelChoices: Array<[string, string]> = [
	['standard', 'Standard pricing'],
	['volume', 'Volume pricing'],
];

@OBJ
export class PriceUpdateView extends ElObj {
	private currentPriceModel: PricingModel;
	private labelInput: TextInput;
	private defaultCheckbox: Checkbox;
	private pk: PricePk;
	private priceModelComboBox: ComboBox;
	private pricingView: PricingView;
	private submitBtn: FancyPushButton;

	constructor(pk?: number | string) {
		const root = document.getElementById('id_lb-price-update');
		super({root});
		this.pk = Number.NaN;
		this.currentPriceModel = PricingModel.Standard;
		const checkboxContainer = new ElObj({
			classNames: [
				'mdc-form-field',
				'lb-vertical-input-box--field',
			],
			parent: this,
			styles: [
				['margin-left', '-8px'],
			],
			tagName: 'div',
		});
		const defaultCheckboxId = `id_lb-price-default-input`;
		this.defaultCheckbox = new Checkbox({
			inputId: defaultCheckboxId,
			inputName: 'default',
			parent: checkboxContainer,
		});
		const defaultCheckboxLabel = new ElObj({
			attributes: [
				['for', defaultCheckboxId],
			],
			parent: checkboxContainer,
			tagName: 'label',
		});
		defaultCheckboxLabel.setText('Default price');
		this.labelInput = new TextInput({
			classNames: [
				'lb-vertical-input-box--field',
			],
			fullWidth: true,
			labelText: 'Label (optional, only you will see it)',
			outlined: true,
			name: 'label',
			parent: this,
		});
		this.priceModelComboBox = new ComboBox({
			attributes: [
				['name', 'pricing_model'],
			],
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		this.pricingView = new PricingView({
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		const axnBox = new ElObj({
			classNames: [
				'lb-vertical-input-box--actions',
			],
			parent: this,
			tagName: 'div',
		});
		this.submitBtn = new FancyPushButton({
			parent: axnBox,
			raised: true,
			text: 'Save',
			type: 'submit',
		});
		this.init(pk);
	}

	destroy(): void {
		this.priceModelComboBox.destroy();
		this.currentPriceModel = PricingModel.Standard;
		super.destroy();
	}

	private async fetchPrice(pk: PricePk): Promise<IPrice> {
		return svc.price.get(pk);
	}

	private async fetchPriceTiers(pricePk: PricePk): Promise<Array<IPriceTier>> {
		return await svc.priceTier.list({pricePk});
	}

	private async init(pk?: number | string): Promise<void> {
		Obj.connect(
			this.priceModelComboBox, 'currentIndexChanged',
			this, 'priceModelComboBoxIndexChanged');
		for (const [value, label] of pricingModelChoices) {
			this.priceModelComboBox.addItem(label, value);
		}
		if (typeof pk === 'string') {
			pk = Number.parseInt(pk);
		}
		if ((pk === undefined) || !isNumber(pk)) {
			logger.error('init: Invalid PK.');
			return;
		}
		this.pk = pk;
		const obj = await this.fetchPrice(this.pk);
		this.defaultCheckbox.setChecked(obj.default);
		let tiers: Array<IPriceTier> = [];
		if (obj.isVolumePricing) {
			tiers = await this.fetchPriceTiers(this.pk);
		}
		this.setPriceData(obj, tiers);
	}

	@SLOT
	private priceModelComboBoxIndexChanged(): void {
		let newModel: PricingModel;
		switch (this.currentPriceModel) {
			case PricingModel.Standard:
				newModel = PricingModel.Volume;
				break;
			case PricingModel.Volume:
				newModel = PricingModel.Standard;
				break;
		}
		this.setCurrentPriceModel(newModel);
	}

	private setCurrentPriceModel(model: PricingModel): void {
		if (model === this.currentPriceModel) {
			return;
		}
		this.currentPriceModel = model;
		this.pricingView.setCurrentPricingModelView(
			this.currentPriceModel);
	}

	private setPriceData(data: IPrice, tiers?: Array<IPriceTier>): void {
		this.labelInput.setText(data.label);
		const model = data.isVolumePricing ?
			PricingModel.Volume :
			PricingModel.Standard;
		this.priceModelComboBox.setCurrentIndex(model);
		switch (model) {
			case PricingModel.Volume:
				this.pricingView.setTiers(tiers || []);
				break;
			case PricingModel.Standard:
				this.pricingView.setUnitAmount(data.unitAmount || '');
				break;
		}
	}
}

@OBJ
class PricingView extends ElObj {
	private stdPricingView: StandardPricingView;
	private volPricingView: VolumePricingView;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.stdPricingView = new StandardPricingView({
			parent: this,
		});
		this.volPricingView = new VolumePricingView();
		this.volPricingView.hide();
		this.appendChild(this.volPricingView);
	}

	destroy(): void {
		this.stdPricingView.destroy();
		this.volPricingView.destroy();
		super.destroy();
	}

	setCurrentPricingModelView(model: PricingModel): void {
		switch (model) {
			case PricingModel.Standard:
				this.volPricingView.hide();
				this.stdPricingView.show();
				this.stdPricingView.priceInput.setRequired(true);
				break;
			case PricingModel.Volume:
				this.stdPricingView.hide();
				this.stdPricingView.priceInput.setRequired(false);
				this.volPricingView.show();
				break;
		}
	}

	setTiers(tiers: Array<IPriceTier>): void {
		this.volPricingView.setTiers(tiers);
	}

	setUnitAmount(amount: number | string): void {
		this.stdPricingView.priceInput.setText(String(amount));
	}
}

@OBJ
class StandardPricingView extends ElObj {
	priceInput: TextInput;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--column',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.priceInput = new TextInput({
			classNames: [
				'lb-price-create-row',
			],
			labelText: 'Price',
			outlined: true,
			name: 'unit_amount',
			parent: this,
		});
	}
}

@OBJ
class VolumePricingView extends ElObj {
	addTierButton: FancyPushButton;
	tierTable: TierTable;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--column',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		const btnContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
			],
			parent: this,
			tagName: 'div',
		});
		this.addTierButton = new FancyPushButton({
			classNames: [
				'align-self--flex-end',
			],
			leadingIcon: {name: 'add'},
			parent: btnContainer,
			text: 'Add another tier',
		});
		Obj.connect(
			this.addTierButton, 'clicked',
			this, 'addTierButtonClicked');
		this.tierTable = new TierTable({
			parent: this,
		});
	}

	@SLOT
	private addTierButtonClicked(): void {
		this.tierTable.addRow();
	}

	destroy(): void {
		Obj.disconnect(
			this.addTierButton, 'clicked',
			this, 'addTierButtonClicked');
		this.addTierButton.destroy();
		this.tierTable.destroy();
		super.destroy();
	}

	setTiers(tiers: Array<IPriceTier>): void {
		this.tierTable.clearContents();
		this.tierTable.setRowCount(tiers.length);
		let firstUnit: number = 0;
		for (let i = 0; i < tiers.length; ++i) {
			const obj = tiers[i];
			let lastUnit: number;
			if (isNumber(obj.upToIncluding)) {
				lastUnit = obj.upToIncluding;
			} else {
				// Last row
				lastUnit = Number.POSITIVE_INFINITY;
			}
			this.tierTable.setRow(i, firstUnit, lastUnit, obj.unitAmount);
			if (Number.isFinite(lastUnit)) {
				firstUnit = lastUnit + 1;
			}
		}
	}
}
