import mapboxgl from 'mapbox-gl';

import {OBJ} from '../../../obj';
import {InteractiveMapControlMode} from '../../../constants';
import {Control, ControlButton} from './control';

@OBJ
export class InfoControl extends Control {
	static mode: InteractiveMapControlMode = InteractiveMapControlMode.InfoMode;

	protected _onAdd(map: mapboxgl.Map): void {
		super._onAdd(map);
		const btn = new ControlButton({
			classNames: 'lb-map-control-info-button',
			icon: 'info',
			iconIsOutlined: true,
			parent: this,
			title: 'Info tool',
		});
		this.buttons.append(btn);
		this.connectButton(btn);
	}

	setActive(active: boolean): void {
		super.setActive(active);
		if (this.isActive()) {
			this.addClassToMapContainer('lb-cursor--crosshair');
		} else {
			this.removeClassFromMapContainer('lb-cursor--crosshair');
		}
	}
}
