import {ElObj, elObjOpts} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {isNumber, iterableToArray, numberFormat, stringIterableToStringArray} from '../../util';
import {getLogger} from '../../logging';
import {Layout, LayoutOpts} from './layout';
import type {ProjectListView} from './view';
import {ToolButton} from '../../ui/toolbutton';
import {Point} from '../../tools';
import {Menu} from '../../ui/menu';
import {Checkbox} from '../../ui/checkbox';
import {CheckState} from '../../constants';
import {List, ListItem, ListItemOpts} from '../../ui/list';
import {LinearProgress} from '../../ui/progress';

const logger = getLogger('projectlistview');

@OBJ
export class ListLayout extends Layout {
	private list: List;

	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, tagName?: TagName);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, root?: Element | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts>, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts?: Partial<LayoutOpts>);
	constructor(view: ProjectListView | null, root?: Element | null);
	constructor(view: ProjectListView | null, tagName?: TagName);
	constructor(view: ProjectListView | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, a?: Partial<LayoutOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<LayoutOpts>(a, b, c);
		super(view, opts);
		const gridCell = new ElObj({
			classNames: [
				'mdc-layout-grid__cell',
				'mdc-layout-grid__cell--span-12',
			],
			parent: this,
			tagName: 'div',
		});
		this.list = new List({
			parent: gridCell,
			twoLine: true,
		});
	}

	private addProject(project: IProject): void {
		const item = new ProjectListItem(this, project);
		Obj.connect(
			item, 'checkStateChanged',
			this, 'projectCheckStateChanged');
		this.list.addItem(item);
	}

	clear(): void {
		this.list.clear();
	}

	destroy(): void {
		this.list.destroy();
		super.destroy();
	}

	private *listItems(): IterableIterator<ProjectListItem> {
		for (const item of this.list) {
			yield <ProjectListItem>item;
		}
	}

	@SLOT
	private projectCheckStateChanged(): void {
		this.projectSelectionChanged();
	}

	setProgressVisible(slug: string, visible: boolean): void {
		for (const item of this.listItems()) {
			if (item.project.slug === slug) {
				item.setProgressVisible(visible);
				return;
			}
		}
		logger.warning('setProgressVisible: No matching object for given parameter');
	}

	selectedProjects(): Array<string> {
		const rv: Array<string> = [];
		for (const item of this.listItems()) {
			if (item.isChecked()) {
				rv.push(item.project.slug);
			}
		}
		return rv;
	}

	setDownloadUrl(slug: string, url: string | null): void {
		for (const item of this.listItems()) {
			if (item.project.slug === slug) {
				item.setDownloadUrl(url);
				return;
			}
		}
		logger.error('setDownloadUrl: "%s" was not found', slug);
	}

	async setProjects(projects: Iterable<IProject>): Promise<void> {
		this.clear();
		const objs = iterableToArray(projects);
		for (let i = 0; i < objs.length; ++i) {
			this.addProject(objs[i]);
		}
		setTimeout(() => {
			this.syncInvoiceQuantities();
		}, 3);
	}

	private async syncInvoiceQuantities(): Promise<void> {
		for (const item of this.listItems()) {
			if (isNumber(item.project.invoiceId)) {
				if (this.view) {
					item.setInvoiceQuantity((await this.view.fetchInvoice(item.project.invoiceId)).totalQuantity);
				} else {
					logger.warning('syncInvoiceQuantities: No view reference.');
				}
			} else {
				logger.warning('syncInvoiceQuantities: Got Project w/no Invoice ID.');
			}
		}
	}
}

enum ProjectOption {
	Archive = 'Archive',
	Restore = 'Restore',
	Copy = 'Copy',
	Download = 'Download',
	DownloadWithOwnerName = 'Download w/Owner Names',
	Pay = 'Pay',
}

interface ProjectListItemOpts extends ListItemOpts {
	invoiceQuantity: number | string;
}

@OBJ
class ProjectListItem extends ListItem {
	private actionIcon: ToolButton;
	private checkbox: Checkbox;
	private menu: Menu | null;
	private progress: LinearProgress | null;
	private projectOptions: Array<ProjectOption>;
	project: IProject;
	private view: ListLayout | null;

	constructor(view: ListLayout | null, project: IProject, opts?: Partial<ProjectListItemOpts>) {
		opts = opts || {};
		opts.text = (project.title.trim().length > 0) ? project.title : 'Untitled Project';
		opts.secondaryText = '';
		const checkbox = new Checkbox();
		opts.leadingObj = checkbox;
		const actionButton = new ToolButton({icon: 'more_vert'});
		opts.trailingObj = actionButton;
		opts.twoLine = true;
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-project-list-layout-item',
			...classNames,
		];
		super(opts);
		this.project = project;
		this.setHref(this.project.absoluteUrl);
		this.actionIcon = actionButton;
		this.checkbox = checkbox;
		this.menu = null;
		this.progress = null;
		const projOpts: Array<ProjectOption> = [];
		if (this.project.absoluteListUrl) {
			projOpts.push(
				ProjectOption.Download,
			);
		} else {
			if (this.project.paymentAllowed && this.project.paymentRequired) {
				projOpts.push(ProjectOption.Pay);
			}
		}
		projOpts.push(ProjectOption.Copy);
		if (this.project.archived) {
			projOpts.push(ProjectOption.Restore);
		} else {
			projOpts.push(ProjectOption.Archive);
		}
		if (this.project.absoluteListUrl && this.project.downloadWithOwnerNameEnabled) {
			projOpts.push(
				ProjectOption.DownloadWithOwnerName,
			);
		}
		this.projectOptions = projOpts;
		this.view = view;
		if (opts.invoiceQuantity !== undefined) {
			this.setInvoiceQuantity(opts.invoiceQuantity);
		}
		Obj.connect(
			this.checkbox, 'stateChanged',
			this, 'checkStateChanged');
		Obj.connect(
			this.actionIcon, 'clicked',
			this, 'actionButtonClicked');
	}

	@SLOT
	private actionButtonClicked(checked: boolean, point: Point): void {
		this.openMenu(point);
	}

	checkState(): CheckState {
		return this.checkbox.checkState();
	}

	@SIGNAL
	private checkStateChanged(state: number): void {
	}

	destroy(): void {
		this.destroyProgress();
		this.destroyMenu();
		Obj.disconnect(
			this.actionIcon, 'clicked',
			this, 'actionButtonClicked');
		this.actionIcon.destroy();
		super.destroy();
	}

	private destroyMenu(): void {
		if (this.menu) {
			Obj.disconnect(
				this.menu, 'selectionChanged',
				this, 'menuSelectionChanged');
			Obj.disconnect(
				this.menu, 'closed',
				this, 'menuClosed');
			this.menu.destroy();
		}
		this.menu = null;
	}

	private destroyProgress(): void {
		if (this.progress) {
			this.progress.destroy();
		}
		this.progress = null;
	}

	@SLOT
	private menuClosed(): void {
		this.destroyMenu();
	}

	@SLOT
	private menuSelectionChanged(index: number): void {
		if (index >= 0 && index < this.projectOptions.length) {
			this.projectOptionSelected(this.projectOptions[index]);
		}
	}

	private openMenu(point: Point): void {
		this.destroyMenu();
		this.menu = new Menu();
		Obj.connect(
			this.menu, 'selectionChanged',
			this, 'menuSelectionChanged');
		Obj.connect(
			this.menu, 'closed',
			this, 'menuClosed');
		for (const obj of this.projectOptions) {
			this.menu.addItem(obj);
		}
		this.menu.open(point);
	}

	@SLOT
	private async projectOptionSelected(option: ProjectOption): Promise<void> {
		if (!this.view) {
			return;
		}
		switch (option) {
			case ProjectOption.Download:
				await this.view.downloadButtonClicked(this.project.slug);
				break;
			case ProjectOption.DownloadWithOwnerName:
				await this.view.downloadWithOwnerNameButtonClicked(this.project.slug);
				break;
			case ProjectOption.Pay:
				await this.view.payButtonClicked(this.project.slug);
				break;
			case ProjectOption.Copy:
				await this.view.cloneButtonClicked(this.project.slug);
				break;
			case ProjectOption.Archive:
				await this.view.archiveButtonClicked(this.project.slug);
				break;
			case ProjectOption.Restore:
				await this.view.restoreButtonClicked(this.project.slug);
				break;
		}
	}

	setCheckState(state: CheckState): void {
		this.checkbox.setCheckState(state);
	}

	setDownloadUrl(url: string | null): void {
		this.destroyMenu();
		if ((typeof url === 'string') && (url.trim().length > 0)) {
			this.projectOptions = [
				ProjectOption.Download,
				ProjectOption.Copy,
				ProjectOption.Archive,
			];
		} else {
			this.projectOptions = [
				ProjectOption.Pay,
				ProjectOption.Copy,
				ProjectOption.Archive,
			];
		}
	}

	private setHref(href: string): void {
		this.textEl.setAttribute('href', href);
	}

	setInvoiceQuantity(qty: number | string): void {
		this.setSecondaryText(`Mailing addresses: ${numberFormat(qty)}`);
	}

	setProgressVisible(visible: boolean): void {
		if (this.progress) {
			if (this.progress.isVisible() === visible) {
				// Nothing to do
				return;
			}
			this.progress.setVisible(visible);
			if (!visible) {
				this.destroyProgress();
			}
			return;
		}
		if (visible) {
			this.progress = new LinearProgress({
				indeterminate: true,
			});
			this.appendChild(this.progress);
			this.progress.show();
		}
	}

	protected textElObj(): ElObj {
		return new ElObj({
			attributes: [
				['href', '#'],
			],
			classNames: [
				'mdc-list-item__text',
				'lb-project-list-layout-item__text',
			],
			tagName: 'a',
		});
	}
}
