import {CoordinateType} from '../constants';

function fuzzyCompare(p1: number, p2: number): boolean {
	return ((Math.abs(p1 - p2) * 1000000000000.) <= Math.min(Math.abs(p1), Math.abs(p2)));
}

function isValidLat(lat: number): boolean {
	return (lat >= -90.0) && (lat <= 90.0);
}

function isValidLong(lng: number): boolean {
	return (lng >= -180.0) && (lng <= 180.0);
}

export class GeoCoordinate {
	//                                      long    lat
	static fromPoint(point: GeoJsonPoint | [number, number]): GeoCoordinate {
		let latitude: number;
		let longitude: number;
		if (Array.isArray(point)) {
			[longitude, latitude] = point;
		} else {
			[longitude, latitude] = point.coordinates;
		}
		return new this(latitude, longitude);
	}

	private m_latitude: number;
	private m_longitude: number;

	constructor(latitude: number = 0.0, longitude: number = 0.0) {
		this.m_latitude = latitude;
		this.m_longitude = longitude;
	}

	eq(other: GeoCoordinate): boolean {
		let longEq =
			(Number.isNaN(this.m_longitude) && Number.isNaN(other.m_longitude)) ||
			fuzzyCompare(this.m_longitude, other.m_longitude);
		const latEq =
			(Number.isNaN(this.m_latitude) && Number.isNaN(other.m_latitude)) ||
			fuzzyCompare(this.m_latitude, other.m_latitude);
		if (!Number.isNaN(this.m_latitude) && ((this.m_latitude === 90.0) || (this.m_latitude === -90.0))) {
			longEq = true;
		}
		return longEq && latEq;
	}

	isValid(): boolean {
		return this.type() === CoordinateType.Coordinate2D;
	}

	latitude(): number {
		return this.m_latitude;
	}

	longitude(): number {
		return this.m_longitude;
	}

	setLatitude(latitude: number): void {
		this.m_latitude = latitude;
	}

	setLongitude(longitude: number): void {
		this.m_longitude = longitude;
	}

	toString(): string {
		return `(${this.longitude()}, ${this.latitude()})`;
	}

	type(): CoordinateType {
		if (isValidLong(this.m_longitude) && isValidLat(this.m_latitude)) {
			return CoordinateType.Coordinate2D;
		}
		return CoordinateType.InvalidCoordinate;
	}
}
