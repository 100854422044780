import {ProjectBatchAction, ProjectListView} from '../projectlist/view';
import {OBJ} from '../../obj';
import {IProjectListOpt, svc} from '../../request';

@OBJ
export class ArchivedProjectListView extends ProjectListView {
	protected async fetchProjects(opt?: Partial<Omit<IProjectListOpt, 'filter'>>): Promise<Array<IProject>> {
		return await svc.project.archivedList(opt);
	}

	protected async init(): Promise<void> {
		this.batchActions = [ProjectBatchAction.Merge, ProjectBatchAction.Restore];
		this.head.addClass('lol-llama2');
		this.layoutGridRoot.addClass('lol-llama2');
		return await super.init();
	}
}
