import {MDCSegmentedButton, MDCSegmentedButtonEvent} from '@material/segmented-button';

import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {OBJ, SIGNAL} from '../obj';
import {bind, iterableToArray, stringIterableToStringArray} from '../util';
import {list} from '../tools';
import {getLogger} from '../logging';

const logger = getLogger('ui.segmentedbutton');

interface SegmentOpts {
	icon: string;
	label: string;
	selected: boolean;
}

interface SegmentedButtonGroupOpts extends ElObjOpts {
	multiSelect: boolean;
	segments: Iterable<Partial<SegmentOpts>>
}

@OBJ
export class SegmentedButtonGroup extends ElObj {
	private buttons: list<SegmentedButton>;
	private ctrl: MDCSegmentedButton | null;
	private multi: boolean;

	constructor(opts: Partial<SegmentedButtonGroupOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<SegmentedButtonGroupOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<SegmentedButtonGroupOpts> | null, tagName?: TagName);
	constructor(opts: Partial<SegmentedButtonGroupOpts> | null, root?: Element | null);
	constructor(opts: Partial<SegmentedButtonGroupOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<SegmentedButtonGroupOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<SegmentedButtonGroupOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<SegmentedButtonGroupOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['role', opts.multiSelect ? 'group' : 'radiogroup'],
			...attributes,
		];
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		if (!opts.multiSelect) {
			classNames.unshift('mdc-segmented-button--single-select');
		}
		classNames.unshift('mdc-segmented-button');
		opts.classNames = [...classNames];
		opts.tagName = 'div';
		super(opts);
		this.buttons = new list<SegmentedButton>();
		this.ctrl = null;
		this.multi = Boolean(opts.multiSelect);
		this.init(opts.segments);
	}

	private addButton(data: Partial<SegmentOpts>): void {
		const {icon, label, selected} = data;
		const btn = new SegmentedButton({
			icon,
			label,
			multiSelect: this.multi,
			parent: this,
			selected,
		});
		this.buttons.append(btn);
	}

	private addEventListeners(): void {
		this.addEventListener('change', this.domEvent);
	}

	clear(): void {
		for (const obj of this.buttons) {
			obj.destroy();
		}
		this.buttons.clear();
		super.clear();
	}

	@SIGNAL
	private clicked(index: number, checked: boolean, segmentId: string): void {
	}

	private ctrlChangeEvent(event: MDCSegmentedButtonEvent): void {
		const {index, selected, segmentId} = event.detail;
		this.clicked(index, selected, segmentId || '');
	}

	destroy(): void {
		this.removeEventListeners();
		this.destroyCtrl();
		this.clear();
		super.destroy();
	}

	private destroyCtrl(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'change':
				this.ctrlChangeEvent(<MDCSegmentedButtonEvent>event);
				break;
		}
	}

	private init(segs?: Iterable<Partial<SegmentOpts>>): void {
		if (segs) {
			this.setSegments(segs);
		}
		this.addEventListeners();
	}

	isSegmentSelected(index: number): boolean {
		return this.ctrl ?
			this.ctrl.isSegmentSelected(index) :
			false;
	}

	private removeEventListeners(): void {
		this.removeEventListener('MDCSegmentedButton:change', this.domEvent);
	}

	segmentIndex(segment: SegmentedButton): number {
		return this.buttons.indexOf(segment);
	}

	selectSegment(index: number): void {
		this.ctrl && this.ctrl.selectSegment(index);
	}

	setSegments(segments: Iterable<Partial<SegmentOpts>>): void {
		this.destroyCtrl();
		this.clear();
		const segs = iterableToArray(segments);
		if (!this.multi && (segs.length > 0) && (segs.filter(s => Boolean(s.selected)).length < 1)) {
			logger.warning('setSegments: Radio button group without defining selected button.');
			segs[0].selected = true;
		}
		for (const obj of segs) {
			this.addButton(obj);
		}
		if (!this.buttons.isEmpty()) {
			this.ctrl = new MDCSegmentedButton(this.elem);
		}
	}
}

interface SegmentedButtonOpts extends ElObjOpts {
	icon: string;
	label: string;
	multiSelect: boolean;
	selected: boolean;
	text: string;
}

@OBJ
class SegmentedButton extends ElObj {
	private iconEl: ElObj | null;
	private labelEl: ElObj | null;
	private multi: boolean;

	constructor(opts: Partial<SegmentedButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<SegmentedButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<SegmentedButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<SegmentedButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<SegmentedButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<SegmentedButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<SegmentedButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<SegmentedButtonOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['type', 'button'],
			...attributes,
		];
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-segmented-button__segment',
			...classNames,
		];
		opts.tagName = 'button';
		super(opts);
		this.multi = Boolean(opts.multiSelect);
		this.iconEl = null;
		this.labelEl = null;
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
		if (opts.label) {
			this.setLabel(opts.label);
		} else if (opts.text) {
			this.setLabel(opts.text);
		}
		this.setSelected(Boolean(opts.selected));
	}

	destroy(): void {
		if (this.iconEl) {
			this.iconEl.destroy();
		}
		this.iconEl = null;
		if (this.labelEl) {
			this.labelEl.destroy();
		}
		this.labelEl = null;
		super.destroy();
	}

	icon(): string {
		return this.iconEl ?
			this.iconEl.text() :
			'';
	}

	isChecked(): boolean {
		return this.isSelected();
	}

	isSelected(): boolean {
		return this.hasClass('mdc-segmented-button__segment--selected');
	}

	setChecked(checked: boolean): void {
		this.setSelected(checked);
	}

	setIcon(icon: string | null): void {
		const str = (icon || '').trim();
		if (str.length < 1) {
			if (this.iconEl) {
				this.iconEl.destroy();
			}
			this.iconEl = null;
			return;
		}
		if (!this.iconEl) {
			this.iconEl = new ElObj({
				classNames: [
					'material-icons',
					'mdc-segmented-button__icon',
				],
				tagName: 'i',
			});
			this.insertChild(0, this.iconEl);
		}
		this.iconEl.setText(str);
	}

	setLabel(label: string | null): void {
		const str = (label || '').trim();
		if (str.length < 1) {
			if (this.labelEl) {
				this.labelEl.destroy();
			}
			this.labelEl = null;
			return;
		}
		if (!this.labelEl) {
			this.labelEl = new ElObj({
				classNames: 'mdc-segmented-button__label',
				tagName: 'span',
			});
			this.insertChild(1, this.labelEl);
		}
		this.labelEl.setText(str);
	}

	setSelected(checked: boolean): void {
		this.setClass(checked, 'mdc-segmented-button__segment--selected');
		if (this.multi) {
			this.setAttribute('aria-pressed', checked ? 'true' : 'false');
		} else {
			this.setAttribute('aria-checked', checked ? 'true' : 'false');
		}
	}

	setText(text?: string | null): void {
		this.setLabel(text || null);
	}

	text(): string {
		return this.labelEl ?
			this.labelEl.text() :
			'';
	}
}
