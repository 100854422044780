import {OBJ, SIGNAL} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import type {ProjectListView} from './view';

export interface LayoutOpts extends ElObjOpts {
	projects: Iterable<IProject>;
}

@OBJ
export class Layout extends ElObj {
	view: ProjectListView | null;

	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, tagName: TagName, parent?: ElObj | null);
	constructor(view: ProjectListView | null, root: Element | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, tagName?: TagName);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts> | null, root?: Element | null);
	constructor(view: ProjectListView | null, opts: Partial<LayoutOpts>, parent?: ElObj | null);
	constructor(view: ProjectListView | null, opts?: Partial<LayoutOpts>);
	constructor(view: ProjectListView | null, root?: Element | null);
	constructor(view: ProjectListView | null, tagName?: TagName);
	constructor(view: ProjectListView | null, parent?: ElObj | null);
	constructor(view: ProjectListView | null, a?: Partial<LayoutOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<LayoutOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.view = view;
		if (opts.projects) {
			this.setProjects(opts.projects);
		}
	}

	async archiveButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.archiveProject(slug);
		}
	}

	async cloneButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.cloneProject(slug);
		}
	}

	async downloadButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.downloadList(slug);
		}
	}

	async downloadWithOwnerNameButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.downloadListWithOwnerName(slug);
		}
	}

	async payButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.beginPayment(slug);
		}
	}

	@SIGNAL
	protected projectSelectionChanged(): void {
	}

	async restoreButtonClicked(slug: string): Promise<void> {
		if (this.view) {
			await this.view.restoreProject(slug);
		}
	}

	selectedProjects(): Array<string> {
		return [];
	}

	setDownloadUrl(slug: string, url: string | null): void {
	}

	setProgressVisible(slug: string, visible: boolean): void {
	}

	async setProjects(projects: Iterable<IProject>): Promise<void> {
	}
}
