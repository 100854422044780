import type {
	MapMouseEvent,
	MapTouchEvent,
	MapboxGeoJSONFeature,
} from 'mapbox-gl';

import {Mode} from './mode';
import {MultiFeature} from '../features/multi';
import {Feature} from '../features/feature';
import {clientMightBeMac} from '../../../../util';
import {
	isActiveFeature,
	moveFeatures,
	createSupplementaryPoints,
	doubleClickZoom,
} from './util';
import {
	MapMouseFeatureEvt,
	MouseEvt,
	MapTouchFeatureEvt,
	MapMouseEvt,
	MapTouchEvt,
	KeyboardEvt,
	isMapMouseEvent,
} from '../events';
import {
	DrawMode,
	Cursor,
	DrawEventType,
	FeatureRole,
	CssClassName,
} from '../constants';

export class SimpleSelect extends Mode {
	name = DrawMode.SimpleSelect;
	state!: {
		boxSelectElement: HTMLElement | null;
		boxSelecting: boolean;
		boxSelectStartLocation: IGenericPoint | null;
		canBoxSelect: boolean;
		canDragMove: boolean;
		dragMoveLocation: {lng: number; lat: number;} | null;
		dragMoving: boolean;
		initiallySelectedFeatureIds: Array<string>;
	};

	clickAnywhere(): void {
		// Clear the re-render selection
		const wasSelected: Array<string> = this.getSelectedIds();
		if (wasSelected.length > 0) {
			this.clearSelectedFeatures();
			wasSelected.forEach(id => this.doRender(id));
		}
		doubleClickZoom.enable(this.ctx);
		this.stopExtendedInteractions();
	}

	private clickOnDeselectedFeature(featureId: string, mods: {ctrl: boolean; shift: boolean;}, selectedFeatureIds: Array<string>): void {
		if (mods.shift || mods.ctrl) {
			this.select([featureId]);
			this.updateUIClasses({mouse: Cursor.Move});
		} else {
			selectedFeatureIds.forEach(id => this.doRender(id));
			this.setSelected([featureId]);
			this.updateUIClasses({mouse: Cursor.Move});
		}
	}

	private clickOnFeature(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		doubleClickZoom.disable(this.ctx);
		this.stopExtendedInteractions();
		let ctrlKey: boolean = false;
		let shiftKey: boolean = false;
		if (isMapMouseEvent(evt.event)) {
			shiftKey = evt.event.originalEvent.shiftKey;
			ctrlKey = ctrlIsPressed(evt.event.originalEvent);
		}
		const selectedFeatureIds: Array<string> = this.getSelectedIds();
		const featureId: string = (evt.featureTarget && evt.featureTarget.properties && evt.featureTarget.properties.id) || '';
		const isFeatureSelected: boolean = this.isSelected(featureId);
		if (isFeatureSelected) {
			this.clickOnSelectedFeature(
				featureId,
				{ctrl: ctrlKey, shift: shiftKey},
				selectedFeatureIds);
		} else {
			this.clickOnDeselectedFeature(
				featureId,
				{ctrl: ctrlKey, shift: shiftKey},
				selectedFeatureIds);
		}
		this.doRender(featureId);
	}

	private clickOnSelectedFeature(featureId: string, mods: {ctrl: boolean; shift: boolean;}, selectedFeatureIds: Array<string>): void {
		if (mods.shift) {
			this.deselect([featureId]);
			this.updateUIClasses({mouse: Cursor.Pointer});
			if (selectedFeatureIds.length === 1) {
				doubleClickZoom.enable(this.ctx);
			}
		} else {
			const feat: Feature | null = this.getFeature(featureId);
			if (feat && (feat.type !== 'Point')) {
				this.changeMode(DrawMode.DirectSelect, {featureId});
			}
		}
	}

	clickOnVertex(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		// Enter direct select mode
		if (evt.featureTarget && evt.featureTarget.properties) {
			this.changeMode(
				DrawMode.DirectSelect,
				{
					coordPath: evt.featureTarget.properties.coord_path,
					featureId: evt.featureTarget.properties.parent,
					startPos: evt.event.lngLat,
				});
			this.updateUIClasses({mouse: Cursor.Move});
		}
	}

	combineFeatures(): void {
		const selectedFeats: Array<Feature> = this.getSelected();
		if (selectedFeats.length >= 2) {
			const coords: Array<GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][]> = [];
			const combinedFeats: Array<GeoJsonFeature> = [];
			const featType: string = selectedFeats[0].type.replace('Multi', '');
			for (let i = 0; i < selectedFeats.length; i++) {
				const feat: Feature = selectedFeats[i];
				if (feat.type.replace('Multi', '') !== featType) {
					return;
				}
				if (feat.type.startsWith('Multi')) {
					(<GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][]>feat.getCoordinates()).forEach((subcoords: GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][]) => {
						coords.push(subcoords);
					});
				} else {
					coords.push(<GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][]>feat.getCoordinates());
				}
				combinedFeats.push(feat.toGeoJSON());
			}
			if (combinedFeats.length > 1) {
				const multiFeature: Feature = this.newFeature({
					type: 'Feature',
					properties: combinedFeats[0].properties,
					geometry: <GeoJsonMultiPoint | GeoJsonMultiLineString | GeoJsonMultiPolygon>{
						type: `Multi${featType}`,
						coordinates: coords,
					},
				});
				this.addFeature(multiFeature);
				this.deleteFeature(this.getSelectedIds(), {silent: true});
				this.setSelected([multiFeature.id]);
				this.fireEvent(DrawEventType.CombineFeatures,
					{
						createdFeatures: [multiFeature.toGeoJSON()],
						deletedFeatures: combinedFeats,
					});
			}
		}
	}

	protected dragEvent(evt: MapMouseEvt | MapTouchEvt) {
		if (this.state.canDragMove) {
			this.dragMoveEvent(evt.event);
		} else if (this.ctx.options.boxSelect && this.state.canBoxSelect) {
			this.whileBoxSelect(evt);
		}
	}

	dragMoveEvent(event: MapMouseEvent | MapTouchEvent): void {
		// Dragging when drag move is enabled
		this.state.dragMoving = true;
		event.originalEvent.stopPropagation();
		if (this.state.dragMoveLocation) {
			const delta: {lng: number; lat: number;} = {
				lng: event.lngLat.lng - this.state.dragMoveLocation.lng,
				lat: event.lngLat.lat - this.state.dragMoveLocation.lat,
			};
			moveFeatures(this.getSelected(), delta);
		}
		this.state.dragMoveLocation = event.lngLat;
	}

	fireUpdate(): void {
		this.fireEvent(DrawEventType.Update,
			{
				action: 'move',
				features: this.getSelected().map(f => f.toGeoJSON()),
			});
	}

	getUniqueIds(allFeatures: Array<MapboxGeoJSONFeature>): Array<string> {
		const ids: Set<string> = new Set<string>();
		for (let i = 0; i < allFeatures.length; ++i) {
			const feat: MapboxGeoJSONFeature = allFeatures[i];
			if (feat.properties && feat.properties.id) {
				ids.add(feat.properties.id);
			}
		}
		return Array.from(ids.values());
	}

	protected keyPressEvent(evt: KeyboardEvt) {
		if (evt.event.key === 'Escape') {
			const ids = this.getSelectedIds();
			if (ids.length > 0) {
				this.deselect(ids);
			}
		}
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt) {
		this.touchTapMouseClickEvent(evt);
	}

	protected mouseMoveEvent(evt: MapMouseFeatureEvt): void {
		// On mousemove that is not a drag, stop extended interactions.
		// This is useful if you drag off the canvas, release the button,
		// then move the mouse back over the canvas --- we don't allow the
		// interaction to continue then, but we do let it continue if you held
		// the mouse button that whole time
		this.stopExtendedInteractions();
		// Skip render
		evt.needsRender = false;
	}

	protected mouseOutEvent(evt: MouseEvt): void {
		// As soon as you mouse leaves the canvas, update the feature
		if (this.state.dragMoving) {
			this.fireUpdate();
		} else {
			// Skip render
			evt.needsRender = false;
		}
	}

	protected mousePressEvent(evt: MapMouseFeatureEvt) {
		if (isActiveFeature(evt.featureTarget)) {
			this.startOnActiveFeature(evt);
		} else if (this.ctx.options.boxSelect && evt.event.originalEvent.shiftKey && (evt.event.originalEvent.button === 0)) {
			this.startBoxSelect(evt.event);
		}
	}

	protected mouseReleaseEvent(evt: MapMouseFeatureEvt) {
		// End any extended interactions
		if (this.state.dragMoving) {
			this.fireUpdate();
		} else if (this.state.boxSelecting) {
			if (this.state.boxSelectStartLocation && this.ctx.map) {
				const p = uiEventPoint(
					evt.event.originalEvent.clientX,
					evt.event.originalEvent.clientY,
					this.ctx.map.getContainer());
				const bbox: [[number, number], [number, number]] = [
					[this.state.boxSelectStartLocation.x, this.state.boxSelectStartLocation.y],
					[p.x, p.y],
				];
				const featuresInBox: Array<MapboxGeoJSONFeature> = this.featuresAt(null, bbox, 'click');
				const idsToSelect: Array<string> = this.getUniqueIds(featuresInBox)
					.filter(id => !this.isSelected(id));
				if (idsToSelect.length) {
					this.select(idsToSelect);
					idsToSelect.forEach(id => this.doRender(id));
					this.updateUIClasses({mouse: Cursor.Move});
				}
			}
		}
		this.stopExtendedInteractions();
	}

	setup(opts?: {featureIds: Array<string>;}): void {
		// turn the opts into this.state.
		this.state = {
			boxSelectElement: null,
			boxSelecting: false,
			boxSelectStartLocation: null,
			canBoxSelect: false,
			canDragMove: false,
			dragMoveLocation: null,
			dragMoving: false,
			initiallySelectedFeatureIds: (opts && opts.featureIds) || [],
		};
		this.setSelected(this.state.initiallySelectedFeatureIds.filter(id => this.getFeature(id)));
	}

	startBoxSelect(event: MapMouseEvent): void {
		this.stopExtendedInteractions();
		if (this.ctx.map) {
			this.ctx.map.dragPan.disable();
			// Enable box select
			this.state.boxSelectStartLocation = uiEventPoint(
				event.originalEvent.clientX,
				event.originalEvent.clientY,
				this.ctx.map.getContainer(),
			);
		}

		this.state.canBoxSelect = true;
	}

	startOnActiveFeature(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		// Stop any already-underway extended interactions
		this.stopExtendedInteractions();
		// Disable map.dragPan immediately so it can't start
		this.ctx.map && this.ctx.map.dragPan.disable();
		// Re-render it and enable drag move
		if (evt.featureTarget && evt.featureTarget.properties && evt.featureTarget.properties.id) {
			this.doRender(evt.featureTarget.properties.id);
		}
		// Set up the state for drag moving
		this.state.canDragMove = true;
		this.state.dragMoveLocation = evt.event.lngLat;
	}

	stop(): void {
		doubleClickZoom.enable(this.ctx);
		const ids = this.getSelectedIds();
		if (ids.length > 0) {
			this.deselect(ids);
		}
	}

	stopExtendedInteractions(): void {
		if (this.state.boxSelectElement) {
			if (this.state.boxSelectElement.parentNode) {
				this.state.boxSelectElement.parentNode.removeChild(this.state.boxSelectElement);
			}
			this.state.boxSelectElement = null;
		}
		this.ctx.map && this.ctx.map.dragPan.enable();
		this.state.boxSelecting = false;
		this.state.canBoxSelect = false;
		this.state.dragMoving = false;
		this.state.canDragMove = false;
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (geojson: FeatureInternalFeature) => any): void {
		feature.properties.active = this.isSelected(feature.properties.id) ?
			'true' :
			'false';
		display(feature);
		if ((feature.properties.active === 'true') && (feature.geometry.type !== 'Point')) {
			createSupplementaryPoints(
				feature,
				{map: null, midpoints: false, selectedPaths: []},
				null).forEach(display);
		}
	}

	protected touchBeginEvent(evt: MapTouchFeatureEvt) {
		if (isActiveFeature(evt.featureTarget)) {
			this.startOnActiveFeature(evt);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt) {
		this.touchTapMouseClickEvent(evt);
	}

	private touchTapMouseClickEvent(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		// Click (with or without shift) on no feature
		if (!evt.featureTarget) {
			this.clickAnywhere();
		} else if (evt.featureTarget && evt.featureTarget.properties && (evt.featureTarget.properties.meta === FeatureRole.Vertex)) {
			this.clickOnVertex(evt);
		} else if (isFeature(evt)) {
			this.clickOnFeature(evt);
		}
	}

	trash(): void {
		this.deleteFeature(this.getSelectedIds());
		super.trash();
	}

	uncombineFeatures(): void {
		const selectedFeatures: Array<Feature> = this.getSelected();
		if (selectedFeatures.length === 0) {
			return;
		}
		const createdFeatures: Array<FeatureInternalFeature> = [];
		const featuresUncombined: Array<GeoJsonFeature> = [];
		for (let i = 0; i < selectedFeatures.length; i++) {
			const feat: Feature = selectedFeatures[i];
			if (this.isInstance('MultiFeature', feat)) {
				(<MultiFeature>feat).getFeatures().forEach(subFeat => {
					this.addFeature(subFeat);
					subFeat.properties = feat.properties;
					createdFeatures.push(subFeat.toGeoJSON());
					this.select([subFeat.id]);
				});
				this.deleteFeature([feat.id], {silent: true});
				featuresUncombined.push(feat.toGeoJSON());
			}
		}
		if (createdFeatures.length > 1) {
			this.fireEvent(DrawEventType.UncombineFeatures, {
				createdFeatures,
				deletedFeatures: featuresUncombined,
			});
		}
	}

	whileBoxSelect(evt: MapMouseEvt | MapTouchEvt): void {
		this.state.boxSelecting = true;
		this.updateUIClasses({mouse: Cursor.Add});
		// Create the box node if it doesn't exist
		if (!this.state.boxSelectElement) {
			this.state.boxSelectElement = document.createElement('div');
			this.state.boxSelectElement.classList.add(CssClassName.BoxSelect);
			this.ctx.map && this.ctx.map.getContainer().appendChild(this.state.boxSelectElement);
		}
		const coord = extractClientCoords(evt.event.originalEvent);
		if (coord && this.state.boxSelectStartLocation && this.ctx.map) {
			// Adjust the box node's width and xy position
			const current: IGenericPoint = uiEventPoint(
				coord.x,
				coord.y,
				this.ctx.map.getContainer());
			const minX: number = Math.min(this.state.boxSelectStartLocation.x, current.x);
			const maxX: number = Math.max(this.state.boxSelectStartLocation.x, current.x);
			const minY: number = Math.min(this.state.boxSelectStartLocation.y, current.y);
			const maxY: number = Math.max(this.state.boxSelectStartLocation.y, current.y);
			this.state.boxSelectElement.style.transform = `translate(${minX}px, ${minY}px)`;
			this.state.boxSelectElement.style.width = `${maxX - minX}px`;
			this.state.boxSelectElement.style.height = `${maxY - minY}px`;
		}
	}
}

function extractClientCoords(event: MouseEvent | TouchEvent): {x: number; y: number;} | null {
	if (event instanceof MouseEvent) {
		return {
			x: event.clientX,
			y: event.clientY,
		};
	} else {
		if (event.touches.length > 0) {
			return {
				x: event.touches[0].clientX,
				y: event.touches[0].clientY,
			};
		}
	}
	return null;
}

function isFeature(event: {featureTarget?: MapboxGeoJSONFeature | null}): boolean {
	if (event.featureTarget && event.featureTarget.properties) {
		return event.featureTarget.properties.meta === FeatureRole.Feature;
	}
	return false;
}

function uiEventPoint(clientX: number, clientY: number, container: HTMLElement): IGenericPoint {
	const rect = container.getBoundingClientRect();
	return {
		x: clientX - rect.left - container.clientLeft,
		y: clientY - rect.top - container.clientTop,
	};
}

function ctrlIsPressed(event: MouseEvent): boolean {
	return clientMightBeMac() ?
		event.metaKey :
		event.ctrlKey;
}
