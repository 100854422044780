import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {AlignmentFlag, CaseSensitivity, CheckState, ItemDataRole, ItemFlag, LayoutChangeHint, MetaType, Orientation, SortOrder} from '../../constants';
import {Variant, variantCmp} from '../../variant';
import {divmod, isNumber, lowerBound, stringCmp, stringIterableToStringArray} from '../../util';
import {list} from '../../tools';
import {getLogger} from '../../logging';

const logger = getLogger('ui.table');
const InvalidTableItemId = -99;
const ItemIsHeaderItem = 128;

export interface TableItemOpts extends ElObjOpts {
	checkState: CheckState;
	textAlignment: AlignmentFlag;
}

export interface TableItemArgs<T extends TableItemOpts = TableItemOpts> {
	opts: Partial<T>;
	other?: TableItem;
	text?: string;
}

export class ModelIndex {
	column: number;
	row: number;

	constructor(row: number = -1, column: number = -1) {
		this.column = column;
		this.row = row;
	}

	isValid(): boolean {
		return (this.row >= 0) && (this.column >= 0);
	}
}

class ItemData {
	role: number;
	value: Variant;

	constructor(role: number, value: Variant);
	constructor();
	constructor(role?: number, value?: Variant) {
		this.role = isNumber(role) ?
			role :
			-1;
		this.value = (value === undefined) ?
			new Variant() :
			value;
	}

	eq(other: ItemData): boolean {
		return (this.role === other.role) && this.value.eq(other.value);
	}
}

@OBJ
export class TableItem extends ElObj {
	id: number;
	itemFlags: ItemFlag;
	sel: boolean;
	values: list<ItemData>;
	view: Table | null;

	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts(a, b);
		super(args.opts);
		this.id = -1;
		if (args.other) {
			this.itemFlags = args.other.itemFlags;
			this.values = new list<ItemData>(args.other.values);
		} else {
			this.itemFlags = ItemFlag.ItemIsEnabled
				| ItemFlag.ItemIsDragEnabled
				| ItemFlag.ItemIsDropEnabled;
			this.values = new list<ItemData>();
		}
		this.sel = false;
		this.view = null;
		if (args.opts.checkState !== undefined) {
			this.setCheckState(args.opts.checkState);
		}
		if (args.text) {
			this.setText(args.text);
		}
		if (isNumber(args.opts.textAlignment)) {
			this.setTextAlignment(args.opts.textAlignment);
		}
	}

	background(): string {
		return this.data(ItemDataRole.BackgroundRole).toString();
	}

	checkState(): CheckState {
		return this.data(ItemDataRole.CheckStateRole).toNumber();
	}

	clone(): TableItem {
		return new (<typeof TableItem>this.constructor)(this);
	}

	cmp(other: TableItem): number {
		const v1 = this.data(ItemDataRole.DisplayRole);
		const v2 = other.data(ItemDataRole.DisplayRole);
		return variantCmp(v1, v2);
	}

	column(): number {
		return this.view ?
			this.view.column(this) :
			-1;
	}

	data(role: ItemDataRole): Variant {
		for (const data of this.values) {
			if (data.role === role) {
				return data.value;
			}
		}
		return new Variant();
	}

	destroy(): void {
		if (this.view) {
			this.view.removeItem(this);
		}
		this.id = InvalidTableItemId;
		this.itemFlags = 0;
		this.values.clear();
		this.view = null;
		super.destroy();
	}

	flags(): ItemFlag {
		return this.itemFlags;
	}

	foreground(): string {
		return this.data(ItemDataRole.ForegroundRole).toString();
	}

	icon(): string {
		return this.data(ItemDataRole.DecorationRole).toString();
	}

	isSelected(): boolean {
		return this.sel;
	}

	lt(other: TableItem): boolean {
		return Table.variantLessThan(
			this.data(ItemDataRole.DisplayRole),
			other.data(ItemDataRole.DisplayRole));
	}

	row(): number {
		return this.view ?
			this.view.row(this) :
			-1;
	}

	setBackground(colorValue: string): void {
		this.setData(ItemDataRole.BackgroundRole, new Variant(colorValue));
	}

	setCheckState(state: CheckState): void {
		this.setData(ItemDataRole.CheckStateRole, new Variant(state));
	}

	setData(role: number, value: Variant): void {
		let found: boolean = false;
		for (let i = 0; i < this.values.size(); ++i) {
			const data = this.values.at(i);
			if (data.role === role) {
				if (data.value.eq(value)) {
					return;
				}
				data.value = value;
				found = true;
				break;
			}
		}
		if (!found) {
			this.values.append(new ItemData(role, value));
		}
		if (role === ItemDataRole.ForegroundRole) {
			this.setStyleProperty('color', value.toString());
		}
		this.setDataForRole(role, value);
		if (this.view) {
			const roles = (role === ItemDataRole.DisplayRole) ?
				[ItemDataRole.DisplayRole, ItemDataRole.EditRole] :
				[role];
			this.view._itemChanged(this, roles);
		}
	}

	protected setDataForRole(role: number, value: Variant): void {
	}

	setFlags(flags: ItemFlag): void {
		this.itemFlags = flags;
		if (this.view) {
			this.view._itemChanged(this);
		}
	}

	setForeground(colorValue: string): void {
		this.setData(ItemDataRole.ForegroundRole, new Variant(colorValue));
	}

	setIcon(icon: string): void {
		this.setData(ItemDataRole.DecorationRole, new Variant(icon));
	}

	setSelected(selected: boolean): void {
	}

	setText(text?: string | null): void {
		this.setData(
			ItemDataRole.DisplayRole,
			new Variant((typeof text === 'string') ? text : ''));
	}

	setTextAlignment(alignment: AlignmentFlag): void {
		this.setData(ItemDataRole.TextAlignmentRole, new Variant(alignment));
	}

	table(): Table | null {
		return this.view;
	}

	text(): string {
		return this.data(ItemDataRole.DisplayRole).toString();
	}

	textAlignment(): AlignmentFlag {
		return this.data(ItemDataRole.TextAlignmentRole).toNumber();
	}
}

export interface TableOpts extends ElObjOpts {
	columnCount: number;
	horizontalHeaderItemPrototype: TableItem;
	itemPrototype: TableItem;
	rowCount: number;
	verticalHeaderItemPrototype: TableItem;
}

@OBJ
export class Table extends ElObj {
	static sortedInsertionIndex(from: number, to: number, collection: Array<TableItem> | list<TableItem>, sortOrder: SortOrder, item: TableItem): number {
		if (sortOrder === SortOrder.AscendingOrder) {
			return lowerBound(
				Array.from(collection).slice(from, to),
				item,
				tableItemLessThan);
		}
		return lowerBound(
			Array.from(collection).slice(from, to),
			item,
			tableItemGreaterThan);
	}

	static variantLessThan(left: Variant, right: Variant, cs: CaseSensitivity = CaseSensitivity.CaseSensitive, isLocaleAware: boolean = false): boolean {
		if (left.type() === MetaType.Invalid) {
			return false;
		}
		if (right.type() === MetaType.Invalid) {
			return true;
		}
		switch (left.type()) {
			case MetaType.Number:
				return left.toNumber() < right.toNumber();
			case MetaType.Date:
				return left.toDate().lt(right.toDate());
			case MetaType.Time:
				return left.toTime().lt(right.toTime());
			case MetaType.DateTime:
				return left.toDateTime().lt(right.toDateTime());
			default:
				return stringCmp(
					left.toString(),
					right.toString(),
					cs,
					isLocaleAware) < 0;
		}
	}

	private hHeaderItemProto: TableItem | null;
	private hHeaderItems: list<TableItem | null>;
	private itemProto: TableItem | null;
	protected tableItems: list<TableItem | null>;
	private vHeaderItemProto: TableItem | null;
	private vHeaderItems: list<TableItem | null>;

	constructor(opts: Partial<TableOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<TableOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<TableOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<TableOpts>(a, b, c);
		super(opts);
		this.hHeaderItemProto = null;
		this.hHeaderItems = new list<TableItem | null>();
		this.itemProto = null;
		this.tableItems = new list<TableItem | null>();
		this.vHeaderItemProto = null;
		this.vHeaderItems = new list<TableItem | null>();
		if (opts.itemPrototype) {
			this.setItemPrototype(opts.itemPrototype);
		}
		if (opts.horizontalHeaderItemPrototype) {
			this.setHorizontalHeaderItemPrototype(
				opts.horizontalHeaderItemPrototype);
		}
		if (opts.verticalHeaderItemPrototype) {
			this.setVerticalHeaderItemPrototype(
				opts.verticalHeaderItemPrototype);
		}
		Obj.connect(
			this, 'dataChanged',
			this, 'emitItemChanged');
		if (isNumber(opts.rowCount) && isNumber(opts.columnCount)) {
			this.setColumnCount(opts.columnCount);
			this.setRowCount(opts.rowCount);
		}
	}

	protected beginInsertColumns(first: number, last: number): void {
		this.columnsAboutToBeInserted(first, last);
	}

	protected beginInsertRows(first: number, last: number): void {
		this.rowsAboutToBeInserted(first, last);
	}

	protected beginRemoveColumns(first: number, last: number): void {
		this.columnsAboutToBeRemoved(first, last);
	}

	protected beginRemoveRows(first: number, last: number): void {
		this.rowsAboutToBeRemoved(first, last);
	}

	protected beginResetModel(): void {
		this.modelAboutToBeReset();
	}

	@SIGNAL
	protected cellChanged(row: number, column: number): void {
	}

	@SIGNAL
	protected cellClicked(row: number, column: number): void {
	}

	@SIGNAL
	protected cellDoubleClicked(row: number, column: number): void {
	}

	@SLOT
	clear(): void {
		// Removes all items in the view. This will also remove all headers.
		// If you don't want to remove the headers, use Table.clearContents().
		//
		// The table dimensions stay the same.
		for (let i = 0; i < this.vHeaderItems.size(); ++i) {
			const obj = this.vHeaderItems.at(i);
			if (obj) {
				obj.view = null;
				obj.destroy();
				this.vHeaderItems.replace(i, null);
			}
		}
		for (let i = 0; i < this.hHeaderItems.size(); ++i) {
			const obj = this.hHeaderItems.at(i);
			if (obj) {
				obj.view = null;
				obj.destroy();
				this.hHeaderItems.replace(i, null);
			}
		}
		this.clearContents();
	}

	@SLOT
	clearContents(): void {
		// Removes all items not in the headers from the view.
		//
		// The table dimensions stay the same.
		for (let i = 0; i < this.tableItems.size(); ++i) {
			const obj = this.tableItems.at(i);
			if (obj) {
				obj.view = null;
				obj.destroy();
				this.tableItems.replace(i, null);
			}
		}
	}

	column(item: TableItem | null): number {
		return this.index(item).column;
	}

	columnCount(): number {
		return this.hHeaderItems.size();
	}

	@SIGNAL
	protected columnsAboutToBeInserted(first: number, last: number): void {
	}

	@SIGNAL
	protected columnsAboutToBeRemoved(first: number, last: number): void {
	}

	@SIGNAL
	protected columnsInserted(first: number, last: number): void {
	}

	@SIGNAL
	protected columnsRemoved(first: number, last: number): void {
	}

	protected createHeaderItem(orientation: Orientation): TableItem {
		return (orientation === Orientation.Vertical) ?
			this.createVerticalHeaderItem() :
			this.createHorizontalHeaderItem();
	}

	protected createHorizontalHeaderItem(): TableItem {
		return this.hHeaderItemProto ?
			this.hHeaderItemProto.clone() :
			new TableItem();
	}

	protected createItem(): TableItem {
		return this.itemProto ?
			this.itemProto.clone() :
			new TableItem();
	}

	protected createVerticalHeaderItem(): TableItem {
		return this.vHeaderItemProto ?
			this.vHeaderItemProto.clone() :
			new TableItem();
	}

	data(index: ModelIndex, role: ItemDataRole): Variant {
		const item = this.item(index);
		if (item) {
			return item.data(role);
		}
		return new Variant();
	}

	@SIGNAL
	protected dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles: Array<number>): void {
	}

	destroy(): void {
		Obj.disconnect(
			this, 'dataChanged',
			this, 'emitItemChanged');
		if (this.itemProto) {
			this.itemProto.destroy();
		}
		this.itemProto = null;
		if (this.hHeaderItemProto) {
			this.hHeaderItemProto.destroy();
		}
		this.hHeaderItemProto = null;
		if (this.vHeaderItemProto) {
			this.vHeaderItemProto.destroy();
		}
		this.vHeaderItemProto = null;
		this.clear();
		this.hHeaderItems.clear();
		this.tableItems.clear();
		this.vHeaderItems.clear();
		super.destroy();
	}

	@SLOT
	protected emitItemChanged(index: ModelIndex): void {
		const item = this.item(index);
		if (item) {
			this.itemChanged(item);
		}
		this.cellChanged(index.row, index.column);
	}

	@SLOT
	protected emitItemClicked(index: ModelIndex): void {
		const item = this.item(index);
		if (item) {
			this.itemClicked(item);
		}
		this.cellClicked(index.row, index.column);
	}

	@SLOT
	protected emitItemDoubleClicked(index: ModelIndex): void {
		const item = this.item(index);
		if (item) {
			this.itemDoubleClicked(item);
		}
		this.cellDoubleClicked(index.row, index.column);
	}

	protected endInsertColumns(): void {
		this.columnsInserted(-1, -1);
	}

	protected endInsertRows(): void {
		this.rowsInserted(-1, -1);
	}

	protected endRemoveColumns(): void {
		this.columnsRemoved(-1, -1);
	}

	protected endRemoveRows(): void {
		this.rowsRemoved(-1, -1);
	}

	protected endResetModel(): void {
		this.modelReset();
	}

	flags(index: ModelIndex): ItemFlag {
		if (!index.isValid()) {
			return ItemFlag.ItemIsDropEnabled;
		}
		const item = this.item(index);
		if (item) {
			return item.flags();
		}
		return (ItemFlag.ItemIsEditable
			| ItemFlag.ItemIsSelectable
			| ItemFlag.ItemIsUserCheckable
			| ItemFlag.ItemIsEnabled
			| ItemFlag.ItemIsDragEnabled
			| ItemFlag.ItemIsDropEnabled);
	}

	protected hasIndex(row: number, column: number): boolean {
		if ((row < 0) || (column < 0)) {
			return false;
		}
		return (row < this.rowCount()) && (column < this.columnCount());
	}

	headerData(section: number, orientation: Orientation, role: ItemDataRole): Variant {
		if (section < 0) {
			return new Variant();
		}
		let item: TableItem | null = null;
		if ((orientation === Orientation.Horizontal) && (section < this.hHeaderItems.size())) {
			item = this.hHeaderItems.at(section);
		} else if ((orientation === Orientation.Vertical) && (section < this.vHeaderItems.size())) {
			item = this.vHeaderItems.at(section);
		} else {
			// Out of bounds
			return new Variant();
		}
		if (item) {
			return item.data(role);
		}
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(section + 1);
		}
		return new Variant();
	}

	@SIGNAL
	protected headerDataChanged(orientation: Orientation, first: number, last: number): void {
	}

	horizontalHeaderItem(column: number): TableItem | null {
		// Returns the horizontal header item for column, if one has been set;
		// otherwise returns null.
		if ((column >= 0) && (column < this.hHeaderItems.size())) {
			return this.hHeaderItems.at(column);
		}
		return null;
	}

	horizontalHeaderItemPrototype(): TableItem | null {
		return this.hHeaderItemProto;
	}

	index(row: number, column: number): ModelIndex;
	index(item: TableItem | null): ModelIndex;
	index(a: number | TableItem | null, b?: number): ModelIndex {
		if (isNumber(a) && isNumber(b)) {
			return new ModelIndex(a, b);
		}
		const item = <TableItem | null>a;
		if (item) {
			let i: number;
			const id = item.id;
			if ((id >= 0) && (id < this.tableItems.size()) && (this.tableItems.at(id) === item)) {
				i = id;
			} else {
				i = this.tableItems.indexOf(item);
			}
			if (i >= 0) {
				return new ModelIndex(...divmod(i, this.hHeaderItems.size()));
			}
		}
		return new ModelIndex();
	}

	indexFromItem(item: TableItem | null): ModelIndex {
		// Returns the ModelIndex associated with the given item.
		return this.index(item);
	}

	protected indexIsValid(index: ModelIndex): boolean {
		return index.isValid()
			&& (index.row < this.vHeaderItems.size())
			&& (index.column < this.hHeaderItems.size());
	}

	@SLOT
	insertColumn(column: number): boolean {
		// Inserts an empty column into the table at column.
		return this.insertColumns(column);
	}

	insertColumns(column: number, count: number = 1): boolean {
		const colCount = this.hHeaderItems.size();
		if ((count < 1) || (column < 0) || (column > colCount)) {
			return false;
		}
		this.beginInsertColumns(column, column + count - 1);
		const rowCount = this.vHeaderItems.size();
		this.hHeaderItems.insert(column, count, null);
		if (colCount === 0) {
			this.tableItems.insert(
				this.tableItems.size(),
				rowCount * count,
				null);
		} else {
			for (let row = 0; row < rowCount; ++row) {
				this.tableItems.insert(
					this.tableIndex(row, column),
					count,
					null);
			}
		}
		this.endInsertColumns();
		return true;
	}

	@SLOT
	insertRow(row: number): boolean {
		// Inserts an empty row into the table at row.
		return this.insertRows(row);
	}

	insertRows(row: number, count: number = 1): boolean {
		const rowCount = this.vHeaderItems.size();
		if ((count < 1) || (row < 0) || (row > rowCount)) {
			return false;
		}
		this.beginInsertRows(row, row + count - 1);
		const colCount = this.hHeaderItems.size();
		this.vHeaderItems.insert(row, count, null);
		if (rowCount === 0) {
			this.tableItems.insert(
				this.tableItems.size(),
				colCount * count,
				null);
		} else {
			this.tableItems.insert(
				this.tableIndex(row, 0),
				colCount * count,
				null);
		}
		this.endInsertRows();
		return true;
	}

	item(row: number, column: number): TableItem | null;
	item(index: ModelIndex): TableItem | null;
	item(a: ModelIndex | number, b?: number): TableItem | null {
		// Returns the item for the given (row, column) or index, if one has
		// been set; otherwise returns null.
		let index: ModelIndex;
		if (isNumber(a) && isNumber(b)) {
			index = new ModelIndex(a, b);
		} else {
			index = <ModelIndex>a;
		}
		if (this.indexIsValid(index)) {
			return this.tableItems.at(
				this.tableIndex(index.row, index.column));
		}
		return null;
	}

	@SIGNAL
	protected itemChanged(item: TableItem): void {
	}

	_itemChanged(item: TableItem | null, roles?: Array<number>): void {
		if (!item) {
			return;
		}
		if (item.flags() & ItemIsHeaderItem) {
			const row = this.vHeaderItems.indexOf(item);
			if (row >= 0) {
				this.headerDataChanged(Orientation.Vertical, row, row);
			} else {
				const column = this.hHeaderItems.indexOf(item);
				if (column >= 0) {
					this.headerDataChanged(
						Orientation.Horizontal,
						column,
						column);
				}
			}
		} else {
			const idx = this.index(item);
			if (idx.isValid()) {
				this.dataChanged(idx, idx, roles || []);
			}
		}
	}

	@SIGNAL
	protected itemClicked(item: TableItem): void {
	}

	@SIGNAL
	protected itemDoubleClicked(item: TableItem): void {
	}

	itemFromIndex(index: ModelIndex): TableItem | null {
		return this.item(index);
	}

	itemPrototype(): TableItem | null {
		return this.itemProto;
	}

	@SIGNAL
	protected itemSelectionChanged(): void {
	}

	@SIGNAL
	protected layoutAboutToBeChanged(hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
	}

	@SIGNAL
	protected layoutChanged(hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
	}

	@SIGNAL
	protected modelAboutToBeReset(): void {
	}

	@SIGNAL
	protected modelReset(): void {
	}

	@SLOT
	removeColumn(column: number): boolean {
		// Removes the column and all its items from the table.
		return this.removeColumns(column);
	}

	removeColumns(column: number, count: number = 1): boolean {
		if ((count < 1) || (column < 0) || ((column + count) > this.hHeaderItems.size())) {
			return false;
		}
		this.beginRemoveColumns(column, column + count - 1);
		let oldItem: TableItem | null;
		for (let row = this.rowCount() - 1; row >= 0; --row) {
			const idx = this.tableIndex(row, column);
			for (let k = idx; k < idx; ++k) {
				oldItem = this.tableItems.at(k);
				if (oldItem) {
					oldItem.view = null;
					oldItem.destroy();
				}
			}
			this.tableItems.remove(idx, count);
		}
		for (let col = column; col < (column + count); ++col) {
			oldItem = this.hHeaderItems.at(col);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.hHeaderItems.remove(column, count);
		this.endRemoveColumns();
		return true;
	}

	removeItem(item: TableItem): void {
		let i: number = this.tableItems.indexOf(item);
		if (i >= 0) {
			const idx = this.index(item);
			this.tableItems.replace(i, null);
			this.dataChanged(idx, idx, []);
			return;
		}
		i = this.vHeaderItems.indexOf(item);
		if (i >= 0) {
			this.vHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Vertical, i, i);
			return;
		}
		i = this.hHeaderItems.indexOf(item);
		if (i >= 0) {
			this.hHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Horizontal, i, i);
			return;
		}
	}

	@SLOT
	removeRow(row: number): boolean {
		// Removes the row and all its items from the table.
		return this.removeRows(row);
	}

	removeRows(row: number, count: number = 1): boolean {
		if ((count < 1) || (row < 0) || ((row + count) > this.vHeaderItems.size())) {
			return false;
		}
		this.beginRemoveRows(row, row + count - 1);
		const idx = this.tableIndex(row, 0);
		const n = count * this.columnCount();
		let oldItem: TableItem | null;
		for (let k = idx; k < (n + idx); ++k) {
			oldItem = this.tableItems.at(k);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.tableItems.remove(Math.max(idx, 0), n);
		for (let v = row; v < (row + count); ++v) {
			oldItem = this.vHeaderItems.at(v);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.vHeaderItems.remove(row, count);
		this.endRemoveRows();
		return true;
	}

	row(item: TableItem | null): number {
		return this.index(item).row;
	}

	rowCount(): number {
		return this.vHeaderItems.size();
	}

	@SIGNAL
	protected rowsAboutToBeInserted(first: number, last: number): void {
	}

	@SIGNAL
	protected rowsAboutToBeRemoved(first: number, last: number): void {
	}

	@SIGNAL
	protected rowsInserted(first: number, last: number): void {
	}

	@SIGNAL
	protected rowsRemoved(first: number, last: number): void {
	}

	setColumnCount(count: number): void {
		// Sets the number of columns in this table's model to count. If this
		// is less than columnCount(), the data in the unwanted columns is
		// discarded.
		const colCount = this.hHeaderItems.size();
		if ((count < 0) || (count === colCount)) {
			return;
		}
		if (colCount < count) {
			this.insertColumns(Math.max(colCount, 0), count - colCount);
		} else {
			this.removeColumns(Math.max(count, 0), colCount - count);
		}
	}

	setData(index: ModelIndex, value: Variant, role: ItemDataRole): boolean {
		if (!index.isValid()) {
			return false;
		}
		let item: TableItem | null = this.item(index);
		if (item) {
			item.setData(role, value);
			return true;
		}
		if (!value.isValid()) {
			// Don't create dummy table items for empty values
			return false;
		}
		item = this.createItem();
		this.setItem(index.row, index.column, item);
		item.setData(role, value);
		return true;
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: ItemDataRole): boolean {
		let item: TableItem | null = null;
		if ((orientation === Orientation.Horizontal) && (section < this.hHeaderItems.size())) {
			item = this.hHeaderItems.at(section);
		} else if ((orientation === Orientation.Vertical) && (section < this.vHeaderItems.size())) {
			item = this.vHeaderItems.at(section);
		}
		if (item) {
			item.setData(role, value);
			return true;
		}
		if (!value.isValid()) {
			// Don't create dummy table items for empty values
			return false;
		}
		item = this.createHeaderItem(orientation);
		this.setHeaderItem(section, orientation, item);
		item.setData(role, value);
		return true;
	}

	setHeaderItem(section: number, orientation: Orientation, item: TableItem | null): void {
		if (orientation === Orientation.Vertical) {
			this.setVerticalHeaderItem(section, item);
		} else {
			this.setHorizontalHeaderItem(section, item);
		}
	}

	setHorizontalHeaderItem(column: number, item: TableItem | null): void {
		// Sets the horizontal header item for column to item. If necessary,
		// the column count is increased to fit the item. The previous header
		// item (if there was one) is deleted.
		if (item) {
			if ((column < 0) || (column >= this.hHeaderItems.size())) {
				return;
			}
			const oldItem = this.hHeaderItems.at(column);
			if (item === oldItem) {
				return;
			}
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
			item.view = this;
			item.itemFlags = item.itemFlags | ItemIsHeaderItem;
			this.hHeaderItems.replace(column, item);
			this.headerDataChanged(Orientation.Horizontal, column, column);
		} else {
			const obj = this.takeHorizontalHeaderItem(column);
			obj && obj.destroy();
		}
	}

	setHorizontalHeaderItemPrototype(proto: TableItem | null): void {
		if (proto === this.hHeaderItemProto) {
			return;
		}
		if (this.hHeaderItemProto) {
			this.hHeaderItemProto.destroy();
			this.hHeaderItemProto = null;
		}
		this.hHeaderItemProto = proto;
	}

	setHorizontalHeaderLabels(labels: Iterable<string>): void {
		const labelArr = stringIterableToStringArray(labels);
		let item: TableItem | null = null;
		for (let i = 0; (i < this.columnCount()) && (i < labelArr.length); ++i) {
			item = this.horizontalHeaderItem(i);
			if (!item) {
				item = this.createHorizontalHeaderItem();
				this.setHorizontalHeaderItem(i, item);
			}
			item.setText(labelArr[i]);
		}
	}

	setItem(row: number, column: number, item: TableItem | null): void {
		// Sets the item for the given (row, column) to item.
		//
		// The table takes ownership of the item.
		//
		// Note that if sorting is enabled and column is the current sort
		// column, the row will be moved to the sorted position determined by
		// item.
		//
		// If you want to set several items of a particular row (say, by
		// calling setItem() in a loop), you may want to turn off sorting
		// before doing so, and turn it back on afterwards; this will allow
		// you to use the same row argument for all items in the same row
		// (i.e. setItem() will not move the row).
		if (item) {
			if (item.view) {
				logger.warning('setItem: cannot insert an item that is already owned by another Table');
			} else {
				item.view = this;
				const idx = this.tableIndex(row, column);
				if ((idx < 0) || (idx >= this.tableItems.size())) {
					return;
				}
				const oldItem = this.tableItems.at(idx);
				if (item === oldItem) {
					return;
				}
				if (oldItem) {
					oldItem.view = null;
					oldItem.destroy();
				}
				if (item) {
					item.id = idx;
				}
				this.tableItems.replace(idx, item);
			}
		} else {
			const obj = this.takeItem(row, column);
			obj && obj.destroy();
		}
		const idx = this.index(row, column);
		this.dataChanged(idx, idx, []);
	}

	setItemPrototype(itemProto: TableItem | null): void {
		if (itemProto === this.itemProto) {
			return;
		}
		if (this.itemProto) {
			this.itemProto.destroy();
			this.itemProto = null;
		}
		this.itemProto = itemProto;
	}

	setRowCount(count: number): void {
		const rowCount = this.vHeaderItems.size();
		if ((count < 0) || (count === rowCount)) {
			return;
		}
		if (rowCount < count) {
			this.insertRows(Math.max(rowCount, 0), count - rowCount);
		} else {
			this.removeRows(Math.max(count, 0), rowCount - count);
		}
	}

	setVerticalHeaderItem(row: number, item: TableItem | null): void {
		// Sets the vertical header item for row to item.
		if (item) {
			if ((row < 0) || (row >= this.vHeaderItems.size())) {
				return;
			}
			const oldItem = this.vHeaderItems.at(row);
			if (item === oldItem) {
				return;
			}
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
			item.view = this;
			item.itemFlags = item.itemFlags | ItemIsHeaderItem;
			this.vHeaderItems.replace(row, item);
			this.headerDataChanged(Orientation.Vertical, row, row);
		} else {
			const obj = this.takeVerticalHeaderItem(row);
			obj && obj.destroy();
		}
	}

	setVerticalHeaderItemPrototype(proto: TableItem | null): void {
		if (proto === this.vHeaderItemProto) {
			return;
		}
		if (this.vHeaderItemProto) {
			this.vHeaderItemProto.destroy();
			this.vHeaderItemProto = null;
		}
		this.vHeaderItemProto = proto;
	}

	setVerticalHeaderLabels(labels: Iterable<string>): void {
		const labelArr = stringIterableToStringArray(labels);
		let item: TableItem | null = null;
		for (let i = 0; (i < this.rowCount()) && (i < labelArr.length); ++i) {
			item = this.verticalHeaderItem(i);
			if (!item) {
				item = this.createVerticalHeaderItem();
				this.setVerticalHeaderItem(i, item);
			}
			item.setText(labelArr[i]);
		}
	}

	protected tableIndex(row: number, column: number): number {
		return (row * this.hHeaderItems.size()) + column;
	}

	takeHorizontalHeaderItem(column: number): TableItem | null {
		// Removes the horizontal header item at column from the header
		// without deleting it.
		let obj: TableItem | null = null;
		if ((column >= 0) && (column < this.hHeaderItems.size())) {
			obj = this.hHeaderItems.at(column);
			if (obj) {
				obj.view = null;
				obj.itemFlags &= ~ItemIsHeaderItem;
				this.hHeaderItems.replace(column, null);
			}
		}
		return obj;
	}

	takeItem(row: number, column: number): TableItem | null {
		// Removes the item at (row, column) from the table without
		// deleting it.
		let obj: TableItem | null = null;
		const i = this.tableIndex(row, column);
		if ((i >= 0) && (i < this.tableItems.size())) {
			obj = this.tableItems.at(i);
			if (obj) {
				obj.view = null;
				obj.id = -1;
				this.tableItems.replace(i, null);
				const idx = this.index(row, column);
				this.dataChanged(idx, idx, []);
			}
		}
		return obj;
	}

	takeVerticalHeaderItem(row: number): TableItem | null {
		// Removes the vertical header item at row from the header without
		// deleting it.
		let obj: TableItem | null = null;
		if ((row >= 0) && (row < this.vHeaderItems.size())) {
			obj = this.vHeaderItems.at(row);
			if (obj) {
				obj.view = null;
				obj.itemFlags &= ~ItemIsHeaderItem;
				this.vHeaderItems.replace(row, null);
			}
		}
		return obj;
	}

	verticalHeaderItem(row: number): TableItem | null {
		// Returns the vertical header item for row.
		if ((row >= 0) && (row < this.vHeaderItems.size())) {
			return this.vHeaderItems.at(row);
		}
		return null;
	}

	verticalHeaderItemPrototype(): TableItem | null {
		return this.vHeaderItemProto;
	}
}

function tableItemCmpAsc(a: [TableItem, number], b: [TableItem, number]): number {
	return a[0].cmp(b[0]);
}

function tableItemCmpDesc(a: [TableItem, number], b: [TableItem, number]): number {
	return a[0].cmp(b[0]) * -1;
}

function tableItemGreaterThan(a: TableItem, b: TableItem): boolean {
	return b.lt(a);
}

function tableItemLessThan(a: TableItem, b: TableItem): boolean {
	return a.lt(b);
}

function isTableItemArgs<T extends TableItemOpts = TableItemOpts>(obj: any): obj is TableItemArgs<T> {
	try {
		if (obj && obj.hasOwnProperty('opts')) {
			return true;
		}
	} catch {
	}
	return false;
}

export function tableItemOpts<T extends TableItemOpts = TableItemOpts>(a?: TableItem | TableItemArgs<T> | string | Partial<T>, b?: Partial<T>): TableItemArgs<T> {
	if (isTableItemArgs<T>(a)) {
		return a;
	}
	let opts: Partial<T> | undefined = undefined;
	let other: TableItem | undefined = undefined;
	let text: string | undefined = undefined;
	if (typeof a === 'string') {
		text = a;
	} else if (a instanceof TableItem) {
		other = a;
	} else if (a !== undefined) {
		opts = a;
	}
	if (b !== undefined) {
		opts = b;
	}
	if (opts === undefined) {
		opts = {};
	}
	return {opts, other, text};
}
