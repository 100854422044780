import {
	Map as MapboxMap,
	GeoJSONSource,
} from 'mapbox-gl';

import {Feature} from './features/feature';
import {IMapboxDrawContext} from './mapboxdraw';
import {
	MapSourceId,
	DrawEventType,
} from './constants';

const interactionHandlerNames: Array<MapInteractionType> = [
	'boxZoom',
	'doubleClickZoom',
	'dragPan',
	'dragRotate',
	'keyboard',
	'scrollZoom',
	'touchZoomRotate',
];

export class Store {
	private animReqId: number;
	private changedFeatureIds: Set<string>;
	ctx!: IMapboxDrawContext; // Defined just after constructor by caller
	private deletedFeaturesToEmit: Array<Feature>;
	private emitSelectionChange: boolean;
	private featureIds: Set<string>;
	private features: Map<string, Feature>;
	private isDirty: boolean;
	private readonly mapInitialConfig: Map<MapInteractionType, boolean>;
	render: () => void;
	private selectedCoordinates: Array<CoordObj>;
	private readonly selectedFeatureIds: Set<string>;
	private sources: {hot: Array<FeatureInternalFeature>; cold: Array<FeatureInternalFeature>};

	constructor() {
		this.changedFeatureIds = new Set<string>();
		this.deletedFeaturesToEmit = [];
		this.emitSelectionChange = false;
		this.featureIds = new Set<string>();
		this.features = new Map<string, Feature>();
		this.isDirty = false;
		this.mapInitialConfig = new Map<MapInteractionType, boolean>();
		this.animReqId = 0;
		this.selectedCoordinates = [];
		this.selectedFeatureIds = new Set<string>();
		this.sources = {
			hot: [],
			cold: [],
		};
		this.render = () => {
			// Deduplicate requests to render and tie them to animation
			// frames.
			if (this.animReqId === 0) {
				this.animReqId = requestAnimationFrame(() => {
					this.animReqId = 0;
					this._render();
				});
			}
		};
	}

	add(feature: Feature): this {
		this.featureChanged(feature.id);
		this.features.set(feature.id, feature);
		this.featureIds.add(feature.id);
		return this;
	}

	clearChangedIds(): this {
		this.changedFeatureIds.clear();
		return this;
	}

	clearSelected(options?: InternalEventOpt): this {
		this.deselect(
			Array.from(this.selectedFeatureIds.values()),
			options);
		return this;
	}

	clearSelectedCoordinates(): this {
		if (this.selectedCoordinates.length > 0) {
			this.selectedCoordinates = [];
			this.emitSelectionChange = true;
		}
		return this;
	}

	createRenderBatch(): () => void {
		const holdRender = this.render;
		let numRenders = 0;
		this.render = () => numRenders++;
		return () => {
			this.render = holdRender;
			if (numRenders > 0) {
				this.render();
			}
		};
	}

	delete(featureIds: Array<string>, options?: InternalEventOpt): this {
		this.deselect(featureIds);
		featureIds.forEach(id => {
			if (this.featureIds.has(id)) {
				this.featureIds.delete(id);
				this.selectedFeatureIds.delete(id);
				if (!options || !options.silent) {
					const feat = this.features.get(id);
					if (feat && (this.deletedFeaturesToEmit.indexOf(feat) === -1)) {
						this.deletedFeaturesToEmit.push(feat);
					}
				}
				this.features.delete(id);
				this.isDirty = true;
			}
		});
		this.refreshSelectedCoordinates(options);
		return this;
	}

	deselect(featureIds: Array<string>, options?: InternalEventOpt): this {
		const notify = !Boolean(options && options.silent);
		featureIds.forEach(id => {
			if (this.selectedFeatureIds.has(id)) {
				this.selectedFeatureIds.delete(id);
				this.changedFeatureIds.add(id);
				if (notify) {
					this.emitSelectionChange = true;
				}
			}
		});
		this.refreshSelectedCoordinates(options);
		return this;
	}

	destroy(): void {
		this.animReqId = 0;
		this.changedFeatureIds.clear();
		this.deletedFeaturesToEmit = [];
		this.emitSelectionChange = false;
		this.featureIds.clear();
		this.features.clear();
		this.isDirty = false;
		this.mapInitialConfig.clear();
		this.selectedCoordinates = [];
		this.selectedFeatureIds.clear();
		this.sources = {
			hot: [],
			cold: [],
		};
	}

	featureChanged(featureId: string): this {
		this.changedFeatureIds.add(featureId);
		return this;
	}

	get(featureId: string): Feature | null {
		return this.features.get(featureId) || null;
	}

	getAll(): Array<Feature> {
		return Array.from(this.features.values());
	}

	getAllIds(): Array<string> {
		return Array.from(this.featureIds.values());
	}

	getChangedIds(): Array<string> {
		return Array.from(this.changedFeatureIds.values());
	}

	getInitialConfigValue(interaction: MapInteractionType): boolean | null {
		return this.mapInitialConfig.get(interaction) || null;
	}

	getSelected(): Array<Feature> {
		const rv: Array<Feature> = [];
		for (const id of this.selectedFeatureIds) {
			const obj = this.features.get(id);
			if (obj) {
				rv.push(obj);
			}
		}
		return rv;
	}

	getSelectedCoordinates(): Array<{coordinates: GeoJsonPosition}> {
		return this.selectedCoordinates.map(coord => {
			const feature = this.get(coord.feature_id);
			return {
				coordinates: feature ?
					feature.getCoordinate(coord.coord_path) :
					[],
			};
		});
	}

	getSelectedIds(): Array<string> {
		return Array.from(this.selectedFeatureIds.values());
	}

	isSelected(featureId?: string): boolean {
		return (featureId === undefined) ?
			false :
			this.selectedFeatureIds.has(featureId);
	}

	mapConfigValue(key: MapInteractionType): boolean | null {
		const rv = this.mapInitialConfig.get(key);
		return (rv === undefined) ?
			null :
			rv;
	}

	refreshSelectedCoordinates(options?: InternalEventOpt): void {
		const newSelectedCoordinates = this.selectedCoordinates
			.filter(point => this.selectedFeatureIds.has(point.feature_id));
		if ((this.selectedCoordinates.length !== newSelectedCoordinates.length) && (!options || !options.silent)) {
			this.emitSelectionChange = true;
		}
		this.selectedCoordinates = newSelectedCoordinates;
	}

	restoreMapConfig(map: MapboxMap): void {
		for (const [name, enabled] of this.mapInitialConfig) {
			if (enabled) {
				map[name].enable();
			} else {
				map[name].disable();
			}
		}
	}

	select(featureIds: Array<string>, options?: InternalEventOpt): this {
		featureIds.forEach(id => {
			if (!this.selectedFeatureIds.has(id)) {
				this.selectedFeatureIds.add(id);
				this.changedFeatureIds.add(id);
				if (!options || !options.silent) {
					this.emitSelectionChange = true;
				}
			}
		});
		return this;
	}

	setDirty(isDirty: boolean): this {
		this.isDirty = isDirty;
		return this;
	}

	setFeatureProperty(featureId: string, propertyName: string, value: any): void {
		const feat = this.get(featureId);
		if (feat) {
			feat.setProperty(propertyName, value);
			this.featureChanged(featureId);
		}
	}

	setMapConfigValue(key: MapInteractionType, value: boolean): void {
		this.mapInitialConfig.set(key, value);
	}

	setSelected(featureIds: Array<string>, options?: InternalEventOpt): this {
		// Deselect any features not in the new selection
		this.deselect(
			Array.from(this.selectedFeatureIds.values())
				.filter(id =>
					featureIds.indexOf(id) === -1),
			options);
		// Select any features in the new selection that were not already selected
		this.select(
			featureIds.filter(id => !this.selectedFeatureIds.has(id)),
			options);
		return this;
	}

	setSelectedCoordinates(coordinates: Array<CoordObj>): this {
		this.selectedCoordinates = coordinates;
		this.emitSelectionChange = true;
		return this;
	}

	storeMapConfig(map: MapboxMap): void {
		for (const key of interactionHandlerNames) {
			this.setMapConfigValue(key, map[key].isEnabled() || false);
		}
	}

	private _cleanUp(): void {
		this.isDirty = false;
		this.clearChangedIds();
	}

	private _render(): void {
		const map = this.ctx.map;
		if (!map || !map.getSource(MapSourceId.Hot)) {
			this._cleanUp();
			return;
		}
		const evts = this.ctx.events;
		const mode = evts.currentModeName();
		this.ctx.ui.queueMapClasses({mode});
		let newHotIds: Array<string> = [];
		let newColdIds;
		if (this.isDirty) {
			newColdIds = this.getAllIds();
		} else {
			newHotIds = this.getChangedIds().filter(id => this.get(id) !== null);
			newColdIds = this.sources.hot.filter(geojson => geojson.properties.id && newHotIds.indexOf(geojson.properties.id) === -1 && this.get(geojson.properties.id) !== null).map(geojson => geojson.properties.id);
		}
		this.sources.hot = [];
		const lastColdCount = this.sources.cold.length;
		this.sources.cold = this.isDirty ?
			[] :
			this.sources.cold.filter(feat => (newHotIds.indexOf(feat.properties.id || feat.properties.parent || '') === -1));
		const coldChanged = (lastColdCount !== this.sources.cold.length) || (newColdIds.length > 0);
		const renderFeature = (id: string, source: 'hot' | 'cold') => {
			const feature = this.get(id);
			if (feature) {
				const featureInternal = feature.internal(mode);
				evts.currentModeRender(
					featureInternal,
					feat => {
						this.sources[source].push(feat);
					});
			}
		};
		newHotIds.forEach(id => renderFeature(id, 'hot'));
		newColdIds.forEach(id => renderFeature(<string>id, 'cold'));
		if (coldChanged) {
			(<GeoJSONSource>map.getSource(MapSourceId.Cold)).setData({
				type: 'FeatureCollection',
				features: this.sources.cold,
			});
		}
		(<GeoJSONSource>map.getSource(MapSourceId.Hot)).setData({
			type: 'FeatureCollection',
			features: this.sources.hot,
		});
		if (this.emitSelectionChange) {
			map.fire(DrawEventType.SelectionChange, {
				features: this.getSelected().map(feature => feature.toGeoJSON()),
				points: this.getSelectedCoordinates().map(coordinate => ({
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'Point',
						coordinates: coordinate.coordinates,
					},
				})),
			});
			this.emitSelectionChange = false;
		}
		if (this.deletedFeaturesToEmit.length) {
			const geojsonToEmit = this.deletedFeaturesToEmit.map(feature => feature.toGeoJSON());
			this.deletedFeaturesToEmit = [];
			map.fire(DrawEventType.Delete, {
				features: geojsonToEmit,
			});
		}
		this._cleanUp();
	}
}
