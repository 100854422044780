import {svc} from '../../request';
import {OBJ} from '../../obj';
import {ElObj} from '../../elobj';
import {ActionIcon, ProjectTile} from './tile';
import {Layout} from './layout';
import {getLogger} from '../../logging';
import {list} from '../../tools';
import {isNumber} from '../../util';

const logger = getLogger('projectlistview');

@OBJ
export class TileLayout extends Layout {
	private slugTileMap: Map<string, ProjectTile> = new Map<string, ProjectTile>();
	private tiles: list<ProjectTile> = new list<ProjectTile>();

	destroy(): void {
		this.destroyTiles();
		super.destroy();
	}

	private destroyGridCells(): void {
		for (const obj of this.querySelectorAll('.mdc-layout-grid__cell')) {
			obj.destroy();
		}
	}

	private destroyTiles(): void {
		for (const obj of this.tiles) {
			obj.destroy();
		}
		this.tiles.clear();
		for (const obj of this.slugTileMap.values()) {
			obj.destroy();
		}
		this.slugTileMap.clear();
	}

	private async populate(projects: Iterable<IProject>): Promise<void> {
		this.destroyTiles();
		this.destroyGridCells();
		for (const project of projects) {
			const gridCell = new ElObj({
				classNames: 'mdc-layout-grid__cell',
				parent: this,
				tagName: 'div',
			});
			const tile = this.tile(project);
			if (project.absoluteListUrl) {
				tile.setDownloadUrl(project.absoluteListUrl);
			} else {
				tile.setPayButtonEnabled(project.paymentAllowed && project.paymentRequired);
			}
			tile.setUrl(project.absoluteUrl);
			gridCell.appendChild(tile);
			tile.setProgressVisible(true);
			this.tiles.append(tile);
			this.slugTileMap.set(project.slug, tile);
		}
		setTimeout(() => {
			this.syncMapImages();
			this.syncOrderQuantities();
		}, 3);
	}

	setDownloadUrl(slug: string, url: string | null): void {
		const tile = this.slugTileMap.get(slug);
		if (tile) {
			tile.setDownloadUrl(url);
		} else {
			logger.error('setDownloadUrl: No tile was found for "%s"', slug);
		}
	}

	setProgressVisible(slug: string, visible: boolean): void {
		for (const tile of this.tiles) {
			if (tile.project.slug === slug) {
				tile.setProgressVisible(visible);
				return;
			}
		}
		logger.warning('setProgressVisible: No matching object for given parameter');
	}

	async setProjects(projects: Iterable<IProject>): Promise<void> {
		await this.populate(projects);
	}

	private async syncMapImage(slug: string, tile: ProjectTile): Promise<void> {
		let url: string = '';
		const cfgs = await svc.map.list({
			image: 'sync',
			projectSlug: slug,
		});
		if (cfgs.length === 1) {
			url = cfgs[0].imageUrl;
		} else {
			logger.warning('syncMapImage: Got %s GeoMapConfiguration objects returned for %s. Expected exactly one.', cfgs.length, slug);
		}
		tile.setBackgroundImageUrl(url);
	}

	private async syncMapImages(): Promise<void> {
		for (const [slug, tile] of this.slugTileMap) {
			await this.syncMapImage(slug, tile);
			tile.setProgressVisible(false);
		}
	}

	private async syncOrderQuantities(): Promise<void> {
		for (const obj of this.tiles) {
			if (isNumber(obj.project.invoiceId)) {
				if (this.view) {
					obj.setInvoiceQuantity((await this.view.fetchInvoice(obj.project.invoiceId)).totalQuantity);
				} else {
					logger.warning('setProjects: No view reference.');
				}
			} else {
				logger.warning('populate: Got Project w/no invoice ID.');
			}
		}
	}

	private tile(project: IProject): ProjectTile {
		const tile = new ProjectTile(this, project);
		const axnIcon = new ActionIcon();
		tile.addActionIcon(axnIcon);
		return tile;
	}
}
