import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {getLogger} from '../../logging';
import {bind, stringIterableToStringArray} from '../../util';
import {svc} from '../../request';
import {ExistingPaymentMethodView, NewPaymentMethodView, PaymentMethodView} from './paymentmethodview';
import {ViewSwitcher} from './viewswitcher';
import {list} from '../../tools';

const logger = getLogger('paymentcreateview');

enum ViewType {
	ExistingPaymentMethod,
	NewPaymentMethod,
	MessageView,
	NoView,
}

interface LastErrorData {
	code: string;
	declineCode: string;
	message: string;
	type: string;
}

@OBJ
export class PaymentCreateView extends ElObj {
	static VendorScriptElementId: string = 'id_lb-payment-vendor-script';
	static VendorScriptUrl: string = 'https://js.stripe.com/v3/';

	private currentViewType: ViewType;
	private lastError: stripe.Error | null;
	private messageView: ElObj | null;
	private paymentMethods: list<IPaymentMethod>;
	private paymentMethodView: PaymentMethodView | null;
	private paymentVendor: stripe.Stripe | null;
	private paymentVendorScriptLoaded: boolean;
	private paymentVendorScriptLoading: boolean;
	private processing: boolean;
	private viewSwitcher: ViewSwitcher;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-payment-view',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.currentViewType = ViewType.NoView;
		this.lastError = null;
		this.messageView = null;
		this.paymentMethods = new list<IPaymentMethod>();
		this.paymentMethodView = null;
		this.paymentVendor = null;
		this.paymentVendorScriptLoaded = false;
		this.paymentVendorScriptLoading = false;
		this.processing = false;
		this.viewSwitcher = new ViewSwitcher();
		this.viewSwitcher.hide();
		Obj.connect(
			this.viewSwitcher, 'buttonClicked',
			this, 'viewSwitcherButtonClicked');
		this.appendChild(this.viewSwitcher);
		this.init();
	}

	@SIGNAL
	private currentViewTypeChanged(): void {
	}

	destroy(): void {
		this.currentViewType = ViewType.NewPaymentMethod;
		this.paymentMethods.clear();
		if (this.paymentMethodView) {
			this.paymentMethodView.destroy();
		}
		this.paymentMethodView = null;
		this.paymentVendor = null;
		this.paymentVendorScriptLoaded = false;
		this.paymentVendorScriptLoading = false;
		this.processing = false;
		Obj.disconnect(
			this.viewSwitcher, 'buttonClicked',
			this, 'viewSwitcherButtonClicked');
		this.viewSwitcher.destroy();
		super.destroy();
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'load':
				this.domLoadEvent(event);
				break;
			case 'error':
				this.domErrorEvent(event);
				break;
		}
	}

	private domErrorEvent(event: Event): void {
		this.paymentVendorScriptLoaded = false;
		this.paymentVendorScriptLoading = false;
		logger.error('Error during attempt to load payment handler', event);
	}

	private domLoadEvent(event: Event): void {
		this.paymentVendorScriptLoaded = true;
		this.paymentVendorScriptLoading = false;
		logger.debug('Payment handler loaded');
		this.paymentVendorLoaded();
	}

	private async fetchPaymentMethods(): Promise<Array<IPaymentMethod>> {
		return await svc.paymentMethod.list();
	}

	private async fetchPaymentHandlerPublicKey(): Promise<string> {
		return (await svc.account.get()).paymentHandlerPublicKey;
	}

	hasAcceptableInput(): boolean {
		if (this.paymentMethodView) {
			return this.paymentMethodView.hasAcceptableInput();
		}
		return false;
	}

	private init(): void {
		this.loadPaymentVendor();
	}

	lastErrorData(): LastErrorData | null {
		if (this.lastError) {
			return {
				code: this.lastError.code || '',
				declineCode: this.lastError.decline_code || '',
				message: this.lastError.message || '',
				type: this.lastError.type,
			};
		}
		return null;
	}

	private loadPaymentVendor(): void {
		if (this.paymentVendorScriptLoaded || this.paymentVendorScriptLoading) {
			return;
		}
		this.paymentVendorScriptLoading = true;
		logger.debug('Loading payment handler');
		const elem = document.createElement('script');
		elem.id = PaymentCreateView.VendorScriptElementId;
		elem.addEventListener('error', this.domEvent);
		elem.addEventListener('load', this.domEvent);
		elem.type = 'application/javascript';
		elem.src = PaymentCreateView.VendorScriptUrl;
		document.head.appendChild(elem);
	}

	@SIGNAL
	private paymentMethodChanged(): void {
	}

	private paymentMethodData(): {card: stripe.elements.Element;} | string | null {
		if (!this.paymentMethodView) {
			logger.error('paymentMethodData: No payment method view is defined.');
			return null;
		}
		switch (this.currentViewType) {
			case ViewType.NewPaymentMethod:
				if (!(this.paymentMethodView instanceof NewPaymentMethodView)) {
					logger.error('paymentMethodData: Invalid view object for current view.');
					return null;
				}
				return {card: this.paymentMethodView.paymentMethodInput};
			case ViewType.ExistingPaymentMethod:
				if (!(this.paymentMethodView instanceof ExistingPaymentMethodView)) {
					logger.error('paymentMethodData: Invalid view object for current view.');
					return null;
				}
				const paymentMethod = this.paymentMethodView.selectedPaymentMethod();
				return paymentMethod ?
					paymentMethod.id :
					null;
			default:
				logger.error('paymentMethodData: Invalid payment method state');
				return null;
		}
	}

	@SLOT
	private async paymentMethodRemovalRequest(paymentMethod: IPaymentMethod): Promise<void> {
		await this.removePaymentMethod(paymentMethod.id);
		this.paymentMethods = new list<IPaymentMethod>(await this.fetchPaymentMethods());
		// If there are no longer any payment methods, switch view to enable
		// user to enter a new payment method.
		if (this.paymentMethods.isEmpty()) {
			this.setCurrentViewType(ViewType.NewPaymentMethod);
		} else {
			if (this.paymentMethodView) {
				this.paymentMethodView.clear();
				for (const obj of this.paymentMethods) {
					this.paymentMethodView.addPaymentMethod(obj);
				}
			}
		}
	}

	private async paymentVendorLoaded(): Promise<void> {
		if (!this.paymentVendor) {
			const pubKey = await this.fetchPaymentHandlerPublicKey();
			this.paymentMethods = new list<IPaymentMethod>(await this.fetchPaymentMethods());
			this.paymentVendor = window.Stripe(pubKey);
			this.setCurrentViewType(this.paymentMethods.isEmpty() ?
				ViewType.NewPaymentMethod :
				ViewType.ExistingPaymentMethod);
		}
	}

	private async removePaymentMethod(paymentMethodId: string): Promise<void> {
		return await svc.paymentMethod.delete(paymentMethodId);
	}

	private savePaymentMethod(): boolean {
		if ((this.currentViewType === ViewType.NewPaymentMethod) && this.paymentMethodView && (this.paymentMethodView instanceof NewPaymentMethodView)) {
			return this.paymentMethodView.savePaymentMethod();
		}
		return false;
	}

	private setCurrentViewType(viewType: ViewType): void {
		if (viewType === this.currentViewType) {
			return;
		}
		if (this.paymentMethodView) {
			Obj.disconnect(
				this.paymentMethodView, 'paymentMethodChanged',
				this, 'paymentMethodChanged');
			Obj.disconnect(
				this.paymentMethodView, 'paymentMethodRemovalRequest',
				this, 'paymentMethodRemovalRequest');
			this.paymentMethodView.destroy();
		}
		this.paymentMethodView = null;
		if (this.messageView) {
			this.messageView.destroy();
		}
		this.messageView = null;
		this.currentViewType = viewType;
		let nextMessageView: ElObj | null = null;
		let nextPaymentMethodView: PaymentMethodView | null = null;
		let nextViewSwitcherText: string = '';
		switch (this.currentViewType) {
			case ViewType.ExistingPaymentMethod:
				nextViewSwitcherText = 'Enter a new payment method';
				nextPaymentMethodView = new ExistingPaymentMethodView();
				for (const obj of this.paymentMethods) {
					nextPaymentMethodView.addPaymentMethod(obj);
				}
				this.viewSwitcher.show();
				break;
			case ViewType.NewPaymentMethod:
				this.viewSwitcher.setVisible(!this.paymentMethods.isEmpty());
				nextViewSwitcherText = 'Choose an existing payment method';
				if (this.paymentVendor) {
					nextPaymentMethodView = new NewPaymentMethodView({
						paymentVendor: this.paymentVendor,
					});
				} else {
					logger.error('No payment vendor initialized.');
				}
				break;
			case ViewType.MessageView:
				nextMessageView = new ElObj({
					classNames: 'lb-payment-message-view',
					tagName: 'div',
				});
				break;
			case ViewType.NoView:
				logger.warning('setCurrentViewType: Got NoView type.');
				break;
		}
		if (nextPaymentMethodView && nextMessageView) {
			logger.warning('setCurrentViewType: Both message and payment method views have been instantiated.');
		}
		this.messageView = nextMessageView;
		this.paymentMethodView = nextPaymentMethodView;
		this.viewSwitcher.setText(nextViewSwitcherText);
		let toInsert: ElObj | null = null;
		if (this.paymentMethodView) {
			toInsert = this.paymentMethodView;
			Obj.connect(
				this.paymentMethodView, 'paymentMethodChanged',
				this, 'paymentMethodChanged');
			Obj.connect(
				this.paymentMethodView, 'paymentMethodRemovalRequest',
				this, 'paymentMethodRemovalRequest');
		} else if (this.messageView) {
			toInsert = this.messageView;
		}
		if (toInsert) {
			this.viewSwitcher.insertAdjacentElement(
				'beforebegin',
				toInsert);
		}
		this.currentViewTypeChanged();
	}

	setMessageText(text: string): void {
		if (this.messageView) {
			this.messageView.setText(text);
		} else {
			logger.error('setMessageText: Called with no message view instantiated.');
		}
	}

	showMessage(message: string): void {
		this.setCurrentViewType(ViewType.MessageView);
		this.setMessageText(message);
	}

	async submitPayment(clientSecret: string): Promise<boolean> {
		this.lastError = null;
		if (this.paymentVendor) {
			const paymentMethodData = this.paymentMethodData();
			if (paymentMethodData) {
				const data: stripe.ConfirmCardPaymentData = {
					payment_method: paymentMethodData,
				};
				if (this.savePaymentMethod()) {
					data.setup_future_usage = 'off_session';
				}
				const resp = await this.paymentVendor.confirmCardPayment(
					clientSecret,
					data);
				if (resp.error) {
					this.lastError = resp.error;
					return false;
				} else {
					return true;
				}
			} else {
				logger.error('submitPayment: Invalid payment method data.');
			}
		} else {
			logger.error('submitPayment: Payment handler is not defined.');
		}
		return false;
	}

	@SLOT
	private viewSwitcherButtonClicked(): void {
		let nextView: ViewType = ViewType.NewPaymentMethod;
		switch (this.currentViewType) {
			case ViewType.NewPaymentMethod:
				nextView = ViewType.ExistingPaymentMethod;
				break;
			case ViewType.ExistingPaymentMethod:
				nextView = ViewType.NewPaymentMethod;
				break;
		}
		this.setCurrentViewType(nextView);
	}
}
