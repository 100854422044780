import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {List, ListItem} from '../../ui/list';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {pixelString, stringIterableToStringArray} from '../../util';
import {CaseSensitivity, MatchFlag} from '../../constants';
import {getLogger} from '../../logging';
import {list} from '../../tools';

const logger = getLogger('projectdetailview');

export interface AutoCompleteOpts extends ElObjOpts {
	items: Iterable<string>;
}

// @OBJ
// export class AutoComplete extends ElObj {
// 	protected completer: Completer;
// 	protected items: list<AutoCompleteItem>;
// 	protected list: List;
//
// 	constructor(opts: Partial<AutoCompleteOpts> | null, tagName: TagName, parent?: ElObj | null);
// 	constructor(opts: Partial<AutoCompleteOpts> | null, root: Element | null, parent?: ElObj | null);
// 	constructor(tagName: TagName, parent?: ElObj | null);
// 	constructor(root: Element | null, parent?: ElObj | null);
// 	constructor(opts: Partial<AutoCompleteOpts> | null, tagName?: TagName);
// 	constructor(opts: Partial<AutoCompleteOpts> | null, root?: Element | null);
// 	constructor(opts: Partial<AutoCompleteOpts>, parent?: ElObj | null);
// 	constructor(opts?: Partial<AutoCompleteOpts>);
// 	constructor(root?: Element | null);
// 	constructor(tagName?: TagName);
// 	constructor(parent?: ElObj | null);
// 	constructor(a?: Partial<AutoCompleteOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
// 		const opts = elObjOpts<AutoCompleteOpts>(a, b, c);
// 		const classNames = opts.classNames ?
// 			stringIterableToStringArray(opts.classNames) :
// 			[];
// 		opts.classNames = [
// 			'lb-autocomplete-result-container',
// 			...classNames,
// 		];
// 		opts.tagName = 'div';
// 		super(opts);
// 		this.hide();
// 		this.completer = new Completer(opts.items);
// 		this.completer.setCaseSensitivity(CaseSensitivity.CaseInsensitive);
// 		this.completer.setFilterMode(MatchFlag.MatchContains);
// 		this.items = new list<AutoCompleteItem>();
// 		this.list = new List({twoLine: true});
// 		this.appendChild(this.list);
// 		Obj.connect(
// 			this.list, 'itemActivated',
// 			this, 'listItemActivated');
// 	}
//
// 	@SIGNAL
// 	activated(completion: string, index: number): void {
// 	}
//
// 	completionCount(): number {
// 		return this.completer.completionCount();
// 	}
//
// 	completionPrefix(): string {
// 		return this.completer.completionPrefix();
// 	}
//
// 	currentRow(): number {
// 		return this.list.currentRow();
// 	}
//
// 	currentRowCompletion(): string {
// 		const currRow = this.currentRow();
// 		if ((currRow >= 0) && (currRow < this.completer.completionCount())) {
// 			for (let i = 0; this.completer.setCurrentRow(i); ++i) {
// 				if (i === currRow) {
// 					return this.completer.currentCompletion();
// 				}
// 			}
// 		}
// 		return '';
// 	}
//
// 	destroy(): void {
// 		this.items.clear();
// 		this.list.destroy();
// 		this.completer.destroy();
// 		super.destroy();
// 	}
//
// 	isOpen(): boolean {
// 		return this.isVisible()
// 			&& Boolean(this.parentEl())
// 			&& (this.completer.completionCount() > 0);
// 	}
//
// 	@SLOT
// 	protected listItemActivated(item: ListItem): void {
// 		const index = this.list.index(item);
// 		if (index >= 0) {
// 			for (let i = 0; this.completer.setCurrentRow(i); ++i) {
// 				if (i === index) {
// 					this.activated(
// 						this.completer.currentCompletion(),
// 						this.completer.currentIndex());
// 					return;
// 				}
// 			}
// 		} else {
// 			logger.warning('listItemActivated: List returned invalid index for item.');
// 		}
// 	}
//
// 	setItems(items: Iterable<AutoCompleteItem>): void {
// 		this.items.clear();
// 		this.list.clear();
// 		this.items = new list<AutoCompleteItem>(items);
// 		this.completer.setItems(this.items.map(item => item.text));
// 	}
//
// 	setPrefix(prefix: string): void {
// 		this.list.clear();
// 		if (prefix.length > 0) {
// 			this.completer.setCompletionPrefix(prefix);
// 			const count = this.completer.completionCount();
// 			if (count > 0) {
// 				this.show();
// 				for (let i = 0; this.completer.setCurrentRow(i); ++i) {
// 					const text = this.completer.currentCompletion();
// 					const index = this.completer.currentIndex();
// 					let secondaryText: string | undefined = undefined;
// 					if (index >= 0 && index < this.items.size()) {
// 						const item = this.items.at(index);
// 						secondaryText = item.label;
// 					}
// 					this.list.addItem({text, secondaryText});
// 				}
// 			} else {
// 				this.hide();
// 			}
// 		} else {
// 			this.hide();
// 		}
// 		this.updateHeight();
// 	}
//
// 	updateHeight(): void {
// 		let height: number = 0;
// 		const listElem = this.list.element();
// 		if (listElem) {
// 			if (this.list.count() > 0) {
// 				const item = this.list.item(0);
// 				if (item) {
// 					height = Math.min(336, item.rect().height * this.list.count());
// 				} else {
// 					logger.error('updateHeight: Non-empty list does not return first item.');
// 				}
// 			}
// 			const listStyle = window.getComputedStyle(listElem);
// 			const listHeightStr = listStyle.height.trim().toLowerCase();
// 			const heightWithoutPadding = extractStyleNumber(listHeightStr);
// 			const heightWithPadding = this.list.rect().height;
// 			const paddingHeight = heightWithPadding - heightWithoutPadding;
// 			if (paddingHeight > 0) {
// 				height += paddingHeight;
// 			}
// 		}
// 		this.setStyleProperty('height', pixelString(height));
// 	}
// }

@OBJ
export class AutoComplete extends ElObj {
	protected items: list<IAutoCompleteItem>;
	protected list: List;

	constructor(opts: Partial<AutoCompleteOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<AutoCompleteOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<AutoCompleteOpts> | null, tagName?: TagName);
	constructor(opts: Partial<AutoCompleteOpts> | null, root?: Element | null);
	constructor(opts: Partial<AutoCompleteOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<AutoCompleteOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<AutoCompleteOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<AutoCompleteOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-autocomplete-result-container',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.hide();
		this.items = new list<IAutoCompleteItem>();
		this.list = new List({twoLine: true});
		this.appendChild(this.list);
		Obj.connect(
			this.list, 'itemActivated',
			this, 'listItemActivated');
	}

	@SIGNAL
	activated(index: number): void {
	}

	destroy(): void {
		this.items.clear();
		this.list.destroy();
		super.destroy();
	}

	isOpen(): boolean {
		return this.isVisible() && Boolean(this.parentEl());
	}

	@SLOT
	protected listItemActivated(item: ListItem): void {
		const index = this.list.index(item);
		if (index >= 0) {
			this.list.currentRow();
			for (let i = 0; i < this.items.size(); ++i) {
				if (i === index) {
					this.activated(index);
					return;
				}
			}
		} else {
			logger.warning('listItemActivated: List returned invalid index for item.');
		}
	}

	setItems(items: Iterable<IAutoCompleteItem>): void {
		this.items.clear();
		this.list.clear();
		this.items = new list<IAutoCompleteItem>(items);
		if (this.items.isEmpty()) {
			this.hide();
		} else {
			this.show();
			for (const item of this.items) {
				this.list.addItem({
					secondaryText: item.label,
					text: item.text,
				});
			}
		}
		this.updateHeight();
	}

	updateHeight(): void {
		let height: number = 0;
		const listElem = this.list.element();
		if (listElem) {
			if (this.list.count() > 0) {
				const item = this.list.item(0);
				if (item) {
					height = Math.min(336, item.rect().height * this.list.count());
				} else {
					logger.error('updateHeight: Non-empty list does not return first item.');
				}
			}
			const listStyle = window.getComputedStyle(listElem);
			const listHeightStr = listStyle.height.trim().toLowerCase();
			const heightWithoutPadding = extractStyleNumber(listHeightStr);
			const heightWithPadding = this.list.rect().height;
			const paddingHeight = heightWithPadding - heightWithoutPadding;
			if (paddingHeight > 0) {
				height += paddingHeight;
			}
		}
		this.setStyleProperty('height', pixelString(height));
	}
}

function extractStyleNumber(s: string): number {
	s = s.replace(/px$/, '');
	return (s.indexOf('.') >= 0) ?
		Number.parseFloat(s) :
		Number.parseInt(s);
}

class Match {
	index: number;
	value: string;

	constructor(index: number, value: string) {
		this.index = index;
		this.value = value;
	}
}

class Completer {
	private candidates: list<string>;
	private caseSen: CaseSensitivity;
	private currRow: number;
	private matches: list<Match>;
	private mode: MatchFlag;
	private prefix: string;

	constructor(items?: Iterable<string>) {
		this.candidates = new list<string>(items);
		this.caseSen = CaseSensitivity.CaseInsensitive;
		this.currRow = -1;
		this.matches = new list<Match>();
		this.mode = MatchFlag.MatchStartsWith;
		this.prefix = '';
	}

	caseSensitivity(): CaseSensitivity {
		return this.caseSen;
	}

	completionCount(): number {
		return this.matches.size();
	}

	completionPrefix(): string {
		return this.prefix;
	}

	currentCompletion(): string {
		if (this.currRow >= 0) {
			return this.matches.at(this.currRow).value;
		}
		return '';
	}

	currentIndex(): number {
		if (this.currRow >= 0) {
			return this.matches.at(this.currRow).index;
		}
		return -1;
	}

	currentRow(): number {
		return this.currRow;
	}

	destroy(): void {
		this.invalidate();
		this.candidates.clear();
		this.caseSen = CaseSensitivity.CaseSensitive;
		this.mode = MatchFlag.MatchStartsWith;
		this.prefix = '';
	}

	private filter(item: string): void {
		this.invalidate();
		if (this.caseSen === CaseSensitivity.CaseInsensitive) {
			item = item.toLocaleLowerCase();
		}
		for (let i = 0; i < this.candidates.size(); ++i) {
			const candidate = this.candidates.at(i);
			const s = (this.caseSen === CaseSensitivity.CaseInsensitive) ?
				candidate.toLocaleLowerCase() :
				candidate;
			let matched: boolean = false;
			switch (this.mode) {
				case MatchFlag.MatchStartsWith:
					matched = s.startsWith(item);
					break;
				case MatchFlag.MatchContains:
					matched = s.indexOf(item) >= 0;
					break;
				case MatchFlag.MatchEndsWith:
					matched = s.endsWith(item);
					break;
			}
			if (matched) {
				this.matches.append(new Match(i, candidate));
			}
		}
		this.currRow = this.matches.isEmpty() ?
			-1 :
			0;
	}

	filterMode(): MatchFlag {
		return this.mode;
	}

	private invalidate(): void {
		this.currRow = -1;
		this.matches = new list<Match>();
	}

	items(): list<string> {
		return this.candidates;
	}

	setCaseSensitivity(cs: CaseSensitivity): void {
		if (cs === this.caseSen) {
			return;
		}
		this.caseSen = cs;
		this.invalidate();
	}

	setCompletionPrefix(prefix: string): void {
		this.prefix = prefix;
		this.filter(this.prefix);
	}

	setCurrentRow(row: number): boolean {
		const matchCount = this.matches.size();
		if ((row < 0) || (matchCount < 1) || (row >= matchCount)) {
			return false;
		}
		this.currRow = row;
		return true;
	}

	setFilterMode(mode: MatchFlag): void {
		if (mode === this.mode) {
			return;
		}
		this.mode = mode;
		this.invalidate();
	}

	setItems(items: Iterable<string>): void {
		this.invalidate();
		this.candidates = new list<string>(items);
	}
}
