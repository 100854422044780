import {Layer} from '../../../ui/map';
import {Id as SourceId} from './source';
import {PARCEL_LAYER_MIN_ZOOM, PARCEL_LINE_LAYER_MIN_ZOOM, PARCEL_SOURCE_LAYER_ID} from '../../../constants';

export enum Color {
	DoNotMailCentroidDark = '#FF0000',
	DoNotMailFillDark = '#FF0000',
	FilterFillDark = '#404040',
	FilterFillOutlineDark = '#404040',
	FilterStrokeDark = '#404040',
	FilterSymbolDark = 'hsl(230, 29%, 35%)',
	FilterSymbolHaloDark = 'hsl(0, 0%, 100%)',
	ParcelFillDark = '#6200EE',
	ParcelFillLight = '#2D65FF',
	ParcelLineDark = '#FFFFFF',
}

export enum Id {
	DoNotMailParcelCentroid = 'id_lb-do-not-mail-parcel-centroid-layer',
	DoNotMailParcelFill = 'id_lb-do-not-mail-parcel-fill-layer',
	FilterFill = 'id_lb-filter-fill-layer',
	FilterStroke = 'id_lb-filter-stroke-layer',
	FilterSymbol = 'id_lb-filter-symbol-layer',
	ParcelFill = 'id_lb-parcel-fill-layer',
	ParcelLine = 'id_lb-parcel-line-layer',
}

export const layers: Array<Layer> = [
	{
		id: Id.FilterFill,
		type: 'fill',
		paint: {
			'fill-color': Color.FilterFillDark,
			'fill-outline-color': Color.FilterFillOutlineDark,
			'fill-opacity': 0.1,
		},
		source: SourceId.Filter,
	},
	{
		id: Id.FilterStroke,
		type: 'line',
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': Color.FilterStrokeDark,
			'line-width': 2,
		},
		source: SourceId.Filter,
	},
	{
		id: Id.FilterSymbol,
		type: 'symbol',
		layout: {
			'text-field': ['get', 'label'],
			'text-padding': 3,
			'text-size': 18,
		},
		paint: {
			'text-color': Color.FilterSymbolDark,
			'text-halo-blur': 0.5,
			'text-halo-color': Color.FilterSymbolHaloDark,
			'text-halo-width': 1,
		},
		source: SourceId.Filter,
	},
	{
		id: Id.ParcelFill,
		layout: {
			visibility: 'none',
		},
		minzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'fill-color': Color.ParcelFillDark,
			'fill-opacity': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'hover',
					],
					false,
				],
				0.65,
				0.25,
			],
		},
		source: SourceId.Parcel,
		'source-layer': PARCEL_SOURCE_LAYER_ID,
		type: 'fill',
	},
	{
		id: Id.ParcelLine,
		layout: {
			visibility: 'none',
		},
		minzoom: PARCEL_LINE_LAYER_MIN_ZOOM,
		paint: {
			'line-color': Color.ParcelLineDark,
			'line-width': 0.4,
		},
		source: SourceId.Parcel,
		'source-layer': PARCEL_SOURCE_LAYER_ID,
		type: 'line',
	},
	{
		id: Id.DoNotMailParcelCentroid,
		maxzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'circle-color': Color.DoNotMailCentroidDark,
			'circle-opacity': 1,
		},
		source: SourceId.DoNotMailParcelCentroid,
		type: 'circle',
	},
	{
		id: Id.DoNotMailParcelFill,
		minzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'fill-color': Color.DoNotMailFillDark,
			'fill-opacity': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'hover',
					],
					false,
				],
				0.65,
				0.45,
			],
		},
		source: SourceId.DoNotMailParcel,
		type: 'fill',
	},
];
