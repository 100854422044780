import {Obj, OBJ, SIGNAL, SLOT} from '../../../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../../../elobj';
import {Expression} from './expression';
import {CheckState} from '../../../../constants';
import {ToolButton} from '../../../../ui/toolbutton';
import {FilterEnabledToggle} from '../filterlist';
import {getLogger} from '../../../../logging';
import {Menu} from '../../../../ui/menu';
import {list, Point} from '../../../../tools';

const logger = getLogger('projectdetailview.filter');

interface FilterOpts extends ElObjOpts {
	expressionToken: IExpressionToken;
}

@OBJ
export class Filter extends ElObj {
	private disabled: boolean;
	private enabledToggle: FilterEnabledToggle | null;
	private expr: Expression | null;
	private expressionToken: IExpressionToken;
	filterId: number;
	private moreOptsButton: ToolButton | null;
	private moreOptsMenu: Menu | null;
	topLevel: boolean;

	constructor(opts: Partial<FilterOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<FilterOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<FilterOpts> | null, tagName?: TagName);
	constructor(opts: Partial<FilterOpts> | null, root?: Element | null);
	constructor(opts: Partial<FilterOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<FilterOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<FilterOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<FilterOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.disabled = false;
		this.enabledToggle = null;
		this.expr = null;
		this.expressionToken = opts.expressionToken || {attributes: [], operators: []};
		this.filterId = -this.instanceNumber;
		this.moreOptsButton = null;
		this.moreOptsMenu = null;
		this.topLevel = false;
	}

	private choicesForFieldValue(fieldValue: string): Array<IExpressionAttributeChoice> {
		for (const obj of this.expressionToken.attributes) {
			if (obj.value === fieldValue) {
				return obj.choices;
			}
		}
		return [];
	}

	private choiceLabelForChoiceValue(choiceValue: string, choices: Array<IExpressionAttributeChoice>): string {
		for (const obj of choices) {
			if (obj.value === choiceValue) {
				return obj.label;
			}
		}
		return '';
	}

	@SIGNAL
	private deleteButtonClicked(): void {
	}

	destroy(): void {
		this.destroyMoreOpts();
		this.destroyExpr();
		this.destroyEnabledToggle();
		this.expressionToken = {attributes: [], operators: []};
		this.filterId = -this.instanceNumber;
		this.topLevel = false;
		super.destroy();
	}

	private destroyEnabledToggle(): void {
		if (this.enabledToggle) {
			Obj.disconnect(
				this.enabledToggle, 'clicked',
				this, 'enabledToggleClicked');
			this.enabledToggle.destroy();
		}
		this.enabledToggle = null;
	}

	private destroyExpr(): void {
		if (this.expr) {
			Obj.disconnect(
				this.expr, 'currentFieldChanged',
				this, 'exprCurrentFieldChanged');
			Obj.disconnect(
				this.expr, 'currentOperandChanged',
				this, 'exprCurrentOperandChanged');
			Obj.disconnect(
				this.expr, 'currentOperatorChanged',
				this, 'exprCurrentOperatorChanged');
			Obj.disconnect(
				this.expr, 'operandTextInputReturnPressed',
				this, 'exprOperandTextInputReturnPressed');
			this.expr.destroy();
		}
		this.expr = null;
	}

	private destroyMoreOpts(): void {
		this.destroyMoreOptsMenu();
		if (this.moreOptsButton) {
			this.moreOptsButton.destroy();
		}
		this.moreOptsButton = null;
	}

	private destroyMoreOptsMenu(): void {
		if (this.moreOptsMenu) {
			Obj.disconnect(
				this.moreOptsMenu, 'closed',
				this, 'moreOptsMenuClosed');
			Obj.disconnect(
				this.moreOptsMenu, 'selectionChanged',
				this, 'moreOptsMenuSelectionChanged');
			this.moreOptsMenu.destroy();
		}
		this.moreOptsMenu = null;
	}

	@SIGNAL
	private enabledChanged(enabledToggleIsChecked: boolean): void {
	}

	enabledToggleCheckState(): CheckState {
		return this.enabledToggle ?
			this.enabledToggle.checkState() :
			CheckState.Unchecked;
	}

	@SLOT
	private enabledToggleClicked(): void {
		if (this.enabledToggle) {
			this.enabledChanged(this.enabledToggle.isChecked());
		} else {
			logger.error('enabledToggleClicked: Toggle not defined.');
		}
	}

	@SLOT
	private exprCurrentFieldChanged(value: string): void {
		if (this.expr) {
			const ops = operatorsForFieldLabel(value, this.expressionToken);
			this.expr.setOperatorChoices(ops.map(op => op.label));
			const choices = choicesForFieldLabel(value, this.expressionToken);
			if (choices.length > 0) {
				this.expr.setOperandControlType('choices');
				this.expr.setOperandChoices(choices.map(x => x.label));
			} else {
				this.expr.setOperandControlType('text');
			}
			this.expressionChanged();
		}
	}

	@SLOT
	private exprCurrentOperandChanged(value: string): void {
		if (this.expr && this.expr.currentOperandControlType() === 'choices') {
			this.expressionChanged();
		}
	}

	@SLOT
	private exprCurrentOperatorChanged(value: string): void {
		this.expressionChanged();
	}

	@SIGNAL
	private expressionChanged(): void {
	}

	expressionData(): IExpression | null {
		if (this.expr) {
			const fieldLabel = this.expr.currentField();
			const lhs = valueForLabel(fieldLabel, this.expressionToken.attributes);
			const operandLabel = this.expr.currentOperand();
			const rhs = (this.expr.currentOperandControlType() === 'text') ?
				operandLabel :
				valueForLabel(
					operandLabel,
					choicesForFieldLabel(fieldLabel, this.expressionToken));
			return {
				id: this.expr.expressionId,
				lhs,
				operator: valueForLabel(this.expr.currentOperator(), this.expressionToken.operators),
				rhs,
			};
		}
		return null;
	}

	@SIGNAL
	private exprOperandTextInputReturnPressed(): void {
	}

	private fieldLabelForFieldValue(fieldValue: string): string {
		for (const obj of this.expressionToken.attributes) {
			if (obj.value === fieldValue) {
				return obj.label;
			}
		}
		return '';
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	@SLOT
	private moreButtonOptsClicked(checked: boolean, point: Point): void {
		this.openMoreOptsMenu(point);
	}

	@SLOT
	private moreOptsMenuClosed(): void {
		this.destroyMoreOptsMenu();
	}

	@SLOT
	private moreOptsMenuSelectionChanged(index: number): void {
		if (index === 0) {
			this.deleteButtonClicked();
		} else {
			logger.warning('moreOptsMenuSelectionChanged: Got invalid index');
		}
	}

	private openMoreOptsMenu(point: Point): void {
		this.destroyMoreOptsMenu();
		this.moreOptsMenu = new Menu();
		Obj.connect(
			this.moreOptsMenu, 'closed',
			this, 'moreOptsMenuClosed');
		Obj.connect(
			this.moreOptsMenu, 'selectionChanged',
			this, 'moreOptsMenuSelectionChanged');
		this.moreOptsMenu.addItem('Delete');
		this.moreOptsMenu.open(point);
	}

	private operatorLabelForOperatorValue(operatorValue: string): string {
		for (const obj of this.expressionToken.operators) {
			if (obj.value === operatorValue) {
				return obj.label;
			}
		}
		return '';
	}

	private operatorsForFieldValue(fieldValue: string): Array<IExpressionOperator> {
		const rv: Array<IExpressionOperator> = [];
		for (const obj of this.expressionToken.attributes) {
			if (obj.value === fieldValue) {
				const opValues = obj.operators;
				for (const op of this.expressionToken.operators) {
					if (opValues.indexOf(op.value) >= 0) {
						rv.push(op);
					}
					if (rv.length === opValues.length) {
						// Found everything we need.
						break;
					}
				}
				break;
			}
		}
		return rv;
	}

	setControlsEnabled(enabled: boolean): void {
		if (!enabled && this.moreOptsMenu) {
			this.destroyMoreOptsMenu();
		}
		if (this.enabledToggle) {
			this.enabledToggle.setDisabled(!enabled);
		}
		if (this.expr) {
			this.expr.setControlsEnabled(enabled);
		}
		if (this.moreOptsButton) {
			this.moreOptsButton.setDisabled(!enabled);
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		if (this.disabled) {
			this.destroyMoreOptsMenu();
		}
		this.enabledToggle && this.enabledToggle.setDisabled(this.disabled);
		this.expr && this.expr.setDisabled(this.disabled);
		this.moreOptsButton && this.moreOptsButton.setDisabled(this.disabled);
		if (this.disabled) {
			if (this.enabledToggle) {
				Obj.disconnect(
					this.enabledToggle, 'clicked',
					this, 'enabledToggleClicked');
			}
			if (this.expr) {
				Obj.disconnect(
					this.expr, 'currentFieldChanged',
					this, 'exprCurrentFieldChanged');
				Obj.disconnect(
					this.expr, 'currentOperandChanged',
					this, 'exprCurrentOperandChanged');
				Obj.disconnect(
					this.expr, 'currentOperatorChanged',
					this, 'exprCurrentOperatorChanged');
				Obj.disconnect(
					this.expr, 'operandTextInputReturnPressed',
					this, 'exprOperandTextInputReturnPressed');
			}
			if (this.moreOptsButton) {
				Obj.disconnect(
					this.moreOptsButton, 'clicked',
					this, 'moreButtonOptsClicked');
			}
		} else {
			if (this.enabledToggle) {
				Obj.connect(
					this.enabledToggle, 'clicked',
					this, 'enabledToggleClicked');
			}
			if (this.expr) {
				Obj.connect(
					this.expr, 'currentFieldChanged',
					this, 'exprCurrentFieldChanged');
				Obj.connect(
					this.expr, 'currentOperandChanged',
					this, 'exprCurrentOperandChanged');
				Obj.connect(
					this.expr, 'currentOperatorChanged',
					this, 'exprCurrentOperatorChanged');
				Obj.connect(
					this.expr, 'operandTextInputReturnPressed',
					this, 'exprOperandTextInputReturnPressed');
			}
			if (this.moreOptsButton) {
				Obj.connect(
					this.moreOptsButton, 'clicked',
					this, 'moreButtonOptsClicked');
			}
		}
	}

	setEnabledToggleCheckState(state: CheckState): void {
		if (this.enabledToggle) {
			this.enabledToggle.setCheckState(state);
		}
	}

	setEnabledToggleVisible(visible: boolean): void {
		if (!visible) {
			this.destroyEnabledToggle();
			return;
		}
		if (!this.enabledToggle) {
			const enabledToggleContainer = new ElObj({
				styles: [
					['display', 'flex'],
					['align-items', 'center'],
					['justify-content', 'center'],
					['padding', '0 7px'],
				],
				tagName: 'div',
			});
			this.enabledToggle = new FilterEnabledToggle(
				false,
				{
					classNames: [
						'lb-filterbox-header-toolbutton',
					],
					parent: enabledToggleContainer,
					title: 'Enable/disable this filter',
				});
			Obj.connect(
				this.enabledToggle, 'clicked',
				this, 'enabledToggleClicked');
			this.insertChild(0, enabledToggleContainer);
		}
	}

	setExpressionData(data: IExpression | null): void {
		if (data) {
			if (this.expr) {
				this.expr.expressionId = data.id;
				const fieldValue = data.lhs;
				const fieldLabel = this.fieldLabelForFieldValue(fieldValue);
				this.expr.setCurrentField(fieldLabel);
				const operatorValue = data.operator;
				const operatorLabel = this.operatorLabelForOperatorValue(operatorValue);
				this.expr.setCurrentOperator(operatorLabel);
				const operandValue = data.rhs;
				let currentOperand: string;
				const fieldChoices = this.choicesForFieldValue(fieldValue);
				if (fieldChoices.length > 0) {
					this.expr.setOperandControlType('choices');
					const choiceLabels = fieldChoices.map(x => x.label);
					this.expr.setOperandChoices(choiceLabels);
					currentOperand = this.choiceLabelForChoiceValue(operandValue, fieldChoices);
				} else {
					this.expr.setOperandControlType('text');
					currentOperand = operandValue;
				}
				this.expr.setCurrentOperand(currentOperand);
			}
		} else {
			this.destroyExpr();
		}
	}

	setExpressionVisible(visible: boolean): void {
		if (!visible) {
			this.destroyExpr();
			return;
		}
		if (!this.expr) {
			this.expr = new Expression();
			Obj.connect(
				this.expr, 'currentFieldChanged',
				this, 'exprCurrentFieldChanged');
			Obj.connect(
				this.expr, 'currentOperandChanged',
				this, 'exprCurrentOperandChanged');
			Obj.connect(
				this.expr, 'currentOperatorChanged',
				this, 'exprCurrentOperatorChanged');
			Obj.connect(
				this.expr, 'operandTextInputReturnPressed',
				this, 'exprOperandTextInputReturnPressed');
			this.expr.setFieldChoices(this.expressionToken.attributes.map(obj => obj.label));
			this.expr.setOperatorChoices(operatorsForFieldLabel('---', this.expressionToken).map(obj => obj.label));
			this.insertChild(1, this.expr);
		}
	}

	setMoreOptionsButtonVisible(visible: boolean): void {
		if (!visible) {
			this.destroyMoreOpts();
			return;
		}
		if (!this.moreOptsButton) {
			this.moreOptsButton = new ToolButton({
				classNames: [
					'lb-filterbox-header-toolbutton',
				],
				icon: 'more_vert',
				title: 'More options',
			});
			Obj.connect(
				this.moreOptsButton, 'clicked',
				this, 'moreButtonOptsClicked');
			this.insertChild(2, this.moreOptsButton);
		}
	}
}

function labelForValue<T extends {label: string; value: string;}>(value: string, choices: Iterable<T>): string {
	for (const obj of choices) {
		if (obj.value === value) {
			return obj.label;
		}
	}
	return '';
}

function choicesForFieldLabel(fieldLabel: string, exprToken: IExpressionToken): Array<IExpressionAttributeChoice> {
	for (const attr of exprToken.attributes) {
		if (attr.label === fieldLabel) {
			return attr.choices;
		}
	}
	return [];
}

function operatorsForFieldLabel(fieldLabel: string, exprToken: IExpressionToken): list<IExpressionOperator> {
	const rv = new list<IExpressionOperator>();
	for (const attr of exprToken.attributes) {
		if (attr.label === fieldLabel) {
			const opValues = attr.operators;
			for (const op of exprToken.operators) {
				if (opValues.indexOf(op.value) >= 0) {
					rv.append(op);
				}
				if (rv.size() === opValues.length) {
					// Found everything we need.
					break;
				}
			}
			break;
		}
	}
	return rv;
}

function valueForLabel<T extends {label: string; value: string;}>(label: string, choices: Iterable<T>): string {
	for (const obj of choices) {
		if (obj.label === label) {
			return obj.value;
		}
	}
	return '';
}
