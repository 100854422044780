import {datetime} from './datetime';

export enum LogLevel {
	CRITICAL = 50,
	ERROR = 40,
	WARNING = 30,
	INFO = 20,
	DEBUG = 10,
	NOTSET = 0,
}

// Slightly simpler access to log levels
export const CRITICAL = LogLevel.CRITICAL;
export const ERROR = LogLevel.ERROR;
export const WARNING = LogLevel.WARNING;
export const INFO = LogLevel.INFO;
export const DEBUG = LogLevel.DEBUG;
export const NOTSET = LogLevel.NOTSET;

interface LogFilter {
	filter(record: LogRecord): boolean;
}

const BASIC_FORMAT = 'levelname:asctime:name:message';
const FORMAT_ARG_SEP = ':';

class Filterer implements LogFilter {
	filters: LogFilter[];

	constructor() {
		this.filters = [];
	}

	addFilter(filter: LogFilter): void {
		if (this.filters.indexOf(filter) === -1) {
			this.filters = [...this.filters, filter];
		}
	}

	filter(record: LogRecord): boolean {
		let result: boolean = true;
		for (let i = 0; i < this.filters.length; ++i) {
			result = this.filters[i].filter(record);
			if (!result) {
				break;
			}
		}
		return result;
	}

	removeFilter(filter: LogFilter): void {
		this.filters = [...this.filters.filter(f => f !== filter)];
	}
}

export class Formatter {
	format(record: LogRecord): string {
		return this.formatMessage(record);
	}

	formatMessage(record: LogRecord): string {
		return BASIC_FORMAT
			.split(':')
			.map(s => record.attribute(s))
			.join(FORMAT_ARG_SEP);
	}

	formatTime(record: LogRecord): string {
		return record.created.isoformat();
	}
}

const _defaultFormatter = new Formatter();

export class Handler extends Filterer {
	formatter: Formatter | null;
	name: string;
	private _level: LogLevel;

	constructor(level: LogLevel = LogLevel.NOTSET) {
		super();
		this.formatter = null;
		this.name = '';
		this._level = level;
	}

	get level(): LogLevel {
		return this._level;
	}

	emit(record: LogRecord): void {
		throw new Error('emit must be implemented by Handler subclasses');
	}

	format(record: LogRecord): string {
		let formatter;
		if (this.formatter === null) {
			formatter = _defaultFormatter;
		} else {
			formatter = this.formatter;
		}
		return formatter.format(record);
	}

	handle(record: LogRecord): boolean {
		const result = this.filter(record);
		if (result) {
			this.emit(record);
		}
		return result;
	}

	setLevel(level: LogLevel): void {
		this._level = level;
	}
}

export class Logger extends Filterer {
	static manager: Manager;
	static root: Logger;

	disabled: boolean;
	handlers: Handler[];
	name: string;
	parent: Logger | null;
	propagate: boolean;
	private _level: LogLevel;
	private _manager: Manager | null;

	constructor(name: string, level: LogLevel = LogLevel.NOTSET, propagate: boolean = true) {
		super();
		this.disabled = false;
		this.handlers = [];
		this.name = name;
		this.parent = null;
		this.propagate = propagate;
		this._level = level;
		this._manager = null;
	}

	get level(): LogLevel {
		return this._level;
	}

	get manager(): Manager {
		if (this._manager) {
			return this._manager;
		}
		if (Logger.manager) {
			return Logger.manager;
		}
		throw new Error(`No Manager set for logger "${this.name}"`);
	}

	set manager(val: Manager) {
		this._manager = val;
	}

	addHandler(hdlr: Handler): void {
		if (this.handlers.indexOf(hdlr) === -1) {
			this.handlers = [...this.handlers, hdlr];
		}
	}

	callHandlers(record: LogRecord): void {
		let _logger: Logger | null = this;
		while (_logger) {
			for (let i = 0; i < _logger.handlers.length; ++i) {
				const hdlr = _logger.handlers[i];
				if (record.levelno >= hdlr.level) {
					hdlr.handle(record);
				}
			}
			if (!_logger.propagate) {
				_logger = null;
			} else {
				_logger = _logger.parent;
			}
		}
	}

	critical(...args: any[]): void {
		if (this.isEnabledFor(LogLevel.CRITICAL)) {
			this._log(LogLevel.CRITICAL, ...args);
		}
	}

	debug(...args: any[]): void {
		if (this.isEnabledFor(LogLevel.DEBUG)) {
			this._log(LogLevel.DEBUG, ...args);
		}
	}

	error(...args: any[]): void {
		if (this.isEnabledFor(LogLevel.ERROR)) {
			this._log(LogLevel.ERROR, ...args);
		}
	}

	getEffectiveLevel(): LogLevel {
		// Get the effective level for this logger.
		//
		// Loop through this logger and its parents in the logger hierarchy,
		// looking for a non-zero logging level. Return the first one found.
		let _logger: Logger | null = this;
		while (_logger) {
			if (_logger.level) {
				return _logger.level;
			}
			_logger = _logger.parent;
		}
		return LogLevel.NOTSET;
	}

	handle(record: LogRecord): void {
		if (!this.disabled && this.filter(record)) {
			this.callHandlers(record);
		}
	}

	info(...args: any[]): void {
		if (this.isEnabledFor(LogLevel.INFO)) {
			this._log(LogLevel.INFO, ...args);
		}
	}

	isEnabledFor(level: LogLevel): boolean {
		if (this.disabled) {
			return false;
		}
		return level >= this.getEffectiveLevel();
	}

	log(level: LogLevel, ...args: any[]): void {
		if (this.isEnabledFor(level)) {
			this._log(level, ...args);
		}
	}

	makeRecord(name: string, level: LogLevel, ...args: any[]): LogRecord {
		return new LogRecord(name, level, ...args);
	}

	removeHandler(hdlr: Handler): void {
		this.handlers = [...this.handlers.filter(h => h !== hdlr)];
	}

	setLevel(level: LogLevel): void {
		this._level = level;
	}

	warning(...args: any[]): void {
		if (this.isEnabledFor(LogLevel.WARNING)) {
			this._log(LogLevel.WARNING, ...args);
		}
	}

	_log(level: LogLevel, ...msg: any[]): void {
		const record = this.makeRecord(this.name, level, ...msg);
		this.handle(record);
	}
}

export class LogRecord {
	args: any[];
	created: datetime;
	levelname: string;
	levelno: LogLevel;
	msg: string;
	name: string;

	constructor(name: string, level: LogLevel, ...args: any[]) {
		let msg: any;
		[msg, ...this.args] = args;
		msg = ((msg === undefined) && (args.length < 1)) ?
			'' :
			(typeof msg === 'string') ?
				msg :
				String(msg);
		this.created = datetime.now();
		this.levelname = getLevelName(level);
		this.levelno = level;
		this.name = name;
		this.msg = msg;
	}

	get asctime(): string {
		return this.created.isoformat();
	}

	get message(): string {
		return this.msg;
	}

	attribute(name: string): string {
		switch (name) {
			case 'levelname':
				return this.levelname;
			case 'levelno':
				return this.levelno.toString();
			case 'asctime':
				return this.asctime;
			case 'name':
				return this.name;
			case 'message':
				return this.message;
			default:
				return '';
		}
	}
}

const _loggerClass = Logger;

class Manager {
	disable: boolean;
	loggerClass: typeof Logger | null;
	loggerMap: Map<string, Logger>;
	root: Logger;

	constructor(rootnode: Logger) {
		this.disable = false;
		this.loggerClass = null;
		this.loggerMap = new Map<string, Logger>();
		this.root = rootnode;
	}

	getLogger(name: string): Logger {
		// Get a logger with the specified name (channel name), creating it if
		// it doesn't yet exist. This name is a dot-separated hierarchical
		// name, such as "a", "a.b", "a.b.c" or similar.
		//
		// If a PlaceHolder existed for the specified name [i.e. the logger
		// didn't exist but a child of it did], replace it with the created
		// logger and fix up the parent/child references which pointed to the
		// placeholder to now point to the logger.
		let rv: Logger;
		const logger: Logger | undefined = this.loggerMap.get(name);
		if (logger !== undefined) {
			rv = logger;
			if (rv instanceof PlaceHolder) {
				const placeHolder: PlaceHolder = rv;
				rv = new (this.loggerClass || _loggerClass)(name);
				rv.manager = this;
				this.loggerMap.set(name, rv);
				this._fixupChildren(placeHolder, rv);
				this._fixupParents(rv);
			}
		} else {
			rv = new (this.loggerClass || _loggerClass)(name);
			rv.manager = this;
			this.loggerMap.set(name, rv);
			this._fixupParents(rv);
		}
		return rv;
	}

	private _fixupChildren(ph: PlaceHolder, aLogger: Logger): void {
		// Ensure that children of the placeholder ph are connected to the
		// specified logger.
		const name = aLogger.name;
		const namelen = name.length;
		for (const child of ph.loggerMap.keys()) {
			if (child.parent && (child.parent.name.substring(0, namelen) !== name)) {
				aLogger.parent = child.parent;
				child.parent = aLogger;
			}
		}
	}

	private _fixupParents(aLogger: Logger): void {
		// Ensure that there are either loggers or placeholders all the way
		// from the specified logger to the root of the logger hierarchy.
		const name = aLogger.name;
		let i: number = name.lastIndexOf('.');
		let rv: Logger | null = null;
		while ((i > 0) && (rv === null)) {
			const substr = name.substring(0, i);
			if (!this.loggerMap.has(substr)) {
				this.loggerMap.set(substr, new PlaceHolder(aLogger));
			} else {
				const obj = <Logger>this.loggerMap.get(substr);
				if (obj instanceof PlaceHolder) {
					obj.append(aLogger);
				} else {
					rv = obj;
				}
			}
			i = substr.lastIndexOf('.');
		}
		if (rv === null) {
			rv = this.root;
		}
		aLogger.parent = rv;
	}
}

class PlaceHolder extends Logger {
	// PlaceHolder instances are used in the Manager logger hierarchy to take
	// the place of nodes for which no loggers have been defined. This class
	// is intended for internal use only and not as part of the public API.
	loggerMap: Map<Logger, null>;

	constructor(aLogger: Logger) {
		super('PlaceHolder', undefined, undefined);
		this.loggerMap = new Map<Logger, null>([[aLogger, null]]);
	}

	append(aLogger: Logger): void {
		if (!this.loggerMap.has(aLogger)) {
			this.loggerMap.set(aLogger, null);
		}
	}
}

class RootLogger extends Logger {
	constructor(name: string = 'root', level: LogLevel = LogLevel.WARNING, propagate: boolean = true) {
		super(name, level, propagate);
	}
}

type _Stream = (...args: any[]) => any;

export class StreamHandler extends Handler {
	stream: _Stream;

	constructor(level: LogLevel = LogLevel.NOTSET, stream: _Stream = console.log) {
		super(level);
		this.stream = stream;
	}

	emit(record: LogRecord): void {
		const msg = this.format(record);
		const stream = this.stream;
		stream(msg, ...record.args);
	}
}

const root = new RootLogger();
Logger.root = root;
Logger.manager = new Manager(Logger.root);

export function basicConfig(cfg?: any) {
	if (root.handlers.length === 0) {
		cfg = cfg || {};
		let handlers: Handler[];
		if (cfg.hasOwnProperty('handlers')) {
			const hdlrs = cfg.handlers;
			if (Array.isArray(hdlrs)) {
				handlers = hdlrs;
			} else {
				throw new Error('Value for key "handlers" in logging config must be an Array of type Handler.');
			}
		} else {
			handlers = [new StreamHandler()];
		}
		const fmt = new Formatter();
		for (let i = 0; i < handlers.length; ++i) {
			const hdlr = handlers[i];
			if (hdlr.formatter === null) {
				hdlr.formatter = fmt;
			}
			root.addHandler(hdlr);
		}
		if (cfg.hasOwnProperty('level')) {
			root.setLevel(cfg.level);
		}
	}
}

export function critical(...args: any[]): void {
	log(LogLevel.CRITICAL, ...args);
}

export function debug(...args: any[]): void {
	log(LogLevel.DEBUG, ...args);
}

export function error(...args: any[]): void {
	log(LogLevel.ERROR, ...args);
}

export function getLogger(name: string | null = null): Logger {
	if (name === null) {
		return root;
	}
	return Logger.manager.getLogger(name);
}

export function getLevelName(level: LogLevel): string {
	return LogLevel[level];
}

export function info(...args: any[]): void {
	log(LogLevel.INFO, ...args);
}

export function log(level: LogLevel, ...args: any[]): void {
	if (root.handlers.length === 0) {
		basicConfig();
	}
	root.log(level, ...args);
}

export function warning(...args: any[]): void {
	log(LogLevel.WARNING, ...args);
}
