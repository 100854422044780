import {Obj, OBJ, SIGNAL, SLOT} from '../../../../obj';
import {ElObj, ElObjOpts, elObjOpts} from '../../../../elobj';
import {ComboBox} from '../../../../ui/combobox';
import {TextInput, TextInputIconPosition} from '../../../../ui/textinput';
import {arrayCmp, stringIterableToStringArray} from '../../../../util';
import {getLogger} from '../../../../logging';
import {list} from '../../../../tools';

const logger = getLogger('projectdetailview.expression');
type OperandControlType = 'text' | 'choices';

@OBJ
export class Expression extends ElObj {
	private disabled: boolean;
	expressionId: number;
	private fieldChoices: list<string>;
	private fieldComboBox: ComboBox;
	private operandChoices: list<string>;
	private operandControlType: OperandControlType;
	private operandInputControl: TextInput | ComboBox;
	private operandControlParent: ElObj | null;
	private operatorChoices: list<string>;
	private operatorComboBox: ComboBox;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-filter-expression',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.expressionId = -this.instanceNumber;
		this.disabled = false;
		this.fieldChoices = new list<string>();
		this.operandChoices = new list<string>();
		this.operandControlParent = null;
		this.operandControlType = 'text';
		this.operatorChoices = new list<string>();
		const fieldLabelEl = new ElObj({
			classNames: [
				'lb-filter-expression__combobox-label',
				'lb-filter-expression-section',
			],
			parent: this,
			tagName: 'label',
		});
		this.fieldComboBox = new ComboBox({
			classNames: [
				'lb-filter-expression__combobox',
			],
			parent: fieldLabelEl,
		});
		this.fieldComboBox.setAttribute('title', 'What to filter');
		this.connectToFieldComboBox();
		const opLabelEl = new ElObj({
			classNames: [
				'lb-filter-expression__combobox-label',
				'lb-filter-expression-section',
			],
			parent: this,
			tagName: 'label',
		});
		this.operatorComboBox = new ComboBox({
			classNames: [
				'lb-filter-expression__combobox',
			],
			parent: opLabelEl,
		});
		this.operatorComboBox.setAttribute('title', 'Operation to apply');
		this.connectToOperatorComboBox();
		this.operandInputControl = new TextInput({
			classNames: [
				'lb-filterbox-input',
				'lb-filter-expression-section',
			],
			trailingIcon: {icon: 'save', outlined: true, interactive: true, title: 'Save label'},
			parent: this,
		});
		this.operandInputControl.setAttribute('title', 'Value to filter');
		this.connectToOperandInputControl();
	}

	private connectToAllControls(): void {
		this.connectToFieldComboBox();
		this.connectToOperatorComboBox();
		this.connectToOperandInputControl();
	}

	private connectToFieldComboBox(): void {
		Obj.connect(
			this.fieldComboBox, 'currentIndexChanged',
			this, 'fieldComboBoxCurrentIndexChanged');
	}

	private connectToOperandInputControl(): void {
		switch (this.operandControlType) {
			case 'text':
				Obj.connect(
					this.operandInputControl, 'returnPressed',
					this, 'operandTextInputReturnPressed');
				Obj.connect(
					this.operandInputControl, 'textChanged',
					this, 'operandTextInputTextChanged');
				Obj.connect(
					this.operandInputControl, 'iconActivated',
					this, 'operandTextInputIconActivated');
				break;
			case 'choices':
				this.operandInputControl.setAttribute('title', 'Value to filter');
				Obj.connect(
					this.operandInputControl, 'currentIndexChanged',
					this, 'operandComboBoxCurrentIndexChanged');
				break;
		}
	}

	private connectToOperatorComboBox(): void {
		Obj.connect(
			this.operatorComboBox, 'currentIndexChanged',
			this, 'operatorComboBoxCurrentIndexChanged');
	}

	currentField(): string {
		return this.fieldComboBox.currentText();
	}

	@SIGNAL
	private currentFieldChanged(value: string): void {
	}

	currentOperand(): string {
		if (this.operandInputControl instanceof TextInput) {
			return this.operandInputControl.text();
		}
		return this.operandInputControl.currentText();
	}

	currentOperandControlType(): OperandControlType {
		return this.operandControlType;
	}

	@SIGNAL
	private currentOperandChanged(value: string): void {
	}

	currentOperator(): string {
		return this.operatorComboBox.currentText();
	}

	@SIGNAL
	private currentOperatorChanged(value: string): void {
	}

	destroy(): void {
		this.disconnectFromAllControls();
		this.fieldComboBox.destroy();
		this.operatorComboBox.destroy();
		this.operandInputControl.destroy();
		super.destroy();
	}

	private disconnectFromAllControls(): void {
		this.disconnectFromFieldComboBox();
		this.disconnectFromOperatorComboBox();
		this.disconnectFromOperandInputControl();
	}

	private disconnectFromFieldComboBox(): void {
		Obj.disconnect(
			this.fieldComboBox, 'currentIndexChanged',
			this, 'fieldComboBoxCurrentIndexChanged');
	}

	private disconnectFromOperandInputControl(): void {
		switch (this.operandControlType) {
			case 'text':
				Obj.disconnect(
					this.operandInputControl, 'returnPressed',
					this, 'operandTextInputReturnPressed');
				Obj.disconnect(
					this.operandInputControl, 'textChanged',
					this, 'operandTextInputTextChanged');
				Obj.disconnect(
					this.operandInputControl, 'iconActivated',
					this, 'operandTextInputIconActivated');
				break;
			case 'choices':
				Obj.disconnect(
					this.operandInputControl, 'currentIndexChanged',
					this, 'operandComboBoxCurrentIndexChanged');
				break;
		}
	}

	private disconnectFromOperatorComboBox(): void {
		Obj.disconnect(
			this.operatorComboBox, 'currentIndexChanged',
			this, 'operatorComboBoxCurrentIndexChanged');
	}

	@SLOT
	private fieldComboBoxCurrentIndexChanged(index: number): void {
		this.currentFieldChanged(this.fieldComboBox.currentText());
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	@SLOT
	private operandComboBoxCurrentIndexChanged(index: number): void {
		if (this.operandControlType === 'choices') {
			this.currentOperandChanged((<ComboBox>this.operandInputControl).currentText());
		}
	}

	@SLOT
	private operandTextInputIconActivated(position: TextInputIconPosition): void {
		if (position === TextInputIconPosition.Trailing) {
			this.operandTextInputReturnPressed();
		} else {
			logger.warning('operandTextInputIconActivated: Unrecognized argument');
		}
	}

	@SIGNAL
	private operandTextInputReturnPressed(): void {
	}

	@SLOT
	private operandTextInputTextChanged(text: string): void {
		this.currentOperandChanged(text);
	}

	@SLOT
	private operatorComboBoxCurrentIndexChanged(index: number): void {
		this.currentOperatorChanged(this.operatorComboBox.currentText());
	}

	setControlsEnabled(enabled: boolean): void {
		this.fieldComboBox.setDisabled(!enabled);
		this.operatorComboBox.setDisabled(!enabled);
		this.operandInputControl.setDisabled(!enabled);
	}

	setCurrentField(value: string): void {
		const index = this.fieldChoices.indexOf(value);
		if (index >= 0) {
			this.fieldComboBox.setCurrentIndex(index);
		} else {
			logger.warning('setCurrentField: Value not a valid option.');
		}
	}

	setCurrentOperand(value: string): void {
		if (this.operandInputControl instanceof TextInput) {
			this.operandInputControl.setText(value);
		} else {
			const index = this.operandChoices.indexOf(value);
			if (index >= 0) {
				this.operandInputControl.setCurrentIndex(index);
			} else {
				logger.warning('setCurrentOperand: Value not a valid option.');
			}
		}
	}

	setCurrentOperator(value: string): void {
		const index = this.operatorChoices.indexOf(value);
		if (index >= 0) {
			this.operatorComboBox.setCurrentIndex(index);
		} else {
			logger.warning('setCurrentOperator: Value not a valid option.');
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		if (this.disabled) {
			this.disconnectFromAllControls();
		} else {
			this.connectToAllControls();
		}
		this.fieldComboBox.setDisabled(this.disabled);
		this.operatorComboBox.setDisabled(this.disabled);
		this.operandInputControl.setDisabled(this.disabled);
	}

	setFieldChoices(choices: Iterable<string>): void {
		const newChoices = stringIterableToStringArray(choices);
		if (arrayCmp(this.fieldChoices.toArray(), newChoices)) {
			return;
		}
		this.fieldChoices = new list<string>(newChoices);
		this.fieldComboBox.clear();
		for (const choice of this.fieldChoices) {
			this.fieldComboBox.addItem(choice);
		}
	}

	setOperandChoices(choices: Iterable<string>): void {
		if (!(this.operandInputControl instanceof ComboBox)) {
			logger.warning('setOperandChoices: Combobox has not been initialized');
			return;
		}
		const newChoices = stringIterableToStringArray(choices);
		if (arrayCmp(this.operandChoices.toArray(), newChoices)) {
			return;
		}
		this.operandChoices = new list<string>(newChoices);
		this.operandInputControl.clear();
		for (const choice of this.operandChoices) {
			this.operandInputControl.addItem(choice);
		}
	}

	setOperandControlType(type: OperandControlType): void {
		if (type === this.operandControlType) {
			return;
		}
		this.disconnectFromOperandInputControl();
		this.operandInputControl.destroy();
		if (this.operandControlParent) {
			this.operandControlParent.destroy();
		}
		this.operandControlParent = null;
		this.operandControlType = type;
		switch (this.operandControlType) {
			case 'text':
				this.operandInputControl = new TextInput({
					classNames: [
						'lb-filterbox-input',
						'lb-filter-expression-section',
					],
					trailingIcon: {icon: 'save', outlined: true, interactive: true, title: 'Save label'},
					parent: this,
				});
				this.operandInputControl.setAttribute('title', 'Value to filter');
				break;
			case 'choices':
				this.operandControlParent = new ElObj({
					classNames: [
						'lb-filter-expression__combobox-label',
						'lb-filter-expression-section',
					],
					parent: this,
					tagName: 'label',
				});
				this.operandInputControl = new ComboBox({
					classNames: [
						'lb-filter-expression__combobox',
					],
					parent: this.operandControlParent,
				});
				this.operandInputControl.setAttribute('title', 'Value to filter');
				break;
		}
		this.connectToOperandInputControl();
	}

	setOperatorChoices(choices: Iterable<string>): void {
		const newChoices = stringIterableToStringArray(choices);
		if (arrayCmp(this.operatorChoices.toArray(), newChoices)) {
			return;
		}
		this.operatorChoices = new list<string>(newChoices);
		this.operatorComboBox.clear();
		for (const choice of this.operatorChoices) {
			this.operatorComboBox.addItem(choice);
		}
	}
}
