import mapboxgl from 'mapbox-gl';

import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {ElObj, ElObjOpts, elObjOpts} from '../../../elobj';
import {PushButton, PushButtonOpts} from '../../pushbutton';
import {list} from '../../../tools';
import {InteractiveMapControlMode} from '../../../constants';

interface ControlButtonOpts extends PushButtonOpts {
	icon: string;
	iconIsOutlined: boolean;
}

@OBJ
export class ControlButton extends PushButton {
	private iconIsOutlined: boolean;

	constructor(opts: Partial<ControlButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ControlButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ControlButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ControlButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<ControlButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ControlButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ControlButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ControlButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'lb-map-control-button',
			...classNames,
		];
		super(opts);
		this.iconIsOutlined = Boolean(opts.iconIsOutlined);
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
	}

	private iconEl(): ElObj | null {
		return this.querySelector(this.iconIsOutlined ? '.material-icons-outlined' : '.material-icons');
	}

	setIcon(icon: string, outlined?: boolean): void {
		let el = this.iconEl();
		if (outlined !== undefined) {
			this.iconIsOutlined = outlined;
		}
		if (!el) {
			el = new ElObj({
				classNames: [
					this.iconIsOutlined ? 'material-icons-outlined' : 'material-icons',
					'lb-map-control-button-icon',
				],
				parent: this,
				tagName: 'i',
			});
		}
		el.setText(icon);
	}
}

@OBJ
export class Control extends ElObj {
	static mode: InteractiveMapControlMode = InteractiveMapControlMode.NoMode;

	private active: boolean;
	protected buttons: list<ControlButton>;
	private disabled: boolean;
	protected map: mapboxgl.Map | null;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			(typeof opts.classNames === 'string') ?
				[opts.classNames] :
				Array.from(opts.classNames) :
			[];
		opts.classNames = [
			'mapboxgl-ctrl',
			'mapboxgl-ctrl-group',
			'lb-map-control',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.active = false;
		this.buttons = new list<ControlButton>();
		this.disabled = false;
		this.map = null;
	}

	protected activationChanged(): void {
	}

	protected addClassToMapContainer(...className: Array<string>): void {
		if (this.map) {
			const el = this.map.getCanvasContainer();
			el.classList.add(...className);
		}
	}

	@SLOT
	private _buttonClicked(): void {
		this.buttonClicked(this.mode());
	}

	@SIGNAL
	private buttonClicked(mode: InteractiveMapControlMode): void {
	}

	protected connectButton(button: ElObj): void {
		Obj.connect(
			button, 'clicked',
			this, '_buttonClicked');
	}

	destroy(): void {
		this.active = false;
		this.map = null;
		this.destroyButtons();
		super.destroy();
	}

	private destroyButtons(): void {
		for (const obj of this.buttons) {
			obj.destroy();
		}
		this.buttons.clear();
	}

	protected disconnectButton(button: ElObj): void {
		Obj.disconnect(
			button, 'clicked',
			this, '_buttonClicked');
	}

	element(): HTMLElement {
		// Setting tagName in constructor creates the respective HTMLElement
		return <HTMLElement>super.element();
	}

	isActive(): boolean {
		return this.active;
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	mode(): InteractiveMapControlMode {
		return (<typeof Control>this.constructor).mode;
	}

	onAdd(map: mapboxgl.Map): HTMLElement {
		this.map = map;
		this._onAdd(this.map);
		return this.element();
	}

	protected _onAdd(map: mapboxgl.Map): void {
	}

	onRemove(map: mapboxgl.Map): void {
		this._onRemove(map);
		this.active = false;
		this.map = null;
		this.destroyButtons();
		const parent = this.element().parentNode;
		if (parent) {
			parent.removeChild(this.element());
		}
	}

	protected _onRemove(map: mapboxgl.Map): void {
	}

	protected removeClassFromMapContainer(...className: Array<string>): void {
		if (this.map) {
			const el = this.map.getCanvasContainer();
			el.classList.remove(...className);
		}
	}

	setActive(active: boolean): void {
		if (active === this.active) {
			return;
		}
		this.active = active;
		for (const obj of this.buttons) {
			obj.setClass(this.active, 'lb-map-control-button--active');
		}
		this.activationChanged();
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		for (const obj of this.buttons) {
			if (this.disabled) {
				this.disconnectButton(obj);
			} else {
				this.connectButton(obj);
			}
			obj.setDisabled(this.disabled);
		}
	}
}
