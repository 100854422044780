import {MDCTextField} from '@material/textfield';

import {bind, stringIterableToStringArray} from '../../util';
import {OBJ, PROP, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {CSS_CLASS_FULL_WIDTH} from '../../constants';
import {Variant} from '../../variant';
import {TextInputIcon, TextInputIconOpts, TextInputIconPosition} from './icon';

export interface TextInputOpts extends ElObjOpts {
	columns: number | string | null;
	fullWidth: boolean;
	inputId: string;
	labelText: string;
	leadingIcon: Partial<TextInputIconOpts> | string;
	name: string;
	noLabel: boolean;
	outlined: boolean;
	placeholder: string;
	required: boolean;
	resizable: boolean;
	rows: number | string | null;
	textarea: boolean;
	trailingIcon: Partial<TextInputIconOpts> | string;
	type: string;
	underlinedLessDenseNoPadding: boolean;
	underlinedLessDenseNoPaddingWhiteBackground: boolean;
	underlinedLessDenseWhiteBackground: boolean;
}

@OBJ
export class TextInput extends ElObj {
	private ctrl: MDCTextField | null;
	private iconMap: Map<TextInputIconPosition, TextInputIcon>;
	private initOpts: Partial<TextInputOpts>;
	private inputElDisabled: boolean;

	constructor(opts: Partial<TextInputOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TextInputOpts> | null, parent?: ElObj | null);
	constructor(opts: Partial<TextInputOpts> | null, root?: Element | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TextInputOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<TextInputOpts> | null);
	constructor(root?: Element | null);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<TextInputOpts> | ElObj | Element | null, b?: ElObj | Element | null, c?: ElObj | null) {
		const opts = elObjOpts<TextInputOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-text-input',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.iconMap = new Map<TextInputIconPosition, TextInputIcon>();
		this.initOpts = {...opts};
		this.inputElDisabled = false;
		this.init(this.initOpts);
		this.ctrl = new MDCTextField(this.elem);
		this.ctrl.layout();
	}

	protected addEventListeners(): void {
		const inp = this.inputEl();
		if (inp) {
			inp.addEventListener('input', this.domEvent, true);
			inp.addEventListener('keydown', this.domEvent, true);
		}
	}

	blur(): void {
		const el = this.inputEl();
		if (el) {
			el.blur();
		}
	}

	clear(): void {
		this.setText('');
		// we don't get an domEvent when setting value to the empty string. Don't
		// know but I've also not spent any time looking into it. Probably
		// just Google being crappy at writing software again.
		this.textChanged(this.text());
	}

	clone(): TextInput {
		return new (<typeof TextInput>this.constructor)(this.initOpts);
	}

	destroy(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
		for (const obj of this.iconMap.values()) {
			obj.destroy();
		}
		this.iconMap.clear();
		this.removeEventListeners();
		super.destroy();
	}

	@bind
	protected domEvent(event: Event): void {
		switch (event.type) {
			case 'keydown':
				this.domKeyDownEvent(<KeyboardEvent>event);
				break;
			case 'input':
				this.domInputEvent();
				break;
		}
	}

	private domInputEvent(): void {
		this.textChanged(this.text());
	}

	protected domKeyDownEvent(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.returnPressed();
		}
	}

	focus(): void {
		if (this.ctrl) {
			this.ctrl.focus();
		}
	}

	hasAcceptableInput(): boolean {
		return this.reportValidity();
	}

	icon(position: TextInputIconPosition): TextInputIcon | null {
		return this.iconMap.get(position) || null;
	}

	@SIGNAL
	iconActivated(position: TextInputIconPosition): void {
	}

	private inputEl(): ElObj | null {
		return this.querySelector('.mdc-text-field__input');
	}

	protected init(opts: Partial<TextInputOpts>): void {
		this.addClass('mdc-text-field', 'lb-text-input');
		this.setClass(Boolean(opts.noLabel), 'mdc-text-field--no-label');
		if (!opts.textarea) {
			if (opts.underlinedLessDenseNoPaddingWhiteBackground) {
				this.addClass('lb-text-input--underlined-less-dense-no-padding-white-background');
			} else if (opts.underlinedLessDenseNoPadding) {
				this.addClass('lb-text-input--underlined-less-dense-no-padding');
			} else if (opts.underlinedLessDenseWhiteBackground) {
				this.addClass('lb-text-input--underlined-less-dense-white-background');
			}
		}
		if (opts.outlined) {
			this.addClass('mdc-text-field--outlined');
			const outline = new ElObj({
				classNames: 'mdc-notched-outline',
				parent: this,
				tagName: 'div',
			});
			new ElObj({
				classNames: 'mdc-notched-outline__leading',
				parent: outline,
				tagName: 'span',
			});
			const notch = new ElObj({
				classNames: 'mdc-notched-outline__notch',
				parent: outline,
				tagName: 'div',
			});
			if (!opts.noLabel) {
				new ElObj({
					classNames: 'mdc-floating-label',
					parent: notch,
					tagName: 'label',
				});
			}
			new ElObj({
				classNames: 'mdc-notched-outline__trailing',
				parent: outline,
				tagName: 'div',
			});
			if (opts.textarea) {
				this.addClass('mdc-text-field--textarea');
				let textAreaParent: ElObj = this;
				if (opts.resizable) {
					textAreaParent = new ElObj({
						classNames: 'mdc-text-field__resizer',
						parent: this,
						tagName: 'div',
					});
				}
				const textarea = new ElObj({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				if (opts.columns) {
					textarea.setAttribute('cols', String(opts.columns));
				}
				if (opts.rows) {
					textarea.setAttribute('rows', String(opts.rows));
				}
			} else {
				const input = new ElObj({
					attributes: [['type', 'text']],
					classNames: 'mdc-text-field__input',
					parent: this,
					tagName: 'input',
				});
			}
		} else {
			this.addClass('mdc-text-field--filled');
			new ElObj({
				classNames: 'mdc-text-field__ripple',
				parent: this,
				tagName: 'div',
			});
			if (!opts.noLabel) {
				new ElObj({
					classNames: 'mdc-floating-label',
					parent: this,
					tagName: 'label',
				});
			}
			if (opts.textarea) {
				this.addClass('mdc-text-field--textarea');
				let textAreaParent: ElObj = this;
				if (opts.resizable) {
					textAreaParent = new ElObj({
						classNames: 'mdc-text-field__resizer',
						parent: this,
						tagName: 'div',
					});
				}
				const textarea = new ElObj({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				if (opts.columns) {
					textarea.setAttribute('cols', String(opts.columns));
				}
				if (opts.rows) {
					textarea.setAttribute('rows', String(opts.rows));
				}
			} else {
				const input = new ElObj({
					attributes: [['type', 'text']],
					classNames: 'mdc-text-field__input',
					parent: this,
					tagName: 'input',
				});
			}
			new ElObj({
				classNames: 'mdc-line-ripple',
				parent: this,
				tagName: 'div',
			});
		}
		if (opts.type) {
			const inp = this.inputEl();
			if (inp) {
				inp.setAttribute('type', opts.type);
			}
		}
		if (opts.name) {
			this.setName(opts.name);
		}
		if (opts.inputId) {
			this.setInputId(opts.inputId);
		}
		if (opts.labelText) {
			this.setLabelText(opts.labelText);
		}
		this.addEventListeners();
		if (opts.required) {
			this.setRequired(opts.required);
		}
		if (opts.fullWidth) {
			this.setFullWidth(opts.fullWidth);
		}
		if (opts.leadingIcon) {
			this.setLeadingIcon(opts.leadingIcon);
		}
		if (opts.trailingIcon) {
			this.setTrailingIcon(opts.trailingIcon);
		}
		if (opts.placeholder) {
			this.setPlaceHolder(opts.placeholder);
		}
	}

	inputId(): string {
		const el = this.inputEl();
		return el ?
			el.attribute('id') || '' :
			'';
	}

	isDisabled(): boolean {
		if (this.ctrl) {
			return this.ctrl.disabled;
		}
		return false;
	}

	private isFullWidth(): boolean {
		return this.hasClass(CSS_CLASS_FULL_WIDTH);
	}

	isInputDisabled(): boolean {
		return this.inputElDisabled;
	}

	isOutlined(): boolean {
		return this.hasClass('mdc-text-field--outlined');
	}

	isRequired(): boolean {
		if (this.ctrl) {
			return this.ctrl.required;
		}
		return false;
	}

	private isTextArea(): boolean {
		return this.hasClass('mdc-text-field--textarea');
	}

	labelEl(): ElObj | null {
		const el = this.querySelector('label');
		if (el) {
			return el;
		}
		const id = this.inputId();
		if (id) {
			const me = this.element();
			if (me) {
				const parent = me.parentElement;
				if (parent) {
					const elem = parent.querySelector(`label[for="${id}"]`);
					if (elem) {
						return new ElObj({root: elem});
					}
				}
			}
		}
		return null;
	}

	labelText(): string {
		const el = this.labelEl();
		return el ?
			el.text() :
			'';
	}

	leadingIcon(): TextInputIcon | null {
		return this.icon(TextInputIconPosition.Leading);
	}

	private lineRippleEl(): ElObj | null {
		return this.querySelector('.mdc-line-ripple');
	}

	max(): string {
		return this.ctrl ?
			this.ctrl.max :
			'';
	}

	min(): string {
		return this.ctrl ?
			this.ctrl.min :
			'';
	}

	name(): string {
		const inp = this.inputEl();
		const el = inp && <HTMLInputElement | null>inp.element();
		return el ?
			el.name :
			'';
	}

	private outlineEl(): ElObj | null {
		return this.querySelector('.mdc-notched-outline');
	}

	property(name: string): Variant {
		switch (name) {
			case 'text':
				return new Variant(this.text());
			case 'maxLength':
				return this.ctrl ?
					new Variant(this.ctrl.maxLength) :
					new Variant();
			case 'leadingIconName': {
				const icon = this.icon(TextInputIconPosition.Leading);
				return new Variant(icon ?
					icon.icon() :
					'');
			}
			case 'trailingIconName': {
				const icon = this.icon(TextInputIconPosition.Trailing);
				return new Variant(icon ?
					icon.icon() :
					'');
			}
			default:
				return super.property(name);
		}
	}

	protected removeEventListeners(): void {
		const inp = this.inputEl();
		if (inp) {
			inp.removeEventListener('input', this.domEvent, true);
			inp.removeEventListener('keydown', this.domEvent, true);
		}
	}

	reportValidity(): boolean {
		const el = this.inputEl();
		return el ?
			el.reportValidity() :
			super.reportValidity();
	}

	@SIGNAL
	returnPressed(): void {
	}

	setDisabled(disabled: boolean): void {
		if (this.ctrl) {
			this.ctrl.disabled = disabled;
		}
	}

	setFullWidth(fullWidth: boolean): void {
		if (fullWidth === this.isFullWidth()) {
			return;
		}
		this.setClass(fullWidth, CSS_CLASS_FULL_WIDTH);
		if (this.ctrl) {
			this.ctrl.layout();
		}
	}

	private setIcon(position: TextInputIconPosition, opts: Partial<TextInputIconOpts> | string): void {
		const o: Partial<TextInputIconOpts> = (typeof opts === 'string') ?
			{icon: opts} :
			opts;
		const existing = this.iconMap.get(position);
		if (existing) {
			if (o.icon !== undefined) {
				existing.setIcon(o.icon, o.outlined);
			}
			if (o.interactive !== undefined) {
				existing.setInteractive(o.interactive);
			}
			if (o.title !== undefined) {
				existing.setTitle(o.title);
			}
			return;
		}
		o.position = position;
		o.textInput = this;
		const obj = new TextInputIcon(o);
		this.iconMap.set(position, obj);
	}

	setInputDisabled(disabled: boolean): void {
		if (disabled === this.inputElDisabled) {
			return;
		}
		this.inputElDisabled = disabled;
		const el = this.inputEl();
		if (el) {
			el.setDisabled(this.inputElDisabled);
		}
	}

	setInputId(id: string): void {
		let el = this.inputEl();
		if (el) {
			el.setAttribute('id', id);
		}
		el = this.labelEl();
		if (el) {
			el.setAttribute('for', id);
		}
	}

	protected setInputType(type: string): void {
		this.setAttribute('type', type);
	}

	setLabelText(text: string): void {
		const el = this.labelEl();
		if (el) {
			el.setText(text);
		}
	}

	setLeadingIcon(opts: Partial<TextInputIconOpts> | string): void {
		this.setIcon(TextInputIconPosition.Leading, opts);
	}

	setMax(value: number | string): void {
		if (this.ctrl) {
			this.ctrl.max = String(value);
		}
	}

	setMin(value: number | string): void {
		if (this.ctrl) {
			this.ctrl.min = String(value);
		}
	}

	setName(name: string): void {
		const el = this.inputEl();
		if (el) {
			el.setAttribute('name', name);
		}
	}

	setPlaceHolder(placeHolder?: string): void {
		const inp = this.inputEl();
		if (inp) {
			if (placeHolder) {
				inp.setAttribute('placeholder', placeHolder);
			} else {
				inp.removeAttribute('placeholder');
			}
		}
	}

	setProperty(name: string, value: Variant): boolean {
		switch (name) {
			case 'text':
				this.setText(value.toString());
				return true;
			case 'label':
				this.setLabelText(value.toString());
				return true;
			case 'disabled':
				this.setDisabled(value.toBoolean());
				return true;
			case 'maxLength': {
				if (this.ctrl) {
					this.ctrl.maxLength = value.toNumber();
					return true;
				}
				return false;
			}
			case 'leadingIconName':
				this.setLeadingIcon(value.toString());
				return true;
			case 'trailingIconName':
				this.setTrailingIcon(value.toString());
				return true;
			default:
				return super.setProperty(name, value);
		}
	}

	setReadOnly(readOnly: boolean): void {
		const el = this.inputEl();
		const elem = el && <HTMLInputElement | null>el.element();
		if (elem) {
			elem.tabIndex = readOnly ?
				-1 :
				0;
			elem.readOnly = readOnly;
		}
	}

	setRequired(required: boolean): void {
		if (this.ctrl) {
			this.ctrl.required = required;
		}
	}

	setStep(value: number | string): void {
		if (this.ctrl) {
			this.ctrl.step = String(value);
		}
	}

	@SLOT
	setText(text: string): void {
		if (this.ctrl) {
			this.ctrl.value = text;
		}
	}

	setTrailingIcon(opts: Partial<TextInputIconOpts> | string): void {
		this.setIcon(TextInputIconPosition.Trailing, opts);
	}

	setValid(valid: boolean): void {
		if (this.ctrl) {
			this.ctrl.valid = valid;
		}
	}

	step(): string {
		return this.ctrl ?
			this.ctrl.step :
			'';
	}

	@PROP({WRITE: 'setText', USER: true, NOTIFY: 'textChanged'})
	text(): string {
		return this.ctrl ?
			this.ctrl.value :
			'';
	}

	@SIGNAL
	textChanged(value: string): void {
	}

	trailingIcon(): TextInputIcon | null {
		return this.icon(TextInputIconPosition.Trailing);
	}
}
