import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {Dialog} from '../../ui/dialog';
import {PaymentCreateView} from '../paymentcreate';
import {getLogger} from '../../logging';
import {FancyPushButton} from '../../ui/pushbutton';
import {numberFormat, stringIterableToStringArray} from '../../util';

const logger = getLogger('paymentdialogview');

interface PaymentDialogViewOpts extends ElObjOpts {
	amount: number | string;
	paymentIntent: IPaymentIntent;
	title: string;
}

@OBJ
export class PaymentDialogView extends ElObj {
	private amount: string;
	private buttonBox: ElObj;
	private cancelButton: FancyPushButton;
	private createView: PaymentCreateView | null;
	private dialog: Dialog | null;
	private paymentData: IPaymentIntent | null;
	private processing: boolean;
	private submitButton: FancyPushButton;
	private title: string;

	constructor(opts: Partial<PaymentDialogViewOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PaymentDialogViewOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PaymentDialogViewOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PaymentDialogViewOpts> | null, root?: Element | null);
	constructor(opts: Partial<PaymentDialogViewOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PaymentDialogViewOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PaymentDialogViewOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PaymentDialogViewOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-payment-dialog-view',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.amount = '';
		this.buttonBox = new ElObj({
			classNames: 'lb-button-box',
			parent: this,
			tagName: 'div',
		});
		this.cancelButton = new FancyPushButton({
			parent: this.buttonBox,
			text: 'Cancel',
		});
		Obj.connect(
			this.cancelButton, 'clicked',
			this, 'canceled');
		this.createView = null;
		this.dialog = null;
		this.paymentData = null;
		this.processing = false;
		this.submitButton = new FancyPushButton({
			filled: true,
		});
		this.submitButton.setDisabled(true);
		this.buttonBox.appendChild(this.submitButton);
		Obj.connect(
			this.submitButton, 'clicked',
			this, 'submitPayment');
		this.title = '';
		// FIXME: Don't forget to create the associated slots from above declarations
		if (opts.amount !== undefined) {
			this.setAmount(opts.amount);
		}
		if (opts.paymentIntent !== undefined) {
			this.setPaymentData(opts.paymentIntent);
		}
		if (opts.title !== undefined) {
			this.setTitle(opts.title);
		}
	}

	@SLOT
	beginPayment(): void {
		if (this.processing) {
			return;
		}
		this.processing = true;
		this.openDialog();
	}

	@SLOT
	private canceled(): void {
		this.destroyDialog();
		this.finished(false);
		this.processing = false;
	}

	destroy(): void {
		this.destroyCreateView();
		this.destroyDialog();
		this.createView = null;
		this.dialog = null;
		this.paymentData = null;
		this.processing = false;
		this.title = '';
		super.destroy();
	}

	private destroyCreateView(): void {
		if (this.createView) {
			this.createView.destroy();
		}
		this.createView = null;
	}

	private destroyDialog(): void {
		if (this.dialog) {
			Obj.disconnect(
				this.dialog, 'closed',
				this, 'dialogClosed');
			Obj.disconnect(
				this.dialog, 'opened',
				this, 'dialogOpened');
			this.dialog.destroy();
		}
		this.dialog = null;
	}

	@SLOT
	private dialogClosed(): void {
		this.destroyDialog();
	}

	@SLOT
	private dialogOpened(): void {
	}

	@SLOT
	private async endPayment(success: boolean = false): Promise<void> {
		if (!this.processing) {
			return;
		}
		if (success) {
			// Handle success (Signal?)
			this.destroyDialog();
		} else {
			if (this.createView) {
				const errorData = this.createView.lastErrorData();
				if (errorData) {
					this.submitButton.hide();
					this.cancelButton.setText('OK');
					this.createView.showMessage(errorData.message);
				} else {
					logger.warning('endPayment: Unsuccessful payment attempt has no associated error data.');
				}
			} else {
				logger.warning('endPayment: Payment view is no longer defined.');
			}
		}
		if (this.createView) {
			this.cancelButton.setDisabled(false);
			this.submitButton.setDisabled(false);
		}
		this.processing = false;
		if (success) {
			this.finished(success);
		}
	}

	@SIGNAL
	private finished(success: boolean): void {
	}

	private openDialog(): void {
		this.destroyDialog();
		const title = (this.title.trim().length > 0) ?
			this.title :
			undefined;
		this.dialog = new Dialog({title});
		Obj.connect(
			this.dialog, 'closed',
			this, 'dialogClosed');
		Obj.connect(
			this.dialog, 'opened',
			this, 'dialogOpened');
		if (!this.createView) {
			this.createView = new PaymentCreateView();
			Obj.connect(
				this.createView, 'currentViewTypeChanged',
				this, 'paymentMethodChanged');
			Obj.connect(
				this.createView, 'paymentMethodChanged',
				this, 'paymentMethodChanged');
			this.insertChild(0, this.createView);
		}
		this.dialog.appendElObj(this);
		this.dialog.open();
	}

	@SLOT
	private paymentMethodChanged(): void {
		let submitIsEnabled = false;
		if (this.createView) {
			submitIsEnabled = this.createView.hasAcceptableInput();
		}
		this.submitButton.setDisabled(!submitIsEnabled);
	}

	setAmount(amount: number | string): void {
		this.amount = String(amount);
		const amt = (String(this.amount).trim() || '0');
		this.submitButton.setText(`Pay $${numberFormat(amt)}`);
	}

	setPaymentData(data: IPaymentIntent | null): void {
		this.paymentData = data;
	}

	setTitle(title: string): void {
		this.title = title;
	}

	@SLOT
	private async submitPayment(): Promise<void> {
		if (this.paymentData) {
			if (this.createView) {
				this.cancelButton.setDisabled(true);
				this.submitButton.setDisabled(true);
				const ok = await this.createView.submitPayment(this.paymentData.clientSecret);
				await this.endPayment(ok);
			} else {
				logger.error('submitPayment: Payment view not defined');
			}
		} else {
			logger.error('submitPayment: Payment data is not set.');
		}
	}
}
