import {MDCDrawer} from '@material/drawer';

import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {bind} from '../../util';
import {getLogger} from '../../logging';
import {TextInput, TextInputIconPosition} from '../../ui/textinput';

const logger = getLogger('projectdetailview');

@OBJ
export class Drawer extends ElObj {
	private ctrl: MDCDrawer;
	private lastSavedTitle: string;
	private titleTextInput: TextInput;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		if (!opts.root) {
			opts.root = document.getElementById('id_lb-project-detail-drawer');
		}
		if (!opts.root) {
			logger.warning('Drawer: Root element not defined.');
		}
		opts.tagName = 'div';
		super(opts);
		this.lastSavedTitle = '';
		this.titleTextInput = new TextInput({
			attributes: [
				['id', 'id_lb-project-detail-title-input'],
			],
			trailingIcon: {icon: 'save', interactive: true, title: 'Save project title', outlined: false},
			underlinedLessDenseWhiteBackground: true,
			labelText: 'Project title',
		});
		const icon = this.titleTextInput.trailingIcon();
		if (icon) {
			icon.setDisabled(true);
		}
		this.appendChild(this.titleTextInput);
		Obj.connect(
			this.titleTextInput, 'returnPressed',
			this, 'titleTextInputReturnPressed');
		Obj.connect(
			this.titleTextInput, 'textChanged',
			this, 'titleTextInputTextChanged');
		Obj.connect(
			this.titleTextInput, 'iconActivated',
			this, 'titleTextInputIconActivated');
		this.ctrl = new MDCDrawer(this.elem);
		this.addEventListener('MDCDrawer:opened', this.domEvent);
		this.addEventListener('MDCDrawer:closed', this.domEvent);
	}

	appendChild(child: ElObj | Element): void {
		const el = this.innerEl();
		if (el) {
			el.appendChild(child);
		}
	}

	clear(): void {
		const el = this.innerEl();
		if (el) {
			el.clear();
		}
	}

	@SLOT
	close(): void {
		this.setOpen(false);
	}

	@SIGNAL
	private closed(): void {
	}

	private ctrlClosedEvent(event: Event): void {
		this.closed();
		this.openChanged(false);
	}

	private ctrlOpenedEvent(event: Event): void {
		this.opened();
		this.openChanged(true);
	}

	destroy(): void {
		this.clear();
		this.ctrl.destroy();
		this.removeEventListener('MDCDrawer:opened', this.domEvent);
		this.removeEventListener('MDCDrawer:closed', this.domEvent);
		super.destroy();
	}

	@bind
	private domEvent(event: Event): void {
		switch (event.type) {
			case 'MDCDrawer:opened':
				this.ctrlOpenedEvent(event);
				break;
			case 'MDCDrawer:closed':
				this.ctrlClosedEvent(event);
				break;
		}
	}

	private headerEl(): ElObj | null {
		return this.querySelector('#id_lb-project-detail-drawer-header');
	}

	private innerEl(): ElObj | null {
		return this.querySelector('#id_lb-project-detail-drawer-inner');
	}

	insertChild(index: number, child: ElObj | Element): void {
		const el = this.innerEl();
		if (el) {
			el.insertChild(index, child);
		}
	}

	isOpen(): boolean {
		return this.ctrl.open;
	}

	@SLOT
	open(): void {
		this.setOpen(true);
	}

	@SIGNAL
	private openChanged(isOpen: boolean): void {
	}

	@SIGNAL
	private opened(): void {
	}

	removeChild(child: ElObj | Node): void {
		const el = this.innerEl();
		if (el) {
			el.removeChild(child);
		}
	}

	replaceChild(newChild: ElObj, oldChild: ElObj): void {
		const el = this.innerEl();
		if (el) {
			el.replaceChild(newChild, oldChild);
		}
	}

	setOpen(open: boolean): void {
		if (open === this.isOpen()) {
			return;
		}
		this.ctrl.open = open;
	}

	setTitle(title: string): void {
		this.lastSavedTitle = title;
		this.titleTextInput.setText(title);
	}

	private titleEl(): ElObj | null {
		return this.querySelector('#id_lb-project-detail-drawer-title');
	}

	@SIGNAL
	private titleSaved(title: string): void {
	}

	private _titleSaved(title: string): void {
		this.titleSaved(title);
		this.lastSavedTitle = title;
		const icon = this.titleTextInput.trailingIcon();
		if (icon) {
			icon.setDisabled(true);
		}
	}

	@SLOT
	private titleTextInputIconActivated(position: TextInputIconPosition): void {
		if (position === TextInputIconPosition.Trailing) {
			this._titleSaved(this.titleTextInput.text());
		} else {
			logger.warning('titleTextInputIconActivated: Got invalid object position.');
		}
	}

	@SLOT
	private titleTextInputReturnPressed(): void {
		this._titleSaved(this.titleTextInput.text());
	}

	@SLOT
	private titleTextInputTextChanged(text: string): void {
		const icon = this.titleTextInput.trailingIcon();
		if (icon) {
			icon.setDisabled(text === this.lastSavedTitle);
		}
	}
}
