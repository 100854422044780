import {Mode} from './mode';
import {DrawMode} from '../constants';

export class Static extends Mode {
	name = DrawMode.Static;
	state: {} = {};

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		display(feature);
	}
}
