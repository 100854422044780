import {path} from './urls/conf';
import {init as initArchivedProjectList} from './views/archivedprojectlist';
import {init as initDoNotMail} from './views/donotmail';
import {init as initGeneral} from './views/general';
import {init as initPriceCreate} from './views/pricecreate';
import {init as initPriceUpdate} from './views/priceupdate';
import {init as initProjectList} from './views/projectlist';
import {init as initProjectDetail} from './views/projectdetail';
import {RequestHandler} from './urls/handlers';
import {ResolverMatch} from './urls/resolvers';

const urlPatterns = [
	path(/^account\/archive\//, initArchivedProjectList),
	path(/^account\/do-not-mail\//, initDoNotMail),
	path(/^account\//, initGeneral),
	path(/^password-reset\//, initGeneral),
	path(/^sign-in\//, initGeneral),
	path(/^dashboard\/users\//, initGeneral),
	path(/^dashboard\/coupons\//, initGeneral),
	path(/^dashboard\/prices\/(\d+)\/update\//, initPriceUpdate),
	path(/^dashboard\/prices\/create\//, initPriceCreate),
	path(/^dashboard\/prices\//, initGeneral),
	path(/^([A-Z0-9]{5})\//, initProjectDetail),
	path(/^$/, initProjectList),
];
const handler = new RequestHandler();
const req = {pathname: window.location.pathname, urlPatterns};
let match: ResolverMatch | undefined = undefined;
try {
	match = handler.resolveRequest(req);
} catch {
}
if (match) {
	match.func.call(this, ...match.args);
}
