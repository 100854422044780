import {ButtonRole, StandardButton} from '../constants';

export function buttonRole(button: StandardButton): ButtonRole {
	switch (button) {
		case StandardButton.Ok:
		case StandardButton.Accept:
		case StandardButton.Save:
		case StandardButton.Open:
		case StandardButton.SaveAll:
		case StandardButton.Retry:
		case StandardButton.Ignore:
			return ButtonRole.AcceptRole;
		case StandardButton.Cancel:
		case StandardButton.Decline:
		case StandardButton.Close:
		case StandardButton.Abort:
			return ButtonRole.RejectRole;
		case StandardButton.Discard:
			return ButtonRole.DestructiveRole;
		case StandardButton.Help:
			return ButtonRole.HelpRole;
		case StandardButton.Apply:
			return ButtonRole.ApplyRole;
		case StandardButton.Yes:
		case StandardButton.YesToAll:
			return ButtonRole.YesRole;
		case StandardButton.No:
		case StandardButton.NoToAll:
			return ButtonRole.NoRole;
		case StandardButton.RestoreDefaults:
		case StandardButton.Reset:
			return ButtonRole.ResetRole;
		default:
			break;
	}
	return ButtonRole.InvalidRole;
}

export function buttonText(button: StandardButton): string {
	switch (button) {
		case StandardButton.Accept:
			return 'Accept';
		case StandardButton.Decline:
			return 'Decline';
		case StandardButton.Ok:
			return 'Ok';
		case StandardButton.Save:
			return 'Save';
		case StandardButton.SaveAll:
			return 'Save All';
		case StandardButton.Open:
			return 'Open';
		case StandardButton.Yes:
			return 'Yes';
		case StandardButton.YesToAll:
			return 'Yes To All';
		case StandardButton.No:
			return 'No';
		case StandardButton.NoToAll:
			return 'No To All';
		case StandardButton.Abort:
			return 'Abort';
		case StandardButton.Retry:
			return 'Retry';
		case StandardButton.Ignore:
			return 'Ignore';
		case StandardButton.Close:
			return 'Close';
		case StandardButton.Cancel:
			return 'Cancel';
		case StandardButton.Discard:
			return 'Discard';
		case StandardButton.Help:
			return 'Help';
		case StandardButton.Apply:
			return 'Apply';
		case StandardButton.Reset:
			return 'Reset';
		case StandardButton.RestoreDefaults:
			return 'Restore Defaults';
	}
	return '';
}
