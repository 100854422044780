import {AbstractButton} from './abstractbutton';
import {OBJ, SIGNAL} from '../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../elobj';
import {bind, iterableToArray, stringIterableToStringArray} from '../util';
import {Point} from '../tools';

export interface PushButtonOpts extends ElObjOpts {
	text: string;
	title: string;
	type: string;
}

@OBJ
export class PushButton extends AbstractButton {
	constructor(opts: Partial<PushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<PushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PushButtonOpts>(a, b, c);
		if (!opts.tagName) {
			opts.tagName = 'button';
		}
		if (opts.tagName === 'button') {
			const attributes = opts.attributes ?
				iterableToArray(opts.attributes) :
				[];
			opts.attributes = [
				['type', opts.type ? opts.type : 'button'],
				...attributes,
			];
		}
		super(opts);
		this.init(opts);
	}

	protected addEventListeners(): void {
		this.addEventListener('mousedown', this.domEvent);
		this.addEventListener('mouseup', this.domEvent);
		this.addEventListener('click', this.domEvent);
	}

	@SIGNAL
	clicked(checked: boolean = false, point: Point): void {
	}

	destroy(): void {
		this.removeEventListeners();
		super.destroy();
	}

	@bind
	protected domEvent(event: Event): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		switch (event.type) {
			case 'mousedown':
				this.domMouseDownEvent(<MouseEvent>event);
				break;
			case 'mouseup':
				this.domMouseUpEvent(<MouseEvent>event);
				break;
			case 'click':
				const e = <MouseEvent>event;
				this.domMouseClickEvent(<MouseEvent>event);
				break;
		}
	}

	protected domMouseClickEvent(event: MouseEvent): void {
		this.clicked(false, new Point(event.clientX, event.clientY));
	}

	protected domMouseDownEvent(event: MouseEvent): void {
		this.pressed();
	}

	protected domMouseUpEvent(event: MouseEvent): void {
		this.released();
	}

	protected init(opts: Partial<PushButtonOpts>): void {
		if (opts.text) {
			this.setText(opts.text);
		}
		if (opts.title) {
			this.setTitle(opts.title);
		}
		this.addEventListeners();
	}

	protected removeEventListeners(): void {
		this.removeEventListener('mousedown', this.domEvent);
		this.removeEventListener('mouseup', this.domEvent);
		this.removeEventListener('click', this.domEvent);
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.isDisabled()) {
			return;
		}
		super.setDisabled(disabled);
		if (this.isDisabled()) {
			this.removeEventListeners();
		} else {
			this.addEventListeners();
		}
	}

	setTitle(title: string): void {
		if (this._isValid()) {
			(<HTMLElement>this.elem).title = title;
		}
	}

	title(): string {
		if (this._isValid()) {
			return (<HTMLElement>this.elem).title;
		}
		return '';
	}
}

interface FancyPushButtonOpts extends PushButtonOpts {
	filled: boolean;
	leadingIcon: {outlined?: boolean; name: string;};
	outlined: boolean;
	raised: boolean;
	trailingIcon: {outlined?: boolean; name: string;};
}

@OBJ
export class FancyPushButton extends PushButton {
	private labelEl: ElObj;

	constructor(opts: Partial<FancyPushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<FancyPushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<FancyPushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<FancyPushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<FancyPushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<FancyPushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<FancyPushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<FancyPushButtonOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		if (opts.raised) {
			classNames.unshift('mdc-button--raised');
		} else if (opts.filled) {
			classNames.unshift('mdc-button--unelevated');
		} else if (opts.outlined) {
			classNames.unshift('mdc-button--outlined');
		}
		classNames.unshift('mdc-button');
		opts.classNames = [
			'lb-push-button',
			...classNames,
		];
		super(opts);
		new ElObj({
			classNames: 'mdc-button__ripple',
			parent: this,
			tagName: 'span',
		});
		if (opts.leadingIcon) {
			const icon = new ElObj({
				attributes: [
					['aria-hidden', 'true'],
				],
				classNames: [
					'mdc-button__icon',
					opts.leadingIcon.outlined ? 'material-icons-outlined' : 'material-icons',
				],
				parent: this,
				tagName: 'i',
			});
			icon.setText(opts.leadingIcon.name);
		}
		this.labelEl = new ElObj({
			classNames: 'mdc-button__label',
			parent: this,
			tagName: 'span',
		});
		if (opts.trailingIcon) {
			const icon = new ElObj({
				attributes: [
					['aria-hidden', 'true'],
				],
				classNames: [
					'mdc-button__icon',
					opts.trailingIcon.outlined ? 'material-icons-outlined' : 'material-icons',
				],
				parent: this,
				tagName: 'i',
			});
			icon.setText(opts.trailingIcon.name);
		}
		if (opts.text) {
			this.setText(opts.text);
		}
		if (opts.title) {
			this.setTitle(opts.title);
		}
		this.addEventListeners();
	}

	destroy(): void {
		this.labelEl.destroy();
		super.destroy();
	}

	protected init(opts: Partial<PushButtonOpts>): void {
	}

	setText(text?: string | null): void {
		this.labelEl.setText(text);
	}

	text(): string {
		return this.labelEl.text();
	}
}
