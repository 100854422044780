import {ElObj, elObjOpts, ElObjOpts} from '../../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {numberFormat} from '../../../util';
import {PushButton, PushButtonOpts} from '../../../ui/pushbutton';
import {getLogger} from '../../../logging';
import {list} from '../../../tools';

const logger = getLogger('views.parcelinfo');

export enum ParcelAction {
	IgnoreParcel,
}

@OBJ
class ParcelInfoButton extends PushButton {
	constructor(opts: Partial<PushButtonOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<PushButtonOpts> | null, tagName?: TagName);
	constructor(opts: Partial<PushButtonOpts> | null, root?: Element | null);
	constructor(opts: Partial<PushButtonOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<PushButtonOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<PushButtonOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<PushButtonOpts>(a, b, c);
		const styles = opts.styles ?
			Array.from(opts.styles) :
			[];
		opts.styles = [
			['display', 'flex'],
			['align-items', 'center'],
			['justify-content', 'center'],
			['margin-top', '12px'],
			...styles,
		];
		super(opts);
	}

	private iconEl(): ElObj | null {
		return this.querySelector('.material-icons');
	}

	setIcon(icon: string): void {
		let el = this.iconEl();
		if (!el) {
			el = new ElObj({
				classNames: 'material-icons',
				parent: this,
				styles: [
					['font-size', '12px'],
					['padding-right', '4px'],
				],
				tagName: 'i',
			});
		}
		el.setText(icon);
	}
}

@OBJ
export class ParcelInfo extends ElObj {
	private sectns: list<ParcelSection>;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.setStyleProperty('max-height', '150px');
		this.setStyleProperty('overflow', 'auto');
		this.setStyleProperty('width', '185px');
		this.sectns = new list<ParcelSection>();
		this.setText('No info available');
	}

	@SIGNAL
	actionActivated(sectionIndex: number, action: ParcelAction): void {
	}

	addInfo(info: IParcel): ParcelSection {
		return this.insertInfo(-1, info);
	}

	destroy(): void {
		for (const obj of this.sectns) {
			obj.destroy();
		}
		this.sectns.clear();
		super.destroy();
	}

	info(sectionIndex: number): IParcel | null {
		const section = this.section(sectionIndex);
		return section ?
			section.parcel() :
			null;
	}

	insertInfo(index: number, info: IParcel): ParcelSection {
		this.removeText();
		if (index < 0) {
			index = this.sectns.size();
		}
		index = Math.min(index, this.sectns.size());
		const section = new ParcelSection();
		this.sectns.insert(index, section);
		Obj.connect(
			section, 'actionActivated',
			this, 'sectionActionActivated');
		section.setInfo(info);
		this.insertChild(index, section);
		return section;
	}

	private section(sectionIndex: number): ParcelSection | null {
		if (sectionIndex < 0) {
			sectionIndex = this.sectns.size() - 1;
		}
		if (sectionIndex >= 0 && sectionIndex < this.sectns.size()) {
			return this.sectns.at(sectionIndex);
		}
		logger.warning('section: Got invalid section index %s', sectionIndex);
		return null;
	}

	private sectionIndex(section: ElObj): number {
		for (let i = 0; i < this.sectns.size(); ++i) {
			if (this.sectns.at(i) === section) {
				return i;
			}
		}
		return -1;
	}

	@SLOT
	private sectionActionActivated(action: ParcelAction): void {
		const obj = this.sender();
		if (obj) {
			const index = this.sectionIndex(<ElObj>obj);
			if (index >= 0) {
				this.actionActivated(index, action);
			}
		}
	}

	sectionIndexForParcelId(parcelPk: ParcelPk): number {
		for (let i = 0; i < this.sectns.size(); ++i) {
			const nfo = this.sectns.at(i).parcel();
			if (nfo && nfo.uid === parcelPk) {
				return i;
			}
		}
		return -1;
	}

	*sections(): IterableIterator<ParcelSection> {
		yield *this.sectns;
	}

	setInfo(sectionIndex: number, info: IParcel): void {
		const section = this.section(sectionIndex);
		if (section) {
			section.setInfo(info);
		}
	}
}

@OBJ
class ParcelSection extends ElObj {
	private btn: ParcelInfoButton | null;
	private nfo: IParcel | null;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.btn = null;
		this.nfo = null;
	}

	@SIGNAL
	actionActivated(action: ParcelAction): void {
	}

	destroy(): void {
		this.nfo = null;
		super.destroy();
	}

	@SLOT
	private ignoreButtonClicked(): void {
		this.actionActivated(ParcelAction.IgnoreParcel);
	}

	parcel(): IParcel | null {
		return this.nfo;
	}

	setInfo(info: IParcel): void {
		this.nfo = info;
		if (!this.btn) {
			this.btn = new ParcelInfoButton({
				parent: this,
			});
			Obj.connect(
				this.btn, 'clicked',
				this, 'ignoreButtonClicked');
			let topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText('Owners:');
			for (const entity of info.owners) {
				const el = new ElObj({
					attributes: [
						['title', entity.name],
					],
					parent: topLevelEl,
					styles: [
						['padding-left', '8px'],
						['overflow', 'hidden'],
						['text-overflow', 'ellipsis'],
						['white-space', 'nowrap'],
					],
					tagName: 'div',
				});
				el.setText(entity.name);
			}
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText('Addresses:');
			const el = new ElObj({
				attributes: [
					['title', info.streetAddress],
				],
				parent: topLevelEl,
				styles: [
					['padding-left', '8px'],
					['overflow', 'hidden'],
					['text-overflow', 'ellipsis'],
					['white-space', 'nowrap'],
				],
				tagName: 'div',
			});
			el.setText(info.streetAddress);
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText(`Zoning: ${info.zoning}`);
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText(`Total value: $${numberFormat(info.totalValue)}`);
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText(`Land value: $${numberFormat(info.landValue)}`);
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText(`Structures value: $${numberFormat(info.structuresValue)}`);
			topLevelEl = new ElObj({
				parent: this,
				tagName: 'div',
			});
			topLevelEl.setText(`Other value: $${numberFormat(info.otherValue)}`);
		}
		if (info.doNotMail) {
			this.btn.setIcon('check');
			this.btn.setText('un-ignore parcel');
		} else {
			this.btn.setIcon('not_interested');
			this.btn.setText('ignore parcel');
		}
	}
}
