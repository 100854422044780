import geometryCenter from '@turf/center';

import {OBJ, Obj, SIGNAL, SLOT} from '../../../obj';
import {bind, isNumber} from '../../../util';
import {IFilterListOpt, svc} from '../../../request';
import {getLogger} from '../../../logging';
import {ProjectDetailView} from '../view';
import {FilterBox} from './filterbox';
import {ElObj} from '../../../elobj';
import {InteractiveMapControlMode} from '../../../constants';
import {GeoCoordinate, Point} from '../../../tools';
import {UndoCommand} from '../../../ui/undostack';

const logger = getLogger('projectdetail.filtermanager');

@OBJ
export class FilterManager extends Obj {
	private disabled: boolean;
	private expressionToken: IExpressionToken;
	private filterBox: FilterBox | null;
	private identFilterPkMap: Map<number, number>;
	private movingToFilter: IFilter | null;
	private shapeEditingFilterId: number | null;
	private view: ProjectDetailView;

	constructor(view: ProjectDetailView) {
		super();
		this.disabled = false;
		this.expressionToken = {attributes: [], operators: []};
		this.filterBox = null;
		this.identFilterPkMap = new Map<number, number>();
		this.movingToFilter = null;
		this.shapeEditingFilterId = null;
		this.view = view;
		this.init();
	}

	private addConnections(): void {
		Obj.connect(
			this, 'beginShapeEditing',
			this, 'closeFilterBox');
		Obj.connect(
			this, 'filterCreated',
			this, '_filtersCreated');
		Obj.connect(
			this, 'filterDeleted',
			this, '_filterDeleted');
		Obj.connect(
			this, 'filterUpdated',
			this, '_filterUpdated');
		Obj.connect(
			this.view, 'placeAutoCompleteActivated',
			this, 'placeAutoCompleteActivated');
		Obj.connect(
			this.view.map, 'moveEnded',
			this, 'mapMoveEnded');
		Obj.connect(
			this.view.map, 'featuresCreated',
			this, 'mapFeaturesCreated');
		Obj.connect(
			this.view.map, 'featuresDeleted',
			this, 'mapFeaturesDeleted');
		Obj.connect(
			this.view.map, 'featuresUpdated',
			this, 'mapFeaturesUpdated');
		Obj.connect(
			this.view.map, 'mapClicked',
			this, 'mapClicked');
		Obj.connect(
			this.view.filterList, 'filterClicked',
			this, 'filterClicked');
		Obj.connect(
			this.view.filterList, 'filterEnabledClicked',
			this, 'filterEnabledClicked');
	}

	private async addEditableFiltersToMap(filterId: number | Array<number>): Promise<void> {
		const ids = Array.isArray(filterId) ?
			filterId :
			[filterId];
		const filters = await this.fetchFilters();
		this.setMapFilterSourceData(filters.filter(f => (ids.indexOf(f.id) < 0)));
		this.view.map.addFeature(
			filtersToFeatureCollection(
				filters.filter(f => (ids.indexOf(f.id) >= 0))));
	}

	@SIGNAL
	private beginShapeEditing(filterId: number): void {
	}

	@SLOT
	closeFilterBox(): void {
		this.destroyFilterBox();
	}

	private async createFilter(data: Partial<INewFilter>): Promise<void> {
		const ident = generateUniqueIdent(this.identFilterPkMap);
		const cmd = new CreateFilterCommand(
			ident,
			data,
			this._undoCmdCreateFilterForwardFunc,
			this._undoCmdCreateFilterBackwardFunc);
		this.view.undoStack.push(cmd);
	}

	private async _createFilter(data: Partial<INewFilter>): Promise<IFilter> {
		const rv = await svc.filter.create(this.newFilterObject(data));
		this.filterCreated(rv);
		return rv;
	}

	currentFilterBoxFilterId(): number {
		return this.filterBox ?
			this.filterBox.id() :
			Number.NaN;
	}

	currentlyEditingShape(): boolean {
		return isNumber(this.shapeEditingFilterId);
	}

	currentShapeEditingFilterId(): number {
		return isNumber(this.shapeEditingFilterId) ?
			this.shapeEditingFilterId :
			Number.NaN;
	}

	private async deleteFilter(filter: IFilter): Promise<void> {
		let ident: number | undefined = this._uniqueIdentForFilterPk(filter.id);
		if (ident === undefined) {
			ident = generateUniqueIdent(this.identFilterPkMap);
			this.identFilterPkMap.set(ident, filter.id);
		}
		const cmd = new DeleteFilterCommand(
			ident,
			filter,
			this._undoCmdDeleteFilterBackwardFunc,
			this._undoCmdDeleteFilterForwardFunc);
		this.view.undoStack.push(cmd);
	}

	private async _deleteFilter(pk: number): Promise<void> {
		await svc.filter.delete(pk);
		this.filterDeleted(pk);
	}

	private deleteMapFeatures(featureIds: Array<number | string>): void {
		this.view.map.removeFeature(featureIds);
	}

	destroy(): void {
		Obj.disconnect(
			this.view.map, 'loaded',
			this, 'mapLoaded');
		this.movingToFilter = null;
		this.shapeEditingFilterId = null;
		this.identFilterPkMap.clear();
		super.destroy();
	}

	private destroyFilterBox(): void {
		if (this.filterBox) {
			Obj.disconnect(
				this.filterBox, 'enabledChanged',
				this, 'filterBoxEnabledChanged');
			Obj.disconnect(
				this.filterBox, 'closeButtonClicked',
				this, 'filterBoxCloseButtonClicked');
			Obj.disconnect(
				this.filterBox, 'deleteButtonPressed',
				this, 'filterBoxDeleteButtonClicked');
			Obj.disconnect(
				this.filterBox, 'saveButtonPressed',
				this, 'filterBoxSaveButtonClicked');
			Obj.disconnect(
				this.filterBox, 'addButtonClicked',
				this, 'filterBoxAddButtonClicked');
			Obj.disconnect(
				this.filterBox, 'expressionChanged',
				this, 'filterBoxExpressionChanged');
			this.filterBox.destroy();
		}
		this.filterBox = null;
	}

	@SIGNAL
	private endShapeEditing(filterId: number): void {
	}

	async fetchFilter(pk: number): Promise<IFilter> {
		return await svc.filter.get(pk);
	}

	private async fetchFilters(opt?: Partial<Omit<IFilterListOpt, 'projectSlug'>>): Promise<Array<IFilter>> {
		return await svc.filter.list({
			...(opt || {}),
			projectSlug: this.view.slug,
		});
	}

	@SLOT
	private async filterBoxAddButtonClicked(): Promise<void> {
		if (this.filterBox) {
			await this.createFilter({
				enabled: true,
				expression: {
					lhs: '',
					operator: '',
					rhs: '',
				},
				geometry: null,
				geometryIsCircle: false,
				icon: '',
				label: '',
				parentId: this.filterBox.id(),
				projectSlug: this.view.slug,
			});
		}
	}

	@SLOT
	private async filterBoxCloseButtonClicked(): Promise<void> {
		this.destroyFilterBox();
	}

	@SLOT
	private async filterBoxDeleteButtonClicked(filterId: number): Promise<void> {
		if (isNumber(filterId) && (filterId > 0)) {
			await this.deleteFilter(await this.fetchFilter(filterId));
		}
		if (this.filterBox) {
			if (this.filterBox.isTopLevelFilter(filterId)) {
				this.filterBox.destroy();
				this.filterBox = null;
			} else {
				this.filterBox.removeChildFilter(filterId);
			}
		}
	}

	@SLOT
	private filterBoxEditButtonClicked(): void {
		if (this.filterBox) {
			if (isNumber(this.shapeEditingFilterId) && (this.shapeEditingFilterId === this.filterBox.id())) {
				this.stopEditingFilterShape();
			} else {
				this.startEditingFilterShape(this.filterBox.id());
			}
		}
	}

	@SLOT
	private async filterBoxEnabledChanged(filterId: number, enabled: boolean): Promise<void> {
		await this.updateFilter({pk: filterId, data: {enabled}});
	}

	@SLOT
	private async filterBoxExpressionChanged(filterId: number): Promise<void> {
		if (this.filterBox) {
			const childFilter = this.filterBox.childFilter(filterId);
			// const exprData = this.filterBox.expressionData(filterId);
			if (childFilter) {
				// await this.updateExpressions(filterId, [exprData]);
				await this.updateFilter({pk: filterId, data: childFilter});
			}
		}
	}

	@SLOT
	private async filterBoxSaveButtonClicked(filterId: number): Promise<void> {
		if (this.filterBox) {
			if (isNumber(filterId)) {
				if (filterId === this.filterBox.id()) {
					// Save parent filter
					// const obj = await this.fetchFilter(filterId);
					await this.updateFilter({pk: filterId, data: {label: this.filterBox.label()}});
					// if (this.currentlyEditingShape() && isNumber(this.shapeEditingFilterId) && (this.shapeEditingFilterId === obj.id)) {
					// 	await this.stopEditingFilterShape();
					// }
				} else {
					// Save child filter
					const childFilter = this.filterBox.childFilter(filterId);
					if (childFilter) {
						await this.updateFilter({pk: filterId, data: childFilter});
					} else {
						logger.error('No child filter returned for filter ID %s', filterId);
					}
					// const existingFilter = await this.fetchFilter(filterId);
					// const newExprData = this.filterBox.expressionData(filterId);
					// const exprId = existingFilter.expression ?
					// 	existingFilter.expression.id :
					// 	Number.NaN;
					// if (isNumber(exprId)) {
					// 	const exprData = this.filterBox.expressionData(filterId);
					// 	if (exprData) {
					// 		const existingExpr = existingFilter.expression || {};
					// 		const newExpr = {
					// 			...existingExpr,
					// 			...exprData,
					// 		};
					// 		await this.updateExpressions(existingFilter.id, [newExpr]);
					// 	} else {
					// 		logger.warning('filterSaveClicked: Given filter for update has related expression but object returns no such data.');
					// 	}
					// } else {
					// 	logger.warning('filterSaveClicked: Given filter for update has not related expression.');
					// }
				}
			} else {
				logger.error('filterBoxSaveButtonClicked: Invalid argument value.');
			}
		}
	}

	@SLOT
	private async filterClicked(filterId: number, point: Point): Promise<void> {
		const filter = await this.fetchFilter(filterId);
		if (filter.geometry) {
			this.flyToFilter(filter);
		}
	}

	@SLOT
	private async filterEnabledClicked(filterId: number): Promise<void> {
		const obj = await this.fetchFilter(filterId);
		await this.updateFilter({pk: filterId, data: {enabled: !obj.enabled}});
	}

	@SIGNAL
	private filterCreated(filter: IFilter): void {
	}

	@SLOT
	private async _filtersCreated(filter: IFilter): Promise<void> {
		if (this.filterBox) {
			const parentFilterId = this.currentFilterBoxFilterId();
			if (isNumber(filter.parentId) && (filter.parentId === parentFilterId) && filter.expression && isNumber(filter.expression.id)) {
				this.filterBox.addChildFilterExpression(filter);
			}
		}
		const allFilters = await this.fetchFilters();
		this.setFilterListFilters(allFilters);
		this.setMapFilterSourceData(allFilters);
	}

	@SIGNAL
	private filterDeleted(filterId: number): void {
	}

	@SLOT
	private async _filterDeleted(filterId: number): Promise<void> {
		this.removeFilters([filterId]);
		const filters = await this.fetchFilters();
		this.setMapFilterSourceData(filters);
	}

	@SIGNAL
	private filterUpdated(filter: IFilter): void {
	}

	@SLOT
	private async _filterUpdated(filter: IFilter): Promise<void> {
		if (this.filterBox) {
			const currFilterId = this.filterBox.id();
			if (filter.id === currFilterId) {
				await this.setFilterBoxData(filter);
			} else if (filter.parentId === currFilterId) {
				this.filterBox.setChildFilterData(filter.id, filter);
			}
		}
		this.setFilterListFilters([filter]);
		this.setMapFilterSourceData(await this.fetchFilters());
	}

	flyToFilter(filter: IFilter): void {
		if (filter.geometry && filter.geometry.bbox) {
			if (this.filterBox && (this.filterBox.id() !== filter.id)) {
				this.destroyFilterBox();
			}
			this.movingToFilter = filter;
			this.view.flyTo(<[number, number, number, number]>filter.geometry.bbox);
		} else {
			logger.warning('flyToFilter: Filter has no geometry and/or bbox.');
		}
	}

	private async init(): Promise<void> {
		Obj.connect(
			this.view.map, 'loaded',
			this, 'mapLoaded');
		this.addConnections();
		this.expressionToken = (await svc.ui.get()).expressions;
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	isFilterBoxOpen(): boolean {
		return Boolean(this.filterBox);
	}

	@SLOT
	private async mapClicked(point: Point, coord: GeoCoordinate): Promise<void> {
		switch (this.view.map.interactiveControlMode()) {
			case InteractiveMapControlMode.ShapeSelect:
			case InteractiveMapControlMode.DrawShape:
			case InteractiveMapControlMode.InfoMode:
				return;
		}
		const clickedFilters = await this.fetchFilters({point: coord});
		const clickedFilter = (clickedFilters.length > 0) ?
			clickedFilters[0] :
			null;
		if (clickedFilter) {
			if ((this.currentFilterBoxFilterId() === clickedFilter.id) && isNumber(this.shapeEditingFilterId) && (this.shapeEditingFilterId === clickedFilter.id)) {
				// Filter in "edit" mode. Close the filter popup to allow for
				// further unobstructed editing.
				this.destroyFilterBox();
				// Stop here; nothing further to do.
				return;
			}
			if (this.currentShapeEditingFilterId() === clickedFilter.id) {
				// Assume map is in draw shape mode and user clicked shape
				// (perhaps trying to click on node). Don't throw the filter
				// box back in their face.;
				return;
			}
			// Either reposition an already open filter popup or open a new
			// one.
			if (this.isFilterBoxOpen() && (this.currentFilterBoxFilterId() !== clickedFilter.id)) {
				// FilterBox open for some other filter.
				//
				// Close it before setting new filter data to avoid
				// potentially displaying new filter data in box currently
				// positioned at the other filter.
				this.closeFilterBox();
			}
			await this.openFilterBox(clickedFilter, this.view.lastMousePos);
		} else {
			// No filters under the mouse. Close any open filter popups.
			this.destroyFilterBox();
		}
	}

	@SLOT
	private async mapFeaturesCreated<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Promise<void> {
		const tempFeatIds: Array<string> = [];
		for (let i = 0; i < features.length; ++i) {
			const feat = features[i];
			if (typeof feat.id === 'string') {
				tempFeatIds.push(feat.id);
			}
		}
		const prepped = preparedFeatures(features);
		for (let i = 0; i < prepped.length; ++i) {
			const feat = prepped[i];
			await this.createFilter({geometry: feat.geometry, geometryIsCircle: feat.properties.circle});
		}
		this.deleteMapFeatures(tempFeatIds);
	}

	@SLOT
	private async mapFeaturesDeleted<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Promise<void> {
		for (let i = 0; i < features.length; ++i) {
			const feat = features[i];
			if (feat.id !== undefined) {
				const featId = isNumber(feat.id) ?
					feat.id :
					Number(feat.id);
				if (isNumber(featId)) {
					await this.deleteFilter(await this.fetchFilter(featId));
				}
			}
		}
	}

	@SLOT
	private async mapFeaturesUpdated<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Promise<void> {
		const prepped = preparedUpdateFeatures(features);
		for (let i = 0; i < prepped.length; ++i) {
			const feat = prepped[i];
			await this.updateFilter({
				pk: feat.id,
				data: {
					geometry: feat.geometry,
					geometryIsCircle: feat.properties.circle,
				},
			});
		}
	}

	@SLOT
	private async mapLoaded(): Promise<void> {
		const filters = await this.fetchFilters();
		this.setMapFilterSourceData(filters);
		this.setFilterListFilters(filters);
	}

	@SLOT
	private mapMoveEnded(): void {
		const filter = this.movingToFilter;
		this.movingToFilter = null;
		if (filter && filter.geometry) {
			const center = geometryCenter(filter.geometry);
			const coord = GeoCoordinate.fromPoint(center.geometry);
			const pos = this.view.map.pixelCoordinatesForGeoCoordinate(coord);
			if (pos.isNull()) {
				logger.warning('mapMoveEnded: Got null pixel coordinates');
			} else {
				this.openFilterBox(filter, pos);
			}
		}
	}

	private newFilterObject(data?: Partial<INewFilter>): INewFilter {
		return {
			...baseFilterObject(data),
			expression: data ?
				(data.expression || null) :
				null,
			projectSlug: this.view.slug,
		};
	}

	private async openFilterBox(filterData: IFilter, pos: Point): Promise<void> {
		if (!this.filterBox) {
			this.filterBox = new FilterBox({expressionToken: this.expressionToken});
			this.filterBox.addClass(ElObj.cssClassNames.VisibilityHidden);
			Obj.connect(
				this.filterBox, 'closeButtonClicked',
				this, 'filterBoxCloseButtonClicked');
			Obj.connect(
				this.filterBox, 'deleteButtonPressed',
				this, 'filterBoxDeleteButtonClicked');
			Obj.connect(
				this.filterBox, 'saveButtonPressed',
				this, 'filterBoxSaveButtonClicked');
			Obj.connect(
				this.filterBox, 'addButtonClicked',
				this, 'filterBoxAddButtonClicked');
			Obj.connect(
				this.filterBox, 'editButtonClicked',
				this, 'filterBoxEditButtonClicked');
			Obj.connect(
				this.filterBox, 'enabledChanged',
				this, 'filterBoxEnabledChanged');
			Obj.connect(
				this.filterBox, 'expressionChanged',
				this, 'filterBoxExpressionChanged');
			ElObj.body().appendChild(this.filterBox);
		}
		await this.setFilterBoxData(filterData);
		this.setFilterBoxPosition(pos);
		this.filterBox.removeClass(ElObj.cssClassNames.VisibilityHidden);
	}

	@SLOT
	private async placeAutoCompleteActivated(place: IPlace): Promise<void> {
		await this.createFilter({placePk: place.uid});
	}

	private removeConnections(): void {
		Obj.disconnect(
			this, 'beginShapeEditing',
			this, 'closeFilterBox');
		Obj.disconnect(
			this, 'filterCreated',
			this, '_filtersCreated');
		Obj.disconnect(
			this, 'filterDeleted',
			this, '_filterDeleted');
		Obj.disconnect(
			this, 'filterUpdated',
			this, '_filterUpdated');
		Obj.disconnect(
			this.view, 'placeAutoCompleteActivated',
			this, 'placeAutoCompleteActivated');
		Obj.disconnect(
			this.view.map, 'moveEnded',
			this, 'mapMoveEnded');
		Obj.disconnect(
			this.view.map, 'featuresCreated',
			this, 'mapFeaturesCreated');
		Obj.disconnect(
			this.view.map, 'featuresDeleted',
			this, 'mapFeaturesDeleted');
		Obj.disconnect(
			this.view.map, 'featuresUpdated',
			this, 'mapFeaturesUpdated');
		Obj.disconnect(
			this.view.map, 'mapClicked',
			this, 'mapClicked');
		Obj.disconnect(
			this.view.filterList, 'filterClicked',
			this, 'filterClicked');
		Obj.disconnect(
			this.view.filterList, 'filterEnabledClicked',
			this, 'filterEnabledClicked');
	}

	private async removeEditableFiltersFromMap(filterId: number | Array<number>): Promise<void> {
		const filters = await this.fetchFilters();
		this.view.map.setFilterSourceData(filtersToFeatureCollection(filters));
		this.view.map.removeFeature(filterId);
	}

	private removeFilters(filterIds: Array<number>): void {
		const currFilterBoxId = this.currentFilterBoxFilterId();
		if (filterIds.indexOf(currFilterBoxId) >= 0) {
			this.closeFilterBox();
		} else if (this.filterBox) {
			for (const obj of filterIds) {
				if (this.filterBox.hasChildFilter(obj)) {
					this.filterBox.removeChildFilter(obj);
				}
			}
		}
		this.removeFiltersFromFilterList(filterIds);
		this.removeFiltersFromMap(filterIds);
	}

	private removeFiltersFromFilterList(filterIds: Array<number>): void {
		for (const filterId of filterIds) {
			if (this.view.filterList.indexForFilterId(filterId) >= 0) {
				this.view.filterList.removeFilter(filterId);
			}
		}
		if (this.view.filterList.count() > 0) {
			this.view.setFilterListDividerVisible(true);
		} else {
			this.view.setFilterListDividerVisible(false);
		}
	}

	private removeFiltersFromMap(filterIds: Array<number>): void {
		this.view.map.removeFeature(filterIds);
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.filterBox && this.filterBox.setDisabled(this.disabled);
		if (this.disabled) {
			this.destroyFilterBox();
			if (this.currentlyEditingShape()) {
				this.stopEditingFilterShape();
			}
			this.removeConnections();
		} else {
			this.addConnections();
		}
	}

	private async setFilterBoxData(data: IFilter): Promise<void> {
		if (!this.filterBox) {
			logger.error('setFilterBoxData: FilterBox not instantiated');
			return;
		}
		this.filterBox.setFilter(data);
		for (const child of data.children) {
			// Currently: For all child filters, if they have a related
			// expression, they get added. Otherwise we've no real reason to
			// add them.
			if (child.expression) {
				this.filterBox.addChildFilterExpression(child);
			}
		}
	}

	private setFilterBoxPosition(x: number, y: number): void;
	private setFilterBoxPosition(point: Point): void;
	private setFilterBoxPosition(a: Point | number, b?: number): void {
		if (this.filterBox) {
			let x: number;
			let y: number;
			if (isNumber(a) && isNumber(b)) {
				x = a;
				y = b;
			} else {
				x = (<Point>a).x();
				y = (<Point>a).y();
			}
			this.filterBox.setPosition(x + this.view.drawer.rect().width, y);
		}
	}

	private setFilterListFilters(filters: Array<IFilter>): void {
		for (const filter of filters) {
			if (this.view.filterList.indexForFilterId(filter.id) >= 0) {
				this.view.filterList.updateFilter(filter);
			} else if (!isNumber(filter.parentId)) {
				// Top-level filters only
				this.view.filterList.addFilter(filter);
			}
		}
		if (this.view.filterList.count() > 0) {
			this.view.setFilterListDividerVisible(true);
		} else {
			this.view.setFilterListDividerVisible(false);
		}
	}

	private setMapFilterSourceData(filters: Array<IFilter>): void {
		if (isNumber(this.shapeEditingFilterId)) {
			filters = filters.filter(f => (f.id !== this.shapeEditingFilterId));
		}
		this.view.map.setFilterSourceData(filtersToFeatureCollection(filters));
	}

	private async startEditingFilterShape(filterId: number): Promise<void> {
		if (filterId !== this.shapeEditingFilterId) {
			await this.addEditableFiltersToMap(filterId);
			this.view.setSelectedMapFeatures(filterId);
			this.shapeEditingFilterId = filterId;
			this.beginShapeEditing(this.shapeEditingFilterId);
		}
	}

	async stopEditingFilterShape(): Promise<void> {
		if (isNumber(this.shapeEditingFilterId)) {
			await this.removeEditableFiltersFromMap(this.shapeEditingFilterId);
			this.endShapeEditing(this.shapeEditingFilterId);
		} else {
			logger.warning('stopEditingFilterShape: Called while no filter currently editing.');
		}
		this.shapeEditingFilterId = null;
	}

	// @bind
	// private async _undoCmdCreateFilterForwardFunc(uniqueIdent: number, data: Partial<INewFilter>): Promise<void> {
	// 	// Create new filter
	// 	const filters = await this._createFilter([data]);
	// 	assert(filters.length === 1);
	// 	this.identFilterPkMap.set(uniqueIdent, filters[0].id);
	// }

	@bind
	private async _undoCmdCreateFilterForwardFunc(ident: number, data: Partial<INewFilter>): Promise<void> {
		const filter = await this._createFilter(data);
		this.identFilterPkMap.set(ident, filter.id);
	}

	@bind
	private async _undoCmdCreateFilterBackwardFunc(ident: number): Promise<void> {
		const pk = this.identFilterPkMap.get(ident);
		if (pk === undefined) {
			// BIG ERROR
			logger.error('createBackward: Got undo command without mapped identifier');
		} else {
			await this._deleteFilter(pk);
		}
	}

	@bind
	private async _undoCmdDeleteFilterForwardFunc(ident: number): Promise<void> {
		const pk = this.identFilterPkMap.get(ident);
		if (pk === undefined) {
			// BIG ERROR
			logger.error('deleteForward: Got undo command without mapped identifier');
		} else {
			await this._deleteFilter(pk);
		}
	}

	@bind
	private async _undoCmdDeleteFilterBackwardFunc(ident: number, data: IFilter): Promise<void> {
		const pk = this.identFilterPkMap.get(ident);
		if (pk === undefined) {
			// BIG ERROR
			logger.error('deleteBackward: Got undo command without mapped identifier');
		} else {
			const filter = await this._createFilter(data);
			this.identFilterPkMap.set(ident, filter.id);
		}
	}

	@bind
	private async _undoCmdUpdateFilterForwardFunc(ident: number, data: IFilter): Promise<void> {
		const pk = this.identFilterPkMap.get(ident);
		if (pk === undefined) {
			// BIG ERROR
			logger.error('updateForward: Got undo command without mapped identifier');
		} else {
			data.id = pk;
			await this._updateFilter(data);
		}
	}

	@bind
	private async _undoCmdUpdateFilterBackwardFunc(ident: number, data: IFilter): Promise<void> {
		const pk = this.identFilterPkMap.get(ident);
		if (pk === undefined) {
			// BIG ERROR
			logger.error('updateBackward: Got undo command without mapped identifier');
		} else {
			data.id = pk;
			await this._updateFilter(data);
		}
	}

	private _uniqueIdentForFilterPk(pk: number): number | undefined {
		for (const [ident, filterPk] of this.identFilterPkMap) {
			if (filterPk === pk) {
				return ident;
			}
		}
		return undefined;
	}

	private async updateFilter(data: {pk: number; data: Partial<IFilter>}): Promise<void> {
		let ident = this._uniqueIdentForFilterPk(data.pk);
		if (ident === undefined) {
			ident = generateUniqueIdent(this.identFilterPkMap);
			this.identFilterPkMap.set(ident, data.pk);
		}
		const current = await this.fetchFilter(data.pk);
		const updated = {...current, ...data.data};
		const cmd = new UpdateFilterCommand(
			ident,
			updated,
			this._undoCmdUpdateFilterForwardFunc,
			current,
			this._undoCmdUpdateFilterBackwardFunc);
		this.view.undoStack.push(cmd);
	}

	async _updateFilter(data: IFilter): Promise<IFilter> {
		const rv = await svc.filter.update(data);
		this.filterUpdated(rv);
		return rv;
	}
}

interface ICreateFilterCmdInfo {
	data: Partial<INewFilter>;
	ident: number;
}

function filterToFeature(filter: IFilter): GeoJsonFilterFeaturePerhapsWithoutGeometry {
	let circle: boolean = false;
	let center: Array<number> | undefined = undefined;
	let geometry: GeoJsonPolygon | GeoJsonMultiPolygon | null = null;
	if (filter.geometry) {
		if (filter.geometryIsCircle) {
			circle = true;
			geometry = {
				coordinates: (filter.geometry.coordinates.length > 0) ?
					filter.geometry.coordinates[0] :
					[],
				type: 'Polygon',
			};
			center = geometry.coordinates.length > 0 ?
				geometryCenter(geometry).geometry.coordinates :
				[];
		} else {
			geometry = filter.geometry;
		}
	}
	return {
		id: filter.id,
		geometry,
		properties: {
			center,
			circle,
			label: filter.label,
		},
		type: 'Feature',
	};
}

function ensureMultipolygon<P = GeoJsonProperties>(feature: GeoJsonFeature<GeoJsonPolygon | GeoJsonMultiPolygon, P>): GeoJsonFeature<GeoJsonMultiPolygon, P> {
	if (feature.geometry.type === 'MultiPolygon') {
		return <GeoJsonFeature<GeoJsonMultiPolygon, P>>feature;
	}
	return {
		...feature,
		geometry: {
			coordinates: [feature.geometry.coordinates],
			type: 'MultiPolygon',
		},
	};
}

function baseFilterObject(data?: Partial<IFilterBase>): IFilterBase {
	return {
		enabled: true,
		geometry: null,
		geometryIsCircle: false,
		icon: '',
		label: '',
		parentId: null,
		...(data || {}),
	};
}

function preparedFeatures<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Array<GeoJsonFeature<GeoJsonMultiPolygon, {circle: boolean;}>> {
	const rv: Array<GeoJsonFeature<GeoJsonMultiPolygon, {circle: boolean;}>> = [];
	for (let i = 0; i < features.length; ++i) {
		const feat = features[i];
		if (feat.id === undefined) {
			logger.warning('preparedFeatures: Got feature with no ID.');
			continue;
		}
		if (!feat.geometry) {
			logger.warning('preparedFeatures: Got feature with no geometry.');
			continue;
		}
		if (!((feat.geometry.type === 'Polygon') || (feat.geometry.type === 'MultiPolygon'))) {
			logger.warning('preparedFeatures: Got feature with invalid geometry type.');
			continue;
		}
		const circle: boolean = Boolean(feat.properties && (<{circle?: boolean;}>feat.properties).circle);
		const mpolyFeat = ensureMultipolygon(<GeoJsonFeature<GeoJsonPolygon | GeoJsonMultiPolygon>>feat);
		const properties = mpolyFeat.properties ?
			{...mpolyFeat.properties, circle} :
			{circle};
		rv.push({
			...mpolyFeat,
			properties,
		});
	}
	return rv;
}

function preparedUpdateFeatures<G extends GeoJsonGeometryNoCollection = GeoJsonGeometryNoCollection, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Array<GeoJsonFeature<GeoJsonMultiPolygon, {circle: boolean;}> & {id: number}> {
	const rv: Array<GeoJsonFeature<GeoJsonMultiPolygon, {circle: boolean;}> & {id: number}> = [];
	const prepped = preparedFeatures(features);
	for (let i = 0; i < prepped.length; ++i) {
		const feat = prepped[i];
		const featId = isNumber(feat.id) ?
			feat.id :
			Number(feat.id);
		if (!isNumber(featId)) {
			logger.warning('preparedFeatures: Got feature with invalid ID/ID type.');
			continue;
		}
		feat.id = featId;
		rv.push(<GeoJsonFeature<GeoJsonMultiPolygon, {circle: boolean;}> & {id: number}>feat);
	}
	return rv;
}

function filtersToFeatureCollection(filters: Array<IFilter>): {features: Array<GeoJsonFilterFeature>; type: 'FeatureCollection'} {
	const features: Array<GeoJsonFilterFeature> = [];
	for (let i = 0; i < filters.length; ++i) {
		const feature = filterToFeature(filters[i]);
		if (feature.geometry) {
			features.push(<GeoJsonFilterFeature>feature);
		}
	}
	return {
		features,
		type: 'FeatureCollection',
	};
}

class CreateFilterCommand extends UndoCommand {
	forwardData: Partial<INewFilter>;
	forwardFunc: (uniqueIdent: number, data: Partial<INewFilter>) => any;
	backwardFunc: (uniqueIdent: number) => any;
	uniqueIdent: number;

	constructor(uniqueIdent: number, forwardData: Partial<INewFilter>, forwardFunc: (uniqueIdent: number, data: Partial<INewFilter>) => any, backwardFunc: (uniqueIdent: number) => any) {
		super('create filter');
		this.backwardFunc = backwardFunc;
		this.forwardData = forwardData;
		this.forwardFunc = forwardFunc;
		this.uniqueIdent = uniqueIdent;
	}

	async redo(): Promise<void> {
		await this.forwardFunc(this.uniqueIdent, this.forwardData);
	}

	async undo(): Promise<void> {
		await this.backwardFunc(this.uniqueIdent);
	}
}

class DeleteFilterCommand extends UndoCommand {
	backwardData: IFilter;
	backwardFunc: (uniqueIdent: number, data: IFilter) => any;
	forwardFunc: (uniqueIdent: number) => any;
	uniqueIdent: number;

	constructor(uniqueIdent: number, backwardData: IFilter, backwardFunc: (uniqueIdent: number, data: IFilter) => any, forwardFunc: (uniqueIdent: number) => any) {
		super('delete filter');
		this.backwardData = backwardData;
		this.backwardFunc = backwardFunc;
		this.forwardFunc = forwardFunc;
		this.uniqueIdent = uniqueIdent;
	}

	async redo(): Promise<void> {
		await this.forwardFunc(this.uniqueIdent);
	}

	async undo(): Promise<void> {
		await this.backwardFunc(this.uniqueIdent, this.backwardData);
	}
}

class UpdateFilterCommand extends UndoCommand {
	uniqueIdent: number;
	forwardData: IFilter;
	backwardData: IFilter;
	forwardFunc: (uniqueIdent: number, data: IFilter) => any;
	backwardFunc: (uniqueIdent: number, data: IFilter) => any;

	constructor(uniqueIdent: number, forwardData: IFilter, forwardFunc: (uniqueIdent: number, data: IFilter) => any, backwardData: IFilter, backwardFunc: (uniqueIdent: number, data: IFilter) => any) {
		super('update filter');
		this.uniqueIdent = uniqueIdent;
		this.backwardData = backwardData;
		this.backwardFunc = backwardFunc;
		this.forwardData = forwardData;
		this.forwardFunc = forwardFunc;
	}

	async redo(): Promise<void> {
		await this.forwardFunc(this.uniqueIdent, this.forwardData);
	}

	async undo(): Promise<void> {
		await this.backwardFunc(this.uniqueIdent, this.backwardData);
	}
}

// FIXME: NOT SAFE!!
function generateUniqueIdent(checkMap: Map<number, any>): number {
	let ident: number = Math.trunc(Math.random() * 100000);
	while (checkMap.has(ident)) {
		ident = Math.trunc(Math.random() * 100000);
	}
	return ident;
}
