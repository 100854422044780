import {svc} from '../../request';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {FancyTable, ModelIndex, TableCellEl, TableItem, TableItemArgs, tableItemOpts, TableItemOpts} from '../../ui/table';
import {ItemDataRole, MetaType, Orientation} from '../../constants';
import {Variant} from '../../variant';
import {ToolButton} from '../../ui/toolbutton';
import {stringIterableToStringArray} from '../../util';
import {Dialog} from '../../ui/dialog';
import {FancyPushButton} from '../../ui/pushbutton';
import {getLogger} from '../../logging';
import {list} from '../../tools';

const logger = getLogger('views.donotmail');

@OBJ
class DoNotMailTableCellEl extends TableCellEl {
	private toolButton: ToolButton | null;

	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts<TableItemOpts>(a, b);
		const classNames = args.opts.classNames ?
			stringIterableToStringArray(args.opts.classNames) :
			[];
		args.opts.classNames = [
			'lb-do-not-mail-table-cell',
			...classNames,
		];
		super(args);
		this.toolButton = null;
	}

	destroy(): void {
		this.destroyToolButton();
		super.destroy();
	}

	private destroyToolButton(): void {
		if (this.toolButton) {
			Obj.disconnect(
				this.toolButton, 'clicked',
				this, '_toolButtonClicked');
			this.toolButton.destroy();
		}
		this.toolButton = null;
	}

	private initToolButton(): void {
		if (!this.toolButton) {
			this.toolButton = new ToolButton({
				classNames: [
					'lb-do-not-mail-table-row-tool-button',
				],
				parent: this,
			});
			Obj.connect(
				this.toolButton, 'clicked',
				this, '_toolButtonClicked');
		}
	}

	protected setDataForRole(role: number, value: Variant): void {
		switch (role) {
			case ItemDataRole.DecorationRole:
				const legitIcon = value.isValid() && !value.isNull() && (value.type() !== MetaType.Null);
				this.setToolButtonEnabled(legitIcon);
				if (legitIcon) {
					this.setToolButtonIcon(value.toString());
				}
				break;
			default:
				super.setDataForRole(role, value);
				break;
		}
	}

	private setToolButtonEnabled(enabled: boolean): void {
		if (enabled) {
			this.initToolButton();
		} else {
			this.destroyToolButton();
		}
	}

	private setToolButtonIcon(icon: string): void {
		if (this.toolButton) {
			this.toolButton.setIcon(icon);
		}
	}

	@SIGNAL
	private toolButtonClicked(index: ModelIndex): void {
	}

	@SLOT
	private _toolButtonClicked(): void {
		if (this.view) {
			this.toolButtonClicked(this.view.index(this));
		}
	}
}

@OBJ
export class DoNotMailView extends ElObj {
	private currentIndex: ModelIndex;
	private doNotMailList: list<IDoNotMail>;
	private dialog: Dialog | null;
	private dialogBody: DialogBody | null;
	private table: FancyTable;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		super(opts);
		this.currentIndex = new ModelIndex();
		this.doNotMailList = new list<IDoNotMail>();
		this.dialog = null;
		this.dialogBody = null;
		this.table = new FancyTable({
			classNames: [
				'width--100-percent',
			],
			itemPrototype: new DoNotMailTableCellEl(),
		});
		this.init();
	}

	private confirmDelete(): void {
		this.destroyDialog();
		this.dialogBody = new DialogBody();
		Obj.connect(
			this.dialogBody, 'canceled',
			this, 'removalCanceled');
		Obj.connect(
			this.dialogBody, 'confirmed',
			this, 'removalConfirmed');
		this.dialog = new Dialog({title: 'Remove from do not mail list?'});
		Obj.connect(
			this.dialog, 'closed',
			this, 'dialogClosed');
		this.dialog.appendElObj(this.dialogBody);
		this.dialog.open();
	}

	private async deleteGeoRef(geoRefPk: GeoRefPk): Promise<void> {
		return await svc.geoRef.delete(geoRefPk);
	}

	destroy(): void {
		this.currentIndex = new ModelIndex();
		this.doNotMailList.clear();
		this.destroyDialog();
		this.table.destroy();
		super.destroy();
	}

	private destroyDialog(): void {
		if (this.dialogBody) {
			Obj.disconnect(
				this.dialogBody, 'canceled',
				this, 'removalCanceled');
			Obj.disconnect(
				this.dialogBody, 'confirmed',
				this, 'removalConfirmed');
			this.dialogBody.destroy();
		}
		this.dialogBody = null;
		if (this.dialog) {
			Obj.disconnect(
				this.dialog, 'closed',
				this, 'dialogClosed');
			this.dialog.destroy();
		}
		this.dialog = null;
	}

	@SLOT
	private dialogClosed(): void {
		this.destroyDialog();
	}

	private async fetchDoNotMailList(): Promise<Array<IDoNotMail>> {
		return await svc.geoRef.doNotMailList();
	}

	private async init(): Promise<void> {
		this.doNotMailList = new list<IDoNotMail>(await this.fetchDoNotMailList());
		if (this.doNotMailList.size() > 0) {
			this.appendChild(this.table);
			this.table.setColumnCount(4);
			this.table.setHorizontalHeaderLabels([
				'',
				'Name',
				'Parcel Address',
				'Mailing Address',
			]);
			this.setTableItems(this.doNotMailList);
		} else {
			const el = new ElObj({
				classNames: [
					'text-align--center',
				],
				parent: this,
				tagName: 'div',
			});
			el.setText('Nothing here yet');
		}
	}

	@SLOT
	private itemToolButtonClicked(index: ModelIndex): void {
		if (index.isValid()) {
			this.currentIndex = index;
			this.confirmDelete();
		}
	}

	@SLOT
	private removalCanceled(): void {
		this.currentIndex = new ModelIndex();
		this.destroyDialog();
	}

	@SLOT
	private async removalConfirmed(): Promise<void> {
		if (this.currentIndex.isValid() && (this.currentIndex.row >= 0) && (this.currentIndex.row < this.doNotMailList.size())) {
			const obj = this.doNotMailList.at(this.currentIndex.row);
			await this.deleteGeoRef(obj.geoRefId);
			this.destroyDialog();
			window.location.reload();
		} else {
			logger.error('removalConfirmed: Current index is invalid');
		}
	}

	private setTableItems(objs: list<IDoNotMail>): void {
		this.table.clearContents();
		this.table.setRowCount(objs.size());
		for (let row = 0; row < objs.size(); ++row) {
			const obj = objs.at(row);
			this.table.setHeaderData(
				row,
				Orientation.Vertical,
				new Variant(''),
				ItemDataRole.DisplayRole);
			// ToolButton
			this.table.setData(
				new ModelIndex(row, 0),
				new Variant('clear'),
				ItemDataRole.DecorationRole);
			const it = this.table.item(row, 0);
			if (it) {
				Obj.connect(
					it, 'toolButtonClicked',
					this, 'itemToolButtonClicked');
			}
			// Name
			this.table.setData(
				new ModelIndex(row, 1),
				new Variant(obj.ownerName),
				ItemDataRole.DisplayRole);
			// Parcel address
			let parcAddr = addrStr(obj.parcelAddress);
			let nop: boolean = false;
			if (parcAddr.length < 1) {
				parcAddr = 'No data';
				nop = true;
			}
			this.table.setData(
				new ModelIndex(row, 2),
				new Variant(parcAddr),
				ItemDataRole.DisplayRole);
			if (nop) {
				this.table.setData(
					new ModelIndex(row, 2),
					new Variant('#A3ACB9'),
					ItemDataRole.ForegroundRole);
			}
			// Entity (mailing) address
			const entAddr = addrStr(obj.ownerAddress);
			this.table.setData(
				new ModelIndex(row, 2 + 1),
				new Variant(entAddr),
				ItemDataRole.DisplayRole);
		}
	}
}

function addrStr(addr: IAddress): string {
	const parts = [
		addr.streetAddress,
		addr.city,
		addr.state,
	];
	const keep: Array<string> = [];
	for (let i = 0; i < parts.length; ++i) {
		const part = parts[i].trim();
		if (part.length > 0) {
			keep.push(part);
		}
	}
	return keep.join(', ');
}

@OBJ
class DialogBody extends ElObj {
	private buttonBox: ElObj;
	cancelButton: FancyPushButton;
	submitButton: FancyPushButton;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.buttonBox = new ElObj({
			classNames: [
				'lb-button-box',
				'display--flex',
				'flex-direction--row',
				'justify-content--flex-end',
			],
			parent: this,
			tagName: 'div',
		});
		this.cancelButton = new FancyPushButton({
			filled: true,
			parent: this.buttonBox,
			text: 'Cancel',
		});
		Obj.connect(
			this.cancelButton, 'clicked',
			this, 'canceled');
		this.submitButton = new FancyPushButton({
			parent: this.buttonBox,
			text: 'Remove Record',
		});
		Obj.connect(
			this.submitButton, 'clicked',
			this, 'confirmed');
	}

	@SIGNAL
	private canceled(): void {
	}

	@SIGNAL
	private confirmed(): void {
	}
}
