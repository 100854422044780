import {MapboxGeoJSONFeature} from 'mapbox-gl';
import circle from '@turf/circle';
import distance from '@turf/distance';
import {point} from '@turf/helpers';

import {Mode} from './mode';
import {Feature} from '../features/feature';
import {
	isActiveFeature,
	moveFeatures,
	constrainFeatureMovement,
	createSupplementaryPoints,
	doubleClickZoom,
} from './util';
import {
	DrawMode,
	Cursor,
	DrawEventType,
	FeatureRole,
} from '../constants';
import {
	isMapMouseEvent,
	MapMouseFeatureEvt,
	MouseEvt,
	MapTouchFeatureEvt,
	MapMouseEvt,
	MapTouchEvt,
} from '../events';

interface DirectSelectState {
	canDragMove: boolean;
	dragMoveLocation: {lng: number; lat: number;} | null;
	dragMoving: boolean;
	feature: Feature;
	featureId: string;
	selectedCoordPaths: Array<string>;
}

export class DirectSelect extends Mode {
	name = DrawMode.DirectSelect;
	state!: DirectSelectState;

	protected dragEvent(evt: MapMouseEvt | MapTouchEvt) {
		if (!this.state.canDragMove) {
			return;
		}
		this.state.dragMoving = true;
		evt.event.originalEvent.stopPropagation();
		const lngLat = evt.event.lngLat;
		const delta = {
			// Following assertions occur due to this procedure being called
			// only after location object is instantiated.
			lng: lngLat.lng - this.state.dragMoveLocation!.lng,
			lat: lngLat.lat - this.state.dragMoveLocation!.lat,
		};
		if (this.state.selectedCoordPaths.length > 0) {
			this.dragVertex(lngLat, delta);
		} else {
			this.dragFeature(lngLat, delta);
		}
		this.state.dragMoveLocation = lngLat;
	}

	private dragFeature(lngLat: {lng: number; lat: number;}, delta: {lng: number; lat: number;}): void {
		const selected = this.getSelected();
		moveFeatures(selected, delta);
		for (let i = 0; i < selected.length; ++i) {
			const feat = selected[i];
			if (feat.properties.circle && feat.properties.center) {
				feat.properties.center[0] += delta.lng;
				feat.properties.center[1] += delta.lat;
			}
		}
		this.state.dragMoveLocation = lngLat;
	}

	private dragVertex(lngLat: {lng: number; lat: number;}, delta: {lng: number; lat: number;}): void {
		if (this.state.feature.properties.circle && this.state.feature.properties.center) {
			const center = this.state.feature.properties.center;
			const radius = distance(
				point(center),
				point([lngLat.lng, lngLat.lat]),
				{units: 'kilometers'});
			this.state.feature.incomingCoords(
				circle(center, radius).geometry.coordinates);
			this.state.feature.properties.radiusInKm = radius;
		} else {
			const selectedCoords = this.state.selectedCoordPaths
				.map(coord_path => this.state.feature.getCoordinate(coord_path));
			const selectedCoordPoints = selectedCoords.map(coords => ({
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Point',
					coordinates: coords,
				},
			}));
			const constrainedDelta = constrainFeatureMovement(
				<Array<GeoJsonFeature<GeoJsonPoint>>>selectedCoordPoints,
				delta);
			for (let i = 0; i < selectedCoords.length; i++) {
				const coord = selectedCoords[i];
				this.state.feature.updateCoordinate(
					this.state.selectedCoordPaths[i],
					coord[0] + constrainedDelta.lng,
					coord[1] + constrainedDelta.lat);
			}
		}
	}

	fireUpdate(): void {
		this.fireEvent(DrawEventType.Update,
			{
				action: 'change_coordinates',
				features: this.getSelected().map(f => f.toGeoJSON()),
			});
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt) {
		this.touchTapClickEvent(evt);
	}

	protected mouseMoveEvent(evt: MapMouseFeatureEvt) {
		// On mousemove that is not a drag, stop vertex movement.
		const noCoords = this.state.selectedCoordPaths.length === 0;
		if (isActiveFeature(evt.featureTarget) && noCoords) {
			this.updateUIClasses({mouse: Cursor.Move});
		} else if (isVertex(evt) && !noCoords) {
			this.updateUIClasses({mouse: Cursor.Move});
		} else {
			this.updateUIClasses({mouse: Cursor.None});
		}
		this.stopDragging();
		// Skip render
		evt.needsRender = false;
	}

	protected mouseOutEvent(evt: MouseEvt) {
		// As soon as you mouse leaves the canvas, update the feature
		if (this.state.dragMoving) {
			this.fireUpdate();
		}
		// Skip render
		evt.needsRender = false;
	}

	protected mousePressEvent(evt: MapMouseFeatureEvt) {
		this.touchBeginMousePressEvent(evt);
	}

	protected mouseReleaseEvent(evt: MapMouseFeatureEvt) {
		this.touchEndMouseReleaseEvent();
	}

	onFeature(event: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		if (this.state.selectedCoordPaths.length === 0) {
			this.startDragging(event.event.lngLat);
		} else {
			this.stopDragging();
		}
	}

	onMidpoint(event: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		this.startDragging(event.event.lngLat);
		if (event.featureTarget) {
			const about = event.featureTarget.properties || {};
			this.state.feature.addCoordinate(
				about.coord_path,
				about.lng,
				about.lat);
			this.fireUpdate();
			this.state.selectedCoordPaths = [about.coord_path];
		}
	}

	onVertex(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		this.startDragging(evt.event.lngLat);
		if (evt.featureTarget) {
			const about = evt.featureTarget.properties || {};
			const selectedIndex = this.state.selectedCoordPaths.indexOf(about.coord_path);
			if ((selectedIndex === -1) && isMapMouseEvent(evt.event)) {
				if (evt.event.originalEvent.shiftKey) {
					this.state.selectedCoordPaths.push(about.coord_path);
				} else {
					this.state.selectedCoordPaths = [about.coord_path];
				}
			}
		}
		const selectedCoordinates = this.pathsToCoordinates(
			this.state.featureId,
			this.state.selectedCoordPaths);
		this.setSelectedCoordinates(selectedCoordinates);
	}

	pathsToCoordinates(featureId: string, paths: Array<string>): Array<{feature_id: string; coord_path: string;}> {
		return paths.map(coord_path => ({
			feature_id: featureId,
			coord_path,
		}));
	}

	setup(opts: {featureId: string; coordPath?: string; startPos?: {lng: number; lat: number;}}): void {
		const featureId = opts.featureId;
		let feature = this.getFeature(featureId);
		if (!feature) {
			throw new Error('You must provide a featureId to enter direct select mode');
		}
		if (feature.type === 'Point') {
			throw new TypeError('direct select mode doesn\'t handle point features');
		}
		this.state = {
			feature,
			featureId,
			dragMoveLocation: opts.startPos || null,
			dragMoving: false,
			canDragMove: false,
			selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
		};
		this.setSelectedCoordinates(this.pathsToCoordinates(featureId, this.state.selectedCoordPaths));
		this.setSelected([featureId]);
		doubleClickZoom.disable(this.ctx);
	}

	startDragging(lngLat: {lng: number; lat: number;}): void {
		this.ctx.map && this.ctx.map.dragPan.disable();
		this.state.canDragMove = true;
		this.state.dragMoveLocation = lngLat;
	}

	stop(): void {
		doubleClickZoom.enable(this.ctx);
		const ids = this.getSelectedIds();
		if (ids.length > 0) {
			this.deselect(ids);
		}
		this.clearSelectedCoordinates();
	}

	stopDragging(): void {
		this.ctx.map && this.ctx.map.dragPan.enable();
		this.state.dragMoving = false;
		this.state.canDragMove = false;
		this.state.dragMoveLocation = null;
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		if (this.state.featureId === feature.properties.id) {
			feature.properties.active = 'true';
			display(feature);
			createSupplementaryPoints(
				feature,
				{
					map: this.ctx.map,
					midpoints: !Boolean(feature.properties.circle),
					selectedPaths: this.state.selectedCoordPaths,
				},
				null).forEach(display);
		} else {
			feature.properties.active = 'false';
			display(feature);
		}
	}

	protected touchBeginEvent(evt: MapTouchFeatureEvt) {
		this.touchBeginMousePressEvent(evt);
	}

	private touchBeginMousePressEvent(evt: MapMouseFeatureEvt | MapTouchFeatureEvt) {
		if (isVertex(evt)) {
			this.onVertex(evt);
		} else if (isActiveFeature(evt.featureTarget)) {
			this.onFeature(evt);
		} else if (isMidpoint(evt)) {
			this.onMidpoint(evt);
		}
	}

	protected touchEndEvent(evt: MapTouchFeatureEvt) {
		this.touchEndMouseReleaseEvent();
	}

	private touchEndMouseReleaseEvent(): void {
		if (this.state.dragMoving) {
			this.fireUpdate();
		}
		this.stopDragging();
	}

	private touchTapClickEvent(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		if (evt.featureTarget) {
			if (isActiveFeature(evt.featureTarget)) {
				this.state.selectedCoordPaths = [];
				this.clearSelectedCoordinates();
				this.state.feature.changed();
			} else if (isInactiveFeature(evt)) {
				this.changeMode(
					DrawMode.SimpleSelect,
					{
						featureIds: (evt.featureTarget.properties && (evt.featureTarget.properties.id !== undefined)) ?
							[evt.featureTarget.properties.id] :
							[],
					});
			} else {
				this.stopDragging();
			}
		} else {
			this.changeMode(DrawMode.SimpleSelect);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt) {
		this.touchTapClickEvent(evt);
	}

	trash(): void {
		// Uses number-aware sorting to make sure '9' < '10'. Comparison is reversed because we want them
		// in reverse order so that we can remove by index safely.
		this.state.selectedCoordPaths
			.sort((a, b) => b.localeCompare(a, 'en', {numeric: true}))
			.forEach(id => this.state.feature.removeCoordinate(id));
		this.fireUpdate();
		this.state.selectedCoordPaths = [];
		this.clearSelectedCoordinates();
		if (!this.state.feature.isValid()) {
			this.deleteFeature([this.state.featureId]);
			this.changeMode(DrawMode.SimpleSelect, {});
		}
		super.trash();
	}
}

function isInactiveFeature(event: {featureTarget?: MapboxGeoJSONFeature | null}): boolean {
	if (event.featureTarget && event.featureTarget.properties) {
		return (event.featureTarget.properties.active === 'false') && (event.featureTarget.properties.meta === FeatureRole.Feature);
	}
	return false;
}

function isOfMetaType(type: string): (event: {featureTarget?: MapboxGeoJSONFeature | null}) => boolean {
	return function (event: {featureTarget?: MapboxGeoJSONFeature | null}): boolean {
		if (event.featureTarget && event.featureTarget.properties) {
			return event.featureTarget.properties.meta === type;
		}
		return false;
	};
}

const isVertex = isOfMetaType(FeatureRole.Vertex);
const isMidpoint = isOfMetaType(FeatureRole.Midpoint);
