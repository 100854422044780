import {Obj, OBJ, SLOT} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {TierTable} from './tiertable';
import {ComboBox} from '../../ui/combobox';
import {FancyPushButton} from '../../ui/pushbutton';
import {TextInput} from '../../ui/textinput';
import {stringIterableToStringArray} from '../../util';
import {Checkbox} from '../../ui/checkbox';

enum PricingModel {
	Standard,
	Volume,
}

const pricingModelChoices: Array<[string, string]> = [
	['standard', 'Standard pricing'],
	['volume', 'Volume pricing'],
];

@OBJ
export class PriceCreateView extends ElObj {
	private currentPriceModel: PricingModel;
	private defaultCheckbox: Checkbox;
	private labelInput: TextInput;
	private priceModelComboBox: ComboBox;
	private pricingView: PricingView;
	private submitBtn: FancyPushButton;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.root = document.getElementById('id_lb-price-create');
		super(opts);
		this.currentPriceModel = PricingModel.Standard;
		const checkboxContainer = new ElObj({
			classNames: [
				'mdc-form-field',
				'lb-vertical-input-box--field',
			],
			parent: this,
			styles: [
				['margin-left', '-8px'],
			],
			tagName: 'div',
		});
		const defaultCheckboxId = `id_lb-price-default-input`;
		this.defaultCheckbox = new Checkbox({
			inputId: defaultCheckboxId,
			inputName: 'default',
			parent: checkboxContainer,
		});
		const defaultCheckboxLabel = new ElObj({
			attributes: [
				['for', defaultCheckboxId],
			],
			parent: checkboxContainer,
			tagName: 'label',
		});
		defaultCheckboxLabel.setText('Default price');
		this.labelInput = new TextInput({
			classNames: [
				'lb-vertical-input-box--field',
			],
			fullWidth: true,
			labelText: 'Label (optional, only you will see it)',
			outlined: true,
			name: 'label',
			parent: this,
		});
		this.priceModelComboBox = new ComboBox({
			attributes: [
				['name', 'pricing_model'],
			],
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		this.pricingView = new PricingView({
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		const axnBox = new ElObj({
			classNames: [
				'lb-vertical-input-box--actions',
			],
			parent: this,
			tagName: 'div',
		});
		this.submitBtn = new FancyPushButton({
			parent: axnBox,
			raised: true,
			text: 'Create Price',
			type: 'submit',
		});
		this.init();
	}

	destroy(): void {
		this.priceModelComboBox.destroy();
		this.currentPriceModel = PricingModel.Standard;
		super.destroy();
	}

	private init(): void {
		Obj.connect(
			this.priceModelComboBox, 'currentIndexChanged',
			this, 'priceModelComboBoxIndexChanged');
		for (const [value, label] of pricingModelChoices) {
			this.priceModelComboBox.addItem(label, value);
		}
	}

	@SLOT
	private priceModelComboBoxIndexChanged(): void {
		let newModel: PricingModel;
		switch (this.currentPriceModel) {
			case PricingModel.Standard:
				newModel = PricingModel.Volume;
				break;
			case PricingModel.Volume:
				newModel = PricingModel.Standard;
				break;
		}
		this.setCurrentPriceModel(newModel);
	}

	private setCurrentPriceModel(model: PricingModel): void {
		if (model === this.currentPriceModel) {
			return;
		}
		this.currentPriceModel = model;
		this.pricingView.setCurrentPricingModelView(
			this.currentPriceModel);
	}
}

@OBJ
class PricingView extends ElObj {
	private stdPricingView: StandardPricingView;
	private volPricingView: VolumePricingView;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'div';
		super(opts);
		this.stdPricingView = new StandardPricingView({
			parent: this,
		});
		this.volPricingView = new VolumePricingView();
		this.volPricingView.hide();
		this.appendChild(this.volPricingView);
	}

	destroy(): void {
		this.stdPricingView.destroy();
		this.volPricingView.destroy();
		super.destroy();
	}

	setCurrentPricingModelView(model: PricingModel): void {
		switch (model) {
			case PricingModel.Standard:
				this.volPricingView.hide();
				this.stdPricingView.show();
				break;
			case PricingModel.Volume:
				this.stdPricingView.hide();
				this.volPricingView.show();
				break;
		}
	}
}

@OBJ
class StandardPricingView extends ElObj {
	priceInput: TextInput;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--column',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.priceInput = new TextInput({
			classNames: [
				'lb-price-create-row',
			],
			labelText: 'Price',
			outlined: true,
			name: 'unit_amount',
			parent: this,
		});
	}
}

@OBJ
class VolumePricingView extends ElObj {
	addTierButton: FancyPushButton;
	tierTable: TierTable;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--column',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		const btnContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
			],
			parent: this,
			tagName: 'div',
		});
		this.addTierButton = new FancyPushButton({
			classNames: [
				'align-self--flex-end',
			],
			leadingIcon: {name: 'add'},
			parent: btnContainer,
			text: 'Add another tier',
		});
		Obj.connect(
			this.addTierButton, 'clicked',
			this, 'addTierButtonClicked');
		this.tierTable = new TierTable({
			parent: this,
		});
	}

	@SLOT
	private addTierButtonClicked(): void {
		this.tierTable.addRow();
	}

	destroy(): void {
		Obj.disconnect(
			this.addTierButton, 'clicked',
			this, 'addTierButtonClicked');
		this.addTierButton.destroy();
		this.tierTable.destroy();
		super.destroy();
	}
}
