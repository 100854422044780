import {
	MapMouseEvent,
	MapTouchEvent,
} from 'mapbox-gl';

import {Mode} from './mode';
import {Polygon} from '../features/polygon';
import {
	isVertex,
	createVertex,
	isAtCoordinates,
	doubleClickZoom,
} from './util';
import {
	Cursor,
	DrawMode,
	DrawEventType,
} from '../constants';
import {
	KeyboardEvt,
	MapMouseFeatureEvt,
	MapTouchFeatureEvt,
} from '../events';

export class DrawPolygon extends Mode {
	name = DrawMode.DrawPolygon;
	state!: {polygon: Polygon; currentVertexPosition: number;};

	clickAnywhere(event: MapMouseEvent | MapTouchEvent): void {
		if ((this.state.currentVertexPosition > 0) && isAtCoordinates(event.lngLat, <[number, number]>(<Array<Array<number>>>this.state.polygon.coordinates[0])[this.state.currentVertexPosition - 1])) {
			// this.changeMode(
			// 	DrawMode.SimpleSelect,
			// 	{featureIds: [this.state.polygon.id]});
			this.changeMode(DrawMode.SimpleSelect);
		} else {
			this.updateUIClasses({mouse: Cursor.Add});
			this.state.polygon.updateCoordinate(
				`0.${this.state.currentVertexPosition}`,
				event.lngLat.lng,
				event.lngLat.lat);
			this.state.currentVertexPosition++;
			this.state.polygon.updateCoordinate(
				`0.${this.state.currentVertexPosition}`,
				event.lngLat.lng,
				event.lngLat.lat);
		}
	}

	clickOnVertex(): void {
		// this.changeMode(
		// 	DrawMode.SimpleSelect,
		// 	{featureIds: [this.state.polygon.id]});
		this.changeMode(DrawMode.SimpleSelect);
	}

	protected keyPressEvent(evt: KeyboardEvt) {
		switch (evt.event.key) {
			case 'Escape':
				this.deleteFeature([this.state.polygon.id], {silent: true});
				this.changeMode(DrawMode.SimpleSelect);
				break;
			case 'Enter':
				// this.changeMode(
				// 	DrawMode.SimpleSelect,
				// 	{featureIds: [this.state.polygon.id]});
				this.changeMode(DrawMode.SimpleSelect);
				break;
		}
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt): void {
		this.touchTapMouseClickEventEvent(evt);
	}

	protected mouseMoveEvent(evt: MapMouseFeatureEvt): void {
		this.state.polygon.updateCoordinate(
			`0.${this.state.currentVertexPosition}`,
			evt.event.lngLat.lng,
			evt.event.lngLat.lat);
		if (isVertex(evt.featureTarget)) {
			this.updateUIClasses({mouse: Cursor.Pointer});
		}
	}

	setup(): void {
		const polygon = <Polygon>this.newFeature({
			type: 'Feature',
			properties: {},
			geometry: {
				type: 'Polygon',
				coordinates: [],
			},
		});
		this.addFeature(polygon);
		this.clearSelectedFeatures();
		doubleClickZoom.disable(this.ctx);
		this.updateUIClasses({mouse: Cursor.Add});
		this.activateUIButton('Polygon');
		this.state = {
			polygon,
			currentVertexPosition: 0,
		};
	}

	stop(): void {
		this.updateUIClasses({mouse: Cursor.None});
		doubleClickZoom.enable(this.ctx);
		this.ctx.ui.deactivateButtons();
		// check to see if we've deleted this feature
		if (this.getFeature(this.state.polygon.id)) {
			//remove last added coordinate
			this.state.polygon.removeCoordinate(
				`0.${this.state.currentVertexPosition}`);
			if (this.state.polygon.isValid()) {
				this.fireEvent(DrawEventType.Create,
					{features: [this.state.polygon.toGeoJSON()]});
			} else {
				this.deleteFeature([this.state.polygon.id], {silent: true});
				// this.changeMode(DrawMode.SimpleSelect, {}, {silent: true});
				this.changeMode(DrawMode.SimpleSelect);
			}
		}
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		const isActivePolygon = feature.properties.id === this.state.polygon.id;
		feature.properties.active = isActivePolygon ?
			'true' :
			'false';
		if (isActivePolygon) {
			// Don't render a polygon until it has filterbox positions
			// (and a 3rd which is just the first repeated)
			if (feature.geometry.coordinates.length === 0) {
				return;
			}
			const coordinateCount = (<Array<number>>feature.geometry.coordinates[0]).length;
			// 2 coordinates after selecting a draw type
			// 3 after creating the first point
			if (coordinateCount < 3) {
				return;
			}
			feature.properties.meta = 'feature';
			display(createVertex(
				this.state.polygon.id,
				(<Array<[number, number]>>feature.geometry.coordinates[0])[0],
				'0.0',
				false));
			if (coordinateCount > 3) {
				// Add a start position marker to the map, clicking on this will finish the feature
				// This should only be shown when we're in a valid spot
				const endPos = (<Array<[number, number]>>feature.geometry.coordinates[0]).length - 3;
				display(createVertex(
					this.state.polygon.id,
					(<Array<[number, number]>>feature.geometry.coordinates[0])[endPos],
					`0.${endPos}`,
					false));
			}
			if (coordinateCount <= 4) {
				// If we've only drawn filterbox positions (plus the closer),
				// make a LineString instead of a Polygon
				const lineCoordinates: Array<[number, number]> = [
					[
						(<Array<[number, number]>>feature.geometry.coordinates[0])[0][0],
						(<Array<[number, number]>>feature.geometry.coordinates[0])[0][1],
					],
					[
						(<Array<[number, number]>>feature.geometry.coordinates[0])[1][0],
						(<Array<[number, number]>>feature.geometry.coordinates[0])[1][1],
					],
				];
				// create an initial vertex so that we can track the first point on mobile devices
				display({
					type: 'Feature',
					properties: feature.properties,
					geometry: {
						coordinates: lineCoordinates,
						type: 'LineString',
					},
				});
				if (coordinateCount === 3) {
					return;
				}
			}
			// render the Polygon
			display(feature);
		} else {
			display(feature);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt): void {
		this.touchTapMouseClickEventEvent(evt);
	}

	private touchTapMouseClickEventEvent(evt: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		if (isVertex(evt.featureTarget)) {
			this.clickOnVertex();
		} else {
			this.clickAnywhere(evt.event);
		}
	}

	trash(): void {
		this.deleteFeature([this.state.polygon.id], {silent: true});
		this.changeMode(DrawMode.SimpleSelect);
		super.trash();
	}
}
