import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {ToolButton} from '../../ui/toolbutton';
import {stringIterableToStringArray} from '../../util';
import type {ProjectDetailView} from './view';

@OBJ
export class UndoView extends ElObj {
	private buttons: {redo: ToolButton; undo: ToolButton;};
	private disabled: boolean;
	private txt: {redo: ElObj; undo: ElObj;};
	private view: ProjectDetailView | null;

	constructor(view: ProjectDetailView) {
		const opts: Partial<ElObjOpts> = {};
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'display--flex',
			'flex-direction--row',
			'align-items--center',
			'padding-top--8',
			'justify-content--center',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.disabled = false;
		this.view = view;
		const undoContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
				'align-items--center',
				'margin-right--16',
			],
			parent: this,
			tagName: 'div',
		});
		const undoLabel = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--body2',
				'lb-project-detail-undo-redo-label--disabled',
			],
			parent: undoContainer,
			tagName: 'span',
		});
		undoLabel.setText('undo');
		const redoContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
				'align-items--center',
				'margin-left--16',
			],
			parent: this,
			tagName: 'div',
		});
		const redoLabel = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--body2',
				'lb-project-detail-undo-redo-label--disabled',
			],
			parent: redoContainer,
			tagName: 'span',
		});
		redoLabel.setText('redo');
		this.buttons = {
			redo: new ToolButton({
				classNames: [
					'lb-project-detail-undo-redo-action',
				],
				icon: 'redo',
				parent: redoContainer,
			}),
			undo: new ToolButton({
				classNames: [
					'lb-project-detail-undo-redo-action',
				],
				icon: 'undo',
				parent: undoContainer,
			}),
		};
		this.buttons.undo.setDisabled(true);
		this.buttons.redo.setDisabled(true);
		this.txt = {redo: redoLabel, undo: undoLabel};
		Obj.connect(
			view.undoStack, 'canRedoChanged',
			this, 'canRedoChanged');
		Obj.connect(
			view.undoStack, 'canUndoChanged',
			this, 'canUndoChanged');
		Obj.connect(
			view.undoStack, 'redoTextChanged',
			this, 'redoTextChanged');
		Obj.connect(
			view.undoStack, 'undoTextChanged',
			this, 'undoTextChanged');
		Obj.connect(
			this.buttons.redo, 'clicked',
			this, 'redoClicked');
		Obj.connect(
			this.buttons.undo, 'clicked',
			this, 'undoClicked');
	}

	@SIGNAL
	private actionActivated(action: 'redo' | 'undo'): void {
	}

	@SLOT
	private canRedoChanged(canRedo: boolean): void {
		this.buttons.redo.setEnabled(canRedo);
		this.txt.redo.setClass(!canRedo, 'lb-project-detail-undo-redo-label--disabled');
	}

	@SLOT
	private canUndoChanged(canUndo: boolean): void {
		this.buttons.undo.setEnabled(canUndo);
		this.txt.undo.setClass(!canUndo, 'lb-project-detail-undo-redo-label--disabled');
	}

	destroy(): void {
		if (this.view) {
			Obj.disconnect(
				this.view.undoStack, 'canRedoChanged',
				this, 'canRedoChanged');
			Obj.disconnect(
				this.view.undoStack, 'canUndoChanged',
				this, 'canUndoChanged');
			Obj.disconnect(
				this.view.undoStack, 'redoTextChanged',
				this, 'redoTextChanged');
			Obj.disconnect(
				this.view.undoStack, 'undoTextChanged',
				this, 'undoTextChanged');
		}
		Obj.disconnect(
			this.buttons.redo, 'clicked',
			this, 'redoClicked');
		Obj.disconnect(
			this.buttons.undo, 'clicked',
			this, 'undoClicked');
		this.buttons.redo.destroy();
		this.buttons.undo.destroy();
		this.view = null;
		super.destroy();
	}

	@SLOT
	private redoClicked(): void {
		this.actionActivated('redo');
	}

	@SLOT
	private redoTextChanged(redoText: string): void {
		this.buttons.redo.setTitle((redoText.trim().length > 0) ?
			`redo ${redoText}` :
			'');
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.buttons.redo.setDisabled(this.disabled);
		this.txt.redo.setClass(disabled, 'lb-project-detail-undo-redo-label--disabled');
		this.buttons.undo.setDisabled(this.disabled);
		this.txt.undo.setClass(disabled, 'lb-project-detail-undo-redo-label--disabled');
		if (this.disabled) {
			if (this.view) {
				Obj.disconnect(
					this.view.undoStack, 'canRedoChanged',
					this, 'canRedoChanged');
				Obj.disconnect(
					this.view.undoStack, 'canUndoChanged',
					this, 'canUndoChanged');
				Obj.disconnect(
					this.view.undoStack, 'redoTextChanged',
					this, 'redoTextChanged');
				Obj.disconnect(
					this.view.undoStack, 'undoTextChanged',
					this, 'undoTextChanged');
			}
		} else {
			if (this.view) {
				Obj.connect(
					this.view.undoStack, 'canRedoChanged',
					this, 'canRedoChanged');
				Obj.connect(
					this.view.undoStack, 'canUndoChanged',
					this, 'canUndoChanged');
				Obj.connect(
					this.view.undoStack, 'redoTextChanged',
					this, 'redoTextChanged');
				Obj.connect(
					this.view.undoStack, 'undoTextChanged',
					this, 'undoTextChanged');
			}
		}
	}

	@SLOT
	private undoClicked(): void {
		this.actionActivated('undo');
	}

	@SLOT
	private undoTextChanged(undoText: string): void {
		this.buttons.undo.setTitle((undoText.trim().length > 0) ?
			`undo ${undoText}` :
			'');
	}
}
