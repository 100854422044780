export enum CssClassName {
	ActiveButton = 'active',
	Attribution = 'mapboxgl-ctrl-attrib',
	BoxSelect = 'mapbox-gl-draw_boxselect',
	ControlBase = 'mapboxgl-ctrl',
	ControlButton = 'mapbox-gl-draw_ctrl-draw-btn lb-map-control',
	ControlButtonCircle = 'mapbox-gl-draw_circle',
	ControlButtonCombineFeatures = 'mapbox-gl-draw_combine',
	ControlButtonLine = 'mapbox-gl-draw_line',
	ControlButtonPoint = 'mapbox-gl-draw_point',
	ControlButtonPolygon = 'mapbox-gl-draw_polygon',
	ControlButtonTrash = 'mapbox-gl-draw_trash',
	ControlButtonUncombineFeatures = 'mapbox-gl-draw_uncombine',
	ControlGroup = 'mapboxgl-ctrl-group',
}

export enum Cursor {
	Add = 'add',
	Drag = 'drag',
	Move = 'move',
	None = 'none',
	Pointer = 'pointer',
}

export enum DrawEventType {
	CombineFeatures = 'draw.combine',
	Create = 'draw.create',
	Delete = 'draw.delete',
	ModeChange = 'draw.modechange',
	SelectionChange = 'draw.selectionchange',
	UncombineFeatures = 'draw.uncombine',
	Update = 'draw.update',
}

export enum DrawMode {
	DirectSelect = 'directselect',
	DrawCircle = 'drawcircle',
	DrawLineString = 'drawlinestring',
	DrawPoint = 'drawpoint',
	DrawPolygon = 'drawpolygon',
	SimpleSelect = 'simpleselect',
	Static = 'static',
}

export enum EvtType {
	None = 0,
	Destroy = 16,
	DragEnter = 60,
	DragLeave = 62,
	DragMove = 61,
	EnabledChange = 98,
	Enter = 10,
	FocusIn = 8,
	FocusOut = 9,
	HoverEnter = 127,
	HoverLeave = 128,
	HoverMove = 129,
	KeyPress = 6,
	KeyRelease = 7,
	Leave = 11,
	MouseButtonClick = 1,
	MouseButtonDblClick = 4,
	MouseButtonPress = 2,
	MouseButtonRelease = 3,
	MouseDrag = 599,
	MouseMove = 5,
	TouchBegin = 194,
	TouchDrag = 377,
	TouchEnd = 196,
	TouchTap = 454,
	TouchUpdate = 195,
}

export enum FeatureRole {
	Feature = 'feature',
	Midpoint = 'midpoint',
	Vertex = 'vertex',
}

// Sync with constants::MapLayerId
export const DRAW_MAP_LAYER_ID_PREFIX = 'gl-draw-';

// Sync with constants::DRAW_MAP_LAYER_ID_PREFIX
export enum MapLayerId {
	LineActive = 'gl-draw-line-active',
	LineInactive = 'gl-draw-line-inactive',
	LineStatic = 'gl-draw-line-static',
	PointActive = 'gl-draw-point-active',
	PointInactive = 'gl-draw-point-inactive',
	PointPointStrokeInactive = 'gl-draw-point-point-stroke-inactive',
	PointStatic = 'gl-draw-point-static',
	PointStrokeActive = 'gl-draw-point-stroke-active',
	PolygonAndLineVertexInactive = 'gl-draw-polygon-and-line-vertex-inactive',
	PolygonAndLineVertexStrokeInactive = 'gl-draw-polygon-and-line-vertex-stroke-inactive',
	PolygonFillActive = 'gl-draw-polygon-fill-active',
	PolygonFillInactive = 'gl-draw-polygon-fill-inactive',
	PolygonFillStatic = 'gl-draw-polygon-fill-static',
	PolygonMidpoint = 'gl-draw-polygon-midpoint',
	PolygonStrokeActive = 'gl-draw-polygon-stroke-active',
	PolygonStrokeInactive = 'gl-draw-polygon-stroke-inactive',
	PolygonStrokeStatic = 'gl-draw-polygon-stroke-static',
}

export enum MapSourceId {
	Cold = 'mapbox-gl-draw-cold',
	Hot = 'mapbox-gl-draw-hot',
}

export const WGS84_RADIUS = 6378137;
export const WGS84_FLATTENING = 1 / 298.257223563;
export const WGS84_POLAR_RADIUS = 6356752.3142;
