import circle from '@turf/circle';
import distance from '@turf/distance';
import {point} from '@turf/helpers';

import {Mode} from './mode';
import {Polygon} from '../features/polygon';
import {ButtonId} from '../ui';
import {IMapboxDrawContext} from '../mapboxdraw';
import {
	MapMouseFeatureEvt,
	MapTouchFeatureEvt,
	MapMouseEvt,
	MapTouchEvt, KeyboardEvt,
} from '../events';
import {
	Cursor,
	DrawEventType,
	DrawMode,
} from '../constants';

interface CirclePolygonFeatureProperties {
	center: [number, number];
	circle: boolean;
	radiusInKm: number;
}

interface CirclePolygonFeature extends Polygon {
	properties: CirclePolygonFeatureProperties;
}

export class DrawCircle extends Mode {
	name = DrawMode.DrawCircle;
	state!: {currentVertexPosition: number; polygon: CirclePolygonFeature;};

	protected dragEvent(evt: MapMouseEvt | MapTouchEvt) {
		const center = this.state.polygon.properties.center;
		if (center.length > 0) {
			const {lat, lng} = evt.event.lngLat;
			const distanceInKm = distance(
				point(center),
				point([lng, lat]),
				{units: 'kilometers'});
			const circleFeat = circle(center, distanceInKm);
			this.state.polygon.incomingCoords(circleFeat.geometry.coordinates);
			this.state.polygon.properties.radiusInKm = distanceInKm;
		}
	}

	protected keyPressEvent(evt: KeyboardEvt): void {
		if (evt.event.key === 'Escape') {
			this.deleteFeature([this.state.polygon.id], {silent: true});
			this.changeMode(DrawMode.SimpleSelect);
		}
	}

	protected mousePressEvent(evt: MapMouseFeatureEvt) {
		this.touchBeginMousePressEvent(evt);
	}

	protected mouseReleaseEvent(evt: MapMouseFeatureEvt) {
		this.touchEndMouseReleaseEvent(evt);
	}

	setup(options?: any): void {
		super.setup(options);
		const polygon = <CirclePolygonFeature>this.newFeature({
			geometry: {
				coordinates: [],
				type: 'Polygon',
			},
			properties: {
				center: [],
				circle: true,
				radiusInKm: 0,
			},
			type: 'Feature',
		});
		this.state = {
			currentVertexPosition: 0,
			polygon,
		};
		this.addFeature(polygon);
		this.clearSelectedFeatures();
		dragPan.disable(this.ctx);
		this.updateUIClasses({mouse: Cursor.Add});
		this.activateUIButton(ButtonId.Circle);
	}

	stop(): void {
		dragPan.enable(this.ctx);
		this.updateUIClasses({mouse: Cursor.None});
		this.ctx.ui.deactivateButtons();
		// check to see if we've deleted this feature
		if (this.getFeature(this.state.polygon.id)) {
			if (this.state.polygon.isValid()) {
				this.fireEvent(DrawEventType.Create,
					{features: [this.state.polygon.toGeoJSON()]});
			} else {
				this.deleteFeature([this.state.polygon.id], {silent: true});
				this.changeMode(DrawMode.SimpleSelect, {}, {silent: true});
			}
		}
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		feature.properties.active = (feature.properties.id === this.state.polygon.id) ?
			'true' :
			'false';
		display(feature);
	}

	protected touchBeginEvent(evt: MapTouchFeatureEvt) {
		this.touchBeginMousePressEvent(evt);
	}

	private touchBeginMousePressEvent(event: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		if (this.state.polygon.properties.center.length < 1) {
			const {lat, lng} = event.event.lngLat;
			this.state.polygon.properties.center = [lng, lat];
		}
	}

	protected touchEndEvent(evt: MapTouchFeatureEvt) {
		this.touchEndMouseReleaseEvent(evt);
	}

	private touchEndMouseReleaseEvent(event: MapMouseFeatureEvt | MapTouchFeatureEvt): void {
		this.changeMode(
			DrawMode.SimpleSelect,
			{featureIds: [this.state.polygon.id]});
	}

	trash(): void {
		this.deleteFeature([this.state.polygon.id], {silent: true});
		this.changeMode(DrawMode.SimpleSelect);
		super.trash();
	}
}

const dragPan = {
	enable(ctx: IMapboxDrawContext) {
		setTimeout(() => {
			if (!ctx.map || !ctx.map.dragPan) {
				return;
			}
			const enabled: boolean | null = ctx.store.getInitialConfigValue('dragPan');
			if (typeof enabled === 'boolean') {
				if (enabled) {
					ctx.map.dragPan.enable();
				}
			}
		}, 0);
	},
	disable(ctx: IMapboxDrawContext) {
		setTimeout(() => {
			if (!ctx.map || !ctx.map.dragPan) {
				return;
			}
			ctx.map.dragPan.disable();
		}, 0);
	},
};
