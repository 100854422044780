import {getLogger} from '../../logging';
import {ElObj} from '../../elobj';

const logger = getLogger('archivedprojectlistview');

export function init(): void {
	const root = ElObj.body().querySelector('#id_lb-account-archive');
	if (root) {
		import('./view')
			.then(mod => (new mod.ArchivedProjectListView(root)));
	} else {
		logger.error('Root element was not found');
	}
}
