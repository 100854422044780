import extent from '@mapbox/geojson-extent';
import type {
	MapboxGeoJSONFeature,
	Map as MapboxMap,
} from 'mapbox-gl';

import {Feature} from '../features/feature';
import type {IMapboxDrawContext} from '../mapboxdraw';
import {FeatureRole} from '../constants';

const LAT_MIN = -90;
const LAT_RENDERED_MIN = -85;
const LAT_MAX = 90;
const LAT_RENDERED_MAX = 85;
const LNG_MIN = -270;
const LNG_MAX = 270;

// Ensure that we do not drag north-south far enough for
// - any part of any feature to exceed the poles
// - any feature to be completely lost in the space between the projection's
//   edge and the poles, such that it couldn't be re-selected and moved back
export function constrainFeatureMovement(geojsonFeatures: Array<GeoJSON>, delta: {lng: number; lat: number;}): {lng: number; lat: number;} {
	// "inner edge" = a feature's latitude closest to the equator
	let northInnerEdge: number = LAT_MIN;
	let southInnerEdge: number = LAT_MAX;
	// "outer edge" = a feature's latitude furthest from the equator
	let northOuterEdge: number = LAT_MIN;
	let southOuterEdge: number = LAT_MAX;
	let westEdge: number = LNG_MAX;
	let eastEdge: number = LNG_MIN;
	geojsonFeatures.forEach(feature => {
		const bounds: GeoJsonBBox = extent(feature);
		const featureSouthEdge: number = bounds[1];
		const featureNorthEdge: number = bounds[3];
		const featureWestEdge: number = bounds[0];
		const featureEastEdge: number = bounds[2];
		if (featureSouthEdge > northInnerEdge) {
			northInnerEdge = featureSouthEdge;
		}
		if (featureNorthEdge < southInnerEdge) {
			southInnerEdge = featureNorthEdge;
		}
		if (featureNorthEdge > northOuterEdge) {
			northOuterEdge = featureNorthEdge;
		}
		if (featureSouthEdge < southOuterEdge) {
			southOuterEdge = featureSouthEdge;
		}
		if (featureWestEdge < westEdge) {
			westEdge = featureWestEdge;
		}
		if (featureEastEdge > eastEdge) {
			eastEdge = featureEastEdge;
		}
	});
	// These changes are not mutually exclusive: we might hit the inner
	// edge but also have hit the outer edge and therefore need
	// another readjustment
	const constrainedDelta: {lng: number; lat: number;} = delta;
	if ((northInnerEdge + constrainedDelta.lat) > LAT_RENDERED_MAX) {
		constrainedDelta.lat = LAT_RENDERED_MAX - northInnerEdge;
	}
	if ((northOuterEdge + constrainedDelta.lat) > LAT_MAX) {
		constrainedDelta.lat = LAT_MAX - northOuterEdge;
	}
	if ((southInnerEdge + constrainedDelta.lat) < LAT_RENDERED_MIN) {
		constrainedDelta.lat = LAT_RENDERED_MIN - southInnerEdge;
	}
	if ((southOuterEdge + constrainedDelta.lat) < LAT_MIN) {
		constrainedDelta.lat = LAT_MIN - southOuterEdge;
	}
	if ((westEdge + constrainedDelta.lng) <= LNG_MIN) {
		constrainedDelta.lng += Math.ceil(Math.abs(constrainedDelta.lng) / 360) * 360;
	}
	if ((eastEdge + constrainedDelta.lng) >= LNG_MAX) {
		constrainedDelta.lng -= Math.ceil(Math.abs(constrainedDelta.lng) / 360) * 360;
	}
	return constrainedDelta;
}

export function createMidpoint(parentId: string | undefined, startVertex: FeatureInternalFeature<GeoJsonPoint>, endVertex: FeatureInternalFeature<GeoJsonPoint>): FeatureInternalFeature | null {
	const startCoord: GeoJsonPosition = startVertex.geometry.coordinates;
	const endCoord: GeoJsonPosition = endVertex.geometry.coordinates;
	// If a coordinate exceeds the projection, we can't calculate a midpoint,
	// so run away
	if ((startCoord[1] > LAT_RENDERED_MAX) || (startCoord[1] < LAT_RENDERED_MIN) || (endCoord[1] > LAT_RENDERED_MAX) || (endCoord[1] < LAT_RENDERED_MIN)) {
		return null;
	}
	const lng = (startCoord[0] + endCoord[0]) / 2;
	const lat = (startCoord[1] + endCoord[1]) / 2;
	return {
		type: 'Feature',
		properties: {
			active: 'false',
			coord_path: endVertex.properties.coord_path,
			lat: lat,
			lng: lng,
			meta: 'midpoint',
			parent: parentId,
		},
		geometry: {
			type: 'Point',
			coordinates: [lng, lat],
		},
	};
}

export function createSupplementaryPoints(feature: FeatureInternalFeature, options: {midpoints: boolean; selectedPaths: Array<string>; map: MapboxMap | null}, basePath: string | null): Array<FeatureInternalFeature> {
	const {
		type,
		coordinates,
	} = feature.geometry;
	const featureId: string | undefined = feature.properties.id;
	let supplementaryPoints: Array<FeatureInternalFeature> = [];
	const isCircle = feature.properties.circle === true;
	switch (type) {
		case 'Point':
			// For points, just create a vertex
			supplementaryPoints.push(
				createVertex(
					featureId,
					<GeoJsonPosition>coordinates,
					basePath || '',
					isSelectedPath(basePath)));
			break;
		case 'Polygon':
			// Cycle through a Polygon's rings and
			// process each line
			(<GeoJsonPosition[][]>coordinates)
				.forEach((line, lineIndex) =>
					processLine(
						line,
						(basePath !== null) ?
							`${basePath}.${lineIndex}` :
							String(lineIndex),
						isCircle ? Math.round(line.length / 4) : 1,
					),
				);
			break;
		case 'LineString':
			processLine(<GeoJsonPosition[]>coordinates, basePath);
			break;
		default:
			if (type.startsWith('Multi')) {
				processMultiGeometry();
			}
			break;
	}

	function processLine(line: GeoJsonPosition[], lineBasePath: string | null, step: number = 1): void {
		let firstPointString = '';
		let lastVertex: FeatureInternalFeature<GeoJsonPoint> | null = null;
		for (let i = 0; i < line.length; i += step) {
			const point = line[i];
			const pointPath: string = ((lineBasePath !== undefined) && (lineBasePath !== null)) ?
				`${lineBasePath}.${i}` :
				String(i);
			const vertex: FeatureInternalFeature<GeoJsonPoint> = createVertex(
				featureId,
				point,
				pointPath,
				isSelectedPath(pointPath));
			// If we're creating midpoints, check if there was a
			// vertex before this one. If so, add a midpoint
			// between that vertex and this one.
			if (options.midpoints && lastVertex) {
				const midpoint: FeatureInternalFeature | null = createMidpoint(
					featureId,
					lastVertex,
					vertex);
				if (midpoint) {
					supplementaryPoints.push(midpoint);
				}
			}
			lastVertex = vertex;
			// A Polygon line's last point is the same as the first point. If we're on the last
			// point, we want to draw a midpoint before it but not another vertex on it
			// (since we already a vertex there, from the first point).
			const stringifiedPoint: string = JSON.stringify(point);
			if (firstPointString !== stringifiedPoint) {
				supplementaryPoints.push(vertex);
			}
			if (i === 0) {
				firstPointString = stringifiedPoint;
			}
		}
	}

	function isSelectedPath(path?: string | null): boolean {
		if (!options.selectedPaths || !(typeof path === 'string')) {
			return false;
		}
		return options.selectedPaths.indexOf(path) !== -1;
	}

	// Split a multi-geometry into constituent
	// geometries, and accumulate the supplementary points
	// for each of those constituents
	function processMultiGeometry(): void {
		const subType: string = type.replace('Multi', '');
		(<GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][]>coordinates).forEach((subCoordinates: GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][], index: number) => {
			const subFeature: FeatureInternalFeature<GeoJsonPoint | GeoJsonLineString | GeoJsonPolygon> = {
				type: 'Feature',
				properties: feature.properties,
				geometry: <GeoJsonPoint | GeoJsonLineString | GeoJsonPolygon>{
					type: subType,
					coordinates: subCoordinates,
				},
			};
			supplementaryPoints = supplementaryPoints.concat(
				createSupplementaryPoints(
					subFeature,
					options,
					String(index)));
		});
	}

	return supplementaryPoints;
}

export function createVertex(parentId: string | undefined, coordinates: GeoJsonPosition, path: string, selected: boolean): FeatureInternalFeature<GeoJsonPoint> {
	return {
		type: 'Feature',
		properties: {
			active: selected ?
				'true' :
				'false',
			coord_path: path,
			meta: 'vertex',
			parent: parentId,
		},
		geometry: {
			type: 'Point',
			coordinates,
		},
	};
}

export const doubleClickZoom = {
	enable(ctx: IMapboxDrawContext): void {
		setTimeout(() => {
			// First check we've got a map and some context.
			if (!ctx.map || !ctx.map.doubleClickZoom) {
				return;
			}
			// Now check initial state wasn't false (we leave it disabled if so)
			const enabled: boolean | null = ctx.store.getInitialConfigValue('doubleClickZoom');
			if (typeof enabled === 'boolean') {
				if (enabled) {
					ctx.map.doubleClickZoom.enable();
				}
			}
		}, 0);
	},
	disable(ctx: IMapboxDrawContext): void {
		setTimeout(() => {
			if (!ctx.map || !ctx.map.doubleClickZoom) {
				return;
			}
			// Always disable here, as it's necessary in some cases.
			ctx.map.doubleClickZoom.disable();
		}, 0);
	},
};

export function isActiveFeature(featureTarget?: MapboxGeoJSONFeature | null): boolean {
	if (featureTarget && featureTarget.properties) {
		return (featureTarget.properties.active === 'true') && (featureTarget.properties.meta === FeatureRole.Feature);
	}
	return false;
}

export function isAtCoordinates(lngLat: {lng: number; lat: number;}, coordinates: [number, number]): boolean {
	return (lngLat.lng === coordinates[0]) && (lngLat.lat === coordinates[1]);
}

export function isVertex(featureTarget?: MapboxGeoJSONFeature | null): boolean {
	if (featureTarget && featureTarget.properties) {
		return featureTarget.properties.meta === FeatureRole.Vertex;
	}
	return false;
}

export function moveFeatures(features: Array<Feature>, delta: {lng: number; lat: number;}): void {
	const constrainedDelta = constrainFeatureMovement(<Array<GeoJSON>>features.map(feature => feature.toGeoJSON()), delta);
	for (let i = 0; i < features.length; ++i) {
		const feature = features[i];
		const currentCoordinates = feature.getCoordinates();
		const moveCoordinate = (coord: GeoJsonPosition) =>
			[
				coord[0] + constrainedDelta.lng,
				coord[1] + constrainedDelta.lat,
			];
		const moveRing = (ring: GeoJsonPosition[]) =>
			ring.map(coord => moveCoordinate(coord));
		const moveMultiPolygon = (multi: GeoJsonPosition[][]) =>
			multi.map(ring => moveRing(ring));
		let nextCoordinates: GeoJsonGeometryNoCollection['coordinates'];
		switch (feature.type) {
			case 'Point':
				nextCoordinates = moveCoordinate(<GeoJsonPosition>currentCoordinates);
				break;
			case 'LineString':
			case 'MultiPoint':
				nextCoordinates = (<GeoJsonPosition[]>currentCoordinates).map(moveCoordinate);
				break;
			case 'Polygon':
			case 'MultiLineString':
				nextCoordinates = (<GeoJsonPosition[][]>currentCoordinates).map(moveRing);
				break;
			case 'MultiPolygon':
				nextCoordinates = (<GeoJsonPosition[][][]>currentCoordinates).map(moveMultiPolygon);
				break;
			default:
				continue;
		}
		feature.incomingCoords(nextCoordinates);
	}
}
