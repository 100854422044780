import {Obj, OBJ, SIGNAL} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {iterableToArray} from '../../util';
import {FancyPushButton} from '../../ui/pushbutton';
import {CircularProgress} from '../../ui/progress';
import {getLogger} from '../../logging';

const logger = getLogger('projectdetailview.downloadpay');
const flashingCssClassName = 'lb-glow-button';

export enum DownloadPayButton {
	NoButton,
	DownloadButton,
	PayButton,
}

@OBJ
export class DownloadPay extends ElObj {
	private button: FancyPushButton | null;
	private disabled: boolean;
	private progress: CircularProgress | null;
	private which: DownloadPayButton;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const attributes = opts.attributes ?
			iterableToArray(opts.attributes) :
			[];
		opts.attributes = [
			['id', 'lb-project-detail-download-pay'],
			...attributes,
		];
		opts.tagName = 'div';
		super(opts);
		this.button = null;
		this.disabled = false;
		this.progress = null;
		this.which = DownloadPayButton.NoButton;
	}

	@SIGNAL
	private buttonClicked(): void {
	}

	currentButton(): DownloadPayButton {
		return this.which;
	}

	@SIGNAL
	private currentButtonChanged(which: DownloadPayButton): void {
	}

	destroy(): void {
		this.destroyProgress();
		this.destroyButton();
		super.destroy();
	}

	private destroyButton(): void {
		if (this.button) {
			Obj.disconnect(
				this.button, 'clicked',
				this, 'buttonClicked');
			this.button.destroy();
		}
		this.button = null;
		this.which = DownloadPayButton.NoButton;
	}

	private destroyProgress(): void {
		if (this.progress) {
			this.progress.destroy();
		}
		this.progress = null;
	}

	isButtonFlashing(): boolean {
		if (this.button) {
			return this.button.hasClass(flashingCssClassName);
		}
		return false;
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	private makeDownloadButton(): FancyPushButton {
		return new FancyPushButton({
			// attributes: [
			// 	['download', ''],
			// 	['href', url],
			// ],
			filled: true,
			text: 'Download',
			// tagName: 'a',
		});
	}

	private makePayButton(): FancyPushButton {
		return new FancyPushButton({
			filled: true,
			text: 'Pay & Download',
		});
	}

	setButtonFlashing(flashing: boolean): void {
		if (this.button) {
			this.button.setClass(flashing, flashingCssClassName);
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		if ((this.which !== DownloadPayButton.DownloadButton) && this.button) {
			this.button.setDisabled(this.disabled);
		}
		if (this.disabled) {
			this.destroyProgress();
		}
	}

	// showButton(which: DownloadPayButton.DownloadButton, url: string): void;
	// showButton(which: DownloadPayButton.PayButton): void;
	// showButton(which: DownloadPayButton, url?: string): void {
	// 	if (this.which === which) {
	// 		return;
	// 	}
	// 	let button: FancyPushButton;
	// 	switch (which) {
	// 		case DownloadPayButton.DownloadButton:
	// 			if (url === undefined) {
	// 				logger.error('setButtonVisible: Must specify a URL for download button.');
	// 				return;
	// 			}
	// 			button = this.makeDownloadButton(url);
	// 			break;
	// 		case DownloadPayButton.PayButton:
	// 			button = this.makePayButton();
	// 			break;
	// 		default:
	// 			logger.error('setButtonVisible: Invalid button type');
	// 			return;
	// 	}
	// 	Obj.connect(
	// 		button, 'clicked',
	// 		this, 'buttonClicked');
	// 	this.destroyProgress();
	// 	this.destroyButton();
	// 	this.button = button;
	// 	this.which = which;
	// 	this.appendChild(this.button);
	// 	this.currentButtonChanged(this.which);
	// }

	showButton(which: DownloadPayButton): void {
		if (this.which === which) {
			return;
		}
		let button: FancyPushButton;
		switch (which) {
			case DownloadPayButton.DownloadButton:
				button = this.makeDownloadButton();
				break;
			case DownloadPayButton.PayButton:
				button = this.makePayButton();
				break;
			default:
				logger.error('setButtonVisible: Invalid button type');
				return;
		}
		Obj.connect(
			button, 'clicked',
			this, 'buttonClicked',
		);
		this.destroyProgress();
		this.destroyButton();
		this.button = button;
		this.which = which;
		this.appendChild(this.button);
		this.currentButtonChanged(this.which);
	}

	showDownloadButton(): void {
		this.showButton(DownloadPayButton.DownloadButton);
	}

	showPayButton(): void {
		this.showButton(DownloadPayButton.PayButton);
	}

	showProgress(): void {
		if (this.progress) {
			return;
		}
		this.progress = new CircularProgress({
			indeterminate: true,
			size: 'small',
		});
		this.destroyButton();
		this.appendChild(this.progress);
		this.progress.show();
		this.currentButtonChanged(this.which);
	}

	setButtonEnabled(enabled: boolean): void {
		if (this.button) {
			this.button.setEnabled(enabled);
		}
	}
}
