import {Obj, OBJ, SIGNAL} from '../../obj';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {FancyPushButton} from '../../ui/pushbutton';
import {stringIterableToStringArray} from '../../util';

@OBJ
export class ViewSwitcher extends ElObj {
	private button: FancyPushButton;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'padding-bottom--16',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.button = new FancyPushButton({
			parent: this,
		});
		Obj.connect(
			this.button, 'clicked',
			this, 'buttonClicked');
	}

	@SIGNAL
	private buttonClicked(): void {
	}

	destroy(): void {
		Obj.disconnect(
			this.button, 'clicked',
			this, 'buttonClicked');
		this.button.destroy();
		super.destroy();
	}

	setText(text?: string | null): void {
		this.button.setText(text);
	}

	text(): string {
		return this.button.text();
	}
}
