import {getLogger} from '../../logging';
import {FancyTable, ModelIndex, TableCellEl, TableItem, TableItemArgs, tableItemOpts, TableItemOpts, TableOpts} from '../../ui/table';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, elObjOpts} from '../../elobj';
import {TextInput} from '../../ui/textinput';
import {INFINITY_CHAR_CODE, ItemDataRole, MetaType, Orientation} from '../../constants';
import {Variant} from '../../variant';
import {assert, isNumber, stringIterableToStringArray} from '../../util';
import {ToolButton} from '../../ui/toolbutton';

const logger = getLogger('views.pricecreate');

@OBJ
class TierTableCellEl extends TableCellEl {
	private enabled: boolean;
	private textInput: TextInput | null;
	private toolButton: ToolButton | null;

	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts<TableItemOpts>(a, b);
		const classNames = args.opts.classNames ?
			stringIterableToStringArray(args.opts.classNames) :
			[];
		args.opts.classNames = [
			'lb-price-tier-table-cell',
			...classNames,
		];
		super(args);
		this.enabled = true;
		this.textInput = null;
		this.toolButton = null;
	}

	data(role: ItemDataRole): Variant {
		if (role === ItemDataRole.EditRole) {
			if (this.textInput) {
				return new Variant(this.textInput.text());
			}
			return new Variant();
		} else {
			return super.data(role);
		}
	}

	destroy(): void {
		this.destroyTextInput();
		this.destroyToolButton();
		super.destroy();
	}

	private destroyTextInput(): void {
		if (this.textInput) {
			Obj.disconnect(
				this.textInput, 'textChanged',
				this, '_textInputTextChanged');
			this.textInput.destroy();
		}
		this.textInput = null;
	}

	private destroyToolButton(): void {
		if (this.toolButton) {
			Obj.disconnect(
				this.toolButton, 'clicked',
				this, '_toolButtonClicked');
			this.toolButton.destroy();
		}
		this.toolButton = null;
	}

	private initTextInput(): void {
		if (!this.textInput) {
			this.textInput = new TextInput({
				noLabel: true,
				parent: this,
				underlinedLessDenseNoPadding: false,
				underlinedLessDenseNoPaddingWhiteBackground: false,
				underlinedLessDenseWhiteBackground: false,
			});
			if (this.view) {
				this.setTextInputName(this.view.index(this));
			}
			Obj.connect(
				this.textInput, 'textChanged',
				this, '_textInputTextChanged');
		}
	}

	private initToolButton(): void {
		if (!this.toolButton) {
			this.toolButton = new ToolButton({
				classNames: [
					'lb-price-create-table-row-tool-button',
				],
				parent: this,
			});
			Obj.connect(
				this.toolButton, 'clicked',
				this, '_toolButtonClicked');
		}
	}

	rowChanged(): void {
		if (this.view) {
			const index = this.view.index(this);
			if (index.isValid() && (index.column === 0) && (index.row > 0)) {
				const prevLastUnitItem = this.view.item(index.row - 1, 1);
				if (prevLastUnitItem) {
					const prevLastUnitData = prevLastUnitItem.data(ItemDataRole.EditRole);
					if (prevLastUnitData.isValid() && !prevLastUnitData.isNull()) {
						const num = prevLastUnitData.toNumber();
						if (isNumber(num)) {
							this.setData(ItemDataRole.EditRole, new Variant(num + 1));
						}
					}
				}
			}
			this.setTextInputName(index);
		}
	}

	protected setDataForRole(role: number, value: Variant): void {
		switch (role) {
			case ItemDataRole.EditRole:
				this.setToolButtonEnabled(false);
				this.setTextInputEnabled(true);
				this.setTextInputText(value.toString());
				break;
			case ItemDataRole.DecorationRole:
				this.setTextInputEnabled(false);
				const legitIcon = value.isValid() && !value.isNull() && (value.type() !== MetaType.Null);
				this.setToolButtonEnabled(legitIcon);
				if (legitIcon) {
					this.setToolButtonIcon(value.toString());
				}
				break;
			default:
				super.setDataForRole(role, value);
				break;
		}
	}

	setDisabled(disabled: boolean): void {
		const currentlyDisabled = !this.enabled;
		if (currentlyDisabled === disabled) {
			return;
		}
		this.enabled = !disabled;
		if (this.textInput) {
			this.textInput.setDisabled(!this.enabled);
		}
	}

	private setTextInputEnabled(enabled: boolean): void {
		if (enabled) {
			this.initTextInput();
		} else {
			this.destroyTextInput();
		}
	}

	private setTextInputName(index: ModelIndex): void {
		if (this.textInput && index.isValid() && ((index.column === 1) || (index.column === 2))) {
			const a = (index.column === 1) ?
				'up_to_including' :
				'unit_amount';
			this.textInput.setName(`tiers[${index.row}][${a}]`);
		}
	}

	private setTextInputText(text: string): void {
		if (this.textInput) {
			this.textInput.setText(text);
		}
	}

	private setToolButtonEnabled(enabled: boolean): void {
		if (enabled) {
			this.initToolButton();
		} else {
			this.destroyToolButton();
		}
	}

	private setToolButtonIcon(icon: string): void {
		if (this.toolButton) {
			this.toolButton.setIcon(icon);
		}
	}

	@SIGNAL
	private textInputTextChanged(index: ModelIndex, text: string): void {
	}

	@SLOT
	private _textInputTextChanged(text: string): void {
		if (this.view) {
			this.textInputTextChanged(this.view.index(this), text);
		}
	}

	@SIGNAL
	private toolButtonClicked(index: ModelIndex): void {
	}

	@SLOT
	private _toolButtonClicked(): void {
		if (this.view) {
			this.toolButtonClicked(this.view.index(this));
		}
	}
}

@OBJ
export class TierTable extends FancyTable {
	constructor(opts: Partial<TableOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<TableOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<TableOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<TableOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'lb-price-data-table',
			...classNames,
		];
		opts.columnCount = 4;
		opts.itemPrototype = new TierTableCellEl();
		opts.rowCount = 2;
		super(opts);
		this.setHorizontalHeaderLabels([
			'First unit',
			'Last unit',
			'Price per unit',
			'',
		]);
		this._doVertHeaderDataBS(0);
		this.setRow(0, 1, 1, true, false);
		this._doVertHeaderDataBS(1);
		this.setRow(1, 2, Number.POSITIVE_INFINITY, false, false);
		Obj.connect(
			this, 'rowsRemoved',
			this, '_rowsRemoved');
	}

	addRow(): void {
		const rc = this.rowCount();
		const insertingRowIdx = rc - 1;
		assert(insertingRowIdx >= 1, `addRow: Expected insertion index to be >= 1. Got ${insertingRowIdx}.`);
		// IT IS GUARANTEED the VISUAL ROW COUNT at this point, BEFORE ADDING
		// ANYTHING, will be >= 2 (first and last rows). Likewise, IT IS
		// GUARANTEED the  insertion index for THIS ROW will be >= 1. This is
		// due to 2 rows having been created upon initialization.
		//
		// At this point, before doing anything, it's important to note that
		// we are NOT ADDING ANY ROWS. We are going to "overwrite" the last
		// row (the "sticky last row") then, at the end, we'll add 1 row and
		// make that row the new "sticky last row".
		const prevRowIdx = insertingRowIdx - 1;
		const prevRowLastUnitData = this.lastUnitData(prevRowIdx);
		let prevRowLastUnit: number;
		if (prevRowLastUnitData.isValid() && !prevRowLastUnitData.isNull()) {
			prevRowLastUnit = prevRowLastUnitData.toNumber();
		} else {
			logger.error('addRow: Previous row last unit data is invalid.');
			prevRowLastUnit = 0;
		}
		prevRowLastUnit = isNumber(prevRowLastUnit) ?
			prevRowLastUnit :
			0;
		const insertingRowFirstUnit = prevRowLastUnit + 1;
		const insertingRowLastUnit = insertingRowFirstUnit + 1;
		this.setRow(
			insertingRowIdx,
			insertingRowFirstUnit,
			insertingRowLastUnit,
			true,
			true);
		// Now increment the row count, effectively appending an additional
		// row after the one we just set. After appending, set the row as
		// "sticky last row."
		this.setRowCount(rc + 1);
		const lastRowIdx = this.rowCount() - 1;
		if (lastRowIdx > insertingRowIdx) {
			this._doVertHeaderDataBS(lastRowIdx);
			this.setRow(
				lastRowIdx,
				insertingRowLastUnit + 1,
				Number.POSITIVE_INFINITY,
				false,
				false);
		} else {
			logger.error('addRow: Unable to append new rows.');
		}
	}

	private _doVertHeaderDataBS(rowIdx: number): void {
		this.setHeaderData(
			rowIdx,
			Orientation.Vertical,
			new Variant(''),
			ItemDataRole.DisplayRole);
	}

	@SLOT
	private itemLastUnitTextChanged(index: ModelIndex, text: string): void {
		if (index.isValid() && (index.row < (this.rowCount() - 1))) {
			let num = Number.parseInt(text);
			if (!isNumber(num)) {
				num = 0;
				this.setData(index, new Variant(num), ItemDataRole.EditRole);
			}
			const nextFirstUnit = this.item(index.row + 1, 0);
			if (nextFirstUnit) {
				nextFirstUnit.setData(ItemDataRole.EditRole, new Variant(num + 1));
			}
		}
	}

	@SLOT
	private itemToolButtonClicked(index: ModelIndex): void {
		if (index.isValid()) {
			this.removeInBetweenRow(index.row);
		}
	}

	private lastUnitData(row: number): Variant {
		const item = this.item(row, 1);
		if (item) {
			return item.data(ItemDataRole.EditRole);
		}
		return new Variant();
	}

	@SLOT
	private _rowsRemoved(): void {
		for (let row = 0; row < this.rowCount(); ++row) {
			for (let col = 0; col < 3; ++col) {
				const item = <TierTableCellEl | null>this.item(row, col);
				if (item) {
					item.rowChanged();
				}
			}
		}
	}

	private removeInBetweenRow(rowIdx: number): void {
		if (this.validInBetweenRowIdx(rowIdx)) {
			this.removeRow(rowIdx);
		}
	}

	private setRow(rowIdx: number, firstUnit: number, lastUnit: number, lastUnitIsEnabled: boolean = true, visibleToolButton: boolean = true): void {
		// First unit
		this.setData(
			new ModelIndex(rowIdx, 0),
			new Variant(firstUnit),
			ItemDataRole.EditRole);
		let it = this.item(rowIdx, 0);
		if (it) {
			it.setDisabled(true);
		}
		// Last unit
		const lastUnitVal = (lastUnit === Number.POSITIVE_INFINITY) ?
			String.fromCharCode(INFINITY_CHAR_CODE) :
			lastUnit;
		this.setData(
			new ModelIndex(rowIdx, 1),
			new Variant(lastUnitVal),
			ItemDataRole.EditRole);
		it = this.item(rowIdx, 1);
		if (it) {
			it.setDisabled(!lastUnitIsEnabled);
			if (lastUnitIsEnabled) {
				Obj.connect(
					it, 'textInputTextChanged',
					this, 'itemLastUnitTextChanged');
			}
		}
		// Price per unit
		this.setData(
			new ModelIndex(rowIdx, 2),
			new Variant('0.00'),
			ItemDataRole.EditRole);
		// ToolButton
		this.setData(
			new ModelIndex(rowIdx, 3),
			new Variant(visibleToolButton ?
				'clear' :
				null),
			ItemDataRole.DecorationRole);
		it = visibleToolButton ?
			this.item(rowIdx, 3) :
			null;
		if (it) {
			Obj.connect(
				it, 'toolButtonClicked',
				this, 'itemToolButtonClicked');
		}
	}

	private validInBetweenRowIdx(rowIdx: number): boolean {
		if (rowIdx <= 0) {
			// First row is sticky; can't delete that one.
			logger.warning('validRowIdx: Invalid row index: %s', rowIdx);
			return false;
		}
		if (rowIdx >= (this.rowCount() - 1)) {
			// Last row is sticky; cant delete that one.
			logger.warning('validRowIdx: Invalid row index: %s', rowIdx);
			return false;
		}
		return true;
	}
}
