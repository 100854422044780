import {IMapOpts} from '../../ui/map';
import {OBJ, Obj, SIGNAL, SLOT} from '../../obj';
import {bind, capitalize, numberFormat, pixelString} from '../../util';
import {svc} from '../../request';
import {getLogger} from '../../logging';
import {TextInput, TextInputIconPosition} from '../../ui/textinput';
import {ElObj} from '../../elobj';
import {InteractiveMapControlMode} from '../../constants';
import {ProjectDetailGeoMap} from './map';
import {Drawer} from './drawer';
import {AutoComplete} from './autocomplete';
import {ButtonId} from '../../ui/map/draw/ui';
import {FilterList, FilterManager} from './filters';
import {ParcelAction, ParcelInfo} from './parcels';
import {GeoCoordinate, GeoRectangle, list, Point} from '../../tools';
import {ProjectDetailStats} from './stats';
import {DownloadPay, DownloadPayButton} from './downloadpay';
import {PaymentDialogView} from '../paymentdialog';
import {UndoStack} from '../../ui/undostack';
import {UndoView} from './undoview';
import {Dialog} from '../../ui/dialog';
import {FancyPushButton} from '../../ui/pushbutton';

const logger = getLogger('projectdetailview');

@OBJ
export class ProjectDetailView extends Obj {
	private autoComplete: AutoComplete | null;
	private cancelSrc: {syncCounts: ICancelTokenSource | null};
	private disabled: boolean;
	private downloadPay: DownloadPay;
	private absoluteListUri: string;
	private downloadWithOwnerNameEnabled: boolean;
	drawer: Drawer;
	filterList: FilterList;
	private filterListDivider: ElObj | null;
	private filterMgr: FilterManager;
	lastMousePos: Point;
	map: ProjectDetailGeoMap;
	private mapCfgPk: GeoMapConfigurationPk | null;
	private parcelInfo: ParcelInfo | null;
	private paymentDialog: PaymentDialogView | null;
	private places: list<IPlace>;
	private primaryInput: TextInput | null;
	slug: string;
	private stats: ProjectDetailStats;
	undoStack: UndoStack;
	private undoView: UndoView | null;

	constructor(slug?: string) {
		super();
		this.absoluteListUri = '';
		this.cancelSrc = {syncCounts: null};
		this.downloadWithOwnerNameEnabled = false;
		this.undoStack = new UndoStack();
		this.autoComplete = null;
		this.disabled = false;
		this.filterListDivider = null;
		this.lastMousePos = new Point();
		this.parcelInfo = null;
		this.paymentDialog = null;
		this.places = new list<IPlace>();
		this.primaryInput = null;
		this.slug = slug || '';
		// FIXME: Don't mess ordering below. But fix this BS dependency ASAP.
		this.drawer = new Drawer();
		this.downloadPay = new DownloadPay();
		this.map = new ProjectDetailGeoMap(this, false, this.slug);
		this.mapCfgPk = null;
		this.filterList = new FilterList();
		this.filterMgr = new FilterManager(this);
		this.stats = new ProjectDetailStats();
		this.undoView = null;
		this.init();
	}

	@SLOT
	private autoCompleteActivated(index: number): void {
		this.setAutoCompleteEnabled(false);
		this.setPrimaryInputText('');
		if ((index >= 0) && (index < this.places.size())) {
			this.placeAutoCompleteActivated(this.places.at(index));
		}
	}

	@SLOT
	private async beginPayment(): Promise<void> {
		if (this.paymentDialog) {
			return;
		}
		this.downloadPay.showProgress();
		const invoice = await this.fetchInvoice(true);
		const title = `${this.slug} - ${numberFormat(invoice.totalQuantity)} mailing addresses`;
		const paymentData = await this.fetchPaymentIntent();
		this.paymentDialog = new PaymentDialogView({
			amount: invoice.total,
			paymentIntent: paymentData,
			title,
		});
		Obj.connect(
			this.paymentDialog, 'finished',
			this, 'endPayment');
		this.paymentDialog.beginPayment();
		this.downloadPay.showPayButton();
		this.downloadPay.setButtonEnabled(false);
	}

	closeDrawer(): void {
		this.drawer.close();
	}

	private closePopups(): void {
		this.map.closeAllPopups();
	}

	destroy(): void {
		this.cancelSrc = {syncCounts: null};
		document.removeEventListener('keydown', this.documentEvent);
		window.removeEventListener('resize', this.windowEvent);
		this.closePopups();
		this.destroyPaymentDialog();
		this.destroyAutoComplete();
		[
			this.undoView,
			this.filterMgr,
			this.primaryInput,
		].forEach(o => o && o.destroy());
		this.undoView = null;
		this.primaryInput = null;
		this.mapCfgPk = null;
		this.places.clear();
		this.slug = '';
		super.destroy();
	}

	private destroyAutoComplete(): void {
		if (this.autoComplete) {
			Obj.disconnect(
				this.autoComplete, 'activated',
				this, 'autoCompleteActivated');
			this.autoComplete.destroy();
		}
		this.autoComplete = null;
	}

	private destroyParcelInfo(): void {
		if (this.parcelInfo) {
			Obj.disconnect(
				this.parcelInfo, 'actionActivated',
				this, 'parcelInfoActionActivated');
			this.parcelInfo.destroy();
		}
		this.parcelInfo = null;
	}

	private destroyPaymentDialog(): void {
		if (this.paymentDialog) {
			Obj.disconnect(
				this.paymentDialog, 'finished',
				this, 'endPayment');
			this.paymentDialog.destroy();
		}
		this.paymentDialog = null;
	}

	@bind
	private documentEvent(event: Event): void {
		switch (event.type) {
			case 'keydown': {
				this.documentKeyDownEvent(<KeyboardEvent>event);
				break;
			}
		}
	}

	private documentKeyDownEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Escape':
				if (this.isAutoCompleteOpen()) {
					this.destroyAutoComplete();
				} else if (this.filterMgr.isFilterBoxOpen() || this.hasPopupsOpen()) {
					this.filterMgr.closeFilterBox();
					this.closePopups();
				} else {
					this.syncMapDrawTrashButtonVisibility();
					switch (this.mapInteractiveControlMode()) {
						case InteractiveMapControlMode.ShapeSelect:
							if (this.mapSelectedFeatureCount() > 0) {
								this.setSelectedMapFeatures([]);
							} else {
								this.filterMgr.stopEditingFilterShape();
								this.setMapInteractiveControlMode(
									InteractiveMapControlMode.NoMode);
							}
							break;
						case InteractiveMapControlMode.DrawShape:
							// If control mode is draw shape, the draw mode
							// should be listening for the escape key and,
							// when it's received, will execute default
							// cleanup behavior.
							//
							// Update: It doesn't. Quick fix:
							this.setMapInteractiveControlMode(
								InteractiveMapControlMode.NoMode);
							break;
						case InteractiveMapControlMode.InfoMode:
							this.setMapInteractiveControlMode(
								InteractiveMapControlMode.NoMode);
							break;
						default:
							this.closeDrawer();
							break;
					}
				}
				break;
			case 'Enter':
				if (this.filterMgr.currentlyEditingShape()) {
					this.filterMgr.stopEditingFilterShape();
				}
				break;
		}
	}

	@SLOT
	private downloadButtonClicked(): void {
		window.location.assign('list/');
	}

	// @SLOT
	// private async downloadPayButtonClicked(): Promise<void> {
	// 	if (this.downloadPay.currentButton() === DownloadPayButton.PayButton) {
	// 		await this.payButtonClicked();
	// 	} else {
	// 		if ((this.downloadPay.currentButton() === DownloadPayButton.DownloadButton) && this.downloadPay.isButtonFlashing()) {
	// 			this.downloadPay.setButtonFlashing(false);
	// 		}
	// 	}
	// }

	@SLOT
	private async downloadPayButtonClicked(): Promise<void> {
		if (this.downloadPay.currentButton() === DownloadPayButton.PayButton) {
			await this.payButtonClicked();
		} else if (this.downloadPay.currentButton() === DownloadPayButton.DownloadButton) {
			if (this.downloadPay.isButtonFlashing()) {
				this.downloadPay.setButtonFlashing(false);
			}
			const dia = new Dialog({title: 'Download'});
			const btnParent = new ElObj({
				classNames: [
					'display--flex',
					'flex-direction--column',
				],
				tagName: 'div',
			});
			dia.appendElObj(btnParent);
			new FancyPushButton({
				attributes: [
					['download', ''],
					['href', this.absoluteListUri],
				],
				filled: true,
				parent: btnParent,
				tagName: 'a',
				text: 'Mailing List',
			});
			if (this.downloadWithOwnerNameEnabled) {
				new FancyPushButton({
					attributes: [
						['download', ''],
						['href', `${this.absoluteListUri}?opt=owner`],
					],
					parent: btnParent,
					styles: [
						['margin-top', '16px'],
					],
					tagName: 'a',
					text: 'List w/Owner Names',
				});
			}
			dia.open();
		}
	}

	@SLOT
	private drawerClosed(): void {
		if (this.primaryInput) {
			this.primaryInput.setLeadingIcon({icon: 'fullscreen', title: 'Open side drawer'});
		}
	}

	@SLOT
	private drawerOpened(): void {
		if (this.primaryInput) {
			this.primaryInput.setLeadingIcon({icon: 'fullscreen_exit', title: 'Close side drawer'});
		}
	}

	@SLOT
	private async drawerTitleSaved(title: string): Promise<void> {
		const project = await this.fetchProject();
		this.projectUpdated(await this.updateProject({...project, title}));
	}

	@SLOT
	private async endPayment(success: boolean): Promise<void> {
		if (!this.paymentDialog) {
			return;
		}
		if (success) {
			this.projectUpdated(await this.fetchProject({syncPayment: true}));

		}
		this.paymentProcessEnded(success);
		this.destroyPaymentDialog();
	}

	private async fetchInvoice(sync: boolean = false, cfg?: SafeReqCfg): Promise<IInvoice> {
		const proj = await this.fetchProject({syncInvoice: sync}, cfg);
		if (proj.invoiceId !== null) {
			return await svc.invoice.get(proj.invoiceId, cfg);
		}
		throw new Error('Project failed to return invoice');
	}

	private async fetchParcel(pk: ParcelPk): Promise<IParcel> {
		return await svc.parcel.get(pk);
	}

	private async fetchParcels(point: GeoCoordinate): Promise<Array<IParcel>> {
		return await svc.parcel.list(point);
	}

	private async fetchPaymentIntent(): Promise<IPaymentIntent> {
		return await svc.payment.create({projectSlug: this.slug});
	}

	private async fetchPlaces(name: string): Promise<Array<IPlace>> {
		return await svc.place.list(name);
	}

	private async fetchProject(opt?: Partial<{syncInvoice: boolean; syncPayment: boolean;}>, cfg?: SafeReqCfg): Promise<IProject> {
		return await svc.project.get(this.slug, opt, cfg);
	}

	private async fetchGeoMapConfiguration(pk: GeoMapConfigurationPk): Promise<IGeoMapConfiguration> {
		return await svc.map.get(pk);
	}

	private async fetchGeoRefs(): Promise<Array<IGeoRef>> {
		return await svc.geoRef.list();
	}

	@SLOT
	private async filterCreated(filter: IFilter): Promise<void> {
		if (filter.geometry) {
			this.filterMgr.flyToFilter(filter);
		}
	}

	flyTo(bbox: [number, number, number, number]): void {
		//  long                lat                long                 lat
		// [-77.82740230327359, 34.223342940030975, -77.78976892279576, 34.25637110856619]
		const [long0, lat0, long1, lat1] = bbox;
		const coord0 = new GeoCoordinate(lat0, long0);
		const coord1 = new GeoCoordinate(lat1, long1);
		this.map.fitViewportToGeoShape(
			new GeoRectangle(coord1, coord0),
			{padding: 64});
	}

	@SLOT
	private async geoMapMapClicked(point: Point, coord: GeoCoordinate): Promise<void> {
		this.lastMousePos = point;
		if (this.isAutoCompleteOpen()) {
			this.destroyAutoComplete();
		}
		switch (this.mapInteractiveControlMode()) {
			case InteractiveMapControlMode.ShapeSelect:
			case InteractiveMapControlMode.DrawShape:
				return;
			case InteractiveMapControlMode.InfoMode:
				await this.openParcelPopupFromCoord(coord);
				return;
		}
	}

	@SLOT
	private geoMapPopupClosed(): void {
		this.destroyParcelInfo();
	}

	@SLOT
	private geoMapStyleChanged(newStyleUrl: string): void {
		this.saveGeoMapConfiguration();
	}

	private hasPopupsOpen(): boolean {
		return this.map.hasPopupsOpen();
	}

	private async init(): Promise<void> {
		document.addEventListener('keydown', this.documentEvent, true);
		window.addEventListener('resize', this.windowEvent);
		Obj.connect(
			this, 'parcelExclusionChanged',
			this, 'syncParcelInfo');
		Obj.connect(
			this, 'parcelExclusionChanged',
			this, 'syncDoNotMailGeoRefs');
		Obj.connect(
			this, 'parcelExclusionChanged',
			this, 'syncVisibleParcels');
		Obj.connect(
			this, 'projectUpdated',
			this, 'setDocumentForProject');
		Obj.connect(
			this, 'paymentProcessEnded',
			this, 'paymentEnded');
		Obj.connect(
			this.drawer, 'opened',
			this, 'drawerOpened');
		Obj.connect(
			this.drawer, 'closed',
			this, 'drawerClosed');
		Obj.connect(
			this.drawer, 'openChanged',
			this, 'updateAutoCompletePosition');
		const filterListHeader = new ElObj({
			classNames: 'mdc-list-group__subheader',
			tagName: 'h5',
		});
		filterListHeader.setText('Filters');
		this.drawer.appendChild(filterListHeader);
		this.drawer.appendChild(this.filterList);
		this.filterListDivider = new ElObj({classNames: 'mdc-list-divider', tagName: 'hr'});
		this.drawer.appendChild(this.filterListDivider);
		this.drawer.appendChild(this.stats);
		this.drawer.appendChild(new ElObj({classNames: 'mdc-list-divider', tagName: 'hr'}));
		this.undoView = new UndoView(this);
		Obj.connect(
			this.undoView, 'actionActivated',
			this, 'undoViewActionActivated');
		this.drawer.appendChild(this.undoView);
		const primaryInputRoot = document.getElementById('id_lb-project-detail-primary-input');
		if (primaryInputRoot) {
			this.primaryInput = new TextInput({
				leadingIcon: {
					interactive: true,
					outlined: false,
					icon: 'fullscreen_exit',
				},
				noLabel: true,
				root: primaryInputRoot,
			});
			Obj.connect(
				this.primaryInput, 'textChanged',
				this, 'textInputTextChanged');
			Obj.connect(
				this.primaryInput, 'iconActivated',
				this, 'primaryInputIconActivated');
		} else {
			logger.error('init: Primary input root element was not found');
		}
		const proj = await this.fetchProject();
		Obj.connect(
			this.downloadPay, 'buttonClicked',
			this, 'downloadPayButtonClicked');
		this.drawer.appendChild(this.downloadPay);
		await this.syncDownloadPayButton(proj);
		this.setDocumentTitle(proj.title);
		this.drawer.setTitle(proj.title);
		Obj.connect(
			this.drawer, 'titleSaved',
			this, 'drawerTitleSaved');
		const mapCfg = proj.geoMapConfigurationId ?
			await this.fetchGeoMapConfiguration(proj.geoMapConfigurationId) :
			null;
		this.mapCfgPk = mapCfg ?
			mapCfg.id :
			null;
		Obj.connect(
			this.map, 'loaded',
			this, 'mapLoaded');
		Obj.connect(
			this.map, 'mapClicked',
			this, 'geoMapMapClicked');
		Obj.connect(
			this.map, 'cameraChanged',
			this, 'saveGeoMapConfiguration');
		Obj.connect(
			this.map, 'interactiveControlModeChanged',
			this, 'mapInteractiveControlModeChanged');
		Obj.connect(
			this.map, 'popupClosed',
			this, 'geoMapPopupClosed');
		Obj.connect(
			this.map, 'styleChanged',
			this, 'geoMapStyleChanged');
		Obj.connect(
			this.map, 'featureSelectionChanged',
			this, 'mapFeatureSelectionChanged');
		if (mapCfg) {
			this.map.load(
				'id_lb-project-detail-map',
				geoMapConfigurationToMapOpts(mapCfg));
		} else {
			logger.error('init: Project failed to create map configuration');
		}
		Obj.connect(
			this.filterMgr, 'filterCreated',
			this, 'filterCreated');
		Obj.connect(
			this.filterMgr, 'filterCreated',
			this, 'syncCounts');
		Obj.connect(
			this.filterMgr, 'filterCreated',
			this, 'syncVisibleParcels');
		Obj.connect(
			this.filterMgr, 'filterUpdated',
			this, 'syncCounts');
		Obj.connect(
			this.filterMgr, 'filterUpdated',
			this, 'syncVisibleParcels');
		Obj.connect(
			this.filterMgr, 'filterDeleted',
			this, 'syncCounts');
		Obj.connect(
			this.filterMgr, 'filterDeleted',
			this, 'syncVisibleParcels');
		setTimeout(() => this.syncCounts(), 9);
		if (proj.readOnly !== this.disabled) {
			this.setDisabled(proj.readOnly);
		}
	}

	private initAutoComplete(): void {
		if (!this.autoComplete) {
			this.autoComplete = new AutoComplete();
			Obj.connect(
				this.autoComplete, 'activated',
				this, 'autoCompleteActivated');
			ElObj.body().appendChild(this.autoComplete);
			this.updateAutoCompletePosition();
		}
	}

	private isAutoCompleteOpen(): boolean {
		return Boolean(this.autoComplete && this.autoComplete.isOpen());
	}

	isDisabled(): boolean {
		return this.disabled;
	}

	@SLOT
	private mapFeatureSelectionChanged(): void {
		this.syncMapDrawTrashButtonVisibility();
		if (this.mapSelectedFeatureIds().length < 1) {
			this.setMapInteractiveControlMode(
				InteractiveMapControlMode.NoMode);
			if (this.filterMgr.currentlyEditingShape()) {
				this.filterMgr.stopEditingFilterShape();
			}
		}
	}

	private mapInteractiveControlMode(): InteractiveMapControlMode {
		return this.map.interactiveControlMode();
	}

	@SLOT
	private mapInteractiveControlModeChanged(newMode: InteractiveMapControlMode): void {
		switch (newMode) {
			case InteractiveMapControlMode.DrawShape:
			case InteractiveMapControlMode.InfoMode:
				this.filterMgr.closeFilterBox();
				break;
			case InteractiveMapControlMode.ShapeSelect:
				this.syncMapDrawTrashButtonVisibility();
				if (this.mapSelectedFeatureIds().length < 1) {
					this.setMapInteractiveControlMode(InteractiveMapControlMode.NoMode);
				}
				break;
		}
		if ((newMode !== InteractiveMapControlMode.InfoMode) && this.parcelInfo) {
			this.closePopups();
		}
	}

	@SLOT
	private mapLoaded(): void {
		setTimeout(() => this.syncVisibleParcels(), 0);
		setTimeout(() => this.syncDoNotMailGeoRefs(), 4);
		this.drawer.open();
	}

	private mapSelectedFeatureCount(): number {
		return this.mapSelectedFeatureIds().length;
	}

	private mapSelectedFeatureIds(): Array<string> {
		return this.map.selectedFeatureIds();
	}

	private openParcelPopup(coord: GeoCoordinate, parcels: Array<{parcel: IParcel; ignored: boolean;}>): void {
		this.closePopups();
		this.filterMgr.closeFilterBox();
		this.parcelInfo = new ParcelInfo();
		Obj.connect(
			this.parcelInfo, 'actionActivated',
			this, 'parcelInfoActionActivated');
		for (const obj of parcels) {
			this.parcelInfo.addInfo(obj.parcel);
		}
		this.map.openPopup(
			coord,
			{
				el: this.parcelInfo,
				closeButton: true,
				closeOnClick: false,
			});
	}

	private async openParcelPopupFromCoord(coord: GeoCoordinate): Promise<void> {
		const infos: Array<{parcel: IParcel; ignored: boolean;}> = [];
		for (const parcel of await this.fetchParcels(coord)) {
			infos.push({
				ignored: parcel.doNotMail,
				parcel,
			});
		}
		this.openParcelPopup(coord, infos);
	}

	@SIGNAL
	private async parcelExclusionChanged(parcelInfoSectionIndex: number, parcel: IParcel, excluded: boolean): Promise<void> {
	}

	@SLOT
	private async parcelInfoActionActivated(sectionIndex: number, action: ParcelAction): Promise<void> {
		if (this.parcelInfo) {
			switch (action) {
				case ParcelAction.IgnoreParcel:
					const parcelInfo = this.parcelInfo.info(sectionIndex);
					if (parcelInfo) {
						const geoRef = await svc.geoRef.setDoNotMail(
							!parcelInfo.doNotMail, {
								geometry: null,
								parcelPk: parcelInfo.uid,
							});
						await this.parcelExclusionChanged(
							sectionIndex,
							await this.fetchParcel(parcelInfo.uid),
							geoRef ? geoRef.doNotMail : false);
						setTimeout(() => this.syncCounts(), 3);
					}
					break;
			}
		}
	}

	@SLOT
	private async payButtonClicked(): Promise<void> {
		await this.beginPayment();
	}

	@SIGNAL
	private paymentProcessEnded(paymentSucceeded: boolean): void {
	}

	@SLOT
	private async paymentEnded(success: boolean): Promise<void> {
		await this.syncDownloadPayButton();
		if (success && (this.downloadPay.currentButton() === DownloadPayButton.DownloadButton)) {
			this.downloadPay.setButtonFlashing(true);
		}
	}

	@SIGNAL
	private placeAutoCompleteActivated(place: IPlace): void {
	}

	@SLOT
	private primaryInputIconActivated(position: TextInputIconPosition): void {
		switch (position) {
			case TextInputIconPosition.Leading:
				this.drawer.setOpen(!this.drawer.isOpen());
				break;
		}
	}

	@SIGNAL
	private projectUpdated(project: IProject): void {
	}

	@SLOT
	private async saveGeoMapConfiguration(): Promise<void> {
		if (this.mapCfgPk === null) {
			logger.warning('saveGeoMapConfiguration: Map configuration PK is null.');
		} else {
			const cam = this.map.camera();
			if (cam) {
				await this.updateGeoMapConfiguration(
					this.mapCfgPk,
					mapOptsToGeoMapConfiguration({
						...cam,
						style: this.map.mapStyleUrl() || '',
					}));

			} else {
				logger.warning('saveGeoMapConfiguration: Geo map returned null camera object');
			}
		}
	}

	private setAutoCompleteEnabled(enable: boolean): void {
		if (enable === Boolean(this.autoComplete)) {
			return;
		}
		if (enable) {
			this.initAutoComplete();
		} else {
			this.destroyAutoComplete();
		}
	}

	setDisabled(disabled: boolean): void {
		if (disabled === this.disabled) {
			return;
		}
		this.disabled = disabled;
		this.downloadPay.setDisabled(this.disabled);
		this.filterList.setDisabled(this.disabled);
		this.filterMgr.setDisabled(this.disabled);
		this.map.setDisabled(this.disabled);
		this.primaryInput && this.primaryInput.setInputDisabled(this.disabled);
		if (this.disabled) {
			this.destroyParcelInfo();
			this.destroyAutoComplete();
			Obj.disconnect(
				this, 'parcelExclusionChanged',
				this, 'syncParcelInfo');
			Obj.disconnect(
				this, 'parcelExclusionChanged',
				this, 'syncDoNotMailGeoRefs');
			Obj.disconnect(
				this, 'parcelExclusionChanged',
				this, 'syncVisibleParcels');
			if (this.primaryInput) {
				Obj.disconnect(
					this.primaryInput, 'textChanged',
					this, 'textInputTextChanged');
			}
			Obj.disconnect(
				this.map, 'mapClicked',
				this, 'geoMapMapClicked');
			Obj.disconnect(
				this.map, 'cameraChanged',
				this, 'saveGeoMapConfiguration');
			Obj.disconnect(
				this.map, 'interactiveControlModeChanged',
				this, 'mapInteractiveControlModeChanged');
			Obj.disconnect(
				this.map, 'popupClosed',
				this, 'geoMapPopupClosed');
			Obj.disconnect(
				this.map, 'styleChanged',
				this, 'geoMapStyleChanged');
			Obj.disconnect(
				this.map, 'featureSelectionChanged',
				this, 'mapFeatureSelectionChanged');
			Obj.disconnect(
				this.filterMgr, 'filterCreated',
				this, 'filterCreated');
			Obj.disconnect(
				this.filterMgr, 'filterCreated',
				this, 'syncCounts');
			Obj.disconnect(
				this.filterMgr, 'filterUpdated',
				this, 'syncCounts');
			Obj.disconnect(
				this.filterMgr, 'filterDeleted',
				this, 'syncCounts');
		} else {
			Obj.connect(
				this, 'parcelExclusionChanged',
				this, 'syncParcelInfo');
			Obj.connect(
				this, 'parcelExclusionChanged',
				this, 'syncDoNotMailGeoRefs');
			Obj.connect(
				this, 'parcelExclusionChanged',
				this, 'syncVisibleParcels');
			if (this.primaryInput) {
				Obj.connect(
					this.primaryInput, 'textChanged',
					this, 'textInputTextChanged');
			}
			Obj.connect(
				this.map, 'mapClicked',
				this, 'geoMapMapClicked');
			Obj.connect(
				this.map, 'cameraChanged',
				this, 'saveGeoMapConfiguration');
			Obj.connect(
				this.map, 'interactiveControlModeChanged',
				this, 'mapInteractiveControlModeChanged');
			Obj.connect(
				this.map, 'popupClosed',
				this, 'geoMapPopupClosed');
			Obj.connect(
				this.map, 'styleChanged',
				this, 'geoMapStyleChanged');
			Obj.connect(
				this.map, 'featureSelectionChanged',
				this, 'mapFeatureSelectionChanged');
			Obj.connect(
				this.filterMgr, 'filterCreated',
				this, 'filterCreated');
			Obj.connect(
				this.filterMgr, 'filterCreated',
				this, 'syncCounts');
			Obj.connect(
				this.filterMgr, 'filterUpdated',
				this, 'syncCounts');
			Obj.connect(
				this.filterMgr, 'filterDeleted',
				this, 'syncCounts');
		}
	}

	@SLOT
	private setDocumentForProject(project: IProject): void {
		this.setDocumentTitle(project.title);
		if (this.disabled !== project.readOnly) {
			this.setDisabled(project.readOnly);
		}
	}

	private setDocumentTitle(title: string): void {
		if (title.trim().length > 0) {
			document.title = `${title} - Listblox`;
		} else {
			document.title = 'Listblox';
		}
	}

	private setMapInteractiveControlMode(mode: InteractiveMapControlMode): void {
		this.map.setInteractiveControlMode(mode);
	}

	setSelectedMapFeatures(featureId: number | string | Array<number | string>): void {
		this.map.setSelectedFeatures(featureId);
	}

	private setPrimaryInputText(text: string): void {
		if (this.primaryInput) {
			this.primaryInput.setText(text);
		}
	}

	setFilterListDividerVisible(visible: boolean): void {
		if (this.filterListDivider) {
			this.filterListDivider.setVisible(visible);
		}
	}

	@SLOT
	private async syncCounts(): Promise<void> {
		let canceled: boolean = false;
		if (this.cancelSrc.syncCounts) {
			// Currently awaiting response from previous request.
			// `beginSync()` already called.
			this.cancelSrc.syncCounts.cancel();
		} else {
			this.stats.beginSync();
		}
		this.cancelSrc.syncCounts = svc.cancelTokenSource();
		try {
			const invoice = await this.fetchInvoice(
				true,
				{cancelToken: this.cancelSrc.syncCounts.token});
			// If we're here we  our request was not canceled. We set our
			// values and closeup shop.
			this.stats.setAddressCount(invoice.totalQuantity);
			this.stats.setPrice(invoice.total);
		} catch (exc) {
			if (svc.project.isCancellation(exc)) {
				// Our request was canceled by a subsequent call.
				canceled = true;
			} else {
				throw exc;
			}
		} finally {
			if (!canceled) {
				// This request was not canceled. The values were set above;
				// sync is complete.
				this.cancelSrc.syncCounts = null;
				this.stats.endSync();
			}
		}
	}

	@SLOT
	private async syncDoNotMailGeoRefs(): Promise<void> {
		const objs = await svc.geoRef.geoRefList();
		const mpFeatColl: GeoJsonFeatureCollection<IGeoRefMultiPolygon> = {
			features: [],
			type: 'FeatureCollection',
		};
		const pointFeatColl: GeoJsonFeatureCollection<IGeoRefPoint> = {
			features: [],
			type: 'FeatureCollection',
		};
		for (const obj of objs) {
			if (obj.doNotMail && obj.point) {
				const feat: GeoJsonFeature<IGeoRefPoint> = {
					geometry: obj.point,
					id: obj.id,
					properties: {},
					type: 'Feature',
				};
				pointFeatColl.features.push(feat);
			}
			if (obj.doNotMail && obj.multipolygon) {
				const feat: GeoJsonFeature<IGeoRefMultiPolygon> = {
					geometry: obj.multipolygon,
					id: obj.id,
					properties: {},
					type: 'Feature',
				};
				mpFeatColl.features.push(feat);
			}
		}
		this.map.setDoNotMailSourceData({
			fill: mpFeatColl,
			point: pointFeatColl,
		});
	}

	private async syncDownloadPayButton(project?: IProject): Promise<void> {
		if (!project) {
			project = await this.fetchProject();
		}
		this.downloadWithOwnerNameEnabled = project.downloadWithOwnerNameEnabled;
		let buttonEnabled: boolean;
		if (typeof project.absoluteListUrl === 'string') {
			this.absoluteListUri = project.absoluteListUrl;
			this.downloadPay.showDownloadButton();
			buttonEnabled = true;
		} else {
			this.absoluteListUri = '';
			this.downloadPay.showPayButton();
			buttonEnabled = !project.readOnly;
		}
		this.downloadPay.setButtonEnabled(buttonEnabled);
	}

	private syncMapDrawTrashButtonVisibility(): void {
		setTimeout(() => {
			this.map.setDrawButtonVisible(ButtonId.Trash, this.mapSelectedFeatureIds().length > 0);
		}, 125);
	}

	@SLOT
	private syncParcelInfo(parcelInfoSectionIndex: number, parcel: IParcel, excluded: boolean): void {
		if (this.parcelInfo) {
			this.parcelInfo.setInfo(parcelInfoSectionIndex, parcel);
		}
	}

	@SLOT
	private async syncVisibleParcels(): Promise<void> {
		const pks = await svc.parcel.projectParcelPkList(this.slug);
		this.map.filterParcels(pks);
		if (!this.map.isParcelsVisible()) {
			this.map.setParcelsVisible(true);
		}
	}

	@SLOT
	private async textInputTextChanged(text: string): Promise<void> {
		if (text.trim().length > 0) {
			const places = await this.fetchPlaces(text);
			this.places = new list<IPlace>(places);
			this.setAutoCompleteEnabled(true);
			if (this.autoComplete) {
				const items: Array<IAutoCompleteItem> = [];
				for (const obj of this.places) {
					const labelParts: Array<string> = [
						obj.typeDisplay,
					];
					const city = obj.city.toLocaleLowerCase();
					if (city.length > 0) {
						labelParts.push(`in ${capitalize(city)}`);
						const state = obj.state.toLocaleUpperCase();
						if (state === 'NORTH CAROLINA') {
							labelParts.push(`, NC`);
						}
					}
					items.push({
						label: labelParts.join(' '),
						text: obj.name,
					});

				}
				this.autoComplete.setItems(items);
			}
		} else {
			this.setAutoCompleteEnabled(false);
		}
	}

	@SLOT
	private undoViewActionActivated(action: 'undo' | 'redo'): void {
		switch (action) {
			case 'undo':
				this.undoStack.undo();
				break;
			case 'redo':
				this.undoStack.redo();
				break;
		}
	}

	@SLOT
	private updateAutoCompletePosition(): void {
		if (this.autoComplete) {
			if (this.primaryInput) {
				const bodyAbsY = Math.abs(ElObj.body().rect().y);
				const {height, width, x, y} = this.primaryInput.rect();
				this.autoComplete.setStyleProperty('top', pixelString(y + height + bodyAbsY));
				this.autoComplete.setStyleProperty('left', pixelString(x - 1));
				this.autoComplete.setStyleProperty('width', pixelString(width));
			} else {
				logger.warning('updateAutoCompletePosition: Primary input not found.');
			}
		}
	}

	private async updateGeoMapConfiguration(pk: number, data: Partial<Omit<IGeoMapConfiguration, 'id' | 'imageId' | 'imageUrl'>>): Promise<IGeoMapConfiguration> {
		return await svc.map.update({id: pk, ...data});
	}

	private async updateProject(data: IProject): Promise<IProject> {
		return await svc.project.update(data);
	}

	@bind
	private windowEvent(event: Event): void {
		switch (event.type) {
			case 'resize': {
				this.windowResizeEvent();
				break;
			}
		}
	}

	private windowResizeEvent(): void {
		if (this.isAutoCompleteOpen()) {
			this.updateAutoCompletePosition();
		}
	}
}

function geoMapConfigurationToMapOpts(cfg: Omit<IGeoMapConfiguration, 'id'>): IMapOpts {
	return {
		bearing: cfg.bearing,
		// Remember: center is [longitude, latitude]
		center: [cfg.longitude, cfg.latitude],
		pitch: cfg.pitch,
		style: cfg.styleIdentifier,
		zoom: cfg.zoom,
	};
}

function mapOptsToGeoMapConfiguration(opts: IMapOpts): Omit<IGeoMapConfiguration, 'id' | 'imageId' | 'imageUrl'> {
	return {
		bearing: opts.bearing,
		// Remember: center is [longitude, latitude]
		latitude: opts.center[1],
		longitude: opts.center[0],
		pitch: opts.pitch,
		styleIdentifier: opts.style,
		zoom: opts.zoom,
	};
}

function filterDoNotMailGeoRefs(objs: Iterable<IGeoRef>): Array<IGeoRef> {
	const rv: Array<IGeoRef> = [];
	for (const obj of objs) {
		if (obj.doNotMail) {
			rv.push(obj);
		}
	}
	return rv;
}

function filterMarkOnMapGeoRefs(objs: Iterable<IGeoRef>): Array<IGeoRef> {
	const rv: Array<IGeoRef> = [];
	for (const obj of objs) {
		if (obj.showOnMap) {
			rv.push(obj);
		}
	}
	return rv;
}
