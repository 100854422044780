import mapboxgl from 'mapbox-gl';

import {GeoMap, MapCtrlEventType} from '../../../ui/map';
import {AbstractObj, Obj, OBJ, SLOT} from '../../../obj';
import {DRAW_MAP_LAYER_ID_PREFIX} from '../../../ui/map/draw/constants';
import {Color as LayerColor, Id as LayerId, layers} from './layer';
import {Id as SourceId, sources} from './source';
import {bind} from '../../../util';
import {MapStyle, StyleControl} from '../../../ui/map/controls';
import {InteractiveMapControlMode} from '../../../constants';

interface IFeatureHoverInfo {
	id: number | string | undefined;
	source: string,
	sourceLayer: string | undefined;
}

@OBJ
export class ProjectDetailGeoMap extends GeoMap {
	private hoverInfo: IFeatureHoverInfo | null;
	private parcelLayerExplicitlySetPks: Array<ParcelPk> | null;
	private parcelLayerExplicitlySetVisibility: boolean | null;
	private slug: string;

	constructor(parent: AbstractObj, debug: boolean, slug: string) {
		super(parent, debug);
		this.hoverInfo = null;
		this.parcelLayerExplicitlySetPks = null;
		this.parcelLayerExplicitlySetVisibility = null;
		this.slug = slug;
		Obj.connect(
			this, 'styleChanged',
			this, '_styleChanged');
	}

	destroy(): void {
		this.parcelLayerExplicitlySetPks = null;
		this.parcelLayerExplicitlySetVisibility = null;
		super.destroy();
	}

	private filterLayerMouseEnterEvent(event: mapboxgl.MapMouseEvent): void {
		event.target.getCanvasContainer().classList.add('cursor--pointer');
	}

	private filterLayerMouseLeaveEvent(event: mapboxgl.MapMouseEvent): void {
		event.target.getCanvasContainer().classList.remove('cursor--pointer');
	}

	filterParcels(pks: Array<ParcelPk> | null): void {
		if (this._filterParcels(pks)) {
			this.parcelLayerExplicitlySetPks = pks;
		}
	}

	private _filterParcels(pks: Array<ParcelPk> | null): boolean {
		if (this.map) {
			this.map.setFilter(
				LayerId.ParcelFill,
				Array.isArray(pks) ?
					[
						'in',
						[
							'get',
							'area_pk',
						],
						[
							'literal',
							pks,
						],
					] :
					null);
			return true;
		}
		return false;
	}

	private isLayerVisible(layerId: string): boolean {
		return this.layerVisibility(layerId) !== 'none';
	}

	isParcelsVisible(): boolean {
		return this.isLayerVisible(LayerId.ParcelFill);
	}

	private layerVisibility(layerId: string): string {
		return this.map ?
			this.map.getLayoutProperty(layerId, 'visibility') || '' :
			'';
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
		for (let i = 0; i < sources.length; ++i) {
			this.addSource(sources[i].id, sources[i].source);
		}
		for (let i = 0; i < layers.length; ++i) {
			this.addLayer(layers[i]);
		}
		// Called here so above layers, sources may be defined before super
		// class attempts to load any pending source data.
		super.mapLoadEvent(event);
		this._setLayerColor(this._currentStyleHasDarkBackground());
		event.target.on(
			MapCtrlEventType.MouseMove,
			LayerId.DoNotMailParcelFill,
			this.layerMouseMoveEvent);
		event.target.on(
			MapCtrlEventType.MouseLeave,
			LayerId.DoNotMailParcelFill,
			this.layerMouseLeaveEvent);
		event.target.on(
			MapCtrlEventType.MouseMove,
			LayerId.ParcelFill,
			this.layerMouseMoveEvent);
		event.target.on(
			MapCtrlEventType.MouseLeave,
			LayerId.ParcelFill,
			this.layerMouseLeaveEvent);
		event.target.on(
			MapCtrlEventType.MouseEnter,
			LayerId.FilterFill,
			this.filterLayerMouseEnterEvent);
		event.target.on(
			MapCtrlEventType.MouseLeave,
			LayerId.FilterFill,
			this.filterLayerMouseLeaveEvent);
	}

	@bind
	private layerMouseLeaveEvent(event: mapboxgl.MapMouseEvent): void {
		if (this.hoverInfo) {
			event.target.setFeatureState(
				{
					id: this.hoverInfo.id,
					source: this.hoverInfo.source,
					sourceLayer: this.hoverInfo.sourceLayer,
				},
				{
					hover: false,
				});
			this.hoverInfo = null;
		}
	}

	@bind
	private layerMouseMoveEvent(event: mapboxgl.MapLayerMouseEvent): void {
		if (event.features && event.features.length > 0) {
			if (this.hoverInfo) {
				event.target.setFeatureState(
					{
						id: this.hoverInfo.id,
						source: this.hoverInfo.source,
						sourceLayer: this.hoverInfo.sourceLayer,
					},
					{
						hover: false,
					});
			}
			this.hoverInfo = {
				id: event.features[0].id,
				source: event.features[0].source,
				sourceLayer: event.features[0].sourceLayer,
			};
			event.target.setFeatureState(
				{
					id: this.hoverInfo.id,
					source: this.hoverInfo.source,
					sourceLayer: this.hoverInfo.sourceLayer,
				},
				{
					hover: true,
				});
		}
	}

	setDoNotMailSourceData(data: {fill: GeoJsonFeature | GeoJsonFeatureCollection, point: GeoJsonFeature | GeoJsonFeatureCollection}): void {
		this.setSourceData(SourceId.DoNotMailParcel, data.fill);
		this.setSourceData(SourceId.DoNotMailParcelCentroid, data.point);
	}

	setFilterSourceData(data: GeoJsonFeature | GeoJsonFeatureCollection): void {
		this.setSourceData(SourceId.Filter, data);
	}

	private _setLayerColor(light: boolean): void {
		if (this.map) {
			this.map.setPaintProperty(
				LayerId.ParcelFill,
				'fill-color',
				light ?
					LayerColor.ParcelFillLight :
					LayerColor.ParcelFillDark);
		}
	}

	setParcelsVisible(visible: boolean): void {
		if (this.map) {
			const v = visible ? 'visible' : 'none';
			this.map.setLayoutProperty(LayerId.ParcelFill, 'visibility', v);
			this.map.setLayoutProperty(LayerId.ParcelLine, 'visibility', v);
			this.parcelLayerExplicitlySetVisibility = visible;
		}
	}

	@SLOT
	private _styleChanged(newStyleUrl: string): void {
		this._setLayerColor(this._currentStyleHasDarkBackground());
		this._filterParcels(this.parcelLayerExplicitlySetPks);
		if (this.parcelLayerExplicitlySetVisibility === true) {
			this.setParcelsVisible(true);
		}
	}

	private _currentStyleHasDarkBackground(): boolean {
		const ctrl = <StyleControl | null>this.controlForMode(
			InteractiveMapControlMode.StylePickerMode);
		const styleUrl = ctrl ?
			ctrl.styleUrl() :
			'';
		return styleUrl === StyleControl.styleUrlForMapStyle(MapStyle.Satellite);
	}
}

function isDrawLayerId(layerId: string): boolean {
	return layerId.startsWith(DRAW_MAP_LAYER_ID_PREFIX);
}
