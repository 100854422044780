import {OBJ} from '../../obj';
import {Table, TableItem, TableItemArgs, tableItemOpts, TableItemOpts, TableOpts} from './table';
import {ElObj, elObjOpts, ElObjOpts} from '../../elobj';
import {iterableToArray, stringIterableToStringArray} from '../../util';
import {getLogger} from '../../logging';
import type {Variant} from '../../variant';
import {ItemDataRole} from '../../constants';

const logger = getLogger('ui.table');

@OBJ
export class FancyTable extends Table {
	container: TableContainerEl;

	constructor(opts: Partial<TableOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<TableOpts> | null, tagName?: TagName);
	constructor(opts: Partial<TableOpts> | null, root?: Element | null);
	constructor(opts: Partial<TableOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<TableOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<TableOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<TableOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table',
			'lb-data-table',
			...classNames,
		];
		if (!opts.horizontalHeaderItemPrototype) {
			opts.horizontalHeaderItemPrototype = new TableHeadCellEl();
		}
		if (!opts.itemPrototype) {
			opts.itemPrototype = new TableCellEl();
		}
		if (!opts.verticalHeaderItemPrototype) {
			opts.verticalHeaderItemPrototype = new TableRowEl();
		}
		opts.tagName = 'div';
		super(opts);
		this.container = new TableContainerEl({
			parent: this,
		});
	}

	setHorizontalHeaderItem(column: number, item: TableItem | null): void {
		super.setHorizontalHeaderItem(column, item);
		if (item && (item instanceof TableHeadCellEl)) {
			this.container.table.thead.row.insertChild(column, item);
		}
	}

	setItem(row: number, column: number, item: TableItem | null): void {
		super.setItem(row, column, item);
		if (item && (item instanceof TableCellEl)) {
			const rowItem = this.verticalHeaderItem(row);
			if (rowItem) {
				rowItem.insertChild(column, item);
			} else {
				logger.warning('setItem: Setting item with no header defined.');
			}
		}
	}

	setVerticalHeaderItem(row: number, item: TableItem | null): void {
		super.setVerticalHeaderItem(row, item);
		if (item && (item instanceof TableRowEl)) {
			this.container.table.tbody.insertChild(row, item);
		}
	}
}

@OBJ
export class TableCellEl extends TableItem {
	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts(a, b);
		const classNames = args.opts.classNames ?
			stringIterableToStringArray(args.opts.classNames) :
			[];
		args.opts.classNames = [
			'mdc-data-table__cell',
			...classNames,
		];
		args.opts.tagName = 'td';
		super(args);
	}

	setData(role: number, value: Variant): void {
		super.setData(role, value);
		if (role === ItemDataRole.DisplayRole) {
			this._setText(value.toString());
		}
	}

	protected setDataForRole(role: number, value: Variant): void {
		switch (role) {
			case ItemDataRole.DisplayRole:
				this._setText(value.toString());
				break;
			default:
				super.setDataForRole(role, value);
				break;
		}
	}
}

@OBJ
export class TableContainerEl extends ElObj {
	table: TableEl;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table__table-container',
			...classNames,
		];
		opts.tagName = 'div';
		super(opts);
		this.table = new TableEl({
			parent: this,
		});
	}

	destroy(): void {
		this.table.destroy();
		super.destroy();
	}
}

@OBJ
export class TableRowEl extends TableItem {
	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts(a, b);
		const classNames = args.opts.classNames ?
			stringIterableToStringArray(args.opts.classNames) :
			[];
		args.opts.classNames = [
			'mdc-data-table__row',
			...classNames,
		];
		args.opts.tagName = 'tr';
		super(args);
	}
}

@OBJ
export class TableEl extends ElObj {
	tbody: TableBodyEl;
	thead: TableHeadEl;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table__table',
			...classNames,
		];
		opts.tagName = 'table';
		super(opts);
		this.thead = new TableHeadEl({
			parent: this,
		});
		this.tbody = new TableBodyEl({
			parent: this,
		});
	}

	destroy(): void {
		this.thead.destroy();
		this.tbody.destroy();
		super.destroy();
	}
}

@OBJ
export class TableBodyEl extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table__content',
			...classNames,
		];
		opts.tagName = 'tbody';
		super(opts);
	}
}

@OBJ
export class TableHeadCellEl extends TableItem {
	constructor(text: string, opts?: Partial<TableItemOpts>);
	constructor(other: TableItem, opts?: Partial<TableItemOpts>);
	constructor(text?: string);
	constructor(other?: TableItem);
	constructor(opts?: Partial<TableItemOpts>);
	constructor(args?: TableItemArgs);
	constructor(a?: TableItem | TableItemArgs | string | Partial<TableItemOpts>, b?: Partial<TableItemOpts>) {
		const args = tableItemOpts(a, b);
		const attributes = args.opts.attributes ?
			iterableToArray(args.opts.attributes) :
			[];
		args.opts.attributes = [
			['role', 'columnheader'],
			['scope', 'col'],
			...attributes,
		];
		const classNames = args.opts.classNames ?
			stringIterableToStringArray(args.opts.classNames) :
			[];
		args.opts.classNames = [
			'mdc-data-table__header-cell',
			...classNames,
		];
		args.opts.tagName = 'th';
		super(args);
	}

	protected setDataForRole(role: number, value: Variant): void {
		switch (role) {
			case ItemDataRole.DisplayRole:
				this._setText(value.toString());
				break;
			default:
				super.setDataForRole(role, value);
				break;
		}
	}
}

@OBJ
export class TableHeadEl extends ElObj {
	row: TableHeadRowEl;

	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		opts.tagName = 'thead';
		super(opts);
		this.row = new TableHeadRowEl({
			parent: this,
		});
	}

	destroy(): void {
		this.row.destroy();
		super.destroy();
	}
}

@OBJ
export class TableHeadRowEl extends ElObj {
	constructor(opts: Partial<ElObjOpts> | null, tagName: TagName, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, root: Element | null, parent?: ElObj | null);
	constructor(tagName: TagName, parent?: ElObj | null);
	constructor(root: Element | null, parent?: ElObj | null);
	constructor(opts: Partial<ElObjOpts> | null, tagName?: TagName);
	constructor(opts: Partial<ElObjOpts> | null, root?: Element | null);
	constructor(opts: Partial<ElObjOpts>, parent?: ElObj | null);
	constructor(opts?: Partial<ElObjOpts>);
	constructor(root?: Element | null);
	constructor(tagName?: TagName);
	constructor(parent?: ElObj | null);
	constructor(a?: Partial<ElObjOpts> | ElObj | Element | TagName | null, b?: ElObj | Element | TagName | null, c?: ElObj | null) {
		const opts = elObjOpts<ElObjOpts>(a, b, c);
		const classNames = opts.classNames ?
			stringIterableToStringArray(opts.classNames) :
			[];
		opts.classNames = [
			'mdc-data-table__header-row',
			...classNames,
		];
		opts.tagName = 'tr';
		super(opts);
	}
}
