import {
	MapMouseEvent,
	MapTouchEvent,
	MapDataEvent,
	Map as MapboxMap,
	MapboxGeoJSONFeature,
} from 'mapbox-gl';

import {Mode} from './modes/mode';
import {IMapboxDrawContext} from './mapboxdraw';
import {IMapClass} from './ui';
import {bind, euclideanDistance, isTap} from '../../../util';
import {getLogger} from '../../../logging';
import {
	featuresAtTouch,
	featuresAtClick,
} from './featuresat';
import {
	EvtType,
	DrawMode,
	Cursor,
	DrawEventType,
} from './constants';

const logger = getLogger('mapdraw');

export class Evt {
	needsRender: boolean;
	type: EvtType;

	constructor(type: EvtType) {
		this.needsRender = true;
		this.type = type;
	}
}

export class FeatureEvt extends Evt {
	featureTarget: MapboxGeoJSONFeature | null;

	constructor(type: EvtType, featureTarget: MapboxGeoJSONFeature | null) {
		super(type);
		this.featureTarget = featureTarget;
	}
}

export class KeyboardEvt extends Evt {
	event: KeyboardEvent;

	constructor(type: EvtType, event: KeyboardEvent) {
		super(type);
		this.event = event;
	}
}

export class MapMouseEvt extends Evt {
	event: MapMouseEvent;

	constructor(type: EvtType, event: MapMouseEvent) {
		super(type);
		this.event = event;
	}
}

export class MapTouchEvt extends Evt {
	event: MapTouchEvent;

	constructor(type: EvtType, event: MapTouchEvent) {
		super(type);
		this.event = event;
	}
}

export class MapMouseFeatureEvt extends MapMouseEvt implements FeatureEvt {
	featureTarget: MapboxGeoJSONFeature | null;

	constructor(type: EvtType, event: MapMouseEvent, featureTarget: MapboxGeoJSONFeature | null) {
		super(type, event);
		this.featureTarget = featureTarget;
	}
}

export class MapTouchFeatureEvt extends MapTouchEvt implements FeatureEvt {
	featureTarget: MapboxGeoJSONFeature | null;

	constructor(type: EvtType, event: MapTouchEvent, featureTarget: MapboxGeoJSONFeature | null) {
		super(type, event);
		this.featureTarget = featureTarget;
	}
}

export class MouseEvt extends Evt {
	event: MouseEvent;

	constructor(type: EvtType, event: MouseEvent) {
		super(type);
		this.event = event;
	}
}

export class EventThing {
	ctx!: IMapboxDrawContext; // Set where instantiated just after construction
	private currMode: Mode | null;
	private enabled: boolean;
	private mouseDownInfo: IPointerEventInfo;
	private touchStartInfo: IPointerEventInfo;

	constructor() {
		this.currMode = null;
		this.enabled = false;
		this.mouseDownInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
		this.touchStartInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
	}

	private addEventListeners(): void {
		const map = this.ctx.map;
		if (map) {
			[
				'mousemove',
				'mousedown',
				'mouseup',
				'data',
				'touchmove',
				'touchstart',
				'touchend',
			].forEach(eventType => map.on(eventType, this.event));
		}
		const cont = this.ctx.container;
		if (cont) {
			cont.addEventListener('mouseout', this.event);
			cont.addEventListener('keydown', this.event);
			cont.addEventListener('keyup', this.event);
		}
	}

	changeMode(modeName: string, nextModeOptions?: ModeOpt | {[key: string]: any}, eventOptions?: InternalEventOpt): void {
		if (!this.enabled || (modeName === this.currentModeName())) {
			return;
		}
		this.destroyCurrentMode();
		if (modeName && this.ctx.options.modes.hasOwnProperty(modeName)) {
			this.currMode = new this.ctx.options.modes[modeName](this.ctx);
			this.currMode.start();
			this.currMode.setup(nextModeOptions);
		} else {
			logger.warning('changeMode: Got invalid mode ID %s', modeName);
			this.currMode = null;
		}
		if (this.ctx.map && (!eventOptions || !eventOptions.silent)) {
			this.ctx.map.fire(DrawEventType.ModeChange, {mode: modeName});
		}
		this.ctx.store.setDirty(true);
		this.ctx.store.render();
	}

	combineFeatures(): void {
		this.currMode && this.currMode.combineFeatures();
	}

	currentModeName(): string {
		return this.currMode ?
			this.currMode.name :
			'';
	}

	currentModeRender(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		this.currMode && this.currMode.render(feature, display);
	}

	private dataEvent(event: MapDataEvent): void {
		const map: MapboxMap | null = this.ctx.map;
		if ((event.dataType === 'style') && map) {
			const hasLayers: boolean = this.ctx.options.styles.some(style => map.getLayer(style.id));
			if (!hasLayers) {
				this.ctx.api.addLayers();
				this.ctx.store.setDirty(true);
				this.ctx.store.render();
			}
		}
	}

	private destroyCurrentMode(): void {
		const currMode = this.currMode;
		this.currMode = null;
		if (currMode) {
			currMode.stop();
		}
	}

	@bind
	private event(event: {type: string}): void {
		switch (event.type) {
			case 'mousemove':
				this.mouseMoveEvent(<MapMouseEvent>event);
				break;
			case 'touchmove':
				this.touchMoveEvent(<MapTouchEvent>event);
				break;
			case 'data':
				this.dataEvent(<MapDataEvent>event);
				break;
			case 'mousedown':
				this.mouseDownEvent(<MapMouseEvent>event);
				break;
			case 'mouseup':
				this.mouseUpEvent(<MapMouseEvent>event);
				break;
			case 'mouseout':
				this.mouseOutEvent(<MouseEvent>event);
				break;
			case 'touchstart':
				this.touchStartEvent(<MapTouchEvent>event);
				break;
			case 'touchend':
				this.touchEndEvent(<MapTouchEvent>event);
				break;
			case 'keydown':
				this.keyDownEvent(<KeyboardEvent>event);
				break;
			case 'keyup':
				this.keyUpEvent(<KeyboardEvent>event);
				break;
		}
	}

	isEnabled(): boolean {
		return this.enabled;
	}

	private keyDownEvent(event: KeyboardEvent): void {
		const tgt: EventTarget | null = event.target;
		if (tgt && (tgt instanceof Element) && (tgt.classList.length > 0) && (tgt.classList[0] !== 'mapboxgl-canvas')) {
			return;
		} // we only handle events on the map
		const key: string = event.key;
		switch (key) {
			case 'Backspace':
			case 'Delete':
				if (this.ctx.options.controls.trash && this.currMode) {
					event.preventDefault();
					this.currMode.trash();
				}
				break;
		}
		if (isKeyModeValid(key) && this.currMode) {
			this.currMode.event(new KeyboardEvt(EvtType.KeyPress, event));
		}
		switch (key) {
			case '1':
				if (this.ctx.options.controls.point) {
					this.changeMode(DrawMode.DrawPoint);
					break;
				}
				break;
			case '2':
				if (this.ctx.options.controls.line_string) {
					this.changeMode(DrawMode.DrawLineString);
					break;
				}
				break;
			case '3':
				if (this.ctx.options.controls.polygon) {
					this.changeMode(DrawMode.DrawPolygon);
					break;
				}
				break;
		}
	}

	private keyUpEvent(event: KeyboardEvent): void {
		if (this.currMode && isKeyModeValid(event.key)) {
			this.currMode.event(new KeyboardEvt(EvtType.KeyRelease, event));
		}
	}

	private mouseDownEvent(event: MapMouseEvent): void {
		this.mouseDownInfo = {
			time: Date.now(),
			point: event.point,
		};
		if (this.currMode) {
			this.currMode.event(
				new MapMouseFeatureEvt(
					EvtType.MouseButtonPress,
					event,
					getFeatureAndSetCursor(event.point, this.ctx)));
		}
	}

	private mouseDragEvent(event: MapMouseEvent, currMode: Mode): void {
		if (isClick(this.mouseDownInfo, {point: event.point, time: Date.now()})) {
			event.originalEvent.stopPropagation();
		} else {
			this.ctx.ui.queueMapClasses({mouse: Cursor.Drag});
			currMode.event(new MapMouseEvt(EvtType.MouseDrag, event));
		}
	}

	private mouseMoveEvent(event: MapMouseEvent): void {
		if (this.currMode) {
			if (event.originalEvent.buttons === 1) {
				this.mouseDragEvent(event, this.currMode);
			} else {
				this.currMode.event(
					new MapMouseFeatureEvt(
						EvtType.MouseMove,
						event,
						getFeatureAndSetCursor(event.point, this.ctx)));
			}
		}
	}

	private mouseOutEvent(event: MouseEvent): void {
		if (this.currMode) {
			this.currMode.event(new MouseEvt(EvtType.Leave, event));
		}
	}

	private mouseUpEvent(event: MapMouseEvent): void {
		if (this.currMode) {
			const typ = isClick(this.mouseDownInfo, {point: event.point, time: Date.now()}) ?
				EvtType.MouseButtonClick :
				EvtType.MouseButtonRelease;
			this.currMode.event(
				new MapMouseFeatureEvt(
					typ,
					event,
					getFeatureAndSetCursor(event.point, this.ctx)));
		}
	}

	private removeEventListeners(): void {
		const map = this.ctx.map;
		if (map) {
			[
				'mousemove',
				'mousedown',
				'mouseup',
				'data',
				'touchmove',
				'touchstart',
				'touchend',
			].forEach(eventType => map.off(eventType, this.event));
		}
		const cont = this.ctx.container;
		if (cont) {
			cont.removeEventListener('mouseout', this.event);
			if (this.ctx.options.keybindings) {
				cont.removeEventListener('keydown', this.event);
				cont.removeEventListener('keyup', this.event);
			}
		}
	}

	setEnabled(enable: boolean): void {
		if (enable === this.enabled) {
			return;
		}
		if (enable) {
			this.addEventListeners();
		} else {
			this.destroyCurrentMode();
			this.changeMode(
				DrawMode.SimpleSelect,
				undefined,
				{silent: false}); // FIXME: Was true. Does it work correctly?
			this.removeEventListeners();
		}
		// Set this after the above procedure returns.
		this.enabled = enable;
	}

	private touchDragEvent(event: MapTouchEvent, currMode: Mode): void {
		if (isTap(this.touchStartInfo, {point: event.point, time: Date.now()})) {
			event.originalEvent.stopPropagation();
		} else {
			this.ctx.ui.queueMapClasses({mouse: Cursor.Drag});
			currMode.event(new MapTouchEvt(EvtType.TouchDrag, event));
		}
	}

	private touchEndEvent(event: MapTouchEvent): void {
		if (this.ctx.options.touchEnabled && this.currMode) {
			event.originalEvent.preventDefault();
			const tgts: Array<MapboxGeoJSONFeature> = featuresAtTouch(
				event.point,
				null,
				this.ctx);
			const typ = isTap(this.touchStartInfo, {time: Date.now(), point: event.point}) ?
				EvtType.TouchTap :
				EvtType.TouchEnd;
			this.currMode.event(
				new MapTouchFeatureEvt(
					typ,
					event,
					(tgts.length > 0) ?
						tgts[0] :
						null));
		}
	}

	private touchMoveEvent(event: MapTouchEvent): void {
		if (this.ctx.options.touchEnabled && this.currMode) {
			event.originalEvent.preventDefault();
			this.currMode.event(
				new MapTouchEvt(
					EvtType.TouchUpdate,
					event));
			this.touchDragEvent(event, this.currMode);
		}
	}

	private touchStartEvent(event: MapTouchEvent): void {
		this.touchStartInfo = {
			time: Date.now(),
			point: event.point,
		};
		if (this.ctx.options.touchEnabled && this.currMode) {
			// Prevent emulated mouse events because we will fully handle the touch here.
			// This does not stop the touch events from propagating to mapbox though.
			event.originalEvent.preventDefault();
			const tgts: Array<MapboxGeoJSONFeature> = featuresAtTouch(
				event.point,
				null,
				this.ctx);
			this.currMode.event(
				new MapTouchFeatureEvt(
					EvtType.TouchBegin,
					event,
					(tgts.length > 0) ?
						tgts[0] :
						null));
		}
	}

	trash(): void {
		this.currMode && this.currMode.trash();
	}

	uncombineFeatures(): void {
		this.currMode && this.currMode.uncombineFeatures();
	}
}

function isKeyModeValid(key: string): boolean {
	switch (key) {
		case 'Backspace':
		case 'Delete':
		case '0':
		case '1':
		case '2':
		case '3':
		case '4':
		case '5':
		case '6':
		case '7':
		case '8':
		case '9':
			return false;
	}
	return true;
}

function getFeatureAndSetCursor(point: IGenericPoint, ctx: IMapboxDrawContext): MapboxGeoJSONFeature | null {
	const features = featuresAtClick(point, null, ctx);
	const classes: Partial<IMapClass> = {mouse: Cursor.None};
	if (features.length > 0) {
		classes.mouse = (features[0].properties && (features[0].properties.active === 'true')) ?
			Cursor.Move :
			Cursor.Pointer;
		classes.feature = features[0].properties && features[0].properties.meta;
	}
	if (ctx.events.currentModeName().indexOf('draw') !== -1) {
		classes.mouse = Cursor.Add;
	}
	ctx.ui.queueMapClasses(classes);
	ctx.ui.updateMapClasses();
	return features[0] || null;
}

function isClick(startInfo: IPointerEventInfo, endInfo: IPointerEventInfo): boolean {
	const FINE_TOLERANCE = 4;
	const GROSS_TOLERANCE = 12;
	const INTERVAL = 500;
	startInfo.point = startInfo.point || endInfo.point;
	startInfo.time = startInfo.time || endInfo.time;
	const moveDistance = euclideanDistance(startInfo.point, endInfo.point);
	return (moveDistance < FINE_TOLERANCE) || ((moveDistance < GROSS_TOLERANCE) && ((endInfo.time - startInfo.time) < INTERVAL));
}

export function isMapMouseEvent(event: any): event is MapMouseEvent {
	return event.originalEvent instanceof MouseEvent;
}
