import {Mode} from './mode';
import {Point} from '../features/point';
import {
	KeyboardEvt,
	MapMouseFeatureEvt,
	MapTouchFeatureEvt,
} from '../events';
import {
	DrawMode,
	Cursor,
	DrawEventType,
} from '../constants';

export class DrawPoint extends Mode {
	name = DrawMode.DrawPoint;
	state!: Point;

	protected keyPressEvent(evt: KeyboardEvt) {
		if ((evt.event.key === 'Escape') || (evt.event.key === 'Enter')) {
			this.stopDrawingAndRemove();
		}
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt) {
		this.touchTapMouseClickEvent(evt.event.lngLat);
	}

	setup(options?: any): void {
		super.setup(options);
		this.state = this.newFeature({
			geometry: {
				coordinates: [],
				type: 'Point',
			},
			properties: {},
			type: 'Feature',
		});
		this.addFeature(this.state);
		this.clearSelectedFeatures();
		this.updateUIClasses({mouse: Cursor.Add});
		this.activateUIButton('point');
	}

	stop(): void {
		this.ctx.ui.deactivateButtons();
		if (this.state.getCoordinate().length < 1) {
			this.deleteFeature([this.state.id], {silent: true});
		}
	}

	stopDrawingAndRemove(): void {
		this.deleteFeature([this.state.id], {silent: true});
		this.changeMode(DrawMode.SimpleSelect);
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		// Never render the point we're drawing
		const isActivePoint = feature.properties.id === this.state.id;
		feature.properties.active = isActivePoint ?
			'true' :
			'false';
		if (!isActivePoint) {
			display(feature);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt): void {
		this.touchTapMouseClickEvent(evt.event.lngLat);
	}

	private touchTapMouseClickEvent(lngLat: {lng: number; lat: number;}): void {
		this.updateUIClasses({mouse: Cursor.Move});
		this.state.updateCoordinate('', lngLat.lng, lngLat.lat);
		this.fireEvent(DrawEventType.Create,
			{features: [this.state.toGeoJSON()]});
		this.changeMode(DrawMode.SimpleSelect, {featureIds: [this.state.id]});
	}

	trash(): void {
		this.stopDrawingAndRemove();
		super.trash();
	}
}
